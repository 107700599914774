import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  getCampaignNewData as onGetNewData,
  getCampaign as onGet,
  updateCampaignNewData as onUpdate,
  postCampaignNewData as onPost, 
  clearCampaign as onClearCampaign,
  campaignSuccess as onSuccess,
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { ErrorMessage, useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"
import InputMask from "react-input-mask"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import img_empty from "./../../../assets/images/jobs.png"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import { Role, authorize } from "helpers/authorize"
import parse from 'html-react-parser';

// import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js"
// import draftToHtml from "draftjs-to-html"
// import htmlToDraft from "html-to-draftjs"

const PuankListUserHistory = props => {
  //meta title
  document.title = props.t("Puank List History")

  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const [data, setData] = useState([])
  const [nData, setNData] = useState()
  const [list, setList] = useState()
  const [listOptions, setListOptions] = useState(null)
  const [loading2, setLoading2] = useState(false)
  const [calculateStatus, setCalculateStatus] = useState(null)
  const [listParameters, setListParameters] = useState({
    orderBy: "createdAt",
    ascDesc: -1,
    limit: 10,
    page: 0,
    onlyWait: false,
  })
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const [text, setText] = useState()

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE

  const [imageZoom, setimageZoom] = useState([img_empty])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Profile"), link: `/profile` },
    { title: props.t("Puank List History"), link: "#" },
  ]

  const dispatch = useDispatch()
  const { error } = useSelector(state => ({
    error: state.Campaigns?.error,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    GetList()
  }, [listParameters])

  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Campaign_Continues")}
          </span>
        )
      case "wait":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Wait")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Campaign_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Campaign_Finish")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-light">
            {props.t("Campaign_Other")}
          </span>
        )
    }
  }

  const dataStatusText = status => {
    switch (status) {
      case "wait":
        return (
          <span className="badge badge-soft-warning">{props.t("wait")}</span>
        )
      case "confirmed":
        return (
          <span className="badge badge-soft-success">
            {props.t("confirmed")}
          </span>
        )
      case "rejected":
        return (
          <span className="badge badge-soft-danger">{props.t("rejected")}</span>
        )
      default:
        return (
          <span className="badge badge-soft-danger">
            {props.t("new-data-status-other")}
          </span>
        )
    }
  }

  const puankType = status => {
    switch (status) {
      case "remove":
        return (
          <span className="badge badge-soft-warning">
            {props.t("puank_remove")}
          </span>
        )
      case "add":
        return (
          <span className="badge badge-soft-success">
            {props.t("puank_add")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-danger">
            {props.t("puank_other")}
          </span>
        )
    }
  }

  const TermTypeDesc = type =>
    type == "1"
      ? "Her Alışverişte Geçerli"
      : type == "2"
      ? "X Adet Sat Toplam Y PuanK Kazan"
      : ""

  const TermType = type =>
    type == "1"
      ? "Personel Hedefi"
      : type == "2"
      ? "Şube Hedefi"
      : type == 3
      ? "Şirket Hedefi"
      : ""

  const TermTypeLong_Step1 = (type, startCount, endCount) =>
    type == "1"
      ? endCount == null
        ? `Personellerin ${startCount} adetten fazla satışında aşağıdaki PuanK'lar Kazanılır`
        : `Personellerin ${startCount}-${endCount} adet arasındaki satışında aşağıdaki PuanK'lar Kazanılır`
      : type == "2"
      ? endCount == null
        ? `Şubenin toplam ${startCount} adetten fazla satışında aşağıdaki PuanK'lar Kazanılır`
        : `Şubenin toplam ${startCount}-${endCount} adet arasındaki satışında aşağıdaki PuanK'lar Kazanılır`
      : type == 3
      ? endCount == null
        ? `Şirketin toplam ${startCount} adetten fazla satışında aşağıdaki PuanK'lar Kazanılır`
        : `Şirketin toplam ${startCount}-${endCount} adet arasındaki satışında aşağıdaki PuanK'lar Kazanılır`
      : ""

  const TermTypeLong_Step2 = (type, count) =>
    type == "1"
      ? `Personellerin her ${count} satışı için aşağıdaki PuanK'lar Kazanılır`
      : type == "2"
      ? `Şubenin her ${count} satışı için aşağıdaki PuanK'lar Kazanılır`
      : type == 3
      ? `Şirketin her ${count} satışı için aşağıdaki PuanK'lar Kazanılır`
      : ""

  const GetList = async () => {
    setLoading2(true)
    let data = JSON.stringify(listParameters)

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/api/puank/list-user-history`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
      },
      data: data,
    }

    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          let p = response.data.data
          setList(p)
          let pageCount = response.data.pageCount
          let paging = [1]
          if (pageCount > 1 && pageCount < 8) {
            for (let i = 2; i <= pageCount; i++) {
              paging.push(i)
            }
          } else if (
            pageCount > 1 &&
            pageCount >= 8 &&
            (listParameters.page == 0 || listParameters.page == pageCount - 1)
          ) {
            let center = pageCount / 2
            paging.push(center - 1)
            paging.push(center)
            paging.push(center + 1)
            paging.push(pageCount)
          } else if (pageCount > 1 && pageCount >= 8) {
            let center = listParameters.page + 1
            if (!paging.includes(center - 1)) paging.push(center - 1)
            if (!paging.includes(center)) paging.push(center)
            if (!paging.includes(center + 1)) paging.push(center + 1)
            if (!paging.includes(pageCount)) paging.push(pageCount)
          }

          setListOptions({
            productCount: response.data.productCount,
            pageCount: response.data.pageCount,
            productTotal: response.data.productTotal,
            paging: paging,
            totalPuank: response.data.totalPuank,
          })
        } else {
          setList(null)
          setListOptions(null)
          Swal.fire("Hata", response.data.message, "error")
        }
        setLoading2(false)
      })
      .catch(error => {
        setList(null)
        setListOptions(null)
        setLoading2(false)
        console.error(error)
        Swal.fire("Hata", error.response?.data?.message, "error")
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Puank List History")}
            backLink={`/profile`}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading2} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    {props.t("Puank List History")}
                  </h4>
                  <p className="card-title-desc">
                    {props.t("Puank List History Description")}
                  </p>

                  <Row>
                    <Col md={12}>
                      <div>
                        <div className="mt-1 float-start">
                          {props.t("Rec Total", {
                            val: listOptions?.productTotal || 0,
                          })}
                          <span className="ms-2 badge badge-soft-success font-size-16">
                            {listOptions?.totalPuank != null
                              ? props.t("Total Puank", {
                                  val: listOptions?.totalPuank,
                                })
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="mb-2 d-flex flex-row-reverse">
                        <select
                          className="ms-2"
                          value={listParameters.limit}
                          onChange={e => {
                            let temp = { ...listParameters }
                            temp.limit = parseInt(e.target.value)
                            setListParameters(temp)
                          }}
                        >
                          {[10, 20, 50, 100, 200].map((item, idx) => (
                            <option key={idx}>{item}</option>
                          ))}
                        </select>
                      </div>

                      {list ? (
                        <>
                          <div className="table-responsive">
                            <table className="react-table table table-bordered">
                              <thead>
                                <tr>
                                  <th>{props.t("Campaign")}</th> 
                                  <th>{props.t("Terms")}</th> 
                                  <th>{props.t("Description")}</th>
                                  <th className="tbl-puank">{props.t("Puank")}</th>
                                  <th>{props.t("Status")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list.map((item, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      {item.Campaign?.name}{" "}
                                      <span className="badge badge-soft-info float-end">
                                        {moment(
                                          item.Campaign?.finishDate
                                        ).format("DD.MM.YYYY")}
                                      </span>
                                    </td> 
                                    <td>
                                      <div>
                                        <b>
                                          {TermTypeDesc(item.Term.step_1)}
                                          {" - "}
                                          {TermType(item.Term.step_2)}
                                        </b>
                                      </div>
                                      <div>
                                        {item.Term.step_1 == "1"
                                          ? TermTypeLong_Step1(
                                              item.Term.step_2,
                                              item.Term?._term?.startCount,
                                              item.Term?._term?.endCount
                                            )
                                          : item.Term.step_1 == "2"
                                          ? TermTypeLong_Step2(
                                              item.Term.step_2,
                                              item.Term?._term?.count
                                            )
                                          : ""}
                                      </div>
                                    </td> 
                                    <td className="puank-desc">{parse(item.description)}</td>
                                    <td>{item.puank} PuanK</td>
                                    <td>
                                      <a
                                        onClick={() => {
                                          let targetText = TermType(
                                            item.Term.step_2
                                          )
                                          let targetDescText = ""
                                          if (item.Term.step_1 == "1") {
                                            targetDescText = TermTypeLong_Step1(
                                              item.Term.step_2,
                                              item.Term?._term?.startCount,
                                              item.Term?._term?.endCount
                                            )
                                          } else if (item.Term.step_1 == "2") {
                                            targetDescText = TermTypeLong_Step2(
                                              item.Term.step_2,
                                              item.Term?._term?.count
                                            )
                                          }
                                          let html_text = `<div><div><span class="font-size-20">${targetText}</span></div><div>${targetDescText}</div><br><div>${item.description}</div></div>`

                                          let title = `<span class="badge badge-soft-${
                                            item.puankType == "add"
                                              ? "success"
                                              : "danger"
                                          } font-size-20">${props.t(
                                            "puank_" + item.puankType
                                          )}</span>`
                                          Swal.fire(
                                            title,
                                            html_text,
                                            item.puankType == "add"
                                              ? "success"
                                              : "info"
                                          )
                                        }}
                                      >
                                        {puankType(item.puankType)}
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {listOptions ? (
                              <>
                                <div className="d-flex flex-row float-end">
                                  <Button
                                    className="me-1 btn-sm"
                                    color="light"
                                    disabled={
                                      listParameters.page == 0 ||
                                      listOptions.pageCount == 1
                                    }
                                    onClick={() => {
                                      let temp = { ...listParameters }
                                      temp.page = listParameters.page - 1
                                      setListParameters(temp)
                                    }}
                                  >
                                    <i className="bx bx-chevron-left"></i>
                                  </Button>
                                  {listOptions.paging.map((item, idx) => {
                                    if (
                                      idx > 0 &&
                                      listOptions.paging[idx] -
                                        listOptions.paging[idx - 1] >
                                        1
                                    ) {
                                      return (
                                        <div
                                          key={idx}
                                          className="d-flex flex-row"
                                        >
                                          <Button
                                            className="me-1 btn-sm"
                                            color={
                                              listParameters.page + 1 == item
                                                ? "dark"
                                                : "light"
                                            }
                                            disabled={true}
                                          >
                                            ...
                                          </Button>
                                          <Button
                                            className="me-1 btn-sm"
                                            color="light"
                                            onClick={() => {
                                              let temp = {
                                                ...listParameters,
                                              }
                                              temp.page = item - 1
                                              setListParameters(temp)
                                            }}
                                          >
                                            {item}
                                          </Button>
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <Button
                                          key={idx}
                                          className="me-1 btn-sm"
                                          color={
                                            listParameters.page + 1 == item
                                              ? "dark"
                                              : "light"
                                          }
                                          onClick={() => {
                                            let temp = { ...listParameters }
                                            temp.page = item - 1
                                            setListParameters(temp)
                                          }}
                                        >
                                          {item}
                                        </Button>
                                      )
                                    }
                                  })}
                                  <Button
                                    className="me-1 btn-sm"
                                    color="light"
                                    disabled={
                                      listParameters.page ==
                                        listOptions.pageCount - 1 ||
                                      listOptions.pageCount == 1
                                    }
                                    onClick={() => {
                                      let temp = { ...listParameters }
                                      temp.page = listParameters.page + 1
                                      setListParameters(temp)
                                    }}
                                  >
                                    <i className="bx bx-chevron-right"></i>
                                  </Button>
                                </div>
                              </>
                            ) : null}
                          </div>
                          {/* {listParameters.page}
                          {listOptions.pageCount - 1}
                          {JSON.stringify(listOptions)} 
                          {JSON.stringify(listParameters)} */}
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

PuankListUserHistory.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(PuankListUserHistory)
