import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/atilim.jpg"
import axios from "axios"
import Swal from "sweetalert2"
import Loading from "components/Common/Loading"

const ChangePassword = props => {
  //meta title
  document.title = "Atılım İletişim"

  const dispatch = useDispatch()

  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const [forgetError, set_forgetError] = useState()
  const [forgetSuccessMsg, set_forgetSuccessMsg] = useState()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()

  useEffect(() => {
    //id - hash
    if (params.id && params.hash) {
      confirmControl(params.id, params.hash)
    } else {
      set_forgetError("Parametreler Eksik veya Hatalı")
      setLoading(false)
    }
  }, [])

  const confirmControl = async (id, hash) => {
    let data = JSON.stringify({
      id: id,
      hash: hash,
    })

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_API}/app-auth/hash-control`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    await axios
      .request(config)
      .then(response => {
        if (response.data.status == true) {
          let user = response.data.data
          setUser(user)
        } else {
          set_forgetError(response.data.message)
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
        //Swal.fire("Hata", error.response?.data?.message, "error")
        set_forgetError(error.response?.data?.message || "Hata Oluştu")
      })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Zorunlu"),
      rePassword: Yup.string().required("Zorunlu"),
    }),
    onSubmit: async values => {
      let data = JSON.stringify({
        password: values.password,
        re_password: values.rePassword,
        id: params.id,
        hash: params.hash,
      })
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API}/app-auth/change-password`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      }
      await axios
        .request(config)
        .then(response => {
          if (response.data.status == true) {
            let user = response.data.data
            setUser(null)
            set_forgetSuccessMsg("Şifre Başarıyla Güncellendi.")
          } else {
            set_forgetError(response.data.message)
          }
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          console.error(error)
          //Swal.fire("Hata", error.response?.data?.message, "error")
          set_forgetError(error.response?.data?.message || "Hata Oluştu")
        })
    },
  })
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white">Hesap Onay Kontrol</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="84"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {loading ? <Loading status={loading} /> : null}
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <>
                        <h1 style={{ textAlign: "center", marginTop: -50 }}>
                          <i
                            className="mdi mdi-close-circle text-danger"
                            style={{ fontSize: 100 }}
                          ></i>
                        </h1>
                        <Alert
                          color="danger"
                          style={{ marginTop: "13px", textAlign: "center" }}
                        >
                          {forgetError}
                        </Alert>
                      </>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <>
                        <h1 style={{ textAlign: "center", marginTop: -50 }}>
                          <i
                            className="mdi mdi-check-circle text-success"
                            style={{ fontSize: 100 }}
                          ></i>
                        </h1>
                        <Alert
                          color="success"
                          style={{ marginTop: "13px", textAlign: "center" }}
                        >
                          {forgetSuccessMsg}
                        </Alert>
                      </>
                    ) : null}
                    {user && user ? (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Yeni Şifre</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Yeni Şifre"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Şifre Tekrar</Label>
                          <Input
                            name="rePassword"
                            className="form-control"
                            placeholder="Şifre Tekrar"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.rePassword || ""}
                            invalid={
                              validation.touched.rePassword &&
                              validation.errors.rePassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.rePassword &&
                          validation.errors.rePassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.rePassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Şifre Yenile
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Atılım İletişim - ©{new Date().getFullYear()} Aviyiva</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ChangePassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ChangePassword)
