import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  ToastBody,
  ToastHeader,
} from "reactstrap"
//https://react-table-v7.tanstack.com/docs/examples/sorting
//https://www.material-react-table.com/docs/guides/data-columns#data-(accessor)-columns
import TableContainer from "../../components/Common/TableContainer"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import "./datatables.scss"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux

import {
  kasaBuyGet as onGetData,
  kasaBuyResetAll as onResetData,
  getBranches as onBranches,
  getBuyTypes as onBuyTypes,
  kasaBuyResetError as onResetError,
  kasaBuyDelete as onDelete,
  companiesResetAll as onCompaniesResetAll,
} from "store/actions"
import moment from "moment"
import { currencyFormat } from "helpers/functions"
import CustomModal from "./custom_components/buy_modal"
import { isEmpty } from "lodash"

//https://sweetalert2.github.io/#examples
import Swal from "sweetalert2"

const KasaBuy = props => {
  const dispatch = useDispatch()
  const [branchId, setBranchId] = useState(localStorage.getItem('branch')?parseInt(localStorage.getItem('branch')):undefined)
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalSearch, setModalSearch] = useState(false)
  const [editBody, setEditBody] = useState({})
  const [selectedId, setSelectedId] = useState()
  const [isToday, setIsToday] = useState(false)

  const { error, data, branches, buy_types, buy_type_sub, loading } =
    useSelector(state => ({
      error: state.KasaBuy.error,
      data: state.KasaBuy.data,
      loading: state.KasaBuy.loading,
      branches: state.Companies.branches,
      buy_types: state.Companies.buy_types,
      buy_type_sub: state.Companies.buy_type_sub,
    }))
  //meta title
  document.title = "Kasa | " + props.t("Buy")
  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    // localStorage.setItem("date", date)
    if (date == moment().format("DD-MM-YYYY")) {
      setIsToday(true)
    }else{
      setIsToday(false)
    }
  }, [date])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    }else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }
  }, [branches, branchId])

  useEffect(() => {
    if (branchId != undefined && !isEmpty(date)) {
      dispatch(onGetData(date, branchId))
      if (isEmpty(buy_types)) {
        dispatch(onBuyTypes())
      }
    }
  }, [branchId])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error(error, new Date())
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [error])

  const Role = type => {
    if (branchId && branches) {
      if (branches.find(x => x.id == branchId)?.Role?.includes(type)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("buy_type"),
        accessor: "buy_type",
        Cell: ({ value }) =>
          `${value ? buy_types.find(x => x.slug == value)?.name : ""}`,
      },
      {
        Header: props.t("buy_type_sub"),
        accessor: "buy_type_sub",
        Cell: ({ value }) =>
          `${value ? buy_type_sub.find(x => x.slug == value)?.name : ""}`,
      },
      {
        Header: props.t("Date"),
        accessor: "date",
        Cell: ({ value }) => `${moment(value).format("DD.MM.YYYY")}`,
      },
      {
        Header: props.t("description"),
        accessor: "description",
      },
      {
        Header: props.t("Cash"),
        accessor: "amount",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("Card"),
        accessor: "cardAmount",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("Transfer Buy"),
        accessor: "transferAmount",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("Action"),
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {(isToday && Role("edit")) || Role("past-edit") ? (
              <Button
                className="btn-sm btn-light"
                onClick={() => {
                  let d = [...data]
                  let p = d.filter(x => x._id == value) 
                  if (p.length == 1) {
                    console.log(p[0])
                    setSelectedId(value)
                    setIsEdit(true)
                    setEditBody(p[0])
                    setModal(true)
                  }
                }}
              >
                <i className="bx bx-edit-alt"></i>
              </Button>
            ) : null}
            {(isToday && Role("del")) || Role("past-del") ? (
              <Button
                className="btn-sm btn-light ms-2"
                onClick={() => {
                  Swal.fire({
                    title: "Silmek İstediğinize Emin Misiniz?",
                    text: "Bu İşlem Geri Alınamaz",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet, Sil",
                    cancelButtonText: "İptal",
                  }).then(result => {
                    if (result.isConfirmed) {
                      dispatch(onDelete(value))
                    }
                  })
                }}
              >
                <i className="bx bx-trash"></i>
              </Button>
            ) : null}
          </div>
        ),
      },
    ],
    [data]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ maxWidth: "100%" }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Buy")}
            breadcrumbItem={props.t("Kasa") + " " + props.t("Buy")}
          />
          <Card>
            <CardBody>
              <TableContainer
                loading={loading.status}
                branches={branches}
                columns={columns}
                data={data}
                isGlobalFilter={true}
                isDateFilter={true}
                isAddOptions={(Role("insert") && isToday) || Role("past-insert") ? true : false}
                customPageSize={50}
                handleOrderClicks={() => {
                  console.log("handleOrderClicks", date)
                  setIsEdit(false)
                  setModal(true)
                }}
                handleDateFilter={v => {
                  console.log("handleDateFilter", v)
                  setDate(v)
                  dispatch(onResetData())
                  dispatch(onGetData(v, branchId))
                }}
                handleBranchId={v => {
                  setBranchId(v)
                }}
                className="custom-header-css fix-table"
                defaultBranchId={branchId}
                exportXLSX={true}
                exportFields={[
                  "Tip",
                  "Kategori",
                  "Tarih",
                  "Açıklama",  
                  "Nakit",
                  "Kredi Kartı",
                  "Havale / EFT"
                ]}
                exportTitle={"Gider"}
                excelNumberFields={[ 
                  "Nakit", 
                  "Kredi Kartı", 
                  "Havale / EFT",
                ]}
                refArray={{
                  buy_types: buy_types,
                  buy_type_sub: buy_type_sub
                }} 
              />
            </CardBody>
          </Card>

          {date && date ? (
            <CustomModal
              toggle={() => {
                setModal(!modal)
              }}
              open={modal}
              isEdit={isEdit}
              date={date}
              branchId={branchId}
              branchName={branches.filter(x => x.id == branchId)[0]?.name}
              body={editBody}
              id={selectedId}
              buy_types={buy_types}
              buy_type_sub={buy_type_sub}
            />
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

KasaBuy.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(KasaBuy)
