import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Table,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import { kasaSearchGetSell as onSearch, searchReset as onSearchReset } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

const CustomModal_Search = props => {
  const dispatch = useDispatch() 

  useEffect(()=>{  
    if(props.open == true){
      setTimeout(()=>{
        let _input = document.getElementById('search_input'); 
        if(_input){
          _input.focus();
        }
      },100) 
    } else if(props.open == false){ 
      console.log("xxx", new Date())
      dispatch(onSearchReset());
      setSearch('')
    } 
  },[props.open])

 
  const { error, search } = useSelector(state => ({
    error: state.Kasa.error,
    search: state.Kasa.search,
  }))
  const [_search, setSearch] = useState("")

  const [selected, setSelected] = useState()

  useEffect(()=>{
    if(!isEmpty(search) && search.length == 1){ 
      props.handleBody(search[0])
    } 
  }, [dispatch, search])

  const Loading = status =>
    status ? (
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#b5b5b529",
        }}
      >
        <Col
          md={12}
          className="d-flex justify-content-center align-item-center m-2 p-2"
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </div>
    ) : null

  const columns = useMemo(
    () => [
      {
        Header: props.t("Product"),
        accessor: "name",
      },
      {
        Header: props.t("sku"),
        accessor: "sku",
        width: "200px",
      },
      {
        Header: props.t("barcode"),
        accessor: "barcode",
      },
      {
        Header: props.t("serial_number"),
        accessor: "serial_number",
      },
    ],
    [search, props]
  )
  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Search")} -{" "}
        <small>
          {props.branchName} - {props.date}
        </small>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={10}>
            <Input
            id='search_input' 
              placeholder={`${props.t("sku")}, ${props.t("barcode")}, ${props.t(
                "serial_number"
              )} ${props.t("Search")} ...`}
              value={_search || ""}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  if (!isEmpty(_search)) {
                    dispatch(onSearch(props.date, _search))
                    setSearch('')
                  } else {
                    props.handleBody(null)
                  }
                }
              }}
              onChange={e => {
                setSearch(e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={2}>
            <Button
              className="btn-success w-100"
              onClick={() => {
                //props.handleBody();
                if (!isEmpty(_search)) {
                  dispatch(onSearch(props.date, _search))
                  setSearch('')
                } else {
                  props.handleBody(null)
                }
              }}
            >
              {isEmpty(_search) ? props.t("New Add") : props.t("Search")}
            </Button>
          </Col>
        </Row>
        <div className="react-table mt-2">
          <TableContainer
            columns={columns}
            loading={props.loading}
            data={search}
            customPageSizeOptions={true}
            customPageSize={10}
            isDateFilter={false}
            rowClick={true}
            columnSearch={search?.length > 0 ? true : false}
            handleRow={v => {
              console.log(v.original)
              props.handleBody(v.original)
              setTimeout(()=>{ 
                dispatch(onSearchReset());
              }, 800);
            }}
          />
        </div>
        {/* <div className="table-responsive react-table mt-2">
          {Loading(props.loading || false)}
          <Table bordered hover>
            <thead>
              <tr>
                <td>Seri No</td>
                <td>Barkod</td>
                <td>Stok Kodu</td>
                <td>Ürün Adı</td>
              </tr>
            </thead>
            <tbody>
              {search.map((p, idx) => (
                <tr
                  key={idx}
                  onClick={() => {
                    props.handleBody(p)
                    // setSearch("")
                    // props.toggle()
                  }}
                >
                  <td>{p.serial_number}</td>
                  <td>{p.barcode}</td>
                  <td>{p.sku}</td>
                  <td>{p.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div> */}
      </ModalBody>
    </Modal>
  )
}

CustomModal_Search.propTypes = {
  loading: PropTypes.bool,
  body: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  date: PropTypes.string,
  branchId: PropTypes.number,
  branchName: PropTypes.string,
  handleBody: PropTypes.func,
}

export default withRouter(withTranslation()(CustomModal_Search))
