import {
  GET_BRANCHES,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAIL,
  GET_BANKS,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAIL,
  COMPANIES_RESET,
  COMPANIES_RESET_ERROR,
  GET_K_CAT_SUCCESS,
  GET_K_CAT_FAIL,
  GET_K_CAT,
  GET_PERSONS,GET_PERSONS_SUCCESS,GET_PERSONS_FAIL, COMPANIES_RESET_SUCCESS,
  SET_PERSONS, SET_PERSONS_FAIL, SET_PERSONS_SUCCESS, GET_BUY_TYPES, GET_BUY_TYPES_FAIL, GET_BUY_TYPES_SUCCESS, GET_GAIN_SETTINGS_SUCCESS, GET_GAIN_SETTINGS_FAIL, GET_GAIN_SETTINGS, SET_GAIN_SETTINGS_SUCCESS, SET_GAIN_SETTINGS_FAIL, SET_GAIN_SETTINGS 
} from "./actionTypes"
//BRANCHES
export const getBranchesSuccess = (actionType, data) => ({
  type: GET_BRANCHES_SUCCESS,
  payload: { actionType, data },
})

export const getBranchesFail = (actionType, error) => ({
  type: GET_BRANCHES_FAIL,
  payload: { actionType, error },
})

export const getBranches = () => ({
  type: GET_BRANCHES,
  payload: {},
})


//BANKS
export const getBanksSuccess = (actionType, data) => ({
  type: GET_BANKS_SUCCESS,
  payload: { actionType, data },
})

export const getBanksFail = (actionType, error) => ({
  type: GET_BANKS_FAIL,
  payload: { actionType, error },
})

export const getBanks = () => ({
  type: GET_BANKS,
  payload: {},
})

//BUY_TYPES
export const getBuyTypesSuccess = (actionType, data) => ({
  type: GET_BUY_TYPES_SUCCESS,
  payload: { actionType, data },
})

export const getBuyTypesFail = (actionType, error) => ({
  type: GET_BUY_TYPES_FAIL,
  payload: { actionType, error },
})

export const getBuyTypes = () => ({
  type: GET_BUY_TYPES,
  payload: {},
})


//k_cat
export const get_K_CAT_Success = (actionType, data) => ({
  type: GET_K_CAT_SUCCESS,
  payload: { actionType, data },
})

export const get_K_CAT_Fail = (actionType, error) => ({
  type: GET_K_CAT_FAIL,
  payload: { actionType, error },
})

export const get_K_CAT = () => ({
  type: GET_K_CAT,
  payload: {},
})


//PERSONS
export const getPersonsSuccess = (actionType, data) => ({
  type: GET_PERSONS_SUCCESS,
  payload: { actionType, data },
})

export const getPersonsFail = (actionType, error) => ({
  type: GET_PERSONS_FAIL,
  payload: { actionType, error },
})

export const getPersons = () => ({
  type: GET_PERSONS,
  payload: {},
})

export const setPersonsSuccess = (actionType, data) => ({
  type: SET_PERSONS_SUCCESS,
  payload: { actionType, data },
})

export const setPersonsFail = (actionType, error) => ({
  type: SET_PERSONS_FAIL,
  payload: { actionType, error },
})

export const setPersons = (body) => ({
  type: SET_PERSONS,
  payload: {body},
})


//GAIN_SETTINGS
export const getGainSettingsSuccess = (actionType, data) => ({
  type: GET_GAIN_SETTINGS_SUCCESS,
  payload: { actionType, data },
})

export const getGainSettingsFail = (actionType, error) => ({
  type: GET_GAIN_SETTINGS_FAIL,
  payload: { actionType, error },
})

export const getGainSettings = () => ({
  type: GET_GAIN_SETTINGS,
  payload: {},
})

export const setGainSettingsSuccess = (actionType, data) => ({
  type: SET_GAIN_SETTINGS_SUCCESS,
  payload: { actionType, data },
})

export const setGainSettingsFail = (actionType, error) => ({
  type: SET_GAIN_SETTINGS_FAIL,
  payload: { actionType, error },
})

export const setGainSettings = (body) => ({
  type: SET_GAIN_SETTINGS,
  payload: {body},
})

//--------

export const companiesResetAll = () => ({
  type: COMPANIES_RESET,
})

export const companiesResetError = () => ({
  type: COMPANIES_RESET_ERROR,
})

export const companiesResetSuccess = () => ({
  type: COMPANIES_RESET_SUCCESS,
})
