export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS";
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL";

export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";


export const GET_PERSONS = "GET_PERSONS";
export const GET_PERSONS_SUCCESS = "GET_PERSONS_SUCCESS";
export const GET_PERSONS_FAIL = "GET_PERSONS_FAIL";

export const SET_PERSONS = "SET_PERSONS";
export const SET_PERSONS_SUCCESS = "SET_PERSONS_SUCCESS";
export const SET_PERSONS_FAIL = "SET_PERSONS_FAIL";


export const GET_GAIN_SETTINGS = "GET_GAIN_SETTINGS";
export const GET_GAIN_SETTINGS_SUCCESS = "GET_GAIN_SETTINGS_SUCCESS";
export const GET_GAIN_SETTINGS_FAIL = "GET_GAIN_SETTINGS_FAIL";

export const SET_GAIN_SETTINGS = "SET_GAIN_SETTINGS";
export const SET_GAIN_SETTINGS_SUCCESS = "SET_GAIN_SETTINGS_SUCCESS";
export const SET_GAIN_SETTINGS_FAIL = "SET_GAIN_SETTINGS_FAIL";

//GET_K_CAT
export const GET_K_CAT = "GET_K_CAT";
export const GET_K_CAT_SUCCESS = "GET_K_CAT_SUCCESS";
export const GET_K_CAT_FAIL = "GET_K_CAT_FAIL";


export const GET_BUY_TYPES = "GET_BUY_TYPES";
export const GET_BUY_TYPES_SUCCESS = "GET_BUY_TYPES_SUCCESS";
export const GET_BUY_TYPES_FAIL = "GET_BUY_TYPES_FAIL";

export const COMPANIES_RESET_ERROR = "COMPANIES_RESET_ERROR";
export const COMPANIES_RESET = "COMPANIES_RESET";
export const COMPANIES_RESET_SUCCESS = "COMPANIES_RESET_SUCCESS";