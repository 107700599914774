export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS"
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL"

export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS"
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL"

export const ADD_CAMPAIGN = "ADD_CAMPAIGN"
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS"
export const ADD_CAMPAIGN_FAIL = "ADD_CAMPAIGN_FAIL"

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL"

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL"

export const TERMS_CAMPAIGN = "TERMS_CAMPAIGN"
export const TERMS_CAMPAIGN_SUCCESS = "TERMS_CAMPAIGN_SUCCESS"
export const TERMS_CAMPAIGN_FAIL = "TERMS_CAMPAIGN_FAIL"

export const TERM_CAMPAIGN = "TERM_CAMPAIGN"
export const TERM_CAMPAIGN_SUCCESS = "TERM_CAMPAIGN_SUCCESS"
export const TERM_CAMPAIGN_FAIL = "TERM_CAMPAIGN_FAIL"

export const ADD_TERM_CAMPAIGN = "ADD_TERM_CAMPAIGN"
export const ADD_TERM_CAMPAIGN_SUCCESS = "ADD_TERM_CAMPAIGN_SUCCESS"
export const ADD_TERM_CAMPAIGN_FAIL = "ADD_TERM_CAMPAIGN_FAIL"

export const UPDATE_TERM_CAMPAIGN = "UPDATE_TERM_CAMPAIGN"
export const UPDATE_TERM_CAMPAIGN_SUCCESS = "UPDATE_TERM_CAMPAIGN_SUCCESS"
export const UPDATE_TERM_CAMPAIGN_FAIL = "UPDATE_TERM_CAMPAIGN_FAIL"

export const DELETE_TERM_CAMPAIGN = "DELETE_TERM_CAMPAIGN"
export const DELETE_TERM_CAMPAIGN_SUCCESS = "DELETE_TERM_CAMPAIGN_SUCCESS"
export const DELETE_TERM_CAMPAIGN_FAIL = "DELETE_TERM_CAMPAIGN_FAIL"

export const UPDATE_CAMPAIGN_STEP3 = "UPDATE_CAMPAIGN_STEP3"
export const UPDATE_CAMPAIGN_STEP3_SUCCESS = "UPDATE_CAMPAIGN_STEP3_SUCCESS"
export const UPDATE_CAMPAIGN_STEP3_FAIL = "UPDATE_CAMPAIGN_STEP3_FAIL"

export const GET_BRANCHGROUPS_BY_CAMPAIGN = "GET_BRANCHGROUPS_BY_CAMPAIGN"
export const GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS =
  "GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS"
export const GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL =
  "GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL"
 

export const POST_CAMPAIGN_PRODUCT_TYPE = "POST_CAMPAIGN_PRODUCT_TYPE"
export const POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS = "POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS"
export const POST_CAMPAIGN_PRODUCT_TYPE_FAIL = "POST_CAMPAIGN_PRODUCT_TYPE_FAIL"

export const POST_CAMPAIGN_STATUS = "POST_CAMPAIGN_STATUS"
export const POST_CAMPAIGN_STATUS_SUCCESS = "POST_CAMPAIGN_STATUS_SUCCESS"
export const POST_CAMPAIGN_STATUS_FAIL = "POST_CAMPAIGN_STATUS_FAIL"


export const GET_CAMPAIGN_NEW_DATA_LIST = "GET_CAMPAIGN_NEW_DATA_LIST"
export const GET_CAMPAIGN_NEW_DATA_LIST_SUCCESS = "GET_CAMPAIGN_NEW_DATA_LIST_SUCCESS"
export const GET_CAMPAIGN_NEW_DATA_LIST_FAIL = "GET_CAMPAIGN_NEW_DATA_LIST_FAIL"

export const GET_CAMPAIGN_NEW_DATA = "GET_CAMPAIGN_NEW_DATA"
export const GET_CAMPAIGN_NEW_DATA_SUCCESS = "GET_CAMPAIGN_NEW_DATA_SUCCESS"
export const GET_CAMPAIGN_NEW_DATA_FAIL = "GET_CAMPAIGN_NEW_DATA_FAIL"

export const POST_CAMPAIGN_NEW_DATA = "POST_CAMPAIGN_NEW_DATA"
export const POST_CAMPAIGN_NEW_DATA_SUCCESS = "POST_CAMPAIGN_NEW_DATA_SUCCESS"
export const POST_CAMPAIGN_NEW_DATA_FAIL = "POST_CAMPAIGN_NEW_DATA_FAIL"

export const UPDATE_CAMPAIGN_NEW_DATA = "UPDATE_CAMPAIGN_NEW_DATA"
export const UPDATE_CAMPAIGN_NEW_DATA_SUCCESS = "UPDATE_CAMPAIGN_NEW_DATA_SUCCESS"
export const UPDATE_CAMPAIGN_NEW_DATA_FAIL = "UPDATE_CAMPAIGN_NEW_DATA_FAIL"

export const DELETE_CAMPAIGN_NEW_DATA = "DELETE_CAMPAIGN_NEW_DATA"
export const DELETE_CAMPAIGN_NEW_DATA_SUCCESS = "DELETE_CAMPAIGN_NEW_DATA_SUCCESS"
export const DELETE_CAMPAIGN_NEW_DATA_FAIL = "DELETE_CAMPAIGN_NEW_DATA_FAIL"


export const UPDATE_CAMPAIGN_NEW_DATA_STATUS = "UPDATE_CAMPAIGN_NEW_DATA_STATUS"
export const UPDATE_CAMPAIGN_NEW_DATA_STATUS_SUCCESS = "UPDATE_CAMPAIGN_NEW_DATA_STATUS_SUCCESS"
export const UPDATE_CAMPAIGN_NEW_DATA_STATUS_FAIL = "UPDATE_CAMPAIGN_NEW_DATA_STATUS_FAIL"

 
export const CAMPAIGN_SUCCESS = "CAMPAIGN_SUCCESS"  

export const CAMPAIGN_RESET = "CAMPAIGN_RESET"
export const CAMPAIGNS_RESET_ERROR = "CAMPAIGNS_RESET_ERROR"
export const CAMPAIGNS_RESET = "CAMPAIGNS_RESET"
export const CAMPAIGNS_RESET_SUCCESS = "CAMPAIGNS_RESET_SUCCESS"

export const CAMPAIGN_LOADING = "CAMPAIGN_LOADING"
