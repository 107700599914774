import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getBranchesSuccess,
  getBranchesFail,
  getBanksSuccess,
  getBanksFail,
  get_K_CAT_Success,
  get_K_CAT_Fail,
  getPersonsSuccess,
  getPersonsFail, 
  setPersonsSuccess,
  setPersonsFail, 
  getGainSettingsSuccess,
  getGainSettingsFail, 
  setGainSettingsSuccess,
  setGainSettingsFail,
  getBuyTypesSuccess,
  getBuyTypesFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getBranches, getBanks, getPersons,setPersons,getGainSettings,setGainSettings, get_K_CAT_ALL, getBuyTypes } from "../../helpers/fakebackend_helper"
import {
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_BANKS,
  GET_BANKS_FAIL,
  GET_BANKS_SUCCESS,
  GET_K_CAT_SUCCESS,
  GET_K_CAT_FAIL,
  GET_K_CAT,
  GET_PERSONS,
  GET_PERSONS_FAIL,
  GET_PERSONS_SUCCESS,
  SET_PERSONS_FAIL,
  SET_PERSONS_SUCCESS,
  SET_PERSONS,
  
  GET_GAIN_SETTINGS,
  GET_GAIN_SETTINGS_FAIL,
  GET_GAIN_SETTINGS_SUCCESS,
  SET_GAIN_SETTINGS_FAIL,
  SET_GAIN_SETTINGS_SUCCESS,
  SET_GAIN_SETTINGS,
  GET_BUY_TYPES_FAIL,
  GET_BUY_TYPES_SUCCESS,
  GET_BUY_TYPES,
} from "./actionTypes"
//BRANCHES
function* getBranchesFetch({ payload: {} }) {
  try {
    var response = yield call(getBranches)
    if (response.status) {
      yield put(getBranchesSuccess(GET_BRANCHES_SUCCESS, response.data))
    } else {
      yield put(
        getBranchesFail(
          GET_BRANCHES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getBranchesFail(GET_BRANCHES_FAIL, error))
  }
}

//BANKS
function* getBanksFetch({ payload: {} }) {
  try {
    var response = yield call(getBanks)
    if (response.status) {
      yield put(getBanksSuccess(GET_BANKS_SUCCESS, response.data))
    } else {
      yield put(
        getBanksFail(
          GET_BANKS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getBanksFail(GET_BANKS_FAIL, error))
  }
}

//BUY_TYPES
function* getBuyTypeFetch({ payload: {} }) {
  try {
    var response = yield call(getBuyTypes)
    if (response.status) {
      yield put(getBuyTypesSuccess(GET_BUY_TYPES_SUCCESS, response.data))
    } else {
      yield put(
        getBuyTypesFail(
          GET_BUY_TYPES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getBuyTypesFail(GET_BUY_TYPES_FAIL, error))
  }
}

//PERSONS
function* getPersonsFetch({ payload: {} }) {
  try {
    var response = yield call(getPersons)
    if (response.status) {
      yield put(getPersonsSuccess(GET_PERSONS_SUCCESS, response.data))
    } else {
      yield put(
        getPersonsFail(
          GET_PERSONS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getPersonsFail(GET_PERSONS_FAIL, error))
  }
}

function* setPersonsFetch({ payload: {body} }) {
  try {
    var response = yield call(setPersons, body)
    if (response.status) {
      yield put(setPersonsSuccess(SET_PERSONS_SUCCESS, response.data))
    } else {
      yield put(
        setPersonsFail(
          SET_PERSONS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-2001"
        )
      )
    }
  } catch (error) {
    yield put(setPersonsFail(SET_PERSONS_FAIL, error))
  }
}


//GAIN_SETTINGS
function* getGainSettingsFetch({ payload: {} }) {
  try {
    var response = yield call(getGainSettings)
    if (response.status) {
      yield put(getGainSettingsSuccess(GET_GAIN_SETTINGS_SUCCESS, response.data))
    } else {
      yield put(
        getGainSettingsFail(
          GET_GAIN_SETTINGS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(getGainSettingsFail(GET_GAIN_SETTINGS_FAIL, error))
  }
}

function* setGainSettingsFetch({ payload: {body} }) {
  try {
    var response = yield call(setGainSettings, body)
    if (response.status) {
      yield put(setGainSettingsSuccess(SET_GAIN_SETTINGS_SUCCESS, response.data))
    } else {
      yield put(
        setGainSettingsFail(
          SET_GAIN_SETTINGS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-2001"
        )
      )
    }
  } catch (error) {
    yield put(setGainSettingsFail(SET_GAIN_SETTINGS_FAIL, error))
  }
}

//get_K_CAT_ALL

//BANKS
function* get_K_CAT_ALLFetch({ payload: {} }) {
  try {
    var response = yield call(get_K_CAT_ALL)
    if (response.status) {
      yield put(get_K_CAT_Success(GET_K_CAT_SUCCESS, response.data))
    } else {
      yield put(
        get_K_CAT_Fail(
          GET_K_CAT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COMPANIES-1001"
        )
      )
    }
  } catch (error) {
    yield put(get_K_CAT_Fail(GET_K_CAT_FAIL, error))
  }
}


function* companiesSaga() {
  yield takeEvery(GET_BRANCHES, getBranchesFetch)
  yield takeEvery(GET_BANKS, getBanksFetch)
  yield takeEvery(GET_PERSONS, getPersonsFetch)
  yield takeEvery(SET_PERSONS, setPersonsFetch)
  yield takeEvery(GET_GAIN_SETTINGS, getGainSettingsFetch)
  yield takeEvery(SET_GAIN_SETTINGS, setGainSettingsFetch)
  yield takeEvery(GET_K_CAT, get_K_CAT_ALLFetch)
  yield takeEvery(GET_BUY_TYPES, getBuyTypeFetch)
   
}

export default companiesSaga
