export const GET_PRODUCTS_ALL = "GET_PRODUCTS_ALL"
export const GET_PRODUCTS_ALL_SUCCESS = "GET_PRODUCTS_ALL_SUCCESS"
export const GET_PRODUCTS_ALL_FAIL = "GET_PRODUCTS_ALL_FAIL"

export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL"

export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"


export const IMPORT_XLSX_PRODUCTS = "IMPORT_XLSX_PRODUCTS"
export const IMPORT_XLSX_PRODUCTS_SUCCESS = "IMPORT_XLSX_PRODUCTS_SUCCESS"
export const IMPORT_XLSX_PRODUCTS_FAIL = "IMPORT_XLSX_PRODUCTS_FAIL"


export const GET_VARIANTS_V2= "GET_VARIANTS_V2"
export const GET_VARIANTS_V2_SUCCESS = "GET_VARIANTS_V2_SUCCESS"
export const GET_VARIANTS_V2_FAIL = "GET_VARIANTS_V2_FAIL"

export const GET_VARIANTS = "GET_VARIANTS"
export const GET_VARIANTS_SUCCESS = "GET_VARIANTS_SUCCESS"
export const GET_VARIANTS_FAIL = "GET_VARIANTS_FAIL"

export const GET_VARIANT = "GET_VARIANT"
export const GET_VARIANT_SUCCESS = "GET_VARIANT_SUCCESS"
export const GET_VARIANT_FAIL = "GET_VARIANT_FAIL"


export const ADD_VARIANT = "ADD_VARIANT"
export const ADD_VARIANT_SUCCESS = "ADD_VARIANT_SUCCESS"
export const ADD_VARIANT_FAIL = "ADD_VARIANT_FAIL"

export const UPDATE_VARIANT = "UPDATE_VARIANT"
export const UPDATE_VARIANT_SUCCESS = "UPDATE_VARIANT_SUCCESS"
export const UPDATE_VARIANT_FAIL = "UPDATE_VARIANT_FAIL"

export const DELETE_VARIANT = "DELETE_VARIANT"
export const DELETE_VARIANT_SUCCESS = "DELETE_VARIANT_SUCCESS"
export const DELETE_VARIANT_FAIL = "DELETE_VARIANT_FAIL"



export const GET_SERIES_LIST = "GET_SERIES_LIST"
export const GET_SERIES_LIST_SUCCESS = "GET_SERIES_LIST_SUCCESS"
export const GET_SERIES_LIST_FAIL = "GET_SERIES_LIST_FAIL"

export const GET_SERIES = "GET_SERIES"
export const GET_SERIES_SUCCESS = "GET_SERIES_SUCCESS"
export const GET_SERIES_FAIL = "GET_SERIES_FAIL"


export const ADD_SERIES = "ADD_SERIES"
export const ADD_SERIES_SUCCESS = "ADD_SERIES_SUCCESS"
export const ADD_SERIES_FAIL = "ADD_SERIES_FAIL"

export const UPDATE_SERIES = "UPDATE_SERIES"
export const UPDATE_SERIES_SUCCESS = "UPDATE_SERIES_SUCCESS"
export const UPDATE_SERIES_FAIL = "UPDATE_SERIES_FAIL"

export const DELETE_SERIES = "DELETE_SERIES"
export const DELETE_SERIES_SUCCESS = "DELETE_SERIES_SUCCESS"
export const DELETE_SERIES_FAIL = "DELETE_SERIES_FAIL"

 
export const PRODUCT_RESET = "PRODUCT_RESET"
export const PRODUCTS_RESET_ERROR = "PRODUCTS_RESET_ERROR"
export const PRODUCTS_RESET = "PRODUCTS_RESET"
export const PRODUCTS_RESET_SUCCESS = "PRODUCTS_RESET_SUCCESS"


export const PRODUCT_LOADING = "PRODUCT_LOADING"
