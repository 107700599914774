import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Label } from "reactstrap"


import "./custom.scss"
const AddButton = props => {
  
  return (
    <div
      className="add-btn"
      onClick={() => { 
        props.onClick();
      }}
    > 
      <img className="add-btn-img" />
      <Label className="add-btn-label">{props.label}</Label>
    </div>
  )
}

AddButton.propTypes = {
  label: PropTypes.string, 
  onClick: PropTypes.func
}

export default AddButton
