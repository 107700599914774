export const currencyFormat = n => {
  return n == "0"
    ? "0,00 TL"
    : n
    ? parseFloat(n)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
        .replace(/\.(\d+)$/, ",$1") + " TL"
    : ""
}

export const replaceAll = (str, find, replace) => {
  var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
  return str?.replace(new RegExp(escapedFind, "g"), replace)
}

export const generateRandomString = length => {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
