export const Role = {
  Dev: "61fed88523193552689c3e56",
  Admin: "61fed89e23193552689c3e57",
  Manager: "63f6f95d26a7e0f349bdeb20",
  Coordinator: "63f6fa4f26a7e0f349bdeb21",
  User: "61fed8a723193552689c3e58",
  Guest: "61feda7f23193552689c3e61",
}

export const authorize = (roles = []) => {
  if (typeof roles === "string") {
    roles = [roles]
  }
  let UserRoles = JSON.parse(localStorage.getItem("authUser"))?.roles
  for (let i = 0; i < roles.length; i++) {
    if (UserRoles?.findIndex(x => x._id == roles[i]) > -1) return true
  }
  return false
}
 

export const PERSON_TYPES = [
  { label: "Dev", labelTR: "Geliştirici", value: "61fed88523193552689c3e56" },
  { label: "Admin", labelTR: "Yönetici", value: "61fed89e23193552689c3e57" },
  { label: "Manager", labelTR: "Müdür", value: "63f6f95d26a7e0f349bdeb20" },
  {
    label: "Coordinator",
    labelTR: "Koordinatör",
    value: "63f6fa4f26a7e0f349bdeb21",
  },
  { label: "User", labelTR: "Personel", value: "61fed8a723193552689c3e58" },
  { label: "Guest", labelTR: "Misafir", value: "61feda7f23193552689c3e61" },
]
