import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
//Date Picker
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import "./custom.scss"
import zipcelx from "zipcelx"
import tr from 'date-fns/locale/tr' 
registerLocale("tr", tr)

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col xs={9} sm={5} md={5} className="a-mts-15">
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Arama İşlemi
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`Ara ...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

function DateFilter({ dateFilter, setDateFilter, handle }) {
  const [value, setValue] = React.useState(dateFilter)
  const onChange = useAsyncDebounce(value => {
    setDateFilter(value || undefined)
  }, 200)

  const addDay = count => {
    let isVal = moment(value).add(count, "days").toDate()
    console.log(isVal)
    setValue(isVal)
    handle(moment(isVal).format("DD-MM-YYYY"))
  }

  return (
    <React.Fragment>
      <Col md={3}>
        <div className="aok-datepicker d-flex">
          <a
            onClick={() => {
              addDay(-1)
            }}
            className="d-flex align-items-center me-2"
          >
            {"<"}
          </a>
          <div
            className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"
            style={{ width: "100%" }}
          >
            <div className="position-relative">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Arama İşlemi
                </span>
                <DatePicker
                  onChange={value => {
                    setValue(value)
                    handle(moment(value).format("DD-MM-YYYY"))
                  }}
                  id="search-bar-1"
                  className="form-control"
                  placeholderText={`GG-AA-YYYY`}
                  selected={value}
                  dateFormat="dd-MM-yyyy"
                  locale={"tr"}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
          <a
            onClick={() => {
              addDay(1)
            }}
            className="d-flex align-items-center ms-2"
          >
            {">"}
          </a>
        </div>
      </Col>
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  handleDateFilter,
  handleBranchId,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  isDateFilter,
  branches,
  loading,
  rowClick,
  handleRow,
  columnSearch = true,
  defaultBranchId,
  exportXLSX = false,
  exportFields = [],
  exportTitle,
  excelNumberFields = [],
  refArray = {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setDateFilter,
    setBranchId,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        dateFilter: localStorage.getItem("date")
          ? moment(localStorage.getItem("date"), "DD-MM-YYYY").toDate()
          : moment().format("DD-MM-YYYY").toDate(),
        branchId: defaultBranchId,
        exportXLSX: exportXLSX,
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="mdi mdi-chevron-down position-absolute"></i>
      ) : (
        <i className="mdi mdi-chevron-up position-absolute"></i>
      )
    ) : (
      ""
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInSelectBranch = event => {
    state.branchId = Number(event.target.value)
    localStorage.setItem("branch", event.target.value)
    handleBranchId(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const Loading = status =>
    status ? (
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#b5b5b529",
        }}
      >
        <Col
          md={12}
          className="d-flex justify-content-center align-item-center m-2 p-2"
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </div>
    ) : null

  return (
    <Fragment>
      <Row className="mb-1">
        {customPageSize != 99999 ? (
          <Col
            xs={3}
            sm={customPageSizeOptions ? 2 : 1}
            md={customPageSizeOptions ? 2 : 1}
            className="a-mts-15"
          >
            <select
              className="form-select me-xxl-2 my-3 my-xxl-0 d-inline-block"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 50, 100, 250, 500, 1000, 2000].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </Col>
        ) : null}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        <Col
          sm={customPageSizeOptions ? 5 : 6}
          md={customPageSizeOptions ? 5 : 6}
        >
          <Row className="justify-content-end">
            {exportXLSX ? (
              <Col sm={12} md={2}>
                <Button
                  type="button"
                  color="success"
                  className="btn-md mb-2 me-2 w-100"
                  onClick={() => {
                    console.log("export", exportFields)
                    let branchName =
                      branches.find(x => x.id == state.branchId).name || "?"
                    const config = {
                      filename:
                        (exportTitle || "Export") +
                        " - " +
                        branchName +
                        " - " +
                        moment(state.dateFilter).format("DD.MM.YYYY"),
                      sheet: {
                        data: [],
                      },
                    }
                    const dataSet = config.sheet.data
                    let headerCell = []
                    for (let field of exportFields) {
                      headerCell.push({ value: field, type: "string" })
                    }
                    dataSet.push(headerCell)

                    for (let row of page) {
                      let rowCell = []
                      for (let field of exportFields) {
                        let cell = row.cells.find(x => x.column.Header == field)
                        if (cell) {
                          if (excelNumberFields.includes(field)) {
                            rowCell.push({
                              value: cell.value || 0,
                              type: "number",
                            })
                          } else {
                            if (field == "Banka") {
                              //banks.find(x => x.slug == value)?.title : ""
                              rowCell.push({
                                value: cell.value
                                  ? refArray?.banks?.find(
                                      x => x.slug == cell.value
                                    )?.title
                                  : "",
                                type: "string",
                              })
                            } else if (field == "Tip") {
                              //banks.find(x => x.slug == value)?.title : ""
                              rowCell.push({
                                value: cell.value
                                  ? refArray?.buy_types?.find(
                                      x => x.slug == cell.value
                                    )?.name
                                  : "",
                                type: "string",
                              })
                            } else if (field == "Kategori") {
                              //banks.find(x => x.slug == value)?.title : ""
                              rowCell.push({
                                value: cell.value
                                  ? refArray?.buy_type_sub?.find(
                                      x => x.slug == cell.value
                                    )?.name
                                  : "",
                                type: "string",
                              })
                            } else if (field == "Tarih") {
                              //banks.find(x => x.slug == value)?.title : ""
                              rowCell.push({
                                value: cell.value
                                  ? moment(cell.value).format("DD.MM.YYYY")
                                  : "",
                                type: "string",
                              })
                            } else {
                              rowCell.push({
                                value: cell.value,
                                type: "string",
                              })
                            }
                          }
                        } else {
                          console.log(field, ">>>>>>>> NOT FOUND <<<<<<<<<")
                        }
                      }
                      dataSet.push(rowCell)
                    }
                    //console.log(dataSet)
                    zipcelx(config)
                  }}
                >
                  <i className="mdi mdi-microsoft-excel me-2"></i>
                  {"Dışa Aktar"}
                </Button>
              </Col>
            ) : null}
            {branches ? (
              <Col xs={12} sm={3} md={3}>
                <select
                  className="form-select"
                  value={state.branchId}
                  onChange={onChangeInSelectBranch}
                >
                  {branches.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}
            {/**
             * dateFilter, setDateFilter, handle
             */}
            {isDateFilter && (
              <DateFilter
                dateFilter={state.dateFilter}
                setDateFilter={setDateFilter}
                handle={handleDateFilter}
              />
            )}
            {isAddOptions && (
              <Col sm={12} md={2}>
                <Button
                  type="button"
                  color="success"
                  className="btn-md mb-2 me-2 w-100"
                  onClick={handleOrderClicks}
                >
                  <i className="mdi mdi-plus me-1" />
                  Yeni Kayit
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table aok-react-table">
        {Loading(loading)}
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div
                      className={column.search == false ? "mb-4" : "mb-2"}
                      {...column.getSortByToggleProps()}
                      style={column.width ? { width: column.width + "px" } : {}}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {columnSearch == false || column.search == false ? null : (
                      <Filter column={column} />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    onClick={() => {
                      if (rowClick) {
                        handleRow(row)
                      }
                    }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      {customPageSize != 99999 ? (
        <Row className="justify-content-md-end justify-content-center align-items-center mt-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                className="btn-sm"
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                className="btn-sm"
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Sayfa{" "}
            <strong>
              {pageIndex + 1} - {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              className="form-control-sm"
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                className="btn-sm"
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                className="btn-sm"
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
