import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_REPORTS,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_FAIL,
    DASHBOARD_LOADING
} from "./actionTypes";

export const dashboardLoading = status => ({
  type: DASHBOARD_LOADING,
  payload: { status },
})

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});


 
export const getReports = (date, branchId) => ({
    type: GET_REPORTS,
    payload: { date, branchId },
  })
  
  export const getReportsSuccess = (actionType, data) => ({
    type: GET_REPORTS_SUCCESS,
    payload: { actionType, data },
  })
  
  export const getReportsFail = (actionType, error) => ({
    type: GET_REPORTS_FAIL,
    payload: { actionType, error },
  })
