import {
  CAMPAIGNS_RESET_ERROR,
  CAMPAIGNS_RESET,
  CAMPAIGNS_RESET_SUCCESS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  ADD_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAIL,
  CAMPAIGN_RESET,
  CAMPAIGN_LOADING,
  TERMS_CAMPAIGN_SUCCESS,
  TERM_CAMPAIGN_SUCCESS,
  ADD_TERM_CAMPAIGN_SUCCESS,
  UPDATE_TERM_CAMPAIGN_SUCCESS,
  TERMS_CAMPAIGN_FAIL,
  TERM_CAMPAIGN_FAIL,
  UPDATE_TERM_CAMPAIGN_FAIL,
  DELETE_TERM_CAMPAIGN_FAIL,
  ADD_TERM_CAMPAIGN_FAIL,
  DELETE_TERM_CAMPAIGN_SUCCESS,
  GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL,
  GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_STEP3_FAIL,
  UPDATE_CAMPAIGN_STEP3_SUCCESS,
  POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS,
  POST_CAMPAIGN_PRODUCT_TYPE_FAIL,
  POST_CAMPAIGN_STATUS_SUCCESS,
  POST_CAMPAIGN_STATUS_FAIL,
  CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_NEW_DATA_FAIL,
  POST_CAMPAIGN_NEW_DATA_FAIL,
  UPDATE_CAMPAIGN_NEW_DATA_FAIL,
  DELETE_CAMPAIGN_NEW_DATA_FAIL,
  UPDATE_CAMPAIGN_NEW_DATA_STATUS_FAIL,
  GET_CAMPAIGN_NEW_DATA_LIST_FAIL,
  GET_CAMPAIGN_NEW_DATA_LIST_SUCCESS,
  GET_CAMPAIGN_NEW_DATA_SUCCESS,
  UPDATE_CAMPAIGN_NEW_DATA_SUCCESS,
  DELETE_CAMPAIGN_NEW_DATA_SUCCESS,
  POST_CAMPAIGN_NEW_DATA_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  campaign: {},
  terms: [],
  term: {},
  newData: {},
  newDataList: [],
  error: null,
  success: null,
  loading: true,
  status_branches: [],
  status_groups: [],
}

const Campaigns = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
      }
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload?.data,
        status_branches: [],
        status_groups: [],
      }
    case ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_CAMPAIGN_STEP3_SUCCESS:
    case POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS:
    case POST_CAMPAIGN_STATUS_SUCCESS:
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload.data,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case TERMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        terms: action.payload?.data,
      }
    case TERM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        term: action.payload?.data?.Terms,
      }

    case ADD_TERM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.terms],
      }
    case UPDATE_TERM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        term: action.payload.data,
        terms: state.terms?.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_TERM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        terms: state.terms?.filter(
          x => x._id.toString() !== action.payload?.data?.toString()
        ),
      }
    case GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload?.data,
        status_branches: action.payload?.branchList || [],
        status_groups: action.payload?.groupList || [],
      }
    case UPDATE_CAMPAIGN_STEP3_SUCCESS:
      return {
        ...state,
        status_branches: [],
        status_groups: [],
      }

    case GET_CAMPAIGN_NEW_DATA_LIST_SUCCESS:
      return {
        ...state,
        newDataList: action.payload?.data,
      }
    case GET_CAMPAIGN_NEW_DATA_SUCCESS:
      return {
        ...state,
        newData: action.payload?.data,
      }
    case POST_CAMPAIGN_NEW_DATA_SUCCESS:
      return {
        ...state,
        newDataList: [action.payload.data, ...state.newDataList],
      }
    case UPDATE_CAMPAIGN_NEW_DATA_SUCCESS:
      return {
        ...state,
        newData: action.payload.data,
        newDataList: state.newDataList.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_CAMPAIGN_NEW_DATA_SUCCESS:
      return {
        ...state,
        newDataList: state.newDataList.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case GET_CAMPAIGNS_FAIL:
    case GET_CAMPAIGN_FAIL:
    case ADD_CAMPAIGN_FAIL:
    case UPDATE_CAMPAIGN_FAIL:
    case DELETE_CAMPAIGN_FAIL:
    case TERMS_CAMPAIGN_FAIL:
    case TERM_CAMPAIGN_FAIL:
    case ADD_TERM_CAMPAIGN_FAIL:
    case UPDATE_TERM_CAMPAIGN_FAIL:
    case DELETE_TERM_CAMPAIGN_FAIL:
    case GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL:
    case UPDATE_CAMPAIGN_STEP3_FAIL:
    case POST_CAMPAIGN_PRODUCT_TYPE_FAIL:
    case POST_CAMPAIGN_STATUS_FAIL:
    case GET_CAMPAIGN_NEW_DATA_LIST_FAIL:
    case GET_CAMPAIGN_NEW_DATA_FAIL:
    case POST_CAMPAIGN_NEW_DATA_FAIL:
    case UPDATE_CAMPAIGN_NEW_DATA_FAIL:
    case DELETE_CAMPAIGN_NEW_DATA_FAIL:
    case UPDATE_CAMPAIGN_NEW_DATA_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CAMPAIGNS_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case CAMPAIGNS_RESET_ERROR:
      return {
        ...state,
        error: null,
      }
    case CAMPAIGN_RESET:
      return {
        ...state,
        campaign: {},
        branch: {},
        terms: [],
        term: {},
      }
    case CAMPAIGNS_RESET:
      return {
        ...state,
        data: [],
        terms: [],
        term: {},
        campaign: null,
        error: null,
        success: null,
      }
    case CAMPAIGN_SUCCESS:
      return {
        ...state,
        success: action.payload.data,
      }
    case CAMPAIGN_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default Campaigns
