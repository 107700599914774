import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  ToastBody,
  ToastHeader,
} from "reactstrap"
//https://react-table-v7.tanstack.com/docs/examples/sorting
//https://www.material-react-table.com/docs/guides/search-columns#search-(accessor)-columns
import TableContainer from "../../components/Common/TableContainer"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import "./datatables2.scss"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux

import {
  kasaSearch as onGetData,
  searchReset as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasaSellDelete as onDelete,
  kasaGetDailyCash as onDailyCash,
  companiesResetAll as onCompaniesResetAll,
} from "store/actions"
import moment from "moment"
import { currencyFormat, replaceAll } from "helpers/functions"
import CustomModal from "./custom_components/sell_modal"
import CustomModal_Search from "./custom_components/sell_search_modal"
import DailyModal from "./custom_components/daily_modal"
import { isEmpty } from "lodash"

//https://sweetalert2.github.io/#examples
import Swal from "sweetalert2"
import BreadcrumbCustomNTS from "components/Common/BreadcrumbCustomNTS"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import axios from "axios"
import Breadcrumb from "../../components/Common/Breadcrumb"

const KasaSearch = props => {
  const dispatch = useDispatch()

  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )
  const [searchText, setSearchText] = useState("")
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalSearch, setModalSearch] = useState(false)
  const [modalDaily, setModalDaily] = useState(false)
  const [editBody, setEditBody] = useState({})
  const [selectedId, setSelectedId] = useState()
  const [dailyData, setDailyData] = useState({})
  const [loadingCount, setLoadingCount] = useState(0)
  const [isToday, setIsToday] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [isDayReport, setIsDayReport] = useState()
  const [modalSettings, setModalSettings] = useState(false)
  const [allCheck, setAllCheck] = useState(false)

  const [parameters, setParameters] = useState([
    {
      key: "name",
      title: props.t("Product"),
      check: false,
    },
    {
      key: "description",
      title: props.t("description"),
      check: false,
    },
    {
      key: "barcode",
      title: props.t("barcode"),
      check: false,
    },
    {
      key: "serial_number",
      title: props.t("serial_number"),
      check: false,
    },
    {
      key: "sku",
      title: props.t("sku"),
      check: false,
    },
    {
      key: "person",
      title: props.t("Person"),
      check: false,
    },
    {
      key: "Customer.name",
      title: props.t("Customer Name"),
      check: false,
    },
    {
      key: "Customer.phone",
      title: props.t("Customer Phone"),
      check: false,
    },
  ])
  const {
    error,
    search,
    data,
    branches,
    banks,
    persons,
    loading,
    k_cat,
    k_cat_sub,
    k_cat_sub_alt,
    dailyCash,
    today,
  } = useSelector(state => ({
    error: state.Kasa.error,
    search: state.Kasa.search,
    data: state.Kasa.data,
    loading: state.Kasa.loading,
    branches: state.Companies.branches,
    banks: state.Companies.banks,
    persons: state.Companies.persons,
    k_cat: state.Companies.k_cat,
    k_cat_sub: state.Companies.k_cat_sub,
    k_cat_sub_alt: state.Companies.k_cat_sub_alt,
    dailyCash: state.Kasa.dailyCash,
    today: state.Kasa.today,
  }))
  //meta title
  document.title = "Kasa | " + props.t("Sell")
  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    // localStorage.setItem("date", date)
    if (date == moment().format("DD-MM-YYYY")) {
      setIsToday(true)
    } else {
      setIsToday(false)
    }
  }, [date])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      console.log("default branch", branchId)
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }
  }, [branches, branchId])

  useEffect(() => {
    if (branchId != undefined && !isEmpty(date)) {
      if (isEmpty(banks)) {
        dispatch(onBanks())
      }
      if (isEmpty(persons)) {
        dispatch(onPersons())
      }
      if (isEmpty(k_cat)) {
        dispatch(onK_CAT())
      }
    }
  }, [branchId])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [error])

  // useEffect(() => {
  //   if (date) {
  //     const _date = moment(date, "DD-MM-YYYY").toDate()
  //     const _today = moment(new Date()).toDate()
  //     console.log("_date _today", _date, _today, _date > _today)
  //     if (_date > _today) {
  //       Swal.fire("Devir", "Bugün İçin Devir Kaydı Yapamazsınız", "warning")
  //     }
  //   }
  // }, [date])

  const toggleModalSettings = () => {
    setModalSettings(!modalSettings)
  }

  const Role = type => {
    if (branchId && branches) {
      if (branches.find(x => x.id == branchId)?.Role?.includes(type)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Branch"),
        accessor: "branchId",
        fixed: "left",
        Cell: ({ value }) => (
          <span className="float-end">
            {branches.find(x => x.id == value)?.name}
          </span>
        ),
      },
      {
        Header: props.t("Date"),
        accessor: "date",
        Cell: ({ value }) => (
          <span>
            {value? moment(value).format("DD.MM.YYYY"):''}
          </span>
        ),
      },
      {
        Header: props.t("Person"),
        accessor: "person",
      },
      {
        Header: props.t("Product"),
        accessor: "name",
        width: 350,
      },
      {
        Header: props.t("product_definition"),
        accessor: "product_definition",
        width: 350,
      },
      {
        Header: props.t("barcode"),
        accessor: "barcode",
      },
      {
        Header: props.t("sku"),
        accessor: "sku",
      },
      {
        Header: props.t("serial_number"),
        accessor: "serial_number",
      },
      {
        Header: props.t("agreementNumber"),
        accessor: "agreementNumber",
      },
      {
        Header: props.t("Customer"),
        accessor: "Customer.name",
      },
      {
        Header: props.t("Customer Phone"),
        accessor: "Customer.phone",
      },
      {
        Header: props.t("cost"),
        accessor: "Price.cost",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("net"),
        accessor: "Price.net",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("description"),
        accessor: "description",
      },
      {
        Header: props.t("agreementPrice"),
        accessor: "Price.agreementPrice",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("cash"),
        accessor: "Price.cash",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("bank"),
        accessor: "Price.bank",
        Cell: ({ value }) =>
          `${value ? banks.find(x => x.slug == value)?.title : ""}`,
      },
      {
        Header: props.t("bank_card"),
        accessor: "Price.card",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("installment"),
        accessor: "Price.installment",
        Cell: props => (
          <span>
            {props.value
              ? props.value == 1
                ? `Tek Çekim`
                : props.value == "999"
                ? "Havale / EFT"
                : `${props.value} Taksit`
              : ""}
            {`${
              props.value != "999" &&
              props.row?.original?.Price?.installmentRate
                ? " - %" +
                  props.row?.original?.Price?.installmentRate?.toFixed(2)
                : ""
            }`}
          </span>
        ),
      },
      {
        Header: props.t("bankNet"),
        accessor: "Price.bankNet",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("gain"),
        accessor: "Price.gain",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      //BANK2
      {
        Header: props.t("bank") + "-2",
        accessor: "Price.bank2",
        Cell: ({ value }) =>
          `${value ? banks.find(x => x.slug == value)?.title : ""}`,
      },
      {
        Header: props.t("bank_card") + "-2",
        accessor: "Price.card2",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("installment") + "-2",
        accessor: "Price.installment2",
        Cell: props => (
          <span>
            {props.value
              ? props.value == 1
                ? `Tek Çekim`
                : props.value == "999"
                ? "Havale / EFT"
                : `${props.value} Taksit`
              : ""}
            {`${
              props.value != "999" &&
              props.row?.original?.Price?.installmentRate2
                ? " - %" +
                  props.row?.original?.Price?.installmentRate2?.toFixed(2)
                : ""
            }`}
          </span>
        ),
      },
      {
        Header: props.t("bankNet") + "-2",
        accessor: "Price.bankNet2",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      //BANK3
      {
        Header: props.t("bank") + "-3",
        accessor: "Price.bank3",
        Cell: ({ value }) =>
          `${value ? banks.find(x => x.slug == value)?.title : ""}`,
      },
      {
        Header: props.t("bank_card") + "-3",
        accessor: "Price.card3",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("installment") + "-3",
        accessor: "Price.installment3",
        Cell: props => (
          <span>
            {props.value
              ? props.value == 1
                ? `Tek Çekim`
                : props.value == "999"
                ? "Havale / EFT"
                : `${props.value} Taksit`
              : ""}
            {`${
              props.value != "999" &&
              props.row?.original?.Price?.installmentRate3
                ? " - %" +
                  props.row?.original?.Price?.installmentRate3?.toFixed(2)
                : ""
            }`}
          </span>
        ),
      },
      {
        Header: props.t("bankNet") + "-3",
        accessor: "Price.bankNet3",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("pre_paid"),
        accessor: "Price.pre_paid",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("Action"),
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {/* {(isToday && Role("edit")) || Role("past-edit") ? (
              <Button
                className="btn-sm btn-light"
                onClick={() => {
                  let d = [...search]
                  let p = d.filter(x => x._id == value)
                  console.log(p)
                  if (p.length == 1) {
                    setSelectedId(value)
                    setIsEdit(true)
                    setEditBody(p[0])
                    setModal(true)
                  }
                }}
              >
                <i className="bx bx-edit-alt"></i>
              </Button>
            ) : null}
            {(isToday && Role("del")) || Role("past-del") ? (
              <Button
                className="btn-sm btn-light ms-2"
                onClick={() => {
                  Swal.fire({
                    title: "Silmek İstediğinize Emin Misiniz?",
                    text: "Bu İşlem Geri Alınamaz",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet, Sil",
                    cancelButtonText: "İptal",
                  }).then(result => {
                    if (result.isConfirmed) {
                      dispatch(onDelete(value))
                    }
                  })
                }}
              >
                <i className="bx bx-trash"></i>
              </Button>
            ) : null} */}
          </div>
        ),
      },
    ],
    [search]
  )

  const onSearch = text => {
    if (!isEmpty(text)) {
      let checkedList = parameters.filter(x => x.check == true)
      if (checkedList.length == 0) {
        dispatch(onResetData())
        setTimeout(() => {
          toggleModalSettings()
        }, 300)
      } else {
        let searchBox = []
        for (let c of checkedList) {
          searchBox.push(c.key.toString())
        }
        let _body = {
          type: "sell",
          searchBox: searchBox,
          search: text,
        }
        dispatch(onGetData(_body))
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ maxWidth: "100%" }}>
          {/* Render Breadcrumb */}
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="d-sm-flex align-items-center">
                  <input
                    onChange={e => {
                      setSearchText(e.target.value)
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control"
                    placeholder={`Ara ...`}
                    value={searchText || ""}
                    onKeyDown={e => {
                      if (e.key == "Enter") {
                        onSearch(e.target.value)
                      }
                    }}
                  />
                  <a
                    className="btn btn-md btn-success ms-3"
                    onClick={() => {
                      onSearch(searchText)
                    }}
                  >
                    <i className="bx bx-search me-2"></i>
                    {props.t("Search")}
                  </a>
                </div>
                <div className="d-sm-flex align-items-center">
                  <a
                    className="btn btn-md btn-success ms-3"
                    onClick={() => {
                      dispatch(onResetData())
                    }}
                  >
                    {props.t("Clear")}
                  </a>
                  <a
                    className="font-size-24 ms-3"
                    onClick={() => {
                      toggleModalSettings()
                    }}
                  >
                    <i className="bx bx-cog"></i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <TableContainer
                loading={loading?.status || false}
                columns={columns}
                data={search}
                isGlobalFilter={false}
                isDateFilter={false}
                isAddOptions={false}
                columnSearch={false}
                customPageSize={99999}
                handleOrderClicks={() => {
                  console.log("handleOrderClicks")
                  setIsEdit(false)
                  //setModal(true)
                  setModalSearch(true)
                }}
                className="custom-header-css fix-table"
                refArray={{
                  banks: banks,
                }}
              />
            </CardBody>
          </Card>

          <CustomModal_Search
            loading={loading?.status || false}
            toggle={() => {
              setModalSearch(!modalSearch)
            }}
            open={modalSearch}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            handleBody={v => {
              if (v != null) {
                setEditBody({
                  id: v.id,
                  branchId: v.branchId,
                  sku: v.sku,
                  product_definition: v.product_definition,
                  name: v.name,
                  serial_number: v.serial_number || "",
                  description: v.description || "",
                  vat: v.vat,
                  barcode: v.barcode || "",
                  agreementNumber: "",
                  date: date,
                  k_cat: null,
                  k_cat_sub: null,
                  k_cat_sub_alt: null,
                  person: v.person,
                  Price: {
                    cost: v.cost,
                    cash: null,
                    agreementPrice: null,
                    card: null,
                    bank: null,
                    installment: null,
                    installmentRate: null,
                    bankNet: null,
                    card2: null,
                    bank2: null,
                    installment2: null,
                    installmentRate2: null,
                    bankNet2: null,
                    card3: null,
                    bank3: null,
                    installment3: null,
                    installmentRate3: null,
                    bankNet3: null,
                    net: null,
                    gain: null,
                    wallet: null,
                    price: null,
                  },
                  Customer: {
                    name: v.Customer?.name,
                    phone: v.Customer?.phone,
                  },
                })
              }
              setModal(true)
            }}
          />
          <CustomModal
            toggle={() => {
              setModal(!modal)
            }}
            open={modal}
            isEdit={isEdit}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            body={editBody}
            id={selectedId}
            banks={banks}
            k_cat={k_cat}
            k_cat_sub={k_cat_sub}
            k_cat_sub_alt={k_cat_sub_alt}
            persons={persons}
          />

          <Modal isOpen={modalSettings} toggle={toggleModalSettings} size="md">
            <ModalHeader toggle={toggleModalSettings} tag="h4">
              {props.t("Search Settings")}
            </ModalHeader>
            <ModalBody>
              <div>
                <input
                  className="me-2"
                  id={"prm_all"}
                  type="checkbox"
                  value={allCheck}
                  defaultChecked={allCheck}
                  onChange={e => {
                    let temp = [...parameters]
                    for (let i = 0; i < temp.length; i++) {
                      temp[i].check = e.target.checked
                      document.getElementById(`prm_${temp[i].key}`).checked =
                        e.target.checked
                    }
                    setAllCheck(e.target.checked)
                    setParameters(temp)
                    console.log(parameters)
                  }}
                />
                <label htmlFor={"prm_all"}>{props.t("All")}</label>
              </div>
              {parameters.map((item, idx) => (
                <div key={idx}>
                  <input
                    className="me-2"
                    id={"prm_" + item.key}
                    type="checkbox"
                    value={item.check}
                    defaultChecked={item.check}
                    onChange={e => {
                      let temp = [...parameters]
                      temp[idx].check = e.target.checked
                      setParameters(temp)
                    }}
                  />
                  <label htmlFor={"prm_" + item.key}>{item.title}</label>
                </div>
              ))}
            </ModalBody>
            <ModalFooter>
              <a
                onClick={() => {
                  if (!isEmpty(searchText)) {
                    let checkedList = parameters.filter(x => x.check == true)
                    if (checkedList.length == 0) {
                      Swal.fire("Hata", "Arama Kriteri Belirtilmedi", "error")
                    } else {
                      let searchBox = []
                      for (let c of checkedList) {
                        searchBox.push(c.key.toString())
                      }
                      let _body = {
                        type: "sell",
                        searchBox: searchBox,
                        search: searchText,
                      }
                      dispatch(onGetData(_body))
                      toggleModalSettings()
                    }
                  } else {
                    toggleModalSettings()
                  }
                }}
                className="btn btn-success"
              >
                {props.t("Ok")}
              </a>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

KasaSearch.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(KasaSearch)
