export const GET_COUPONS = "GET_COUPONS"
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS"
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL"

export const GET_COUPON = "GET_COUPON"
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS"
export const GET_COUPON_FAIL = "GET_COUPON_FAIL"

export const ADD_COUPON = "ADD_COUPON"
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS"
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL"

export const UPDATE_COUPON = "UPDATE_COUPON"
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS"
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL"

export const DELETE_COUPON = "DELETE_COUPON"
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS"
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL"

export const TERMS_COUPON = "TERMS_COUPON"
export const TERMS_COUPON_SUCCESS = "TERMS_COUPON_SUCCESS"
export const TERMS_COUPON_FAIL = "TERMS_COUPON_FAIL"

export const TERM_COUPON = "TERM_COUPON"
export const TERM_COUPON_SUCCESS = "TERM_COUPON_SUCCESS"
export const TERM_COUPON_FAIL = "TERM_COUPON_FAIL"

export const ADD_TERM_COUPON = "ADD_TERM_COUPON"
export const ADD_TERM_COUPON_SUCCESS = "ADD_TERM_COUPON_SUCCESS"
export const ADD_TERM_COUPON_FAIL = "ADD_TERM_COUPON_FAIL"

export const UPDATE_TERM_COUPON = "UPDATE_TERM_COUPON"
export const UPDATE_TERM_COUPON_SUCCESS = "UPDATE_TERM_COUPON_SUCCESS"
export const UPDATE_TERM_COUPON_FAIL = "UPDATE_TERM_COUPON_FAIL"

export const DELETE_TERM_COUPON = "DELETE_TERM_COUPON"
export const DELETE_TERM_COUPON_SUCCESS = "DELETE_TERM_COUPON_SUCCESS"
export const DELETE_TERM_COUPON_FAIL = "DELETE_TERM_COUPON_FAIL"

export const UPDATE_COUPON_STEP3 = "UPDATE_COUPON_STEP3"
export const UPDATE_COUPON_STEP3_SUCCESS = "UPDATE_COUPON_STEP3_SUCCESS"
export const UPDATE_COUPON_STEP3_FAIL = "UPDATE_COUPON_STEP3_FAIL"

export const GET_BRANCHGROUPS_BY_COUPON = "GET_BRANCHGROUPS_BY_COUPON"
export const GET_BRANCHGROUPS_BY_COUPON_SUCCESS =
  "GET_BRANCHGROUPS_BY_COUPON_SUCCESS"
export const GET_BRANCHGROUPS_BY_COUPON_FAIL =
  "GET_BRANCHGROUPS_BY_COUPON_FAIL"
 

export const POST_COUPON_PRODUCT_TYPE = "POST_COUPON_PRODUCT_TYPE"
export const POST_COUPON_PRODUCT_TYPE_SUCCESS = "POST_COUPON_PRODUCT_TYPE_SUCCESS"
export const POST_COUPON_PRODUCT_TYPE_FAIL = "POST_COUPON_PRODUCT_TYPE_FAIL"

export const POST_COUPON_STATUS = "POST_COUPON_STATUS"
export const POST_COUPON_STATUS_SUCCESS = "POST_COUPON_STATUS_SUCCESS"
export const POST_COUPON_STATUS_FAIL = "POST_COUPON_STATUS_FAIL"


export const GET_COUPON_NEW_DATA_LIST = "GET_COUPON_NEW_DATA_LIST"
export const GET_COUPON_NEW_DATA_LIST_SUCCESS = "GET_COUPON_NEW_DATA_LIST_SUCCESS"
export const GET_COUPON_NEW_DATA_LIST_FAIL = "GET_COUPON_NEW_DATA_LIST_FAIL"

export const GET_COUPON_NEW_DATA = "GET_COUPON_NEW_DATA"
export const GET_COUPON_NEW_DATA_SUCCESS = "GET_COUPON_NEW_DATA_SUCCESS"
export const GET_COUPON_NEW_DATA_FAIL = "GET_COUPON_NEW_DATA_FAIL"

export const POST_COUPON_NEW_DATA = "POST_COUPON_NEW_DATA"
export const POST_COUPON_NEW_DATA_SUCCESS = "POST_COUPON_NEW_DATA_SUCCESS"
export const POST_COUPON_NEW_DATA_FAIL = "POST_COUPON_NEW_DATA_FAIL"

export const UPDATE_COUPON_NEW_DATA = "UPDATE_COUPON_NEW_DATA"
export const UPDATE_COUPON_NEW_DATA_SUCCESS = "UPDATE_COUPON_NEW_DATA_SUCCESS"
export const UPDATE_COUPON_NEW_DATA_FAIL = "UPDATE_COUPON_NEW_DATA_FAIL"

export const DELETE_COUPON_NEW_DATA = "DELETE_COUPON_NEW_DATA"
export const DELETE_COUPON_NEW_DATA_SUCCESS = "DELETE_COUPON_NEW_DATA_SUCCESS"
export const DELETE_COUPON_NEW_DATA_FAIL = "DELETE_COUPON_NEW_DATA_FAIL"


export const UPDATE_COUPON_NEW_DATA_STATUS = "UPDATE_COUPON_NEW_DATA_STATUS"
export const UPDATE_COUPON_NEW_DATA_STATUS_SUCCESS = "UPDATE_COUPON_NEW_DATA_STATUS_SUCCESS"
export const UPDATE_COUPON_NEW_DATA_STATUS_FAIL = "UPDATE_COUPON_NEW_DATA_STATUS_FAIL"

 
export const COUPON_SUCCESS = "COUPON_SUCCESS"  

export const COUPON_RESET = "COUPON_RESET"
export const COUPONS_RESET_ERROR = "COUPONS_RESET_ERROR"
export const COUPONS_RESET = "COUPONS_RESET"
export const COUPONS_RESET_SUCCESS = "COUPONS_RESET_SUCCESS"

export const COUPON_LOADING = "COUPON_LOADING"
