import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  DASHBOARD_ERROR_RESET,
  DASHBOARD_RESET,
  DASHBOARD_LOADING,
} from "./actionTypes"

const INIT_STATE = {
  chartsData: [],
  reports: {},
  error: null,
  loading: true,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return { ...state, reports: action.payload.data }
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }

        default:
          return state
      }
    case GET_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload.data,
      } 
    case DASHBOARD_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case DASHBOARD_ERROR_RESET:
      return {
        ...state,
        error: null,
      }
    case DASHBOARD_RESET:
      return {
        ...state,
        reports: {},
        error: null,
      }
    default:
      return state
  }
}

export default Dashboard
