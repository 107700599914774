import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import "./custom.scss"
import { Role, authorize } from "helpers/authorize"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [sell, setsell] = useState(false)
  const [buy, setbuy] = useState(false)
  const [settings, setsettings] = useState(false)
  const [report, setreport] = useState(false)
  const [app, setapp] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var matchingMenuItemAlternative = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      } else if (
        items[i].pathname != "/" &&
        props.location.pathname.includes(items[i].pathname)
      ) {
        matchingMenuItemAlternative = items[i]
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    } else if (matchingMenuItemAlternative) {
      activateParentDropdown(matchingMenuItemAlternative)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dashboard"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Default")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/v1/kasa/sell"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    {props.t("Sell and In")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/v1/kasa/payment-purchase"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    {props.t("Payment - Purchase")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/v1/kasa/buy"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    {props.t("Buy and Out")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/v1/kasa/search"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-search me-2"></i>
                    {props.t("Search")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setreport(!report)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-line-chart me-2"></i>
                    {props.t("Reports")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: report })}>
                    <Link to="/v1/reports-categories" className="dropdown-item">
                      {props.t("Report Categories")}
                    </Link>
                    <Link
                      to="/v1/reports-categories-custom-1"
                      className="dropdown-item"
                    >
                      {props.t("Daily Gain Reports")}
                    </Link>
                    <Link
                      to="/v1/reports-categories-custom-2"
                      className="dropdown-item"
                    >
                      {props.t("Daily Gain Reports from Person")}
                    </Link>
                    <Link to="/v1/reports-cost-1" className="dropdown-item">
                      {props.t("Cost Reports")}
                    </Link>
                    <Link
                      to="/v1/reports-categories-by-user"
                      className="dropdown-item"
                    >
                      {props.t("Report Category By User")}
                    </Link>
                  </div>
                </li>
                {authorize([Role.Dev]) ? (
                  <>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setapp(!app)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx bx-tab me-2"></i>
                        {props.t("App")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: app })}
                      >
                        <Link to="/v1/campaigns" className="dropdown-item">
                          {props.t("Campaigns")}
                        </Link>
                        <Link to="/v1/coupons" className="dropdown-item">
                          {props.t("Coupons")}
                        </Link>
                        <Link to="/v1/app-users" className="dropdown-item">
                          {props.t("App Users")}
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setsettings(!settings)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cog me-2"></i>
                        {props.t("Settings")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: settings,
                        })}
                      >
                        <Link to="/settings/banks" className="dropdown-item">
                          {props.t("Banks")}
                        </Link>
                        <Link to="/settings/persons" className="dropdown-item">
                          {props.t("Persons")}
                        </Link>
                        <Link
                          to="/settings/gain-settings"
                          className="dropdown-item"
                        >
                          {props.t("Gain Settings")}
                        </Link>
                      </div>
                    </li>
                  </>
                ) : null}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
      <nav className="mobile-nav">
        <Link className="bloc-icon" to="/dashboard">
          <i className="mdi mdi-home-outline"></i>
        </Link>
        <Link className="bloc-icon" to="/v1/kasa/sell">
          <i className="mdi mdi-barcode"></i>
        </Link>
        <Link className="bloc-icon" to="/v1/kasa/buy">
          <i className="mdi mdi-wallet-plus-outline"></i>
        </Link>
      </nav>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
