import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Redux States 
import {  
     kasaBuyGetSuccess, kasaBuyGetFail,
    kasaBuyLoading, kasaBuyAddFail, 
    kasaBuyAddSuccess, 
    kasaBuyUpdateSuccess, kasaBuyUpdateFail,
    kasaBuyDeleteSuccess, kasaBuyDeleteFail,} from "./actions";

//Include Both Helper File with needed methods
import { 
    addKasaBuy,
    getKasaBuy,
    updateKasaBuy, 
    deleteKasaBuy
}
    from "../../helpers/fakebackend_helper"; 
import { 
    KASA_ADD_BUY, KASA_ADD_BUY_FAIL, KASA_ADD_BUY_SUCCESS, 
    KASA_GET_BUY_FAIL, KASA_GET_BUY, 
    KASA_BUY_LOADING, 
    KASA_UPDATE_BUY, KASA_UPDATE_BUY_FAIL, KASA_UPDATE_BUY_SUCCESS, 
    KASA_DELETE_BUY, KASA_DELETE_BUY_FAIL, KASA_DELETE_BUY_SUCCESS, KASA_GET_BUY_SUCCESS
 } from "./actionTypes";


function* kasaGetBuyFetch({ payload: {date, branchId} }) {
    try { 
        
        yield put(kasaBuyLoading(true));
        var response = yield call(getKasaBuy, date, branchId); 
        
        if(response.status){ 
            yield put(kasaBuyGetSuccess(KASA_GET_BUY_SUCCESS, response.data));
        }else{ 
            yield put(kasaBuyGetFail(KASA_GET_BUY_FAIL, response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1001"));
        }
        yield put(kasaBuyLoading(false));
    } catch (error) {
        yield put(kasaBuyLoading(KASA_BUY_LOADING, false));
        yield put(kasaBuyGetFail(KASA_GET_BUY_FAIL, error));
    }
}

function* kasaAddBuyFetch({ payload: {body} }) {
    try { 
        
        yield put(kasaBuyLoading(true));
        var response = yield call(addKasaBuy, body); 
        
        if(response.status){ 
            yield put(kasaBuyAddSuccess(KASA_ADD_BUY_SUCCESS, response.data));
        }else{ 
            yield put(kasaBuyAddFail(KASA_ADD_BUY_FAIL, response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1002"));
        }
        yield put(kasaBuyLoading(false));
    } catch (error) {
        yield put(kasaBuyLoading(false));
        yield put(kasaBuyAddFail(KASA_GET_BUY_FAIL, error));
    }
}

function* kasaUpdateBuyFetch({ payload: {id, body} }) {
    try { 
        
        yield put(kasaBuyLoading(true));
        var response = yield call(updateKasaBuy,id, body); 
        
        if(response.status){ 
            yield put(kasaBuyUpdateSuccess(KASA_UPDATE_BUY_SUCCESS, response.data));
        }else{ 
            yield put(kasaBuyUpdateFail(KASA_UPDATE_BUY_FAIL, response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1003"));
        }
        yield put(kasaBuyLoading(false));
    } catch (error) {
        yield put(kasaBuyLoading(false));
        yield put(kasaBuyUpdateFail(KASA_UPDATE_BUY_FAIL, error));
    }
}

function* kasaDeleteBuyFetch({ payload: {id, body} }) {
    try { 
        
        yield put(kasaBuyLoading(true));
        var response = yield call(deleteKasaBuy,id, body); 
        
        if(response.status){ 
            yield put(kasaBuyDeleteSuccess(KASA_DELETE_BUY_SUCCESS, response.data));
        }else{ 
            yield put(kasaBuyDeleteFail(KASA_DELETE_BUY_FAIL, response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1003"));
        }
        yield put(kasaBuyLoading(false));
    } catch (error) {
        yield put(kasaBuyLoading(false));
        yield put(kasaBuyDeleteFail(KASA_DELETE_BUY_FAIL, error));
    }
}


 

function* kasaBuySaga() {
    yield takeEvery(KASA_GET_BUY, kasaGetBuyFetch)
    yield takeEvery(KASA_ADD_BUY, kasaAddBuyFetch) 
    yield takeEvery(KASA_UPDATE_BUY, kasaUpdateBuyFetch)
    yield takeEvery(KASA_DELETE_BUY, kasaDeleteBuyFetch)   
  }
  
  export default kasaBuySaga
