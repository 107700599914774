import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  ToastBody,
  ToastHeader,
} from "reactstrap"
//https://react-table-v7.tanstack.com/docs/examples/sorting
//https://www.material-react-table.com/docs/guides/data-columns#data-(accessor)-columns
import TableContainer from "../../components/Common/TableContainer"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import "./datatables.scss"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux

import {
  kasaGetSell as onGetData,
  kasaResetAll as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasaSellDelete as onDelete,
  kasaGetDailyCash as onDailyCash,
  companiesResetAll as onCompaniesResetAll,
} from "store/actions"
import moment from "moment"
import { currencyFormat, replaceAll } from "helpers/functions"
import CustomModal from "./custom_components/sell_modal"
import CustomModal_Search from "./custom_components/sell_search_modal"
import DailyModal from "./custom_components/daily_modal"
import { isEmpty } from "lodash"

//https://sweetalert2.github.io/#examples
import Swal from "sweetalert2"
import BreadcrumbCustomNTS from "components/Common/BreadcrumbCustomNTS"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import axios from "axios"

const KasaSell = props => {
  const dispatch = useDispatch()

  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalSearch, setModalSearch] = useState(false)
  const [modalDaily, setModalDaily] = useState(false)
  const [editBody, setEditBody] = useState({})
  const [selectedId, setSelectedId] = useState()
  const [dailyData, setDailyData] = useState({})
  const [loadingCount, setLoadingCount] = useState(0)
  const [isToday, setIsToday] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [isDayReport, setIsDayReport] = useState()
  const {
    error,
    data,
    branches,
    banks,
    persons,
    loading,
    k_cat,
    k_cat_sub,
    k_cat_sub_alt,
    dailyCash,
    today,
  } = useSelector(state => ({
    error: state.Kasa.error,
    data: state.Kasa.data,
    loading: state.Kasa.loading,
    branches: state.Companies.branches,
    banks: state.Companies.banks,
    persons: state.Companies.persons,
    k_cat: state.Companies.k_cat,
    k_cat_sub: state.Companies.k_cat_sub,
    k_cat_sub_alt: state.Companies.k_cat_sub_alt,
    dailyCash: state.Kasa.dailyCash,
    today: state.Kasa.today,
  }))
  //meta title
  document.title = "Kasa | " + props.t("Sell")
  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    // localStorage.setItem("date", date)
    if (date == moment().format("DD-MM-YYYY")) {
      setIsToday(true)
    } else {
      setIsToday(false)
    }
  }, [date])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      console.log("default branch", branchId)
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }
  }, [branches, branchId])

  useEffect(() => {
    if (branchId != undefined && !isEmpty(date)) {
      console.log("get", branchId)
      dispatch(onGetData(date, branchId))
      dispatch(onDailyCash(date, branchId))
      if (isEmpty(banks)) {
        dispatch(onBanks())
      }
      if (isEmpty(persons)) {
        dispatch(onPersons())
      }
      if (isEmpty(k_cat)) {
        dispatch(onK_CAT())
      }
    }
  }, [branchId])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [error])

  useEffect(() => {
    setDailyData(dailyCash)
    customReportV1()
  }, [dailyCash])

  // useEffect(() => {
  //   if (date) {
  //     const _date = moment(date, "DD-MM-YYYY").toDate()
  //     const _today = moment(new Date()).toDate()
  //     console.log("_date _today", _date, _today, _date > _today)
  //     if (_date > _today) {
  //       Swal.fire("Devir", "Bugün İçin Devir Kaydı Yapamazsınız", "warning")
  //     }
  //   }
  // }, [date])

  const Role = type => {
    if (branchId && branches) {
      if (branches.find(x => x.id == branchId)?.Role?.includes(type)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Person"),
        accessor: "person",
        fixed: "left",
      },
      {
        Header: props.t("Product"),
        accessor: "name",
        width: 350,
      },
      {
        Header: props.t("product_definition"),
        accessor: "product_definition",
        width: 350,
      },
      {
        Header: props.t("barcode"),
        accessor: "barcode",
      },
      {
        Header: props.t("sku"),
        accessor: "sku",
      },
      {
        Header: props.t("serial_number"),
        accessor: "serial_number",
      },
      {
        Header: props.t("agreementNumber"),
        accessor: "agreementNumber",
      },
      {
        Header: props.t("Customer"),
        accessor: "Customer.name",
      },
      {
        Header: props.t("Customer Phone"),
        accessor: "Customer.phone",
      },
      {
        Header: props.t("cost"),
        accessor: "Price.cost",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("net"),
        accessor: "Price.net",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("description"),
        accessor: "description",
      },
      {
        Header: props.t("agreementPrice"),
        accessor: "Price.agreementPrice",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("cash"),
        accessor: "Price.cash",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("bank"),
        accessor: "Price.bank",
        Cell: ({ value }) =>
          `${value ? banks.find(x => x.slug == value)?.title : ""}`,
      },
      {
        Header: props.t("bank_card"),
        accessor: "Price.card",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("installment"),
        accessor: "Price.installment",
        Cell: props => (
          <span>
            {props.value
              ? props.value == 1
                ? `Tek Çekim`
                : props.value == "999"
                ? "Havale / EFT"
                : `${props.value} Taksit`
              : ""}
            {`${
              props.value != "999" &&
              props.row?.original?.Price?.installmentRate
                ? " - %" +
                  props.row?.original?.Price?.installmentRate?.toFixed(2)
                : ""
            }`}
          </span>
        ),
      },
      {
        Header: props.t("bankNet"),
        accessor: "Price.bankNet",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("gain"),
        accessor: "Price.gain",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      //BANK2
      {
        Header: props.t("bank") + "-2",
        accessor: "Price.bank2",
        Cell: ({ value }) =>
          `${value ? banks.find(x => x.slug == value)?.title : ""}`,
      },
      {
        Header: props.t("bank_card") + "-2",
        accessor: "Price.card2",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("installment") + "-2",
        accessor: "Price.installment2",
        Cell: props => (
          <span>
            {props.value
              ? props.value == 1
                ? `Tek Çekim`
                : props.value == "999"
                ? "Havale / EFT"
                : `${props.value} Taksit`
              : ""}
            {`${
              props.value != "999" &&
              props.row?.original?.Price?.installmentRate2
                ? " - %" +
                  props.row?.original?.Price?.installmentRate2?.toFixed(2)
                : ""
            }`}
          </span>
        ),
      },
      {
        Header: props.t("bankNet") + "-2",
        accessor: "Price.bankNet2",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      //BANK3
      {
        Header: props.t("bank") + "-3",
        accessor: "Price.bank3",
        Cell: ({ value }) =>
          `${value ? banks.find(x => x.slug == value)?.title : ""}`,
      },
      {
        Header: props.t("bank_card") + "-3",
        accessor: "Price.card3",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("installment") + "-3",
        accessor: "Price.installment3",
        Cell: props => (
          <span>
            {props.value
              ? props.value == 1
                ? `Tek Çekim`
                : props.value == "999"
                ? "Havale / EFT"
                : `${props.value} Taksit`
              : ""}
            {`${
              props.value != "999" &&
              props.row?.original?.Price?.installmentRate3
                ? " - %" +
                  props.row?.original?.Price?.installmentRate3?.toFixed(2)
                : ""
            }`}
          </span>
        ),
      },
      {
        Header: props.t("bankNet") + "-3",
        accessor: "Price.bankNet3",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("pre_paid"),
        accessor: "Price.pre_paid",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },
      {
        Header: props.t("Action"),
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {(isToday && Role("edit")) || Role("past-edit") ? (
              <Button
                className="btn-sm btn-light"
                onClick={() => {
                  let d = [...data]
                  let p = d.filter(x => x._id == value)
                  console.log(p)
                  if (p.length == 1) {
                    setSelectedId(value)
                    setIsEdit(true)
                    setEditBody(p[0])
                    setModal(true)
                  }
                }}
              >
                <i className="bx bx-edit-alt"></i>
              </Button>
            ) : null}
            {(isToday && Role("del")) || Role("past-del") ? (
              <Button
                className="btn-sm btn-light ms-2"
                onClick={() => {
                  Swal.fire({
                    title: "Silmek İstediğinize Emin Misiniz?",
                    text: "Bu İşlem Geri Alınamaz",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet, Sil",
                    cancelButtonText: "İptal",
                  }).then(result => {
                    if (result.isConfirmed) {
                      dispatch(onDelete(value))
                    }
                  })
                }}
              >
                <i className="bx bx-trash"></i>
              </Button>
            ) : null}
          </div>
        ),
      },
    ],
    [data]
  )

  const customReportV1 = async () => {
    setLoading2(true)
    let startDate = replaceAll(date, "-", ".")
    let endDate = replaceAll(date, "-", ".")

    let body = {
      startDate: startDate,
      endDate: endDate,
      branchId: branchId,
      // k_cat: _body.k_cat,
      // k_cat_sub: _body.k_cat_sub,
      // k_cat_sub_alt: _body.k_cat_sub_alt,
      // product_definition: _body.product_definition,
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_API + "/api/reports/v1/categories-custom-1",
      headers: {
        Authorization: authHeader().Authorization,
      },
      data: body,
    }

    await axios
      .request(config)
      .then(response => {
        setLoading2(false)
        console.log(JSON.stringify(response.data))
        if (response.data.status == true) {
          console.log("today report", response.data.data)
          if (response.data.data?.list.length == 1) {
            const item = response.data.data.list[0]
            setIsDayReport({
              cihaz: {
                kar: currencyFormat(item.report_1.gain),
              },
              aksesuar: {
                kar: currencyFormat(item.report_2.gain),
              },
              diger: {
                kar: currencyFormat(item.report_3.gain + item.report_4.gain),
              },
              total: {
                kar: currencyFormat(
                  item.report_1.gain +
                    item.report_2.gain +
                    item.report_3.gain +
                    item.report_4.gain
                ),
              },
            })
          }
        } else {
        }
      })
      .catch(error => {
        setLoading2(false)
        console.log(error)
        Swal.fire(
          props.t("Error"),
          error?.error ||
            error?.message ||
            error?.error?.message ||
            JSON.stringify(error),
          "error"
        )
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ maxWidth: "100%" }}>
          {/* Render Breadcrumb */}
          <BreadcrumbCustomNTS
            title={props.t("Sell")}
            breadcrumbItem={props.t("Kasa") + " " + props.t("Sell")}
            t={props.t}
            date={date}
            dailyCash={dailyData}
            today={today}
            handleEdit={() => {
              setModalDaily(true)
            }}
            isEdit={
              (isToday && Role("edit")) || Role("past-edit") ? true : false
            }
            isDayReport={isDayReport}
          />
          <Card>
            <CardBody>
              <TableContainer
                loading={loading?.status || false}
                branches={branches}
                columns={columns}
                data={data}
                isGlobalFilter={true}
                isDateFilter={true}
                isAddOptions={
                  (Role("insert") && isToday) || Role("past-insert")
                    ? true
                    : false
                }
                customPageSize={50}
                handleOrderClicks={() => {
                  console.log("handleOrderClicks")
                  setIsEdit(false)
                  //setModal(true)
                  setModalSearch(true)
                }}
                handleDateFilter={v => {
                  console.log("handleDateFilter", v)
                  setDate(v)
                  dispatch(onResetData())
                  dispatch(onGetData(v, branchId))
                  dispatch(onDailyCash(v, branchId))
                }}
                handleBranchId={v => {
                  setBranchId(v)
                }}
                className="custom-header-css fix-table"
                defaultBranchId={branchId}
                exportXLSX={true}
                exportFields={[
                  "Personel",
                  "Ürün Adı",
                  "Ürün Tanımı",
                  "Seri Numarası",
                  "Barkod",
                  "Stok Kodu",
                  "Sözleşme No",
                  "Maliyet",
                  "Nakit",
                  "Banka",
                  "Çekilen Tutar",
                  "Hesaba Geçen",
                  "Kar",
                  "Açıklama",
                ]}
                exportTitle={"Satış"}
                excelNumberFields={[
                  "Maliyet",
                  "Nakit",
                  "Çekilen Tutar",
                  "Hesaba Geçen",
                  "Kar",
                ]}
                refArray={{
                  banks: banks,
                }}
              />
            </CardBody>
          </Card>

          <CustomModal_Search
            loading={loading?.status || false}
            toggle={() => {
              setModalSearch(!modalSearch)
            }}
            open={modalSearch}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            handleBody={v => {
              if (v != null) {
                setEditBody({
                  id: v.id,
                  branchId: branchId,
                  sku: v.sku,
                  product_definition: v.product_definition,
                  name: v.name,
                  serial_number: v.serial_number || "",
                  description: v.description || "",
                  vat: v.vat,
                  barcode: v.barcode || "",
                  agreementNumber: "",
                  date: date,
                  k_cat: null,
                  k_cat_sub: null,
                  k_cat_sub_alt: null,
                  person: v.person,
                  QRCode: {
                    code: "",
                    User: null,
                    QR: null,
                    KASA2: null,
                  },
                  Price: {
                    cost: v.cost,
                    cash: null,
                    agreementPrice: null,
                    card: null,
                    bank: null,
                    installment: null,
                    installmentRate: null,
                    bankNet: null,
                    card2: null,
                    bank2: null,
                    installment2: null,
                    installmentRate2: null,
                    bankNet2: null,
                    card3: null,
                    bank3: null,
                    installment3: null,
                    installmentRate3: null,
                    bankNet3: null,
                    net: null,
                    gain: null,
                    wallet: null,
                    price: null,
                    qr_price: null,
                  },
                  Customer: {
                    name: v.Customer?.name,
                    phone: v.Customer?.phone,
                  },
                })
              } else {
                //clear list
                //clear body
                setEditBody({
                  id: null,
                  branchId: branchId,
                  sku: "",
                  product_definition: "",
                  name: "",
                  serial_number: "",
                  description: "",
                  vat: 18,
                  barcode: "",
                  agreementNumber: "",
                  date: date,
                  k_cat: null,
                  k_cat_sub: null,
                  k_cat_sub_alt: null,
                  person: null,
                  QRCode: {
                    code: "",
                    User: null,
                    QR: null,
                    KASA2: null,
                  },
                  Price: {
                    cost: null,
                    cash: null,
                    agreementPrice: null,
                    card: null,
                    bank: null,
                    installment: null,
                    installmentRate: null,
                    bankNet: null,
                    card2: null,
                    bank2: null,
                    installment2: null,
                    installmentRate2: null,
                    bankNet2: null,
                    card3: null,
                    bank3: null,
                    installment3: null,
                    installmentRate3: null,
                    bankNet3: null,
                    net: null,
                    gain: null,
                    wallet: null,
                    price: null,
                    qr_price: null,
                  },
                  Customer: {
                    name: "",
                    phone: "",
                  },
                })
              }

              setModal(true)
            }}
          />
          <CustomModal
            toggle={() => {
              setModal(!modal)
            }}
            open={modal}
            isEdit={isEdit}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            body={editBody}
            id={selectedId}
            banks={banks}
            k_cat={k_cat}
            k_cat_sub={k_cat_sub}
            k_cat_sub_alt={k_cat_sub_alt}
            persons={persons}
          />

          <DailyModal
            toggle={() => {
              setModalDaily(!modalDaily)
            }}
            open={modalDaily}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            body={dailyData}
            id={selectedId}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

KasaSell.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(KasaSell)
