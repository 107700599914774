import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux
import {
  kasaGetSell as onGetData,
  kasaResetAll as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasaSellDelete as onDelete,
  kasaGetDailyCash as onDailyCash,
  companiesResetAll as onCompaniesResetAll,
} from "store/actions"
import { currencyFormat } from "helpers/functions"
import { isEmpty } from "lodash"
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import Swal from "sweetalert2"
import "./../../../components/Common/custom.scss"
import "./../custom.scss"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import zipcelx from "zipcelx"
import Loading2 from "components/Common/Loading2"
import tr from 'date-fns/locale/tr' 
registerLocale("tr", tr)

const ReportsCategoriesCustom2 = props => {
  //meta title
  document.title = "Günlük Kar Raporu - Personel Bazlı"
  const dispatch = useDispatch()
  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )

  const [loading2, setLoading2] = useState(false)
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const [data, setData] = useState([])
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [_body, setBody] = useState({
    id: null,
    branchId: props.branchId,
    sku: "",
    product_definition: "",
    name: "",
    serial_number: "",
    description: "",
    vat: 18,
    barcode: "",
    agreementNumber: "",
    date: props.date,
    k_cat: null,
    k_cat_sub: null,
    k_cat_sub_alt: null,
    person: null,
    Price: {
      cost: null,
      cash: null,
      agreementPrice: null,
      card: null,
      bank: null,
      installment: null,
      installmentRate: null,
      bankNet: null,
      card2: null,
      bank2: null,
      installment2: null,
      installmentRate2: null,
      bankNet2: null,
      card3: null,
      bank3: null,
      installment3: null,
      installmentRate3: null,
      bankNet3: null,
      net: null,
      gain: null,
      wallet: null,
      price: null,
    },
    Customer: {
      name: "",
      phone: "",
    },
  })

  const changeInputMultiple = objs => {
    let b = { ..._body }
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }

  const {
    error,
    branches,
    reports,
    persons,
    loading,
    k_cat,
    k_cat_sub,
    k_cat_sub_alt,
  } = useSelector(state => ({
    error: state.Dashboard.error,
    branches: state.Companies.branches,
    reports: state.Dashboard.reports,
    persons: state.Companies.persons,
    loading: state.Dashboard.loading,
    k_cat: state.Companies.k_cat,
    k_cat_sub: state.Companies.k_cat_sub,
    k_cat_sub_alt: state.Companies.k_cat_sub_alt,
  }))

  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }

    if (isEmpty(persons)) {
      dispatch(onPersons())
    }
    if (isEmpty(k_cat)) {
      dispatch(onK_CAT())
    }
  }, [branches, branchId, error])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [branchId, error])

  const handle = async () => {
    setLoading2(true)
    let startDate = moment(dateRange[0]).format("DD.MM.YYYY")
    let endDate = moment(dateRange[1]).format("DD.MM.YYYY")

    let body = {
      startDate: startDate,
      endDate: endDate,
      branchId: branchId,
      // k_cat: _body.k_cat,
      // k_cat_sub: _body.k_cat_sub,
      // k_cat_sub_alt: _body.k_cat_sub_alt,
      // product_definition: _body.product_definition,
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_API + "/api/reports/v1/categories-custom-2",
      headers: {
        Authorization: authHeader().Authorization,
      },
      data: body,
    }

    await axios
      .request(config)
      .then(response => {
        setLoading2(false)
        console.log(JSON.stringify(response.data))
        if (response.data.status == true) {
          setData(response.data.data)
        } else {
          setData([])
        }
      })
      .catch(error => {
        setLoading2(false)
        console.log(error)
        setData([])
      })
  }

  const addDay = count => {
    let isVal = moment(date, "DD-MM-YYYY").add(count, "days").toDate()
    setDate(moment(isVal).format("DD-MM-YYYY"))
    //handle(moment(isVal).format("DD-MM-YYYY"), branchId)
  }

 
  const exportExcel = () => {
    const config = {
      filename: "Günlük Kar Raporu - Personel Bazlı",
      sheet: {
        data: [],
      },
    }
    const dataSet = config.sheet.data
    dataSet.push([
      { value: "", type: "string" },
      { value: "Personel", type: "string" },

      { value: "Cihaz-Fiziki", type: "string" },
      { value: "", type: "string" },
      { value: "", type: "string" },

      { value: "Cihaz-Taahütlü", type: "string" },
      { value: "", type: "string" },

      { value: "Aksesuar-Fiziki", type: "string" },
      { value: "", type: "string" },
      { value: "", type: "string" },

      { value: "Aksesuar-Taahütlü", type: "string" },
      { value: "", type: "string" },

      { value: "Turkcell", type: "string" },
      { value: "", type: "string" },
      { value: "", type: "string" },

      { value: "Diğer", type: "string" },
      { value: "", type: "string" },
      { value: "", type: "string" },
    ])
    dataSet.push([
      { value: "Tarih", type: "string" },

      { value: "Ad-Soyad", type: "string" },

      { value: "Adet", type: "string" },
      { value: "Ciro", type: "string" },
      { value: "Kar", type: "string" },

      { value: "Adet", type: "string" },
      { value: "Ciro", type: "string" },

      { value: "Adet", type: "string" },
      { value: "Ciro", type: "string" },
      { value: "Kar", type: "string" },

      { value: "Adet", type: "string" },
      { value: "Ciro", type: "string" },

      { value: "Adet", type: "string" },
      { value: "Ciro", type: "string" },
      { value: "Kar", type: "string" },

      { value: "Adet", type: "string" },
      { value: "Ciro", type: "string" },
      { value: "Kar", type: "string" },
    ])

    if (!isEmpty(data.list)) {
      for (let item of data.list) {
        dataSet.push([
          { value: item.date, type: "string" },
          { value: item.person, type: "string" },
          { value: item.report_1.count, type: "number" },
          { value: item.report_1.gain, type: "number" },
          { value: item.report_1.total, type: "number" },

          { value: item.report_1_2.count, type: "number" },
          { value: item.report_1_2.total, type: "number" },

          { value: item.report_2.count, type: "number" },
          { value: item.report_2.gain, type: "number" },
          { value: item.report_2.total, type: "number" },

          { value: item.report_2_2.count, type: "number" },
          { value: item.report_2_2.total, type: "number" },

          { value: item.report_3.count, type: "number" },
          { value: item.report_3.gain, type: "number" },
          { value: item.report_3.total, type: "number" },

          { value: item.report_4.count, type: "number" },
          { value: item.report_4.gain, type: "number" },
          { value: item.report_4.total, type: "number" },
        ])
      }
    }

    zipcelx(config)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Daily Gain Reports")}
          />
          <Loading2 status={loading2} />
          <Row className="mb-3">
            <Col md={2} xs={12} sm={6}>
              {branches && branches?.length > 1 && branchId != undefined ? (
                <select
                  className="form-select me-xxl-2 my-3 my-xxl-0 d-inline-block"
                  value={branchId}
                  onChange={e => {
                    setBranchId(e.target.value)
                  }}
                >
                  {branches.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </Col>
            <Col md={2}>
              <div className="aok-datepicker d-flex">
                <div className="search-box aok-datepicker me-xxl-2 my-3 my-xxl-0 d-inline-block border-radius-2">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Arama İşlemi
                      </span>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={update => {
                          setDateRange(update)
                        }}
                        className="form-control custom-picker"
                        isClearable={true}
                        placeholderText={`GG.AA.YYYY - GG.AA.YYYY`}
                        dateFormat="dd.MM.yyyy"
                        locale={"tr"}
                      />
                      {/* <DatePicker
                        onChange={value => {
                          setDate(moment(value).format("DD-MM-YYYY"))
                          handle(moment(value).format("DD-MM-YYYY"), branchId)
                        }}
                        id="search-bar-1"
                        className="form-control custom-picker"
                        placeholderText={`GG.AA.YYYY - GG.AA.YYYY`}
                        selected={moment(date, "DD-MM-YYYY").toDate()}
                        dateFormat="dd-MM-yyyy"
                        style={{ borderRadius: "2px", paddingLeft: "10px" }}
                      /> */}
                    </label>
                    <i className="bx bx-calendar-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div>
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    console.log(branchId)
                    console.log(dateRange)
                    console.log(_body)
                    if (isEmpty(branchId?.toString())) {
                      Swal.fire(
                        props.t("Error"),
                        "Şube Seçimi Zorunlu",
                        "error"
                      )
                    } else if (
                      isEmpty(dateRange) ||
                      dateRange.length != 2 ||
                      dateRange[0] == null ||
                      dateRange[1] == null
                    ) {
                      Swal.fire(
                        props.t("Error"),
                        "Tarih Başlangıç ve Bitişini Seçmek Zorunlu",
                        "error"
                      )
                    } else {
                      console.log(branchId)
                      console.log(dateRange)
                      console.log(_body)
                      await handle()
                    }
                  }}
                >
                  {props.t("Get Reports")}
                </Button>
              </div>
            </Col>
          </Row>
          <Row></Row>
          {!isEmpty(data?.list) ? (
            <Card className="mt-2 mb-2">
              <CardBody>
                <div className="alert alert-info">
                  {props.t("Daily Gain Reports")}
                  <Button
                    className="ms-4"
                    type="button"
                    color="success"
                    onClick={() => {
                      exportExcel()
                    }}
                  >
                    <i className="mdi mdi-microsoft-excel"></i>{" "}
                    {props.t("Export")}
                  </Button>
                </div>
                <div className="table-responsive">
                  <table className="react-table table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Personel</th>
                        <th colSpan={3}>Cihaz - Fiziki</th>
                        <th colSpan={2}>Cihaz - Taahütlü</th>
                        <th colSpan={3}>Aksesuar - Fiziki</th>
                        <th colSpan={2}>Aksesuar - Taahütlü</th>
                        <th colSpan={3}>Turkcell</th>
                        <th colSpan={3}>Diğer</th>
                      </tr>
                      <tr>
                        <td>Tarih</td>
                        <td>Ad-Soyad</td>
                        <td className="align-center">Adet</td>
                        <td className="align-right">Kar</td>
                        <td className="align-right">Ciro</td>

                        <td className="align-center">Adet</td>
                        <td className="align-right">Ciro</td>

                        <td className="align-center">Adet</td>
                        <td className="align-right">Kar</td>
                        <td className="align-right">Ciro</td>

                        <td className="align-center">Adet</td>
                        <td className="align-right">Ciro</td>

                        <td className="align-center">Adet</td>
                        <td className="align-right">Kar</td>
                        <td className="align-right">Ciro</td>

                        <td className="align-center">Adet</td>
                        <td className="align-right">Kar</td>
                        <td className="align-right">Ciro</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.list?.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.date}</td>
                          <td>{item.person}</td>
                          <td className="align-center">
                            {item.report_1.count}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_1.gain)}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_1.total)}
                          </td>

                          <td className="align-center">
                            {item.report_1_2.count}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_1_2.total)}
                          </td>

                          <td className="align-center">
                            {item.report_2.count}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_2.gain)}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_2.total)}
                          </td>

                          <td className="align-center">
                            {item.report_2_2.count}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_2_2.total)}
                          </td>

                          <td className="align-center">
                            {item.report_3.count}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_3.gain)}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_3.total)}
                          </td>

                          <td className="align-center">
                            {item.report_4.count}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_4.gain)}
                          </td>
                          <td className="align-right">
                            {currencyFormat(item.report_4.total)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <b>TOPLAM:</b>
                        </td>
                        <td></td>
                        <td className="align-center">
                          <b>
                            {!isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.report_1.count)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>
                            {currencyFormat(
                              !isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_1.gain)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0
                            )}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_1.total)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>

                        <td className="align-center">
                          <b>
                            {!isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.report_1_2.count)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_1_2.total)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>

                        <td className="align-center">
                          <b>
                            {!isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.report_2.count)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_2.gain)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_2.total)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>

                        <td className="align-center">
                          <b>
                            {!isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.report_2_2.count)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_2_2.total)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>

                        <td className="align-center">
                          <b>
                            {!isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.report_3.count)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_3.gain)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_3.total)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>

                        <td className="align-center">
                          <b>
                            {!isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.report_4.count)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0}
                          </b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_4.gain)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(!isEmpty(data?.list)
                                ? data?.list
                                    .map(item => item.report_4.total)
                                    .reduce((prev, next) => prev + (next || 0))
                                : 0)}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

ReportsCategoriesCustom2.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ReportsCategoriesCustom2)
