import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Confirm from "../pages/Authentication/Confirm"
import ChangePassword from "../pages/Authentication/ChangePassword"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Kasa
import Kasa from "../pages/Kasa/index"
import KasaSell from "../pages/Kasa/kasa_sell"
import KasaPaymentPurchase from "../pages/Kasa/kasa_payment_purchase"
import KasaBuy from "../pages/Kasa/kasa_buy"
import Banks from "../pages/Settings/banks"
import Persons from "../pages/Settings/persons"
//Reports
import ReportsCategories from "../pages/Reports/v1/reports-categories"

import ReportsCategoriesCustom1 from "../pages/Reports/v1/reports-categories-custom-1"
import ReportsCategoriesCustom2 from "../pages/Reports/v1/reports-categories-custom-2"
import ReportsCost1 from "../pages/Reports/v1/reports-cost-1"
import ReportsCategoriesByUser from "../pages/Reports/v1/reports-categories-by-user"


import Campaigns from "../pages/Campaigns/v1/index"
import CampaignDetails from "../pages/Campaigns/v1/details" 
import CampaignCreate from "../pages/Campaigns/v1/create"
import CampaignUpdate from "../pages/Campaigns/v1/update"
import CampaignCreateStep2 from "../pages/Campaigns/v1/create-step2" 
// import CampaignProductsStep4 from "../pages/Campaigns/v1/product-step4" 
import CampaignNewDataList from "../pages/Campaigns/v1/new-data-list"
import CampaignNewDataListControl from "../pages/Campaigns/v1/new-data-list-control"


import Coupons from "../pages/Coupons/v1/index"
import CouponDetails from "../pages/Coupons/v1/details" 
import CouponCreate from "../pages/Coupons/v1/create"
import CouponUpdate from "../pages/Coupons/v1/update" 
// import CouponProductsStep4 from "../pages/Coupons/v1/product-step4" 
// import CouponNewDataList from "../pages/Coupons/v1/new-data-list"
// import CouponNewDataListControl from "../pages/Coupons/v1/new-data-list-control"

import PuankList from "../pages/Campaigns/v1/puank-list"
import PuankListUserHistory from "../pages/Campaigns/v1/puank-list-user-history"

import GainSettings from "../pages/Gains/settings"

import AppUserList from "../pages/AppUsers/index"
import KasaSearch from "../pages/Kasa/kasa_search"

import { Role, authorize } from "helpers/authorize"

const authProtectedRoutes = authorize([Role.Dev])?[
  { path: "/dashboard", component: Dashboard },
  { path: "/v1/kasa", component: Kasa },
  { path: "/v1/kasa/sell", component: KasaSell },
  { path: "/v1/kasa/payment-purchase", component: KasaPaymentPurchase },
  { path: "/v1/kasa/buy", component: KasaBuy },
  { path: "/settings/banks", component: Banks },
  { path: "/settings/persons", component: Persons },
  { path: "/v1/kasa/search", component: KasaSearch},

  // Reports

  { path: "/v1/reports-categories", component: ReportsCategories },
  { path: "/v1/reports-categories-custom-1", component: ReportsCategoriesCustom1 },
  { path: "/v1/reports-categories-custom-2", component: ReportsCategoriesCustom2 },
  { path: "/v1/reports-cost-1", component: ReportsCost1 },
  { path: "/v1/reports-categories-by-user", component: ReportsCategoriesByUser },


  //Campaigns

  { path: "/v1/campaigns", component: Campaigns },
  {
    path: "/v1/campaigns/create/step2/:id",
    component: CampaignCreateStep2,
  }, 
  { path: "/v1/campaigns/create", component: CampaignCreate },
  { path: "/v1/campaigns/:id", component: CampaignDetails }, 
  // {
  //   path: "/v1/campaigns/create/step4/:id",
  //   component: CampaignProductsStep4,
  // },
  { path: "/v1/campaigns/update/:id", component: CampaignUpdate }, 
  {
    path: "/v1/campaigns/new-data-list/:id",
    component: CampaignNewDataList,
  },
  {
    path: "/v1/campaigns/new-data-list-control/:id",
    component: CampaignNewDataListControl,
  },
  {
    path: "/v1/campaigns/puank-list/:id",
    component: PuankList,
  },  
  {
    path: "/v1/profile/puank-list-user-history",
    component: PuankListUserHistory,
  },

  //Coupons
  { path: "/v1/coupons", component: Coupons }, 
  // {
  //   path: "/v1/coupons/create/step4/:id",
  //   component: CouponProductsStep4,
  // },
  { path: "/v1/coupons/update/:id", component: CouponUpdate }, 
  { path: "/v1/coupons/create", component: CouponCreate },
  { path: "/v1/coupons/:id", component: CouponDetails }, 
  // {
  //   path: "/v1/coupons/new-data-list/:id",
  //   component: CouponNewDataList,
  // },
  // {
  //   path: "/v1/coupons/new-data-list-control/:id",
  //   component: CouponNewDataListControl,
  // },  
  // {
  //   path: "/v1/coupons/puank-list/:id",
  //   component: PuankCouponList,
  // },  


  { path: "/settings/gain-settings", component: GainSettings},
  
  { path: "/v1/app-users", component: AppUserList},
  //{ path: "/v1/app-users/:id", component: AppUserList},

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]:[
  { path: "/dashboard", component: Dashboard },
  { path: "/v1/kasa", component: Kasa },
  { path: "/v1/kasa/sell", component: KasaSell },
  { path: "/v1/kasa/payment-purchase", component: KasaPaymentPurchase },
  { path: "/v1/kasa/buy", component: KasaBuy },
  // { path: "/settings/banks", component: Banks },
  // { path: "/settings/persons", component: Persons },
  { path: "/v1/kasa/search", component: KasaSearch},

  // Reports

  { path: "/v1/reports-categories", component: ReportsCategories },
  { path: "/v1/reports-categories-custom-1", component: ReportsCategoriesCustom1 },
  { path: "/v1/reports-categories-custom-2", component: ReportsCategoriesCustom2 },
  { path: "/v1/reports-cost-1", component: ReportsCost1 }, 
  { path: "/v1/reports-categories-by-user", component: ReportsCategoriesByUser },

  //Campaigns

  { path: "/v1/campaigns", component: Campaigns },
  {
    path: "/v1/campaigns/create/step2/:id",
    component: CampaignCreateStep2,
  }, 
  { path: "/v1/campaigns/create", component: CampaignCreate },
  { path: "/v1/campaigns/:id", component: CampaignDetails }, 
  // {
  //   path: "/v1/campaigns/create/step4/:id",
  //   component: CampaignProductsStep4,
  // },
  { path: "/v1/campaigns/update/:id", component: CampaignUpdate }, 
  {
    path: "/v1/campaigns/new-data-list/:id",
    component: CampaignNewDataList,
  },
  {
    path: "/v1/campaigns/new-data-list-control/:id",
    component: CampaignNewDataListControl,
  },
  {
    path: "/v1/campaigns/puank-list/:id",
    component: PuankList,
  },  
  {
    path: "/v1/profile/puank-list-user-history",
    component: PuankListUserHistory,
  },

  //Coupons
  { path: "/v1/coupons", component: Coupons }, 
  // {
  //   path: "/v1/coupons/create/step4/:id",
  //   component: CouponProductsStep4,
  // },
  { path: "/v1/coupons/update/:id", component: CouponUpdate }, 
  { path: "/v1/coupons/create", component: CouponCreate },
  { path: "/v1/coupons/:id", component: CouponDetails }, 
  // {
  //   path: "/v1/coupons/new-data-list/:id",
  //   component: CouponNewDataList,
  // },
  // {
  //   path: "/v1/coupons/new-data-list-control/:id",
  //   component: CouponNewDataListControl,
  // },  
  // {
  //   path: "/v1/coupons/puank-list/:id",
  //   component: PuankCouponList,
  // },  


  // { path: "/settings/gain-settings", component: GainSettings},
  
  // { path: "/v1/app-users", component: AppUserList},
  //{ path: "/v1/app-users/:id", component: AppUserList},

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: '/confirm/:id/:hash', component: Confirm},
  { path: '/changePassword/:id/:hash', component: ChangePassword}
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
