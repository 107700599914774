import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  ToastBody,
  ToastHeader,
} from "reactstrap"
//https://react-table-v7.tanstack.com/docs/examples/sorting
//https://www.material-react-table.com/docs/guides/data-columns#data-(accessor)-columns
import TableContainer from "../../components/Common/TableContainer"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//import "./datatables.scss"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux

import {
  kasa2GetSell as onGetData,
  kasaResetAll as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasa2SellDelete as onDelete,
  companiesResetAll as onCompaniesResetAll,
  kasa2SellAdd as onSellAdd,
  kasa2SellUpdate as onSellUpdate,
} from "store/actions"
import moment from "moment"
import { currencyFormat, replaceAll } from "helpers/functions"
import Custom_Payment_Purchase_Modal from "./custom_components/sell_payment_purchase_modal"
import Payment_Purchase_Selection_Modal from "./custom_components/payment_purchase_selection_modal"
import { isEmpty } from "lodash"

//https://sweetalert2.github.io/#examples
import Swal from "sweetalert2"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import axios from "axios"

const KasaPaymentPurchase = props => {
  const dispatch = useDispatch()

  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalSelection, setModalSelection] = useState(false)
  const [modalDaily, setModalDaily] = useState(false)
  const [editBody, setEditBody] = useState({})
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedId, setSelectedId] = useState()
  const [dailyData, setDailyData] = useState({})
  const [loadingCount, setLoadingCount] = useState(0)
  const [isToday, setIsToday] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [isDayReport, setIsDayReport] = useState()
  const {
    error,
    data,
    branches,
    banks,
    persons,
    loading,
    k_cat,
    k_cat_sub,
    k_cat_sub_alt,
    dailyCash,
    today,
    qrCheck,
  } = useSelector(state => ({
    error: state.Kasa.error,
    data: state.Kasa.data2,
    loading: state.Kasa.loading,
    qrCheck: state.Kasa.qrCheck,
    branches: state.Companies.branches,
    banks: state.Companies.banks,
    persons: state.Companies.persons,
    k_cat: state.Companies.k_cat,
    k_cat_sub: state.Companies.k_cat_sub,
    k_cat_sub_alt: state.Companies.k_cat_sub_alt,
    dailyCash: state.Kasa.dailyCash,
    today: state.Kasa.today,
  }))
  //meta title
  document.title = "Kasa | " + props.t("Payment - Purchase")
  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    // localStorage.setItem("date", date)
    if (date == moment().format("DD-MM-YYYY")) {
      setIsToday(true)
    } else {
      setIsToday(false)
    }
  }, [date])

  useEffect(() => {
    setModalSelection(false)
  }, [data])

  useEffect(() => {
    if (!modalSelection) {
      console.log("item null")
      setSelectedItem(null)
    }
  }, [modalSelection])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      console.log("default branch", branchId)
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }
  }, [branches, branchId])

  useEffect(() => {
    if (branchId != undefined && !isEmpty(date)) {
      console.log("get", branchId)
      dispatch(onGetData(date, branchId))
      if (isEmpty(banks)) {
        dispatch(onBanks())
      }
      if (isEmpty(persons)) {
        dispatch(onPersons())
      }
      if (isEmpty(k_cat)) {
        dispatch(onK_CAT())
      }
    }
  }, [branchId])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [error])

  useEffect(() => {
    setDailyData(dailyCash)
    customReportV1()
  }, [dailyCash])

  const Role = type => {
    if (branchId && branches) {
      if (branches.find(x => x.id == branchId)?.Role?.includes(type)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const columns = useMemo(
    () => [ 
      {
        Header: props.t("Product"),
        accessor: "name", 
        Cell: ({ value }) => (
          <span>{props.t(`k2_${value}`)}</span>
        ),
      },
      {
        Header: props.t("cash"),
        accessor: "Price.cash",
        Cell: ({ value }) => (
          <span className="float-end">{`${currencyFormat(value)}`}</span>
        ),
      },  
      {
        Header: props.t("QRCode"),
        accessor: "QRCode",
        Cell: ({ value }) => (
          <span>{`${value?.code || ''}`}</span>
        ),
      },  
      {
        Header: props.t("Customer"),
        accessor: "Customer.name",
      },
      {
        Header: props.t("Customer Phone"),
        accessor: "Customer.phone",
      },  
      {
        Header: props.t("Gain"),
        accessor: "Puank",
        Cell: ({ value }) => (
          <span className="float-end" title={`${value?value.description:''}`}>{`${value?currencyFormat(value.puank):''}`}</span>
        ),
      }, 
      {
        Header: props.t("Description"),
        accessor: "description",
      },  
      {
        Header: props.t("Action"),
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {/* {(isToday && Role("edit")) || Role("past-edit") ? (
              <Button
                className="btn-sm btn-light"
                onClick={() => {
                  let d = [...data]
                  let p = d.filter(x => x._id == value)
                  console.log(p)
                  if (p.length == 1) {
                    setSelectedId(value)
                    setIsEdit(true)
                    setEditBody(p[0])
                    setModal(true)
                  }
                }}
              >
                <i className="bx bx-edit-alt"></i>
              </Button>
            ) : null} */}
            {(isToday && Role("del")) || Role("past-del") ? (
              <Button
                className="btn-sm btn-light ms-2"
                onClick={() => {
                  Swal.fire({
                    title: "Silmek İstediğinize Emin Misiniz?",
                    text: "Bu İşlem Geri Alınamaz",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet, Sil",
                    cancelButtonText: "İptal",
                  }).then(result => {
                    if (result.isConfirmed) {
                      dispatch(onDelete(value))
                    }
                  })
                }}
              >
                <i className="bx bx-trash"></i>
              </Button>
            ) : null}
          </div>
        ),
      },
    ],
    [data]
  )

  const customReportV1 = async () => {
    setLoading2(true)
    let startDate = replaceAll(date, "-", ".")
    let endDate = replaceAll(date, "-", ".")

    let body = {
      startDate: startDate,
      endDate: endDate,
      branchId: branchId,
      // k_cat: _body.k_cat,
      // k_cat_sub: _body.k_cat_sub,
      // k_cat_sub_alt: _body.k_cat_sub_alt,
      // product_definition: _body.product_definition,
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_API + "/api/reports/v1/categories-custom-1",
      headers: {
        Authorization: authHeader().Authorization,
      },
      data: body,
    }

    await axios
      .request(config)
      .then(response => {
        setLoading2(false)
        console.log(JSON.stringify(response.data))
        if (response.data.status == true) {
          console.log("today report", response.data.data)
          if (response.data.data?.list.length == 1) {
            const item = response.data.data.list[0]
            setIsDayReport({
              cihaz: {
                kar: currencyFormat(item.report_1.gain),
              },
              aksesuar: {
                kar: currencyFormat(item.report_2.gain),
              },
              diger: {
                kar: currencyFormat(item.report_3.gain + item.report_4.gain),
              },
              total: {
                kar: currencyFormat(
                  item.report_1.gain +
                    item.report_2.gain +
                    item.report_3.gain +
                    item.report_4.gain
                ),
              },
            })
          }
        } else {
        }
      })
      .catch(error => {
        setLoading2(false)
        console.log(error)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ maxWidth: "100%" }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Sell")}
            breadcrumbItem={props.t("Payment - Purchase")}
          />
          <Card>
            <CardBody>
              <TableContainer
                loading={loading?.status || false}
                branches={branches}
                columns={columns}
                data={data}
                isGlobalFilter={true}
                isDateFilter={true}
                isAddOptions={
                  (Role("insert") && isToday) || Role("past-insert")
                    ? true
                    : false
                }
                customPageSize={50}
                handleOrderClicks={() => {
                  console.log("handleOrderClicks")
                  setIsEdit(false)
                  //setModal(true)
                  setModalSelection(true)
                }}
                handleDateFilter={v => {
                  console.log("handleDateFilter", v)
                  setDate(v)
                  dispatch(onResetData())
                  dispatch(onGetData(v, branchId))
                }}
                handleBranchId={v => {
                  setBranchId(v)
                }}
                className="custom-header-css fix-table"
                defaultBranchId={branchId}
                refArray={{
                  banks: banks,
                }}
              />
            </CardBody>
          </Card>

          <Payment_Purchase_Selection_Modal
            loading={loading?.status || false}
            toggle={() => {
              setModalSelection(!modalSelection)
            }}
            open={modalSelection}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            handleBody={(price, priceType, qrCode, description) => { 

              if (selectedId == null) {
                //Add
                dispatch(
                  onSellAdd({
                    branchId: branchId,
                    date: date,
                    k_cat: null,
                    k_cat_sub: null,
                    k_cat_sub_alt: null,
                    name: priceType,
                    Price: {
                      cash: price,
                    },
                    QRCode: qrCode,
                    description: description
                  })
                )
              } else {
                //update
                dispatch(
                  onSellUpdate(selectedId, {
                    id: selectedId,
                    branchId: branchId,
                    date: date,
                    k_cat: null,
                    k_cat_sub: null,
                    k_cat_sub_alt: null,
                    name: priceType,
                    Price: {
                      cash: price,
                    },
                    QRCode: qrCode,
                  })
                )
              } 
            }}
          />
          <Custom_Payment_Purchase_Modal
            toggle={() => {
              setModal(!modal)
            }}
            open={modal}
            isEdit={isEdit}
            date={date}
            branchId={branchId}
            branchName={branches.filter(x => x.id == branchId)[0]?.name}
            body={editBody}
            id={selectedId}
            banks={banks}
            k_cat={k_cat}
            k_cat_sub={k_cat_sub}
            k_cat_sub_alt={k_cat_sub_alt}
            persons={persons}
            selectedItem={selectedItem}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

KasaPaymentPurchase.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(KasaPaymentPurchase)
