import {
  PRODUCTS_RESET_ERROR,
  PRODUCTS_RESET,
  PRODUCTS_RESET_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  ADD_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  PRODUCT_RESET,
  PRODUCT_LOADING,
  IMPORT_XLSX_PRODUCTS_SUCCESS,
  IMPORT_XLSX_PRODUCTS_FAIL,
  GET_VARIANTS_SUCCESS,
  GET_VARIANT_SUCCESS,
  GET_VARIANTS_FAIL,
  GET_VARIANT_FAIL,
  ADD_VARIANT_SUCCESS,
  UPDATE_VARIANT_SUCCESS,
  DELETE_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  UPDATE_VARIANT_FAIL,
  DELETE_VARIANT_FAIL,
  GET_SERIES_LIST_SUCCESS,
  GET_SERIES_SUCCESS,
  ADD_SERIES_SUCCESS,
  UPDATE_SERIES_SUCCESS,
  DELETE_SERIES_SUCCESS,
  GET_SERIES_LIST_FAIL,
  GET_SERIES_FAIL,
  ADD_SERIES_FAIL,
  UPDATE_SERIES_FAIL,
  DELETE_SERIES_FAIL,
  GET_VARIANTS_V2_SUCCESS,
  GET_VARIANTS_V2_FAIL,
  GET_PRODUCTS_ALL_SUCCESS,
  GET_PRODUCTS_ALL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  tableOptions: null,
  variants: [],
  variantsTableOptions: null,
  seriesList: [],
  seriesListTableOptions: null,
  series: {},
  variant: {},
  importData: null,
  product: {},
  error: null,
  success: null,
  loading: true,
}

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        tableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
        loading:false
      }
    case GET_PRODUCTS_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        tableOptions: {},
        loading:false
      }
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload?.data,
        loading:false
      }
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }
    case IMPORT_XLSX_PRODUCTS_SUCCESS:
      return {
        ...state,
        importData: action.payload?.data,
      }

    case GET_VARIANTS_SUCCESS:
    case GET_VARIANTS_V2_SUCCESS:
      return {
        ...state,
        variants: action.payload?.data,
        variantsTableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
      }
    case GET_VARIANT_SUCCESS:
      return {
        ...state,
        variant: action.payload?.data,
      }

    case ADD_VARIANT_SUCCESS:
      return {
        ...state,
        variants: [action.payload.data, ...state.variants],
      }
    case UPDATE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: state.variants.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: state.variants.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case GET_SERIES_LIST_SUCCESS:
      return {
        ...state,
        seriesList: action.payload?.data,
        seriesListTableOptions: {
          productCount: action.payload?.tableOptions.productCount,
          pageCount: action.payload?.tableOptions.pageCount,
          productTotal: action.payload?.tableOptions.productTotal,
        },
      }
    case GET_SERIES_SUCCESS:
      return {
        ...state,
        series: action.payload?.data,
      }

    case ADD_SERIES_SUCCESS:
      return {
        ...state,
        seriesList: [action.payload.data, ...state.seriesList],
      }
    case UPDATE_SERIES_SUCCESS:
      return {
        ...state,
        seriesList: state.seriesList.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_SERIES_SUCCESS:
      return {
        ...state,
        seriesList: state.seriesList.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case GET_PRODUCTS_FAIL:
    case GET_PRODUCTS_ALL_FAIL:
    case GET_PRODUCT_FAIL:
    case ADD_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case DELETE_PRODUCT_FAIL:
    case IMPORT_XLSX_PRODUCTS_FAIL:
    case GET_VARIANTS_FAIL:
    case GET_VARIANTS_V2_FAIL:
    case GET_VARIANT_FAIL:
    case ADD_VARIANT_FAIL:
    case UPDATE_VARIANT_FAIL:
    case DELETE_VARIANT_FAIL:
    case GET_SERIES_LIST_FAIL:
    case GET_SERIES_FAIL:
    case ADD_SERIES_FAIL:
    case UPDATE_SERIES_FAIL:
    case DELETE_SERIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case PRODUCTS_RESET_SUCCESS:
      return {
        ...state,
        success: null,
        importData: null,
      }
    case PRODUCTS_RESET_ERROR:
      return {
        ...state,
        error: null,
      }
    case PRODUCT_RESET:
      return {
        ...state,
        product: {},
        branch: {},
        importData: null,
      }
    case PRODUCTS_RESET:
      return {
        ...state,
        data: [],
        product: null,
        error: null,
        success: null,
        importData: null,
        tableOptions: null,
        variants: [],
        variant: {},
        variantsTableOptions: null,
        seriesList: [],
        seriesListTableOptions: null,
        series: {},
      }
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default Products
