import usFlag from "../assets/images/flags/us.jpg" 
import trFlag from "../assets/images/flags/turkey.png"

const languages = { 
  tr: {
    label: "Türkçe",
    flag: trFlag,
  }, 
  // en: {
  //   label: "English",
  //   flag: usFlag,
  // },
}

export default languages
