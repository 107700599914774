import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Card, CardBody, CardHeader, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getBanks as onBanks } from "store/actions"
import { isEmpty } from "lodash"
//redux

const Banks = props => {
  const dispatch = useDispatch()
  const { error, banks } = useSelector(state => ({
    error: state.Companies.error,
    banks: state.Companies.banks,
  }))
  //meta title
  document.title = "Banks"
  useEffect(() => {
    if (isEmpty(banks)) {
      dispatch(onBanks())
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Settings")}
            breadcrumbItem={props.t("Banks")}
          />
          {banks?.map((bank, idx) => (
            <Row key={idx}>
              <Col md={12}>
                <h4>{bank.title}</h4>
              </Col>
              {bank?.installments?.map((item, idx2) => (
                <Col key={idx2} md={1}>
                  <Card> 
                    <CardBody>
                      <Label>{item.title}</Label>
                      <p className="m-0">%{item.commission}</p> 
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

Banks.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Banks)
