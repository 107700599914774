const terms_step_1 = [
  { value: "1", label: "Her Alışverişte Geçerli" },
  { value: "2", label: "X Adet Al Y Adet Öde" },
  { value: "3", label: "X Ürünü Alana Y Ürünü Hediye" },
]
const terms_step_2 = [
  {
    parent:["1"],
    value: "1",
    label: "TL Hedefi",
    description:
      "Belirtilen TL Aralıkları İçin Geçerlidir",
  },
  {
    parent:["2"],
    value: "2",
    label: "Adet Hedefi",
    description:
      "Belirtilen Ürün Adedi Satışında Aşağıdaki Koşul Uygulanır",
  },
  {
    parent:["3"],
    value: "3",
    label: "Ürün Hedefi",
    description:
      "Belirtilen Ürün Satın Alındığında Aşağıdaki Kuşullar Geçerlidir",
  }
]

const default_term_step_1_object = {
    startTL: "0",
    endTL: "",
    gainTL: ""
  };
const default_term_step_2_object = {
    count: "0",
    buyCount: "", 
  };
const default_variant = {
  _id:"",
  name:"",
  barcode:"", 
}


const terms_product_step_1 = [
  { value: "all", label: "Ürünlerin Hepsi", description: "Sisteme Kayıtlı Tüm Ürünler Dahil Olur", },
  { value: "brand-categories", label: "Marka ve/veya Kategoriye Göre", description: "Seçilen Marka ve Kategorilere Ait Ürünler Dahil Olur" }, 
  { value: "custom", label: "Bazı Ürünler", description: "Sadece Seçilen Ürünler Dahil Olur",},
]

export { terms_step_1, terms_step_2, default_term_step_1_object, default_term_step_2_object, default_variant, terms_product_step_1}