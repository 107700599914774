import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
  Card,
  CardBody,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import { isEmpty } from "lodash"

const DashboardCard = props => {
  return (
    <Col md={3}>
      <Card>
        <CardBody>
            <b>{props.title}</b>
            <div>{props.val}</div>
        </CardBody>
      </Card>
    </Col>
  )
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  val: PropTypes.any,
}

export default withRouter(withTranslation()(DashboardCard))
