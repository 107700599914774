import {
  GET_APP_USERS_SUCCESS,
  GET_APP_USERS_FAIL,
  GET_APP_USERS,
  ADD_APP_USER_SUCCESS,
  ADD_APP_USER_FAIL,
  ADD_APP_USER, 
  DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_FAIL,
  DELETE_APP_USER,
  APP_USER_LOADING,
   
} from "./actionTypes"


 

//APP_USERS
export const getAppUsersSuccess = (actionType, data, tableOptions) => ({
  type: GET_APP_USERS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getAppUsersFail = (actionType, error) => ({
  type: GET_APP_USERS_FAIL,
  payload: { actionType, error },
})

export const getAppUsers = (parameters) => ({
  type: GET_APP_USERS,
  payload: {parameters},
})

export const getAppUserSuccess = (actionType, data) => ({
  type: GET_APP_USER_SUCCESS,
  payload: { actionType, data },
})

export const getAppUserFail = (actionType, error) => ({
  type: GET_APP_USER_FAIL,
  payload: { actionType, error },
})

export const getAppUser = id => ({
  type: GET_APP_USER,
  payload: { id },
})
 
export const deleteAppUserSuccess = (actionType, data) => ({
  type: DELETE_APP_USER_SUCCESS,
  payload: { actionType, data },
})

export const deleteAppUserFail = (actionType, error) => ({
  type: DELETE_APP_USER_FAIL,
  payload: { actionType, error },
})

export const deleteAppUser = (id, history) => ({
  type: DELETE_APP_USER,
  payload: { id, history },
})
 

export const appUserLoading = status => ({
  type: APP_USER_LOADING,
  payload: { status },
})