import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getCampaignsSuccess,
  getCampaignsFail,
  addCampaignSuccess,
  addCampaignFail,
  getCampaignSuccess,
  getCampaignFail,
  updateCampaignSuccess,
  updateCampaignFail,
  deleteCampaignSuccess,
  deleteCampaignFail,
  campaignLoading,
  addTermCampaignSuccess,
  addTermCampaignFail,
  updateTermCampaignSuccess,
  updateTermCampaignFail,
  termsCampaignSuccess,
  termsCampaignFail,
  termCampaignSuccess,
  termCampaignFail,
  deleteTermCampaignSuccess,
  deleteTermCampaignFail,
  getBranchGroupByCampaignSuccess,
  getBranchGroupByCampaignFail,
  updateBranchGroupByCampaignSuccess,
  updateBranchGroupByCampaignFail,
  postCampaignProductTypeSuccess,
  postCampaignProductTypeFail,
  postCampaignStatusSuccess,
  postCampaignStatusFail,
  campaignSuccess,
  postCampaignNewDataSuccess,
  postCampaignNewDataFail,
  getCampaignNewDataSuccess,
  getCampaignNewDataFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCampaigns,
  addCampaign,
  updateCampaign,
  deleteCampaign,
  getCampaign,
  addTermCampaign,
  updateTermCampaign,
  getTermsCampaign,
  getTermCampaign,
  deleteTermCampaign,
  getBranchGroupByCampaign,
  updateBranchGroupByCampaign,
  postCampaignProductType_Step4,
  postCampaignStatus,
  postCampaignNewData,
  getCampaignNewData,
} from "../../helpers/fakebackend_helper"
import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_FAIL,
  GET_CAMPAIGNS_SUCCESS,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN,
  ADD_CAMPAIGN_FAIL,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAIL,
  GET_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN,
  ADD_TERM_CAMPAIGN_FAIL,
  ADD_TERM_CAMPAIGN_SUCCESS,
  ADD_TERM_CAMPAIGN,
  UPDATE_TERM_CAMPAIGN_SUCCESS,
  UPDATE_TERM_CAMPAIGN_FAIL,
  UPDATE_TERM_CAMPAIGN,
  TERMS_CAMPAIGN_SUCCESS,
  TERMS_CAMPAIGN_FAIL,
  TERM_CAMPAIGN_SUCCESS,
  TERM_CAMPAIGN_FAIL,
  TERMS_CAMPAIGN,
  TERM_CAMPAIGN,
  DELETE_TERM_CAMPAIGN,
  DELETE_TERM_CAMPAIGN_SUCCESS,
  DELETE_TERM_CAMPAIGN_FAIL,
  GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS,
  GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL,
  GET_BRANCHGROUPS_BY_CAMPAIGN,
  UPDATE_CAMPAIGN_STEP3,
  UPDATE_CAMPAIGN_STEP3_SUCCESS,
  UPDATE_CAMPAIGN_STEP3_FAIL,
  POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS,
  POST_CAMPAIGN_PRODUCT_TYPE_FAIL,
  POST_CAMPAIGN_PRODUCT_TYPE,
  POST_CAMPAIGN_STATUS_SUCCESS,
  POST_CAMPAIGN_STATUS_FAIL,
  POST_CAMPAIGN_STATUS,
  POST_CAMPAIGN_NEW_DATA_SUCCESS,
  POST_CAMPAIGN_NEW_DATA_FAIL,
  POST_CAMPAIGN_NEW_DATA,
  GET_CAMPAIGN_NEW_DATA_SUCCESS,
  GET_CAMPAIGN_NEW_DATA_FAIL,
  GET_CAMPAIGN_NEW_DATA,
} from "./actionTypes"

//CAMPAIGNS
function* getCampaignsFetch({ payload: {} }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(getCampaigns)
    if (response.status) {
      yield put(
        getCampaignsSuccess(GET_CAMPAIGNS_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getCampaignsFail(
          GET_CAMPAIGNS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(getCampaignsFail(GET_CAMPAIGNS_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* addCampaignFetch({ payload: { data, file, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(addCampaign, data, file)
    if (response.status) {
      yield put(addCampaignSuccess(ADD_CAMPAIGN_SUCCESS, response.data))
      //history.push(`/v1/campaigns/create/step2/${response.data._id}?l=new`)
      history.push(`/v1/campaigns/${response.data._id}`)
    } else {
      yield put(
        addCampaignFail(
          ADD_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }

    yield put(campaignLoading(false))
  } catch (error) {
    yield put(addCampaignFail(ADD_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* updateCampaignFetch({ payload: { id, data, file, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(updateCampaign, id, data, file)
    if (response.status) {
      yield put(updateCampaignSuccess(UPDATE_CAMPAIGN_SUCCESS, response.data))
      //history.push("/v1/campaigns")
      //history.push(`/v1/campaigns/create/step2/${id}?l=new`)
      history.push(`/v1/campaigns/${id}`)
    } else {
      yield put(
        updateCampaignFail(
          UPDATE_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(updateCampaignFail(UPDATE_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* deleteCampaignFetch({ payload: { id, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(deleteCampaign, id)
    if (response.status) {
      yield put(deleteCampaignSuccess(DELETE_CAMPAIGN_SUCCESS, response.data))
      history.push("/v1/campaigns")
    } else {
      yield put(
        deleteCampaignFail(
          DELETE_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(deleteCampaignFail(DELETE_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* getCampaignByIdFetch({ payload: { id } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(getCampaign, id)
    if (response.status) {
      yield put(getCampaignSuccess(GET_CAMPAIGN_SUCCESS, response.data))
    } else {
      yield put(
        getCampaignFail(
          GET_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(getCampaignFail(GET_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* getTermsCampaignFetch({ payload: { id, level } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(getTermsCampaign, id, level)
    if (response.status) {
      yield put(termsCampaignSuccess(TERMS_CAMPAIGN_SUCCESS, response.data))
    } else {
      yield put(
        termsCampaignFail(
          TERMS_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(termsCampaignFail(TERMS_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* getTermCampaignFetch({ payload: { id, level } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(getTermCampaign, id, level)
    if (response.status) {
      yield put(termCampaignSuccess(TERM_CAMPAIGN_SUCCESS, response.data))
    } else {
      yield put(
        termCampaignFail(
          TERM_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(termCampaignFail(TERM_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* addTempCampaignFetch({ payload: { id, data, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(addTermCampaign, id, data)
    if (response.status) {
      yield put(
        addTermCampaignSuccess(ADD_TERM_CAMPAIGN_SUCCESS, response.data)
      )
      history.push(`/v1/campaigns/${response.data._id}`)
      location.reload()
    } else {
      yield put(
        addTermCampaignFail(
          ADD_TERM_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }

    yield put(campaignLoading(false))
  } catch (error) {
    yield put(addTermCampaignFail(ADD_TERM_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* updateTempCampaignFetch({ payload: { id, level, data, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(updateTermCampaign, id, level, data)
    if (response.status) {
      yield put(
        updateTermCampaignSuccess(UPDATE_TERM_CAMPAIGN_SUCCESS, response.data)
      )
      history.push(`/v1/campaigns/${response.data._id}`)
      location.reload()
    } else {
      yield put(
        updateTermCampaignFail(
          UPDATE_TERM_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }

    yield put(campaignLoading(false))
  } catch (error) {
    yield put(updateTermCampaignFail(UPDATE_TERM_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* deleteTempCampaignFetch({ payload: { id, level, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(deleteTermCampaign, id, level)
    if (response.status) {
      yield put(
        deleteTermCampaignSuccess(DELETE_TERM_CAMPAIGN_SUCCESS, response.data)
      )
      history.push(`/v1/campaigns/${response.data._id}`)
      location.reload()
    } else {
      yield put(
        deleteTermCampaignFail(
          DELETE_TERM_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(deleteTermCampaignFail(DELETE_TERM_CAMPAIGN_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* getBranchGroupByCampaignFetch({ payload: { id } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(getBranchGroupByCampaign, id)
    if (response.status) {
      yield put(
        getBranchGroupByCampaignSuccess(
          GET_BRANCHGROUPS_BY_CAMPAIGN_SUCCESS,
          response.data,
          response.groupList,
          response.branchList
        )
      )
    } else {
      yield put(
        getBranchGroupByCampaignFail(
          GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(
      getBranchGroupByCampaignFail(GET_BRANCHGROUPS_BY_CAMPAIGN_FAIL, error)
    )
    yield put(campaignLoading(false))
  }
}

function* updateBranchGroupByCampaignFetch({ payload: { id, data, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(updateBranchGroupByCampaign, id, data)
    if (response.status) {
      yield put(
        updateBranchGroupByCampaignSuccess(
          UPDATE_CAMPAIGN_STEP3_SUCCESS,
          response.data
        )
      )
      //history.push(`/v1/campaigns/create/step4/${id}`)
      history.push(`/v1/campaigns/${id}`)
    } else {
      yield put(
        updateBranchGroupByCampaignFail(
          UPDATE_CAMPAIGN_STEP3_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(
      updateBranchGroupByCampaignFail(UPDATE_CAMPAIGN_STEP3_FAIL, error)
    )
    yield put(campaignLoading(false))
  }
}

function* postCampaignProductTypeFetch({ payload: { id, data, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(postCampaignProductType_Step4, id, data)
    if (response.status) {
      yield put(
        postCampaignProductTypeSuccess(
          POST_CAMPAIGN_PRODUCT_TYPE_SUCCESS,
          response.data
        )
      )
      history.push(`/v1/campaigns/${id}`)
    } else {
      yield put(
        postCampaignProductTypeFail(
          POST_CAMPAIGN_PRODUCT_TYPE_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(
      postCampaignProductTypeFail(POST_CAMPAIGN_PRODUCT_TYPE_FAIL, error)
    )
    yield put(campaignLoading(false))
  }
}

function* postCampaignStatusFetch({ payload: { id, data, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(postCampaignStatus, id, data)
    if (response.status) {
      if (response.data.status == "wait") {
        yield put(
          campaignSuccess({
            status: "success",
            title: "Başarılı",
            message: "Kampanya Yayınlanmak Üzere Beklemeye Alındı",
          })
        )
      } else if (response.data.status == "stop") {
        yield put(
          campaignSuccess({
            status: "success",
            title: "Kampanya Durduruldu",
            message: "Kampanya Başarıyla Durduruldu",
          })
        )
      } else if (response.data.status == "design") {
        yield put(
          campaignSuccess({
            status: "success",
            title: "Kampanya İptal Edildi",
            message: "Kampanyayı Yeniden Düzenleyebilirsiniz.",
          })
        )
      }

      yield put(
        postCampaignStatusSuccess(POST_CAMPAIGN_STATUS_SUCCESS, response.data)
      )
    } else {
      yield put(
        postCampaignStatusFail(
          POST_CAMPAIGN_STATUS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(postCampaignStatusFail(POST_CAMPAIGN_STATUS_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* postCampaignNewDataFetch({ payload: { id, data, history, dataId } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(postCampaignNewData, id, data, dataId)
    if (response.status) {
      yield put(
        postCampaignNewDataSuccess(
          POST_CAMPAIGN_NEW_DATA_SUCCESS,
          response.data
        )
      )
      console.log("data", response.data)
      yield put(
        campaignSuccess({
          status: response.data.autoConfirm == true ? "success": "warning",
          title: "Kaydedildi",
          message:
            response.data.autoConfirm == true
              ? "Kampanya Başvurusu Başarıyla Kaydedildi"
              : response.data.autoMessage,
        })
      )
      history.push(`/v1/campaigns/new-data-list/${id}`)
    } else {
      yield put(
        postCampaignNewDataFail(
          POST_CAMPAIGN_NEW_DATA_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(postCampaignNewDataFail(POST_CAMPAIGN_NEW_DATA_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* getCampaignNewDataFetch({ payload: { id, campaignId, history } }) {
  try {
    yield put(campaignLoading(true))
    var response = yield call(getCampaignNewData, id, campaignId)
    if (response.status) {
      yield put(
        getCampaignNewDataSuccess(
          GET_CAMPAIGN_NEW_DATA_SUCCESS,
          response.data
        )
      )
        
    } else {
      yield put(
        getCampaignNewDataFail(
          GET_CAMPAIGN_NEW_DATA_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-CAMPAIGNS-1001"
        )
      )
    }
    yield put(campaignLoading(false))
  } catch (error) {
    yield put(getCampaignNewDataFail(GET_CAMPAIGN_NEW_DATA_FAIL, error))
    yield put(campaignLoading(false))
  }
}

function* campaignsSaga() {
  yield takeEvery(GET_CAMPAIGNS, getCampaignsFetch)
  yield takeEvery(GET_CAMPAIGN, getCampaignByIdFetch)
  yield takeEvery(ADD_CAMPAIGN, addCampaignFetch)
  yield takeEvery(UPDATE_CAMPAIGN, updateCampaignFetch)
  yield takeEvery(DELETE_CAMPAIGN, deleteCampaignFetch)

  yield takeEvery(TERMS_CAMPAIGN, getTermsCampaignFetch)
  yield takeEvery(TERM_CAMPAIGN, getTermCampaignFetch)
  yield takeEvery(ADD_TERM_CAMPAIGN, addTempCampaignFetch)
  yield takeEvery(UPDATE_TERM_CAMPAIGN, updateTempCampaignFetch)
  yield takeEvery(DELETE_TERM_CAMPAIGN, deleteTempCampaignFetch)

  yield takeEvery(GET_BRANCHGROUPS_BY_CAMPAIGN, getBranchGroupByCampaignFetch)
  yield takeEvery(UPDATE_CAMPAIGN_STEP3, updateBranchGroupByCampaignFetch)
  yield takeEvery(POST_CAMPAIGN_PRODUCT_TYPE, postCampaignProductTypeFetch)
  yield takeEvery(POST_CAMPAIGN_STATUS, postCampaignStatusFetch)

  yield takeEvery(GET_CAMPAIGN_NEW_DATA, getCampaignNewDataFetch)
  yield takeEvery(POST_CAMPAIGN_NEW_DATA, postCampaignNewDataFetch)
}

export default campaignsSaga
