import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  getPersons as onPersons,
  setPersons as onSetPersons,
  companiesResetError as onResetError,
  companiesResetSuccess as onResetSuccess,
} from "store/actions"
import { isEmpty } from "lodash"
import Swal from "sweetalert2"

//redux

const Persons = props => {
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const { error, persons, success } = useSelector(state => ({
    error: state.Companies.error,
    persons: state.Companies.persons,
    success: state.Companies.success,
  }))
  //meta title
  document.title = "Banks"
  useEffect(() => {
    if (isEmpty(persons)) {
      dispatch(onPersons())
    } else if (isEmpty(list)) {
      setList(persons)
    }

    if (!isEmpty(success)) {
      Swal.fire("İşlem Başarılı", success, "success").then(result => {
        if (result.isConfirmed) {
          dispatch(onResetSuccess())
        }
      })
    }
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [dispatch, persons, success, error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Settings")}
            breadcrumbItem={props.t("Persons")}
          />
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12} className="d-flex justify-content-end mb-2">
                      <Button
                        className="btn-sm btn-success me-2"
                        onClick={() => {
                          let l = [...list]
                          l.push({ name: "" })
                          setList(l)
                        }}
                      >
                        <i className="bx bx-plus"></i>
                        {props.t("Add")}
                      </Button>
                      <Button className="btn-sm btn-success" onClick={() => {
                        dispatch(onSetPersons({data:list}))
                      }}>
                        <i className="bx bx-save me-1"></i>
                        {props.t("Save")}
                      </Button>
                    </Col>
                  </Row>

                  {list.map((item, idx) => (
                    <Row key={idx} className="mt-2 d-flex align-items-center">
                      <Col md={9}>
                        <Input
                          type="text"
                          value={item.name}
                          onChange={e => {
                            let l = [...list]
                            l[idx].name = e.target.value
                            setList(l)
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Button
                          disabled={idx == 0 ? true : false}
                          className="btn-sm btn-info me-1"
                          onClick={() => {
                            let l = [...list]
                            let from = idx
                            let to = idx - 1
                            var f = l.splice(from, 1)[0]
                            l.splice(to, 0, f)
                            setList(l)
                          }}
                        >
                          <i className="bx bx-up-arrow"></i>
                        </Button>
                        <Button
                          disabled={idx == list.length - 1 ? true : false}
                          className="btn-sm btn-info me-1"
                          onClick={() => {
                            let l = [...list]
                            let from = idx
                            let to = idx + 1
                            var f = l.splice(from, 1)[0]
                            l.splice(to, 0, f)
                            setList(l)
                          }}
                        >
                          <i className="bx bx-down-arrow"></i>
                        </Button>
                        <Button
                          className="btn-sm btn-danger"
                          onClick={() => {
                            let l = [...list]
                            l.splice(idx, 1)
                            setList(l)
                          }}
                        >
                          <i className="bx bx-trash"></i>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Persons.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Persons)
