import {
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  GET_COUPONS,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAIL,
  ADD_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  UPDATE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  DELETE_COUPON,
  COUPONS_RESET,
  COUPONS_RESET_ERROR,
  COUPONS_RESET_SUCCESS,
  GET_COUPON,
  GET_COUPON_FAIL,
  GET_COUPON_SUCCESS,
  COUPON_RESET,
  COUPON_LOADING,
  TERMS_COUPON_SUCCESS,
  TERMS_COUPON,
  TERMS_COUPON_FAIL,
  TERM_COUPON,
  TERM_COUPON_FAIL,
  TERM_COUPON_SUCCESS,
  ADD_TERM_COUPON_SUCCESS,
  ADD_TERM_COUPON_FAIL,
  ADD_TERM_COUPON,
  UPDATE_TERM_COUPON,
  UPDATE_TERM_COUPON_FAIL,
  UPDATE_TERM_COUPON_SUCCESS,
  DELETE_TERM_COUPON_SUCCESS,
  DELETE_TERM_COUPON_FAIL,
  DELETE_TERM_COUPON,
  GET_BRANCHGROUPS_BY_COUPON,
  GET_BRANCHGROUPS_BY_COUPON_FAIL,
  GET_BRANCHGROUPS_BY_COUPON_SUCCESS,
  UPDATE_COUPON_STEP3_SUCCESS,
  UPDATE_COUPON_STEP3_FAIL,
  UPDATE_COUPON_STEP3,
  POST_COUPON_PRODUCT_TYPE,
  POST_COUPON_PRODUCT_TYPE_FAIL,
  POST_COUPON_PRODUCT_TYPE_SUCCESS,
  POST_COUPON_STATUS,
  POST_COUPON_STATUS_FAIL,
  POST_COUPON_STATUS_SUCCESS,
  COUPON_SUCCESS,
  POST_COUPON_NEW_DATA,
  POST_COUPON_NEW_DATA_FAIL,
  POST_COUPON_NEW_DATA_SUCCESS,
  GET_COUPON_NEW_DATA,
  GET_COUPON_NEW_DATA_FAIL,
  GET_COUPON_NEW_DATA_SUCCESS,
  UPDATE_COUPON_NEW_DATA_STATUS,
  UPDATE_COUPON_NEW_DATA,
  UPDATE_COUPON_NEW_DATA_FAIL,
  UPDATE_COUPON_NEW_DATA_SUCCESS,
  DELETE_COUPON_NEW_DATA,
  DELETE_COUPON_NEW_DATA_FAIL,
  DELETE_COUPON_NEW_DATA_SUCCESS,
  UPDATE_COUPON_NEW_DATA_STATUS_FAIL,
  UPDATE_COUPON_NEW_DATA_STATUS_SUCCESS,
  GET_COUPON_NEW_DATA_LIST,
  GET_COUPON_NEW_DATA_LIST_SUCCESS,
  GET_COUPON_NEW_DATA_LIST_FAIL,
} from "./actionTypes"



export const couponLoading = status => ({
  type: COUPON_LOADING,
  payload: { status },
})

//COUPONS
export const getCouponsSuccess = (actionType, data, tableOptions) => ({
  type: GET_COUPONS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getCouponsFail = (actionType, error) => ({
  type: GET_COUPONS_FAIL,
  payload: { actionType, error },
})

export const getCoupons = () => ({
  type: GET_COUPONS,
  payload: {},
})

export const getCouponSuccess = (actionType, data) => ({
  type: GET_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const getCouponFail = (actionType, error) => ({
  type: GET_COUPON_FAIL,
  payload: { actionType, error },
})

export const getCoupon = id => ({
  type: GET_COUPON,
  payload: { id },
})

export const addCouponSuccess = (actionType, data) => ({
  type: ADD_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const addCouponFail = (actionType, error) => ({
  type: ADD_COUPON_FAIL,
  payload: { actionType, error },
})

export const addCoupon = (data, file, history) => ({
  type: ADD_COUPON,
  payload: { data, file, history },
})

export const updateCouponSuccess = (actionType, data) => ({
  type: UPDATE_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const updateCouponFail = (actionType, error) => ({
  type: UPDATE_COUPON_FAIL,
  payload: { actionType, error },
})

export const updateCoupon = (id, data, file, history) => ({
  type: UPDATE_COUPON,
  payload: { id, data, file, history },
})

export const deleteCouponSuccess = (actionType, data) => ({
  type: DELETE_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const deleteCouponFail = (actionType, error) => ({
  type: DELETE_COUPON_FAIL,
  payload: { actionType, error },
})

export const deleteCoupon = (id, history) => ({
  type: DELETE_COUPON,
  payload: { id, history },
})

export const termsCouponSuccess = (actionType, data) => ({
  type: TERMS_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const termsCouponFail = (actionType, error) => ({
  type: TERMS_COUPON_FAIL,
  payload: { actionType, error },
})

export const termsCoupon = id => ({
  type: TERMS_COUPON,
  payload: { id },
})

export const termCouponSuccess = (actionType, data) => ({
  type: TERM_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const termCouponFail = (actionType, error) => ({
  type: TERM_COUPON_FAIL,
  payload: { actionType, error },
})

export const termCoupon = (id, level) => ({
  type: TERM_COUPON,
  payload: { id, level },
})

export const addTermCouponSuccess = (actionType, data) => ({
  type: ADD_TERM_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const addTermCouponFail = (actionType, error) => ({
  type: ADD_TERM_COUPON_FAIL,
  payload: { actionType, error },
})

export const addTermCoupon = (id, data, history) => ({
  type: ADD_TERM_COUPON,
  payload: { id, data, history },
})

export const updateTermCouponSuccess = (actionType, data) => ({
  type: UPDATE_TERM_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const updateTermCouponFail = (actionType, error) => ({
  type: UPDATE_TERM_COUPON_FAIL,
  payload: { actionType, error },
})

export const updateTermCoupon = (id, level, data, history) => ({
  type: UPDATE_TERM_COUPON,
  payload: { id, level, data, history },
})

export const deleteTermCouponSuccess = (actionType, data) => ({
  type: DELETE_TERM_COUPON_SUCCESS,
  payload: { actionType, data },
})

export const deleteTermCouponFail = (actionType, error) => ({
  type: DELETE_TERM_COUPON_FAIL,
  payload: { actionType, error },
})

export const deleteTermCoupon = (id, level, history) => ({
  type: DELETE_TERM_COUPON,
  payload: { id, level, history },
})

//--
//Step3

export const getBranchGroupByCoupon = id => ({
  type: GET_BRANCHGROUPS_BY_COUPON,
  payload: { id },
})

export const getBranchGroupByCouponSuccess = (
  actionType,
  data,
  groupList,
  branchList
) => ({
  type: GET_BRANCHGROUPS_BY_COUPON_SUCCESS,
  payload: { actionType, data, groupList, branchList },
})

export const getBranchGroupByCouponFail = (actionType, error) => ({
  type: GET_BRANCHGROUPS_BY_COUPON_FAIL,
  payload: { actionType, error },
})

export const updateBranchGroupByCouponSuccess = (actionType, data) => ({
  type: UPDATE_COUPON_STEP3_SUCCESS,
  payload: { actionType, data },
})

export const updateBranchGroupByCouponFail = (actionType, error) => ({
  type: UPDATE_COUPON_STEP3_FAIL,
  payload: { actionType, error },
})

export const updateBranchGroupByCoupon = (id, data, history) => ({
  type: UPDATE_COUPON_STEP3,
  payload: { id, data, history },
})

//step4 product type
export const postCouponProductType = (id, data, history) => ({
  type: POST_COUPON_PRODUCT_TYPE,
  payload: { id, data, history },
})

export const postCouponProductTypeFail = (actionType, error) => ({
  type: POST_COUPON_PRODUCT_TYPE_FAIL,
  payload: { actionType, error },
})

export const postCouponProductTypeSuccess = (id, data, history) => ({
  type: POST_COUPON_PRODUCT_TYPE_SUCCESS,
  payload: { id, data, history },
})

//status change

export const postCouponStatus = (id, data, history) => ({
  type: POST_COUPON_STATUS,
  payload: { id, data, history },
})

export const postCouponStatusFail = (actionType, error) => ({
  type: POST_COUPON_STATUS_FAIL,
  payload: { actionType, error },
})

export const postCouponStatusSuccess = (id, data, history) => ({
  type: POST_COUPON_STATUS_SUCCESS,
  payload: { id, data, history },
})

//NEW DATA

export const getCouponNewDataList = (id, data, history) => ({
  type: GET_COUPON_NEW_DATA_LIST,
  payload: { id, data, history },
})

export const getCouponNewDataListFail = (actionType, error) => ({
  type: GET_COUPON_NEW_DATA_LIST_FAIL,
  payload: { actionType, error },
})

export const getCouponNewDataListSuccess = (id, data, history) => ({
  type: GET_COUPON_NEW_DATA_LIST_SUCCESS,
  payload: { id, data, history },
})

export const getCouponNewData = (id, couponId, history) => ({
  type: GET_COUPON_NEW_DATA,
  payload: { id, couponId, history },
})

export const getCouponNewDataFail = (actionType, error) => ({
  type: GET_COUPON_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const getCouponNewDataSuccess = (id, data, history) => ({
  type: GET_COUPON_NEW_DATA_SUCCESS,
  payload: { id, data, history },
})

export const postCouponNewData = (id, data, history, dataId) => ({
  type: POST_COUPON_NEW_DATA,
  payload: { id, data, history, dataId },
})

export const postCouponNewDataFail = (actionType, error) => ({
  type: POST_COUPON_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const postCouponNewDataSuccess = (id, data, history) => ({
  type: POST_COUPON_NEW_DATA_SUCCESS,
  payload: { id, data, history },
})

export const updateCouponNewData = (id, data, history) => ({
  type: UPDATE_COUPON_NEW_DATA,
  payload: { id, data, history },
})

export const updateCouponNewDataFail = (actionType, error) => ({
  type: UPDATE_COUPON_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const updateCouponNewDataSuccess = (id, data, history) => ({
  type: UPDATE_COUPON_NEW_DATA_SUCCESS,
  payload: { id, data, history },
})

export const deleteCouponNewData = (id, couponId,  history) => ({
  type: DELETE_COUPON_NEW_DATA,
  payload: { id, couponId, history },
})

export const deleteCouponNewDataFail = (actionType, error) => ({
  type: DELETE_COUPON_NEW_DATA_FAIL,
  payload: { actionType, error },
})

export const deleteCouponNewDataSuccess = (actionType, data) => ({
  type: DELETE_COUPON_NEW_DATA_SUCCESS,
  payload: { actionType, data },
})

export const updateCouponNewDataStatus = (id, data, history) => ({
  type: UPDATE_COUPON_NEW_DATA_STATUS,
  payload: { id, data, history },
})

export const updateCouponNewDataStatusFail = (actionType, error) => ({
  type: UPDATE_COUPON_NEW_DATA_STATUS_FAIL,
  payload: { actionType, error },
})

export const updateCouponNewDataStatusSuccess = (id, data, history) => ({
  type: UPDATE_COUPON_NEW_DATA_STATUS_SUCCESS,
  payload: { id, data, history },
})

//-----
export const couponSuccess = data => ({
  type: COUPON_SUCCESS,
  payload: { data },
})

export const clearCoupon = () => ({
  type: COUPON_RESET,
})
export const couponsResetAll = () => ({
  type: COUPONS_RESET,
})

export const couponsResetError = () => ({
  type: COUPONS_RESET_ERROR,
})

export const couponsResetSuccess = () => ({
  type: COUPONS_RESET_SUCCESS,
})
