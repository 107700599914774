import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  kasaGetSuccess,
  kasaGetFail,
  kasaSellAddFail,
  kasaSellAddSuccess,
  kasaSellUpdateSuccess,
  kasaSellUpdateFail,
  kasaSellDeleteSuccess,
  kasaSellDeleteFail,
  kasa2GetSuccess,
  kasa2GetFail,
  kasa2SellAddFail,
  kasa2SellAddSuccess,
  kasa2SellUpdateSuccess,
  kasa2SellUpdateFail,
  kasa2SellDeleteSuccess,
  kasa2SellDeleteFail,
  kasaSearchGetSuccess,
  kasaSearchGetFail,
  kasaGetDailyCashSuccess,
  kasaGetDailyCashFail,
  kasaSetDailyCashFail,
  kasaSetDailyCashSuccess,
  kasaQrCodeCheckSuccess,
  kasaQrCodeCheckFail,
  kasaLoading,
  qrCodeSearchReset,
  kasaSearchSuccess,
  kasaSearchFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getKasaSell,
  addKasaSell,
  updateKasaSell,
  deleteKasaSell,
  getKasa2Sell,
  addKasa2Sell,
  updateKasa2Sell,
  deleteKasa2Sell,
  getDailyCash,
  setDailyCash,
  postQRCodeCheck,
  getKasaSearchSell,
  getKasaSearch,
} from "../../helpers/fakebackend_helper"
import {
  KASA_GET_SUCCESS,
  KASA_GET_FAIL,
  KASA_GET_SELL,
  KASA_ADD_SELL,
  KASA_ADD_SELL_FAIL,
  KASA_ADD_SELL_SUCCESS,
  KASA_UPDATE_SELL,
  KASA_UPDATE_SELL_FAIL,
  KASA_UPDATE_SELL_SUCCESS,
  KASA_DELETE_SELL,
  KASA_DELETE_SELL_FAIL,
  KASA_DELETE_SELL_SUCCESS,
  KASA2_GET_SUCCESS,
  KASA2_GET_FAIL,
  KASA2_GET_SELL,
  KASA2_ADD_SELL,
  KASA2_ADD_SELL_FAIL,
  KASA2_ADD_SELL_SUCCESS,
  KASA2_UPDATE_SELL,
  KASA2_UPDATE_SELL_FAIL,
  KASA2_UPDATE_SELL_SUCCESS,
  KASA2_DELETE_SELL,
  KASA2_DELETE_SELL_FAIL,
  KASA2_DELETE_SELL_SUCCESS,
  KASA_LOADING,
  KASA_SEARCH_GET_FAIL,
  KASA_SEARCH_GET_SELL,
  KASA_SEARCH_GET_SUCCESS,
  KASA_GET_DAILY_CASH,
  KASA_GET_DAILY_CASH_SUCCESS,
  KASA_GET_DAILY_CASH_FAIL,
  KASA_SET_DAILY_CASH,
  KASA_SET_DAILY_CASH_SUCCESS,
  KASA_SET_DAILY_CASH_FAIL,
  KASA_QRCODE_CHECK_SUCCESS,
  KASA_QRCODE_CHECK_FAIL,
  KASA_QRCODE_CHECK,
  KASA_SEARCH_SUCCESS,
  KASA_SEARCH_FAIL,
  KASA_SEARCH,
} from "./actionTypes"
import moment from "moment"

function* kasaGetSellFetch({ payload: { date, branchId } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(getKasaSell, date, branchId)

    if (response.status) {
      yield put(kasaGetSuccess(KASA_GET_SUCCESS, response.data))
    } else {
      yield put(
        kasaGetFail(
          KASA_GET_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1001"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(KASA_LOADING, false))
    yield put(kasaGetFail(KASA_GET_FAIL, error))
  }
}

function* kasaAddSellFetch({ payload: { body } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(addKasaSell, body)

    if (response.status) {
      yield put(kasaSellAddSuccess(KASA_ADD_SELL_SUCCESS, response.data))
      var response2 = yield call(getDailyCash, body.date, body.branchId)
      if (response2.status) {
        yield put(
          kasaGetDailyCashSuccess(
            KASA_GET_DAILY_CASH_SUCCESS,
            response2.data,
            response2.today
          )
        )
      }
    } else {
      yield put(
        kasaSellAddFail(
          KASA_ADD_SELL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1002"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(false))
    yield put(kasaSellAddFail(KASA_GET_FAIL, error))
  }
}

function* kasaUpdateSellFetch({ payload: { id, body } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(updateKasaSell, id, body)

    if (response.status) {
      yield put(kasaSellUpdateSuccess(KASA_UPDATE_SELL_SUCCESS, response.data))
      var response2 = yield call(
        getDailyCash,
        moment(body.date).format("DD-MM-YYYY"),
        body.branchId
      )
      if (response2.status) {
        yield put(
          kasaGetDailyCashSuccess(
            KASA_GET_DAILY_CASH_SUCCESS,
            response2.data,
            response2.today
          )
        )
      }
    } else {
      yield put(
        kasaSellUpdateFail(
          KASA_UPDATE_SELL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1003"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(false))
    yield put(kasaSellUpdateFail(KASA_UPDATE_SELL_FAIL, error))
  }
}

function* kasaDeleteSellFetch({ payload: { id } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(deleteKasaSell, id)

    if (response.status) {
      yield put(kasaSellDeleteSuccess(KASA_DELETE_SELL_SUCCESS, response.data))
      var response2 = yield call(
        getDailyCash,
        moment(response.data.date).format("DD-MM-YYYY"),
        response.data.branchId
      )
      if (response2.status) {
        yield put(
          kasaGetDailyCashSuccess(
            KASA_GET_DAILY_CASH_SUCCESS,
            response2.data,
            response2.today
          )
        )
      }
    } else {
      yield put(
        kasaSellDeleteFail(
          KASA_DELETE_SELL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1003"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(false))
    yield put(kasaSellDeleteFail(KASA_DELETE_SELL_FAIL, error))
  }
}

function* kasa2GetSellFetch({ payload: { date, branchId } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(getKasa2Sell, date, branchId)

    if (response.status) {
      yield put(kasa2GetSuccess(KASA2_GET_SUCCESS, response.data))
    } else {
      yield put(
        kasa2GetFail(
          KASA2_GET_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA2-1001"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(KASA2_LOADING, false))
    yield put(kasa2GetFail(KASA2_GET_FAIL, error))
  }
}

function* kasa2AddSellFetch({ payload: { body } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(addKasa2Sell, body)

    if (response.status) {
      yield put(kasa2SellAddSuccess(KASA2_ADD_SELL_SUCCESS, response.data))
    } else {
      yield put(
        kasa2SellAddFail(
          KASA2_ADD_SELL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA2-1002"
        )
      )
    }
    yield put(kasaLoading(false))
    yield put(qrCodeSearchReset())
  } catch (error) {
    yield put(kasaLoading(false))
    yield put(kasa2SellAddFail(KASA2_GET_FAIL, error))
    yield put(qrCodeSearchReset())
  }
}

function* kasa2UpdateSellFetch({ payload: { id, body } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(updateKasa2Sell, id, body)

    if (response.status) {
      yield put(
        kasa2SellUpdateSuccess(KASA2_UPDATE_SELL_SUCCESS, response.data)
      )
    } else {
      yield put(
        kasa2SellUpdateFail(
          KASA2_UPDATE_SELL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA2-1003"
        )
      )
    }
    yield put(qrCodeSearchReset())
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(false))
    yield put(qrCodeSearchReset())
    yield put(kasa2SellUpdateFail(KASA2_UPDATE_SELL_FAIL, error))
  }
}

function* kasa2DeleteSellFetch({ payload: { id } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(deleteKasa2Sell, id)

    if (response.status) {
      yield put(
        kasa2SellDeleteSuccess(KASA2_DELETE_SELL_SUCCESS, response.data)
      )
    } else {
      yield put(
        kasa2SellDeleteFail(
          KASA2_DELETE_SELL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA2-1003"
        )
      )
    }
    yield put(qrCodeSearchReset())
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(false))
    yield put(qrCodeSearchReset())
    yield put(kasa2SellDeleteFail(KASA2_DELETE_SELL_FAIL, error))
  }
}

function* kasaSearchGetSellFetch({ payload: { date, search } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(getKasaSearchSell, date, search)

    if (response.status) {
      yield put(kasaSearchGetSuccess(KASA_SEARCH_GET_SUCCESS, response.data))
    } else {
      yield put(
        kasaSearchGetFail(
          KASA_SEARCH_GET_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1001"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(KASA_LOADING, false))
    yield put(kasaSearchGetFail(KASA_SEARCH_GET_FAIL, error))
  }
}

function* kasaSearchFetch({ payload: { body } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(getKasaSearch, body)

    if (response.status) {
      yield put(kasaSearchSuccess(KASA_SEARCH_SUCCESS, response.data))
    } else {
      yield put(
        kasaSearchFail(
          KASA_SEARCH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1001"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(KASA_LOADING, false))
    yield put(kasaSearchFail(KASA_SEARCH_FAIL, error))
  }
}

function* kasaQRCodeCheckFetch({ payload: { qrCode, type } }) {
  try {
    yield put(kasaLoading(true))
    var response = yield call(postQRCodeCheck, { code: qrCode }, type)

    if (response.status) {
      yield put(
        kasaQrCodeCheckSuccess(KASA_QRCODE_CHECK_SUCCESS, response.data)
      )
    } else {
      yield put(
        kasaQrCodeCheckFail(
          KASA_QRCODE_CHECK_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-KASA-1001"
        )
      )
    }
    yield put(kasaLoading(false))
  } catch (error) {
    yield put(kasaLoading(KASA_LOADING, false))
    yield put(kasaQrCodeCheckFail(KASA_QRCODE_CHECK_FAIL, error))
  }
}

function* kasaGetDailyCashFetch({ payload: { date, branchId } }) {
  try {
    var response = yield call(getDailyCash, date, branchId)
    if (response.status) {
      yield put(
        kasaGetDailyCashSuccess(
          KASA_GET_DAILY_CASH_SUCCESS,
          response.data,
          response.today
        )
      )
    } else {
      yield put(
        kasaGetDailyCashFail(
          KASA_GET_DAILY_CASH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-DAILY-CASH-1005"
        )
      )
    }
  } catch (error) {
    yield put(kasaGetDailyCashFail(KASA_GET_DAILY_CASH_FAIL, error))
  }
}

function* kasaSetDailyCashFetch({ payload: { date, branchId, body } }) {
  try {
    var response = yield call(setDailyCash, date, branchId, body)
    if (response.status) {
      yield put(
        kasaSetDailyCashSuccess(
          KASA_SET_DAILY_CASH_SUCCESS,
          response.data,
          response.today
        )
      )
    } else {
      yield put(
        kasaSetDailyCashFail(
          KASA_SET_DAILY_CASH_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-DAILY-CASH-1005"
        )
      )
    }
  } catch (error) {
    yield put(kasaSetDailyCashFail(KASA_SET_DAILY_CASH_FAIL, error))
  }
}

function* kasaSaga() {
  yield takeEvery(KASA_GET_SELL, kasaGetSellFetch)
  yield takeEvery(KASA_ADD_SELL, kasaAddSellFetch)
  yield takeEvery(KASA_UPDATE_SELL, kasaUpdateSellFetch)
  yield takeEvery(KASA_DELETE_SELL, kasaDeleteSellFetch)

  yield takeEvery(KASA2_GET_SELL, kasa2GetSellFetch)
  yield takeEvery(KASA2_ADD_SELL, kasa2AddSellFetch)
  yield takeEvery(KASA2_UPDATE_SELL, kasa2UpdateSellFetch)
  yield takeEvery(KASA2_DELETE_SELL, kasa2DeleteSellFetch)

  yield takeEvery(KASA_SEARCH_GET_SELL, kasaSearchGetSellFetch)
  yield takeEvery(KASA_SEARCH, kasaSearchFetch)
  yield takeEvery(KASA_GET_DAILY_CASH, kasaGetDailyCashFetch)
  yield takeEvery(KASA_SET_DAILY_CASH, kasaSetDailyCashFetch)
  yield takeEvery(KASA_QRCODE_CHECK, kasaQRCodeCheckFetch)
}

export default kasaSaga
