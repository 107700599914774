import {
  COUPONS_RESET_ERROR,
  COUPONS_RESET,
  COUPONS_RESET_SUCCESS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  ADD_COUPON_SUCCESS,
  UPDATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  ADD_COUPON_FAIL,
  UPDATE_COUPON_FAIL,
  DELETE_COUPON_FAIL,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  COUPON_RESET,
  COUPON_LOADING,
  TERMS_COUPON_SUCCESS,
  TERM_COUPON_SUCCESS,
  ADD_TERM_COUPON_SUCCESS,
  UPDATE_TERM_COUPON_SUCCESS,
  TERMS_COUPON_FAIL,
  TERM_COUPON_FAIL,
  UPDATE_TERM_COUPON_FAIL,
  DELETE_TERM_COUPON_FAIL,
  ADD_TERM_COUPON_FAIL,
  DELETE_TERM_COUPON_SUCCESS,
  GET_BRANCHGROUPS_BY_COUPON_FAIL,
  GET_BRANCHGROUPS_BY_COUPON_SUCCESS,
  UPDATE_COUPON_STEP3_FAIL,
  UPDATE_COUPON_STEP3_SUCCESS,
  POST_COUPON_PRODUCT_TYPE_SUCCESS,
  POST_COUPON_PRODUCT_TYPE_FAIL,
  POST_COUPON_STATUS_SUCCESS,
  POST_COUPON_STATUS_FAIL,
  COUPON_SUCCESS,
  GET_COUPON_NEW_DATA_FAIL,
  POST_COUPON_NEW_DATA_FAIL,
  UPDATE_COUPON_NEW_DATA_FAIL,
  DELETE_COUPON_NEW_DATA_FAIL,
  UPDATE_COUPON_NEW_DATA_STATUS_FAIL,
  GET_COUPON_NEW_DATA_LIST_FAIL,
  GET_COUPON_NEW_DATA_LIST_SUCCESS,
  GET_COUPON_NEW_DATA_SUCCESS,
  UPDATE_COUPON_NEW_DATA_SUCCESS,
  DELETE_COUPON_NEW_DATA_SUCCESS,
  POST_COUPON_NEW_DATA_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  coupon: {},
  terms: [],
  term: {},
  newData: {},
  newDataList: [],
  error: null,
  success: null,
  loading: true,
  status_branches: [],
  status_groups: [],
}

const Coupons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
      }
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.payload?.data,
        status_branches: [],
        status_groups: [],
      }
    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
      }
    case UPDATE_COUPON_STEP3_SUCCESS:
    case POST_COUPON_PRODUCT_TYPE_SUCCESS:
    case POST_COUPON_STATUS_SUCCESS:
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.payload.data,
        data: state.data.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case TERMS_COUPON_SUCCESS:
      return {
        ...state,
        terms: action.payload?.data,
      }
    case TERM_COUPON_SUCCESS:
      return {
        ...state,
        term: action.payload?.data?.Terms,
      }

    case ADD_TERM_COUPON_SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.terms],
      }
    case UPDATE_TERM_COUPON_SUCCESS:
      return {
        ...state,
        term: action.payload.data,
        terms: state.terms?.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_TERM_COUPON_SUCCESS:
      return {
        ...state,
        terms: state.terms?.filter(
          x => x._id.toString() !== action.payload?.data?.toString()
        ),
      }
    case GET_BRANCHGROUPS_BY_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.payload?.data,
        status_branches: action.payload?.branchList || [],
        status_groups: action.payload?.groupList || [],
      }
    case UPDATE_COUPON_STEP3_SUCCESS:
      return {
        ...state,
        status_branches: [],
        status_groups: [],
      }

    case GET_COUPON_NEW_DATA_LIST_SUCCESS:
      return {
        ...state,
        newDataList: action.payload?.data,
      }
    case GET_COUPON_NEW_DATA_SUCCESS:
      return {
        ...state,
        newData: action.payload?.data,
      }
    case POST_COUPON_NEW_DATA_SUCCESS:
      return {
        ...state,
        newDataList: [action.payload.data, ...state.newDataList],
      }
    case UPDATE_COUPON_NEW_DATA_SUCCESS:
      return {
        ...state,
        newData: action.payload.data,
        newDataList: state.newDataList.map(x =>
          x._id.toString() === action.payload.data._id.toString()
            ? { x, ...action.payload.data }
            : x
        ),
      }
    case DELETE_COUPON_NEW_DATA_SUCCESS:
      return {
        ...state,
        newDataList: state.newDataList.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      }

    case GET_COUPONS_FAIL:
    case GET_COUPON_FAIL:
    case ADD_COUPON_FAIL:
    case UPDATE_COUPON_FAIL:
    case DELETE_COUPON_FAIL:
    case TERMS_COUPON_FAIL:
    case TERM_COUPON_FAIL:
    case ADD_TERM_COUPON_FAIL:
    case UPDATE_TERM_COUPON_FAIL:
    case DELETE_TERM_COUPON_FAIL:
    case GET_BRANCHGROUPS_BY_COUPON_FAIL:
    case UPDATE_COUPON_STEP3_FAIL:
    case POST_COUPON_PRODUCT_TYPE_FAIL:
    case POST_COUPON_STATUS_FAIL:
    case GET_COUPON_NEW_DATA_LIST_FAIL:
    case GET_COUPON_NEW_DATA_FAIL:
    case POST_COUPON_NEW_DATA_FAIL:
    case UPDATE_COUPON_NEW_DATA_FAIL:
    case DELETE_COUPON_NEW_DATA_FAIL:
    case UPDATE_COUPON_NEW_DATA_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case COUPONS_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case COUPONS_RESET_ERROR:
      return {
        ...state,
        error: null,
      }
    case COUPON_RESET:
      return {
        ...state,
        coupon: {},
        branch: {},
        terms: [],
        term: {},
      }
    case COUPONS_RESET:
      return {
        ...state,
        data: [],
        terms: [],
        term: {},
        coupon: null,
        error: null,
        success: null,
      }
    case COUPON_SUCCESS:
      return {
        ...state,
        success: action.payload.data,
      }
    case COUPON_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default Coupons
