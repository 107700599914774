import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbFilters"

//i18n
import { withTranslation } from "react-i18next"
import * as DOMPurify from "dompurify"

//redux
//import { coupons } from "./../../../common/data/coupon"

import {
  getCoupons as onGet,
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import { Link } from "react-router-dom"
import { replaceAll } from "helpers/functions"
import img_empty from "./../../../assets/images/jobs.png"
import Swal from "sweetalert2"
import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"
import { Role, authorize } from "helpers/authorize"

const Coupons = props => {
  //meta title
  document.title = props.t("Coupons")

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE

  const dispatch = useDispatch()
  const { error, data, loading } = useSelector(state => ({
    error: state.Coupons?.error,
    data: state.Coupons?.data,
    loading: state.Coupons?.loading || false,
  }))

  const breadcrumbItems = [{ title: props.t("Coupons"), link: "/" }]

  const [selectedItem, setSelectedItem] = useState("all")

  const [tempList, setTempList] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(data) && load == false) {
      dispatch(onGet())
      setLoad(true)
    } else {
      setTempList(data)
    }
  }, [data])

  const [filterItems, setFilterItems] = useState([
    {
      id: "all",
      title: props.t("Coupons"),
      badge: "badge badge-soft-success border-0 mb-2",
      default: "badge-soft-primary",
      role: [
        Role.Admin,
        Role.Coordinator,
        Role.Dev,
        Role.Guest,
        Role.Manager,
        Role.User,
      ],
    },
    {
      id: "continue",
      title: props.t("Coupon_Continues"),
      badge: "badge badge-soft-primary border-0 mb-2",
      default: "badge-soft-primary",
      role: [
        Role.Admin,
        Role.Coordinator,
        Role.Dev,
        Role.Guest,
        Role.Manager,
        Role.User,
      ],
    },
    {
      id: "wait",
      title: props.t("Coupon_Wait"),
      badge: "badge badge-soft-primary border-0 mb-2",
      default: "badge-soft-primary",
      role: [
        Role.Admin,
        Role.Coordinator,
        Role.Dev,
        Role.Guest,
        Role.Manager,
        Role.User,
      ],
    },
    {
      id: "design",
      title: props.t("Coupon_Design"),
      badge: "badge badge-soft-primary border-0 mb-2",
      default: "badge-soft-primary",
      role: [Role.Dev],
    },
    {
      id: "finish",
      title: props.t("Coupon_Finish"),
      badge: "badge badge-soft-primary border-0 mb-2",
      default: "badge-soft-primary",
      role: [
        Role.Admin,
        Role.Coordinator,
        Role.Dev,
        Role.Guest,
        Role.Manager,
        Role.User,
      ],
    },
    {
      id: "stop",
      title: props.t("Coupon_Stop"),
      badge: "badge badge-soft-primary border-0 mb-2",
      default: "badge-soft-primary",
      role: [
        Role.Admin,
        Role.Coordinator,
        Role.Dev,
        Role.Guest,
        Role.Manager,
        Role.User,
      ],
    },
  ])

  const cardStatus = status => {
    switch (status) {
      case "continue":
        return "border border-success border-2"
      case "wait":
      case "wait2":
      case "publish":
      case "design":
        return "border border-warning border-2"
      case "finish":
        return "border border-danger border-2"
      default:
        return "border border-light border-2"
    }
  }
  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Coupon_Continues")}
          </span>
        )
      case "wait":
      case "wait2":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Coupon_Wait")}
          </span>
        )
      case "publish":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Coupon_Publish")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Coupon_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Coupon_Finish")}
          </span>
        )
      case "stop":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Coupon_Stop")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-info">
            {props.t("Coupon_Other")}
          </span>
        )
    }
  }

  const handleFilter = id => {
    console.log(id)
    let items = [...filterItems]

    //clear selected temp
    let selectedItemIndex = items.findIndex(x => x.id == selectedItem)
    if (selectedItemIndex != -1) {
      items[selectedItemIndex].badge = replaceAll(
        items[selectedItemIndex].badge,
        "badge-soft-success",
        items[selectedItemIndex].default
      )
    }

    //set selected
    let itemIndex = items.findIndex(x => x.id == id)
    if (itemIndex != -1) {
      items[itemIndex].badge = replaceAll(
        items[itemIndex].badge,
        items[itemIndex].default,
        "badge-soft-success"
      )
    }
    setSelectedItem(id)
    setFilterItems(items)

    //Filter
    if (id == "all") {
      setTempList(data)
    } else {
      setTempList(data.filter(x => x.status == id))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            breadcrumbItems={[]}
            filters={filterItems}
            handle={handleFilter}
            isAdd={authorize([Role.Dev]) ? true : false}
            addTitle={props.t("Create Coupon")}
            addHandle={() => {
              console.log("ADD")
              props.history.push("/v1/coupons/create")
            }}
          />

          <Loading status={loading} />
          {isEmpty(tempList) ? (
            <Row>
              <Col md={12}>
                <div className="alert alert-warning">
                  {props.t("Data Null")}
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {tempList.map((item, idx) => (
            <a key={idx} href={`/v1/coupons/${item._id.toString()}`}>
              <Row>
                <Col md={12}>
                  <Card
                    className={cardStatus(item.status)}
                    style={
                      item.color1?.length>4 && item.color2?.length>4
                        ? {
                            background:
                              `linear-gradient(45deg, ${item.color1}, ${item.color2})`
                          }
                        : {}
                    }
                  >
                    <CardBody>
                      <Row>
                        <Col sm={9} md={9} lg={9} className="bg-yellow">
                          <Row>
                            <Col md={6} sm={12} lg={8}>
                              <span className="title" style={item.textColor?.length>4 ? {color:`${item.textColor}`}:{}}>
                                {item.name}
                                {item.guest ? (
                                  <span className="badge badge-soft-success ms-2">
                                    {props.t("Open to Guest")}
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-danger ms-2">
                                    {props.t("Closed to Guest")}
                                  </span>
                                )}
                              </span>
                            </Col>
                            <Col md={6} sm={12} lg={4}>
                              <Row>
                                <Col className="text-end">
                                  {cardStatusText(item.status)}
                                </Col>
                                <Col className="text-end">
                                  <div className="date-div">
                                    <div className="date-title" style={item.textColor?.length>4 ? {color:`${item.textColor}`}:{}}>
                                      <span>{props.t("Start Date")} : </span>
                                    </div>
                                    <div className="date" style={item.textColor?.length>4 ? {color:`${item.textColor}`}:{}}>
                                      {moment(item.startDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="date-div">
                                    <div className="date-title" style={item.textColor?.length>4 ? {color:`${item.textColor}`}:{}}>
                                      <span>{props.t("End Date")} : </span>
                                    </div>
                                    <div className="date" style={item.textColor?.length>4 ? {color:`${item.textColor}`}:{}}>
                                      {moment(item.finishDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <span className="description" style={item.textColor?.length>4 ? {color:`${item.textColor}`}:{}}> 
                                {
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        item.description
                                      ),
                                    }}
                                  />
                                }
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </a>
          ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

Coupons.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(Coupons)
