import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import {
  kasaSellAdd as onSellAdd,
  kasaSellUpdate as onSellUpdate,
} from "store/actions"
import { useDispatch } from "react-redux"
import { currencyFormat, replaceAll } from "helpers/functions"
import Swal from "sweetalert2"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import axios from "axios"

const CustomModal = props => {
  const dispatch = useDispatch()

  const [customerName, setCustomerName] = useState("")
  const [customerPhone, setCustomerPhone] = useState("")
  const [qr, setQR] = useState(null)
  const [qrType, setQRType] = useState("")
  const [loading, setLoading] = useState(false)
  const [rangeVal, setRangeVal] = useState("")

  const [_body, setBody] = useState({
    id: null,
    branchId: props.branchId,
    sku: "",
    product_definition: "",
    name: "",
    serial_number: "",
    description: "",
    vat: 18,
    barcode: "",
    agreementNumber: "",
    date: props.date,
    k_cat: null,
    k_cat_sub: null,
    k_cat_sub_alt: null,
    person: null,
    QRCode: {
      code: "",
      User: null,
      QR: null,
      KASA2: null,
    },
    Price: {
      cost: null,
      cash: null,
      agreementPrice: null,
      card: null,
      bank: null,
      installment: null,
      installmentRate: null,
      bankNet: null,
      card2: null,
      bank2: null,
      installment2: null,
      installmentRate2: null,
      bankNet2: null,
      card3: null,
      bank3: null,
      installment3: null,
      installmentRate3: null,
      bankNet3: null,
      net: null,
      gain: null,
      wallet: null,
      price: null,
      qr_price: null,
    },
    Customer: {
      name: "",
      phone: "",
    },
  })

  const resetBody = () => {
    setBody({
      id: null,
      branchId: props.branchId,
      sku: "",
      product_definition: "",
      name: "",
      serial_number: "",
      description: "",
      vat: 18,
      barcode: "",
      agreementNumber: "",
      date: props.date,
      k_cat: null,
      k_cat_sub: null,
      k_cat_sub_alt: null,
      person: null,
      QRCode: {
        code: "",
        User: null,
        QR: null,
        KASA2: null,
      },
      Price: {
        cost: null,
        cash: null,
        agreementPrice: null,
        card: null,
        bank: null,
        installment: null,
        installmentRate: null,
        bankNet: null,
        card2: null,
        bank2: null,
        installment2: null,
        installmentRate2: null,
        bankNet2: null,
        card3: null,
        bank3: null,
        installment3: null,
        installmentRate3: null,
        bankNet3: null,
        net: null,
        gain: null,
        wallet: null,
        price: null,
        qr_price: null,
      },
      Customer: {
        name: "",
        phone: "",
      },
    })
    qrStateReset()
  }

  const changeInputMultiple = objs => {
    let b = { ..._body }
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }
  const changeInput = (key, value) => {
    let b = { ..._body }
    b[key] = value
    setBody(b)
  }
  const changeInput2 = (key, key2, value) => {
    let b = { ..._body }
    b[key][key2] = value
    setBody(b)
  }

  useEffect(() => {
    let __body = { ..._body }
    __body.date = props.date
    __body.branchId = props.branchId
    // console.log("__BODY", __body)
    setBody(__body)
  }, [props.date, props.branchId])

  useEffect(() => {
    console.log("useEffect props.body", props.body)
    setBody(props.body)
  }, [props.body])
  useEffect(() => {
    console.log("useEffect _body", _body)
  }, [_body])

  const qrStateReset = () => {
    console.log("reset qr")
    setQR(null)
    let temp = { ..._body }
    temp.Price.qr_price = null
    temp.QRCode.QR = null
    temp.QRCode.User = null
    temp.QRCode.KASA2 = null
    temp.QRCode.code = ""
    setBody(temp)
  }

  const couponCategoryControl = (categoryControl, qrKat, bodyCat) => {
    if (!isEmpty(qrKat)) {
      if (bodyCat == qrKat) {
        categoryControl = true
      } else {
        categoryControl = false
      }
    }
    return categoryControl
  }

  const useDiscount = (_qr, _qrType) => {
    if (_qr) {
      let price = 0
      let swalObject = {}
      switch (_qrType) {
        case "user":
          price = _qr.userTotalPrice
          swalObject = {
            title: `İndirim Uygula`,
            html: `
          <p>${_qr.user.name} ${
              _qr.user.lastName
            }' a ait hesabında <b style='text-wrap: nowrap'>${currencyFormat(
              _qr.userTotalPrice
            )}</b> var. Ne Kadarını Uygulamak İstiyorsunuz?</p>
          <input
            type="number"
            value="${_qr.userTotalPrice}"
            step="0.01"
            max="${_qr.userTotalPrice}"
            min="0"
            class="swal2-input"
            id="range-value"
             />`,
            input: "range",
            inputValue: _qr.userTotalPrice || "0",
            inputAttributes: {
              min: 0,
              max: _qr.userTotalPrice,
              step: 0.01,
            },
            showCancelButton: true,
            confirmButtonText: "İndirim Uygula",
            showLoaderOnConfirm: true,
            cancelButtonText: props.t("Cancel"),
            didOpen: () => {
              const inputRange = Swal.getInput()
              const inputNumber =
                Swal.getHtmlContainer().querySelector("#range-value")

              // remove default output
              inputRange.nextElementSibling.style.display = "none"
              inputRange.style.width = "100%"
              setRangeVal(_qr.userTotalPrice)
              // sync input[type=number] with input[type=range]
              inputRange.addEventListener("input", () => {
                inputNumber.value = inputRange.value
                setRangeVal(inputRange.value)
                console.log(inputRange.value)
              })

              // sync input[type=range] with input[type=number]
              inputNumber.addEventListener("change", () => {
                inputRange.value = inputNumber.value
                setRangeVal(inputNumber.value)
                console.log(inputNumber.value)
              })
            },
            preConfirm: range => {
              console.log("test", range, new Date())

              let temp = { ..._body }
              temp.Price.qr_price = range?.toString() || ""
              temp.QRCode.QR = _qr.qr._id
              temp.QRCode.User = _qr.user._id
              temp.QRCode.KASA2 = null
              setBody(temp)
              // changeInput2("Price", "qr_price", range?.toString() || "")
              // changeInput2("QRCode", "QR", _qr.qr._id)
              // changeInput2("QRCode", "User", _qr.user._id)
              // changeInput2("QRCode", "KASA2", null)
              // let temp = { ..._body }
              // temp.Price.qr_price = range?.toString() || ""
              // temp.QRCode.QR = _qr.qr._id
              // temp.QRCode.User = _qr.user._id
              // temp.QRCode.KASA2 = null
              // setBody(temp)
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }
          break
        case "coupon":
          let categoryControl = false
          categoryControl = couponCategoryControl(
            categoryControl,
            _qr.qr.Coupon.k_cat,
            _body.k_cat
          )
          categoryControl = couponCategoryControl(
            categoryControl,
            _qr.qr.Coupon.k_cat_sub,
            _body.k_cat_sub
          )
          categoryControl = couponCategoryControl(
            categoryControl,
            _qr.qr.Coupon.k_cat_sub_alt,
            _body.k_cat_sub_alt
          )

          if (categoryControl) {
            if (_qr.qr.Coupon.amountType == "TL") {
              price = parseFloat(_qr.qr.Coupon.amount)
            } else if (_qr.qr.Coupon.amountType == "percent") {
              price =
                parseFloat(_qr.qr.Coupon.amount) *
                parseFloat(_body.Price.cash) *
                0.01
            }

            swalObject = {
              title: `Kupon`,
              html: `
            <p>${_qr.user.name} ${
                _qr.user.lastName
              }' için indirim kuponu tutarı <b style='text-wrap: nowrap'>${currencyFormat(
                price
              )}</b> var. Ne Kadarını Uygulamak İstiyorsunuz?</p>`,
              inputValue: price || "0",
              inputAttributes: {
                min: 0,
                max: price,
                step: 0.01,
              },
              showCancelButton: true,
              confirmButtonText: "Kupon Uygula",
              showLoaderOnConfirm: true,
              cancelButtonText: props.t("Cancel"),
              didOpen: () => {
                setRangeVal(price)
              },
              preConfirm: range => {
                // changeInput2("Price", "qr_price", price?.toString())
                // temp.Price.qr_price = price
                // setBody(temp)
                let temp = { ..._body }
                temp.Price.qr_price = price?.toString() || ""
                temp.QRCode.QR = _qr.qr._id
                temp.QRCode.User = _qr.user._id
                temp.QRCode.KASA2 = null
                setBody(temp)
                console.log("temp test", temp)
              },
              allowOutsideClick: () => !Swal.isLoading(),
            }
          } else {
            console.log(
              _body.k_cat == _qr.qr.Coupon.k_cat,
              _body.k_cat_sub == _qr.qr.Coupon.k_cat_sub,
              _body.k_cat_sub_alt == _qr.qr.Coupon.k_cat_sub_alt,
              _body,
              _qr.qr
            )
            swalObject = {
              title: "Kupon Uyumsuz",
              html: "Kupon Kategorisi İle Satış Kategorileri Uyumsuz",
              icon: "error",
            }
          }
      }

      Swal.fire(swalObject).then(result => {
        if (!result.isConfirmed) {
          qrStateReset()
        }
      })
    }
  }

  const onQRControl = qrCode => {
    if (qrCode?.length >= 15) {
      setLoading(true)
      let _qrType = ""
      if (qrCode.includes("CP")) {
        _qrType = "coupon"
      } else if (qrCode.includes("U")) {
        _qrType = "user"
      }
      setQRType(_qrType)

      if (!isEmpty(_qrType)) {
        let data = JSON.stringify({
          code: qrCode,
        })
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_API}/api/qrcode/control?qrType=${_qrType}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader().Authorization,
          },
          data: data,
        }
        axios
          .request(config)
          .then(response => {
            let json = response.data
            if (json.status) {
              setQR(json.data)

              let temp = { ..._body }
              temp.QRCode = {
                code: json.data.qr.code,
                Company: json.data.qr.Company,
                User: json.data.qr.User,
                QR: json.data.qr._id,
              }
              if (isEmpty(_body.Customer.name)) {
                temp.Customer.name = `${json.data.user.name} ${json.data.user.lastName}`
              }
              if (
                isEmpty(_body.Customer.phone) &&
                !isEmpty(json.data.user.phone)
              ) {
                temp.Customer.phone = `${json.data.user.phone.substring(1)}`
              }
              setBody(temp)
              console.log("onQRControl temp", temp)
            } else {
              Swal.fire(
                props.t(
                  "Error",
                  json.message || "Beklenmedik Hata. Kod:955",
                  "error"
                )
              )
              qrStateReset()
            }
            useDiscount(json.data, _qrType)
            setLoading(false)
          })
          .catch(error => {
            console.log(error)

            setLoading(false)
            Swal.fire(
              props.t("Error"),
              error?.response?.data?.message ||
                error?.error ||
                error?.message ||
                error?.error?.message ||
                JSON.stringify(error),
              "error"
            )
            qrStateReset()
          })
      } else {
        Swal.fire("Hata", "QR Kod Tipi Analiz Edilemedi", "error")
        qrStateReset()
        setLoading(false)
      }
    } else {
      Swal.fire("Hata", "QR Kod Hatalı", "error")
      qrStateReset()
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="xl">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.isEdit ? props.t("Sell Edit") : props.t("Sell Add")} -{" "}
        <small>
          {props.branchName} - {_body.date}
        </small>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <Label>
              {props.t("Person")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <select
              className="form-control"
              value={_body.person || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                changeInputMultiple([{ key: "person", value: v }])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.persons
                ? props.persons?.map((item, idx) => (
                    <option key={idx} value={item.name}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <Label>{props.t("Customer Name")}</Label>
            <Input
              placeholder={props.t("Customer Name")}
              value={_body.Customer?.name || ""}
              onChange={e => {
                changeInput2("Customer", "name", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={6}>
            <Label>{props.t("Customer Phone")}</Label>
            <Input
              tag={InputMask}
              mask={"(999) 999-9999"}
              placeholder={props.t("Customer Phone")}
              value={_body.Customer?.phone || ""}
              onChange={e => {
                changeInput2("Customer", "phone", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h4>{props.t("Product Infos")}</h4>
            {_body.product_definition}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>
              {props.t("Sell Type")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <select
              className="form-control"
              value={_body.k_cat || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                let k_cat = props.k_cat.find(x => x._id == v).name

                changeInputMultiple([
                  { key: "k_cat", value: v },
                  { key: "k_cat_sub", value: null },
                  { key: "k_cat_sub_alt", value: null },
                  {
                    key: "product_definition",
                    value: `${k_cat}`,
                  },
                  {
                    key: "agreementNumber",
                    value: "",
                  },
                  {
                    key: "Price.agreementPrice",
                    value: null,
                  },
                ])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.k_cat
                ? props.k_cat?.map((item, idx) => (
                    <option key={idx} value={item._id?.toString()}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </Col>
          <Col md={4}>
            <Label>{props.t("Category")}</Label>
            <select
              className="form-control"
              value={_body.k_cat_sub || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                let k_cat = props.k_cat.find(x => x._id == _body.k_cat).name
                let k_cat_sub = props.k_cat_sub.find(x => x._id == v).name

                changeInputMultiple([
                  { key: "k_cat_sub", value: v },
                  { key: "k_cat_sub_alt", value: null },
                  {
                    key: "product_definition",
                    value: `${k_cat} > ${k_cat_sub}`,
                  },
                ])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.k_cat_sub && _body.k_cat
                ? props.k_cat_sub
                    ?.filter(x => x.k_cat == _body.k_cat?.toString())
                    ?.map((item, idx) => (
                      <option key={idx} value={item._id?.toString()}>
                        {item.name}
                      </option>
                    ))
                : null}
            </select>
          </Col>
          <Col md={4}>
            <Label>{props.t("SubCategory")}</Label>
            <select
              className="form-control"
              value={_body.k_cat_sub_alt || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                let k_cat = props.k_cat.find(x => x._id == _body.k_cat).name
                let k_cat_sub = props.k_cat_sub.find(
                  x => x._id == _body.k_cat_sub
                ).name
                let k_cat_sub_alt = props.k_cat_sub_alt.find(x => x._id == v)

                let temp = [
                  {
                    key: "k_cat_sub_alt",
                    value: v,
                  },
                  {
                    key: "product_definition",
                    value: `${k_cat} > ${k_cat_sub} > ${k_cat_sub_alt.name}`,
                  },
                ]
                console.log("onchange", _body)
                if (
                  (_body.barcode == "8697414671067" ||
                    _body.barcode == "8697414671074" ||
                    _body.barcode == "8697414671340" ||
                    _body.barcode == "8697414670978" ||
                    _body.sku == "100000000000064000TDM" ||
                    _body.sku == "esim0001") &&
                  k_cat_sub_alt?.cost > 0
                ) {
                  temp.push({
                    key: "Price.cost",
                    value: k_cat_sub_alt.cost,
                  })
                } else if (
                  _body.barcode == "8697414671067" ||
                  _body.barcode == "8697414671074" ||
                  _body.barcode == "8697414671340" ||
                  _body.barcode == "8697414670978" ||
                  _body.sku == "100000000000064000TDM" ||
                  _body.sku == "esim0001"
                ) {
                  temp.push({
                    key: "Price.cost",
                    value: "",
                  })
                }

                changeInputMultiple(temp)
              }}
            >
              <option>{props.t("Select")}</option>
              {props.k_cat_sub && _body.k_cat && _body.k_cat_sub
                ? props.k_cat_sub_alt
                    ?.filter(x => x.k_cat_sub == _body.k_cat_sub?.toString())
                    ?.map((item, idx) => (
                      <option key={idx} value={item._id?.toString()}>
                        {item.name}
                      </option>
                    ))
                : null}
            </select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6}>
            <Label>
              {props.t("Product Name")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <Input
              placeholder={props.t("Product Name")}
              value={_body.name || ""}
              onChange={e => {
                changeInput("name", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("sku")}</Label>
            <Input
              placeholder={props.t("sku")}
              value={_body.sku || ""}
              onChange={e => {
                //istisna --> esim0001 veya 100000000000064000TDM icin maliyet ata
                if (
                  (e.target.value == "esim0001" ||
                    e.target.value == "100000000000064000TDM") &&
                  _body.k_cat_sub_alt
                ) {
                  let k_cat_sub_alt = props.k_cat_sub_alt.find(
                    x => x._id == _body.k_cat_sub_alt
                  )
                  if (k_cat_sub_alt?.cost > 0) {
                    let temp = { ..._body }
                    temp.Price.cost = k_cat_sub_alt?.cost
                    temp.sku = e.target.value
                    setBody(temp)
                  } else {
                    changeInput("sku", e.target.value || undefined)
                  }
                } else {
                  changeInput("sku", e.target.value || undefined)
                }
              }}
            />
          </Col>
          <Col md={4}>
            <Label>{props.t("Barcode")}</Label>
            <Input
              placeholder={props.t("Barcode")}
              value={_body.barcode || ""}
              onChange={e => {
                changeInput("barcode", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={4}>
            <Label>{props.t("serial_number")}</Label>
            <Input
              placeholder={props.t("serial_number")}
              value={_body.serial_number || ""}
              onChange={e => {
                changeInput("serial_number", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("cost")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("cost")}
              value={_body.Price?.cost == "0" ? "0" : _body.Price?.cost || ""}
              onChange={e => {
                changeInput2("Price", "cost", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={4}>
            <Label>{props.t("vat")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("vat")}
              value={_body.vat == "0" ? "0" : _body.vat || ""}
              onChange={e => {
                changeInput("vat", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <h4>{props.t("SellInfo")}</h4>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("cash")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("cash")}
              value={_body.Price?.cash == "0" ? "0" : _body.Price?.cash || ""}
              onChange={e => {
                changeInput2("Price", "cash", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={4}>
            <Label>{props.t("QRCode")}</Label>
            <Input
              type="text"
              placeholder={props.t("QRCode")}
              value={_body.QRCode?.code || ""}
              onChange={e => {
                let qrCode = replaceAll(e.target.value, "*", "-") || undefined
                changeInput2("QRCode", "code", qrCode)
                if (qrCode?.length >= 15) {
                  if (qrCode.includes("CP") && qrCode?.length == 16) {
                    onQRControl(qrCode)
                  } else if (qrCode.includes("U") && qrCode?.length == 15) {
                    onQRControl(qrCode)
                  }
                }
              }}
            />
          </Col>
          {!isEmpty(_body.QRCode?.code) ? (
            <Col md={4}>
              <Label>{props.t("Discount by QR")}</Label>
              <Input
                type="text"
                placeholder={props.t("Discount by QR")}
                value={_body.Price?.qr_price?.toString() || ""}
                onClick={() => {
                  if (
                    isEmpty(qr) &&
                    !isEmpty(_body.QRCode?.code) &&
                    _body.QRCode?.code?.length >= 15 &&
                    isEmpty(_body._id)
                  ) {
                    onQRControl(_body.QRCode?.code)
                  } else if (!isEmpty(qr)) {
                    useDiscount(qr, qrType)
                  } else {
                    Swal.fire(
                      "Hata",
                      "Güncellemede QR İndirimlerine Müdahale Edilemez. Farklı bir QR Kod Girebilirsiniz",
                      "error"
                    )
                  }
                }}
                readOnly={true}
              />
            </Col>
          ) : null}
          {_body.k_cat == "63b75213a4f12b3e182cd836" ? (
            <>
              <Col md={4}>
                <Label>
                  {props.t("agreementNumber")}
                  <span className="text-danger ms-2">*Zorunlu</span>
                </Label>
                <Input
                  type="text"
                  placeholder={props.t("agreementNumber")}
                  value={_body.agreementNumber || ""}
                  onChange={e => {
                    changeInput("agreementNumber", e.target.value || undefined)
                  }}
                  required={true}
                />
              </Col>
              <Col md={4}>
                <Label>{props.t("agreementPrice")}</Label>
                <Input
                  type="number"
                  step={0.01}
                  placeholder={props.t("agreementPrice")}
                  value={
                    _body.Price?.agreementPrice == "0"
                      ? "0"
                      : _body.Price?.agreementPrice || ""
                  }
                  onChange={e => {
                    changeInput2(
                      "Price",
                      "agreementPrice",
                      e.target.value || undefined
                    )
                  }}
                />
              </Col>
            </>
          ) : null}
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("bank")}</Label>
            <select
              className="form-control"
              value={_body.Price?.bank || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                changeInput2("Price", "bank", v)

                changeInput2("Price", "installment", null)
                changeInput2("Price", "installmentRate", 0)
              }}
            >
              <option>{props.t("Select")}</option>
              {props.banks.map((bank, idx) => (
                <option key={idx} value={bank.slug}>
                  {bank.title}
                </option>
              ))}
            </select>
          </Col>
          <Col md={4}>
            <Label>{props.t("card_price")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("card_price")}
              value={_body.Price?.card == "0" ? "0" : _body.Price?.card || ""}
              onChange={e => {
                changeInput2("Price", "card", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={4}>
            <Label>
              {props.t("installment")}
              {_body.Price?.installmentRate
                ? " : %" + _body.Price?.installmentRate?.toFixed(2)
                : null}
              {!isEmpty(_body.Price?.bank) ? (
                <span className="text-danger ms-2">*Zorunlu</span>
              ) : null}
            </Label>
            <select
              className="form-control"
              value={_body.Price?.installment || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                changeInput2("Price", "installment", v)
                let index = props.banks.findIndex(
                  x => x.slug == _body.Price?.bank
                )
                let commission = 0
                if (index != -1) {
                  let indexInstallment = props.banks[
                    index
                  ].installments.findIndex(x => x.installment == e.target.value)
                  if (indexInstallment != -1) {
                    commission =
                      props.banks[index].installments[indexInstallment]
                        .commission || 0
                  }
                }
                changeInput2("Price", "installmentRate", commission)
                console.log(_body)
              }}
            >
              <option>{props.t("Select")}</option>
              {_body.Price?.bank
                ? props.banks
                    .find(x => x.slug == _body.Price?.bank)
                    ?.installments?.map((item, idx) => (
                      <option key={idx} value={item.installment}>
                        {item.title}
                      </option>
                    ))
                : null}
            </select>
          </Col>
        </Row>
        {!isEmpty(_body.Price?.bank) ? (
          <Row className="mt-2">
            <Col md={4}>
              <Label>{props.t("bank")}</Label>
              <select
                className="form-control"
                value={_body.Price?.bank2 || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  changeInput2("Price", "bank2", v)

                  changeInput2("Price", "installment2", null)
                  changeInput2("Price", "installmentRate2", 0)
                }}
              >
                <option>{props.t("Select")}</option>
                {props.banks.map((bank, idx) => (
                  <option key={idx} value={bank.slug}>
                    {bank.title}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <Label>{props.t("card_price")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("card_price")}
                value={
                  _body.Price?.card2 == "0" ? "0" : _body.Price?.card2 || ""
                }
                onChange={e => {
                  changeInput2("Price", "card2", e.target.value || undefined)
                }}
              />
            </Col>
            <Col md={4}>
              <Label>
                {props.t("installment")}
                {_body.Price?.installmentRate2
                  ? " : %" + _body.Price?.installmentRate2?.toFixed(2)
                  : null}
                {!isEmpty(_body.Price?.bank2) ? (
                  <span className="text-danger ms-2">*Zorunlu</span>
                ) : null}
              </Label>
              <select
                className="form-control"
                value={_body.Price?.installment2 || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  changeInput2("Price", "installment2", v)
                  let index = props.banks.findIndex(
                    x => x.slug == _body.Price?.bank2
                  )
                  let commission = 0
                  if (index != -1) {
                    let indexInstallment = props.banks[
                      index
                    ].installments.findIndex(
                      x => x.installment == e.target.value
                    )
                    if (indexInstallment != -1) {
                      commission =
                        props.banks[index].installments[indexInstallment]
                          .commission || 0
                    }
                  }
                  changeInput2("Price", "installmentRate2", commission)
                }}
              >
                <option>{props.t("Select")}</option>
                {_body.Price?.bank2
                  ? props.banks
                      .find(x => x.slug == _body.Price?.bank2)
                      ?.installments?.map((item, idx) => (
                        <option key={idx} value={item.installment}>
                          {item.title}
                        </option>
                      ))
                  : null}
              </select>
            </Col>
          </Row>
        ) : null}
        {!isEmpty(_body.Price?.bank2) ? (
          <Row className="mt-2">
            <Col md={4}>
              <Label>{props.t("bank")}</Label>
              <select
                className="form-control"
                value={_body.Price?.bank3 || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  changeInput2("Price", "bank3", v)

                  changeInput2("Price", "installment3", null)
                  changeInput2("Price", "installmentRate3", 0)
                }}
              >
                <option>{props.t("Select")}</option>
                {props.banks.map((bank, idx) => (
                  <option key={idx} value={bank.slug}>
                    {bank.title}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <Label>{props.t("card_price")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("card_price")}
                value={
                  _body.Price?.card3 == "0" ? "0" : _body.Price?.card3 || ""
                }
                onChange={e => {
                  changeInput2("Price", "card3", e.target.value || undefined)
                }}
              />
            </Col>
            <Col md={4}>
              <Label>
                {props.t("installment")}
                {_body.Price?.installmentRate3
                  ? " : %" + _body.Price?.installmentRate3?.toFixed(2)
                  : null}
                {!isEmpty(_body.Price?.bank3) ? (
                  <span className="text-danger ms-2">*Zorunlu</span>
                ) : null}
              </Label>
              <select
                className="form-control"
                value={_body.Price?.installment3 || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  changeInput2("Price", "installment3", v)
                  let index = props.banks.findIndex(
                    x => x.slug == _body.Price?.bank3
                  )
                  let commission = 0
                  if (index != -1) {
                    let indexInstallment = props.banks[
                      index
                    ].installments.findIndex(
                      x => x.installment == e.target.value
                    )
                    if (indexInstallment != -1) {
                      commission =
                        props.banks[index].installments[indexInstallment]
                          .commission || 0
                    }
                  }
                  changeInput2("Price", "installmentRate3", commission)
                }}
              >
                <option>{props.t("Select")}</option>
                {_body.Price?.bank3
                  ? props.banks
                      .find(x => x.slug == _body.Price?.bank3)
                      ?.installments?.map((item, idx) => (
                        <option key={idx} value={item.installment}>
                          {item.title}
                        </option>
                      ))
                  : null}
              </select>
            </Col>
          </Row>
        ) : null}

        <Row className="mt-2">
          <Col md={12}>
            <Label>{props.t("Description")}</Label>
            <Input
              type="textarea"
              placeholder={props.t("Description")}
              value={_body.description || ""}
              onChange={e => {
                changeInput("description", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-success"
          disabled={
            isEmpty(_body.person) ||
            isEmpty(_body.name) ||
            isEmpty(_body.k_cat) ||
            !(
              isEmpty(_body.Price?.bank) ||
              (!isEmpty(_body.Price?.bank) &&
                !(_body.Price?.installment == null))
            ) ||
            !(
              isEmpty(_body.Price?.bank2) ||
              (!isEmpty(_body.Price?.bank2) &&
                !_body.Price?.installment2 == null)
            ) ||
            !(
              isEmpty(_body.Price?.bank3) ||
              (!isEmpty(_body.Price?.bank3) &&
                !(_body.Price?.installment3 == null))
            ) ||
            (_body.k_cat == "63b75213a4f12b3e182cd836" &&
            isEmpty(_body.agreementNumber)
              ? true
              : false)
              ? true
              : false
          }
          onClick={() => {
            if (props.isEdit) {
              dispatch(onSellUpdate(props.id, _body))

              resetBody()
              props.toggle()
            } else {
              dispatch(onSellAdd(_body))

              resetBody()
              props.toggle()
            }
          }}
        >
          {props.t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CustomModal.propTypes = {
  body: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  date: PropTypes.string,
  branchId: PropTypes.number,
  branchName: PropTypes.string,
  banks: PropTypes.array,
  id: PropTypes.string,
  k_cat: PropTypes.array,
  k_cat_sub: PropTypes.array,
  k_cat_sub_alt: PropTypes.array,
  persons: PropTypes.array,
}

export default withRouter(withTranslation()(CustomModal))
