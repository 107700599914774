import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"

import kasaSaga from "./kasa/saga"
import kasaBuySaga from "./kasaBuy/saga"
import companiesSaga from "./companies/saga"
import campaignsSaga from "./campaigns/saga"
import productsSaga from "./products/saga"
import appUsersSaga from "./appusers/saga"
import couponsSaga from "./coupons/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(kasaSaga),
    fork(kasaBuySaga),
    fork(companiesSaga),
    fork(campaignsSaga),
    fork(productsSaga),
    fork(appUsersSaga),
    fork(couponsSaga),
  ])
}
