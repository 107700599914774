import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import { kasaSetDailyCash as onSetDailyCash } from "store/actions"
import { useDispatch } from "react-redux"

const DailyModal = props => {
  const dispatch = useDispatch()

  const [_body, setBody] = useState({
    branchId: props.branchId,
    WebCredit: {
      Cash: {
        gain: null,
        count: null,
        amount: null,
      },
      Card: {
        count: null,
        amount: null,
      },
    },
    NTS: {
      Cash: {
        count: null,
        amount: null,
      },
      Card: {
        count: null,
        amount: null,
        commission: null,
      },
    },
    PayCell: {
      Cash: {
        count: null,
        amount: null,
      },
    },
    Transfer: {
      Cash: {
        amount: null,
      },
    },
    date: props.date,
  })

  const resetBody = () => {
    setBody({
      branchId: props.branchId,
      WebCredit: {
        Cash: {
          gain: null,
          count: null,
          amount: null,
        },
        Card: {
          count: null,
          amount: null,
        },
      },
      NTS: {
        Cash: {
          count: null,
          amount: null,
        },
        Card: {
          count: null,
          amount: null,
          commission: null,
        },
      },
      PayCell: {
        Cash: {
          count: null,
          amount: null,
        },
      },
      Transfer: {
        Cash: {
          amount: null,
        },
      },
      date: props.date,
    })
  }

  const changeInputMultiple = objs => {
    let b = { ..._body }
    console.log("objs", objs)
    console.log("b", b)
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        } else if (arr.length == 4) {
          b[arr[0]][arr[1]][arr[2]][arr[3]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }

  const changeInput = (key, value) => {
    let b = { ..._body }
    b[key] = value
    setBody(b)
  }

  const changeInput2 = (key, key2, value) => {
    let b = { ..._body }
    b[key][key2] = value
    setBody(b)
  }

  useEffect(() => {
    let __body = { ..._body }
    __body.date = props.date
    __body.branchId = props.branchId
    setBody(__body)
  }, [props.date, props.branchId])

  useEffect(() => {
    console.log("devir props.body", props.body)
    let newBody = { ...props.body }
    if (!isEmpty(newBody)) {
      if (
        (newBody.Transfer.Cash.amount == 0 ||
          newBody.Transfer.Cash.amount == null) &&
        newBody.Transfer.Cash.yesterdayAmount != null
      ) {
        newBody.Transfer.Cash.amount = newBody.Transfer.Cash.yesterdayAmount
      }
    }

    setBody(newBody)
  }, [props.body])

  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="md">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.isEdit ? props.t("DailyCash Edit") : props.t("DailyCash Add")} -{" "}
        <small>
          {props.branchName} - {_body?.date}
        </small>
      </ModalHeader>
      <ModalBody>
        <div className="card-bordered pt-2 pb-2">
          <Row className="ps-2  pe-2">
            <Col md={12}>
              <h4 className="bottom-line">{props.t("WebCredit")}</h4>
            </Col>
          </Row>
          <Row className="mt-1 ps-2 pe-2">
            <Col md={3} className="d-flex align-items-center">
              <b>{props.t("Cash")}</b>
            </Col>
            <Col md={3}>
              <Label>{props.t("gain")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("gain")}
                value={_body?.WebCredit?.Cash?.gain || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "WebCredit.Cash.gain",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("count")}</Label>
              <Input
                type="number"
                step={1}
                placeholder={props.t("count")}
                value={_body?.WebCredit?.Cash?.count || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "WebCredit.Cash.count",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("amount")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("amount")}
                value={_body?.WebCredit?.Cash?.amount || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "WebCredit.Cash.amount",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1 ps-2 pe-2">
            <Col md={3} className="d-flex align-items-center">
              <b>{props.t("Card")}</b>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <Label>{props.t("count")}</Label>
              <Input
                type="number"
                step={1}
                placeholder={props.t("count")}
                value={_body?.WebCredit?.Card?.count || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "WebCredit.Card.count",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("amount")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("amount")}
                value={_body?.WebCredit?.Card?.amount || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "WebCredit.Card.amount",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="card-bordered pt-2 pb-2">
          <Row className="ps-2 pe-2">
            <Col md={12}>
              <h4 className="bottom-line">{props.t("NTS")}</h4>
            </Col>
          </Row>
          <Row className="mt-1 ps-2 pe-2">
            <Col md={3} className="d-flex align-items-center ">
              <b>{props.t("Cash")}</b>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <Label>{props.t("count")}</Label>
              <Input
                type="number"
                step={1}
                placeholder={props.t("count")}
                value={_body?.NTS?.Cash?.count || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "NTS.Cash.count",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("amount")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("amount")}
                value={_body?.NTS?.Cash?.amount || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "NTS.Cash.amount",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1 ps-2 pe-2">
            <Col md={3} className="d-flex align-items-center">
              <b>{props.t("Card")}</b>
            </Col>
            <Col md={3}>
              <Label>{props.t("commission")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("commission")}
                value={_body?.NTS?.Card?.commission || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "NTS.Card.commission",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("count")}</Label>
              <Input
                type="number"
                step={1}
                placeholder={props.t("count")}
                value={_body?.NTS?.Card?.count || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "NTS.Card.count",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("amount")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("amount")}
                value={_body?.NTS?.Card?.amount || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "NTS.Card.amount",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="card-bordered pt-2 pb-2">
          <Row className="ps-2 pe-2">
            <Col md={12}>
              <h4 className="bottom-line">{props.t("PayCell")}</h4>
            </Col>
          </Row>
          <Row className="mt-1 ps-2 pe-2">
            <Col md={3} className="d-flex align-items-center ">
              <b>{props.t("Cash")}</b>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <Label>{props.t("count")}</Label>
              <Input
                type="number"
                step={1}
                placeholder={props.t("count")}
                value={_body?.PayCell?.Cash?.count || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "PayCell.Cash.count",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
            <Col md={3}>
              <Label>{props.t("amount")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("amount")}
                value={_body?.PayCell?.Cash?.amount || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "PayCell.Cash.amount",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
          </Row>
        </div>
        {/* <div className="card-bordered pt-2 pb-2">
          <Row className="ps-2 pe-2">
            <Col md={12}>
              <h4 className="bottom-line">{props.t("Transfer")}</h4>
            </Col>
          </Row>
          <Row className="mt-1 ps-2 pe-2">
            <Col md={3} className="d-flex align-items-center ">
              <b>{props.t("Cash")}</b>
            </Col>
            <Col md={3}></Col>
            <Col md={3}></Col>
            <Col md={3}>
              <Label>{props.t("amount")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("amount")}
                value={_body?.Transfer?.Cash?.amount || ""}
                onChange={e => {
                  changeInputMultiple([
                    {
                      key: "Transfer.Cash.amount",
                      value: e.target.value || undefined,
                    },
                  ])
                }}
              />
            </Col>
          </Row>
        </div> */}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-success"
          onClick={() => {
            dispatch(onSetDailyCash(props.date, props.branchId, _body))
            resetBody()
            props.toggle()
          }}
        >
          {props.t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DailyModal.propTypes = {
  body: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  date: PropTypes.string,
  branchId: PropTypes.number,
  branchName: PropTypes.string,
  id: PropTypes.string,
}

export default withRouter(withTranslation()(DailyModal))
