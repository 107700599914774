import {
  KASA_GET_BUY_SUCCESS,
  KASA_GET_BUY_FAIL,
  KASA_GET_BUY,
  KASA_LOADING_BUY,
  KASA_ADD_BUY,
  KASA_ADD_BUY_FAIL,
  KASA_ADD_BUY_SUCCESS,
  KASA_UPDATE_BUY,
  KASA_UPDATE_BUY_FAIL,
  KASA_UPDATE_BUY_SUCCESS,
  KASA_DELETE_BUY_FAIL,
  KASA_DELETE_BUY,
  KASA_DELETE_BUY_SUCCESS,
  KASA_RESET_BUY,
  KASA_RESET_BUY_ERROR,
} from "./actionTypes"

export const kasaBuyLoading = status => ({
  type: KASA_LOADING_BUY,
  payload: { status },
})

//BUY GET
export const kasaBuyGetSuccess = (actionType, data) => ({
  type: KASA_GET_BUY_SUCCESS,
  payload: { actionType, data },
})

export const kasaBuyGetFail = (actionType, error) => ({
  type: KASA_GET_BUY_FAIL,
  payload: { actionType, error },
})

export const kasaBuyGet = (date, branchId) => ({
  type: KASA_GET_BUY,
  payload: { date, branchId },
})

//BUY ADD
export const kasaBuyAddSuccess = (actionType, data) => ({
  type: KASA_ADD_BUY_SUCCESS,
  payload: { actionType, data },
})

export const kasaBuyAddFail = (actionType, error) => ({
  type: KASA_ADD_BUY_FAIL,
  payload: { actionType, error },
})

export const kasaBuyAdd = body => ({
  type: KASA_ADD_BUY,
  payload: { body },
})

//BUY UPDATE
export const kasaBuyUpdateSuccess = (actionType, data) => ({
  type: KASA_UPDATE_BUY_SUCCESS,
  payload: { actionType, data },
})

export const kasaBuyUpdateFail = (actionType, error) => ({
  type: KASA_UPDATE_BUY_FAIL,
  payload: { actionType, error },
})

export const kasaBuyUpdate = (id, body) => ({
  type: KASA_UPDATE_BUY,
  payload: { id, body },
})

//BUY DELETE
export const kasaBuyDeleteSuccess = (actionType, data) => ({
  type: KASA_DELETE_BUY_SUCCESS,
  payload: { actionType, data },
})

export const kasaBuyDeleteFail = (actionType, error) => ({
  type: KASA_DELETE_BUY_FAIL,
  payload: { actionType, error },
})

export const kasaBuyDelete = id => ({
  type: KASA_DELETE_BUY,
  payload: { id },
})

export const kasaBuyResetError = () => ({
  type: KASA_RESET_BUY_ERROR,
})
export const kasaBuyResetAll = () => ({
  type: KASA_RESET_BUY,
})
