import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT, 
  PRODUCTS_RESET,
  PRODUCTS_RESET_ERROR, 
  PRODUCTS_RESET_SUCCESS, 
  GET_PRODUCT,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  PRODUCT_RESET,
  PRODUCT_LOADING,
  IMPORT_XLSX_PRODUCTS,
  IMPORT_XLSX_PRODUCTS_SUCCESS,
  IMPORT_XLSX_PRODUCTS_FAIL,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAIL,
  GET_VARIANTS,
  GET_VARIANT_SUCCESS,
  GET_VARIANT_FAIL,
  GET_VARIANT,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  ADD_VARIANT,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  UPDATE_VARIANT,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
  DELETE_VARIANT,
  GET_SERIES_LIST_SUCCESS,
  GET_SERIES_LIST_FAIL,
  GET_SERIES_LIST,
  GET_SERIES_SUCCESS,
  GET_SERIES_FAIL,
  GET_SERIES,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_FAIL,
  ADD_SERIES,
  UPDATE_SERIES_SUCCESS,
  UPDATE_SERIES_FAIL,
  UPDATE_SERIES,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_FAIL,
  DELETE_SERIES,
  GET_VARIANTS_V2,
  GET_VARIANTS_V2_FAIL,
  GET_VARIANTS_V2_SUCCESS,
  GET_PRODUCTS_ALL,
  GET_PRODUCTS_ALL_SUCCESS,
  GET_PRODUCTS_ALL_FAIL, 
} from "./actionTypes"

export const productLoading = status => ({
  type: PRODUCT_LOADING,
  payload: { status },
})

//PRODUCTS
export const getProductsSuccess = (actionType, data, tableOptions) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getProductsFail = (actionType, error) => ({
  type: GET_PRODUCTS_FAIL,
  payload: { actionType, error },
})

export const getProducts = (data) => ({
  type: GET_PRODUCTS,
  payload: { data },
})

export const getProductSuccess = (actionType, data) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: { actionType, data },
})

export const getProductFail = (actionType, error) => ({
  type: GET_PRODUCT_FAIL,
  payload: { actionType, error },
})

export const getProductsAllSuccess = (actionType, data) => ({
  type: GET_PRODUCTS_ALL_SUCCESS,
  payload: { actionType, data },
})

export const getProductsAllFail = (actionType, error) => ({
  type: GET_PRODUCTS_ALL_FAIL,
  payload: { actionType, error },
})
export const getProductsAll = () => ({
  type: GET_PRODUCTS_ALL,
  payload: {  },
})


export const getProduct = id => ({
  type: GET_PRODUCT,
  payload: { id },
})

export const addProductSuccess = (actionType, data) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: { actionType, data },
})

export const addProductFail = (actionType, error) => ({
  type: ADD_PRODUCT_FAIL,
  payload: { actionType, error },
})

export const addProduct = (data, history) => ({
  type: ADD_PRODUCT,
  payload: { data, history },
})

export const updateProductSuccess = (actionType, data) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: { actionType, data },
})

export const updateProductFail = (actionType, error) => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: { actionType, error },
})

export const updateProduct = (id, data, history) => ({
  type: UPDATE_PRODUCT,
  payload: { id, data, history },
})

export const deleteProductSuccess = (actionType, data) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: { actionType, data },
})

export const deleteProductFail = (actionType, error) => ({
  type: DELETE_PRODUCT_FAIL,
  payload: { actionType, error },
})

export const deleteProduct = (id, history) => ({
  type: DELETE_PRODUCT,
  payload: { id, history },
})
    


export const importXLSXProductsSuccess = (actionType, data, tableOptions) => ({
  type: IMPORT_XLSX_PRODUCTS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const importXLSXProductsFail = (actionType, error) => ({
  type: IMPORT_XLSX_PRODUCTS_FAIL,
  payload: { actionType, error },
})

export const importXLSXProducts = (file) => ({
  type: IMPORT_XLSX_PRODUCTS,
  payload: { file },
})


//VARIANT
export const getVariantsSuccess = (actionType, data, tableOptions) => ({
  type: GET_VARIANTS_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getVariantsFail = (actionType, error) => ({
  type: GET_VARIANTS_FAIL,
  payload: { actionType, error },
})

export const getVariants = (pid, data) => ({
  type: GET_VARIANTS,
  payload: { pid, data },
})

export const getVariants_v2_Success = (actionType, data, tableOptions) => ({
  type: GET_VARIANTS_V2_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getVariants_v2_Fail = (actionType, error) => ({
  type: GET_VARIANTS_V2_FAIL,
  payload: { actionType, error },
})

export const getVariants_v2 = (pid, data) => ({
  type: GET_VARIANTS_V2,
  payload: { pid, data },
})

export const getVariantSuccess = (actionType, data) => ({
  type: GET_VARIANT_SUCCESS,
  payload: { actionType, data },
})

export const getVariantFail = (actionType, error) => ({
  type: GET_VARIANT_FAIL,
  payload: { actionType, error },
})

export const getVariant = id => ({
  type: GET_VARIANT,
  payload: { id },
})


export const addVariantSuccess = (actionType, data) => ({
  type: ADD_VARIANT_SUCCESS,
  payload: { actionType, data },
})

export const addVariantFail = (actionType, error) => ({
  type: ADD_VARIANT_FAIL,
  payload: { actionType, error },
})

export const addVariant = (data, history) => ({
  type: ADD_VARIANT,
  payload: { data, history },
})

export const updateVariantSuccess = (actionType, data) => ({
  type: UPDATE_VARIANT_SUCCESS,
  payload: { actionType, data },
})

export const updateVariantFail = (actionType, error) => ({
  type: UPDATE_VARIANT_FAIL,
  payload: { actionType, error },
})

export const updateVariant = (id, data, history) => ({
  type: UPDATE_VARIANT,
  payload: { id, data, history },
})

export const deleteVariantSuccess = (actionType, data) => ({
  type: DELETE_VARIANT_SUCCESS,
  payload: { actionType, data },
})

export const deleteVariantFail = (actionType, error) => ({
  type: DELETE_VARIANT_FAIL,
  payload: { actionType, error },
})

export const deleteVariant = (id, history) => ({
  type: DELETE_VARIANT,
  payload: { id, history },
})



//SERIES
export const getSeriesListSuccess = (actionType, data, tableOptions) => ({
  type: GET_SERIES_LIST_SUCCESS,
  payload: { actionType, data, tableOptions },
})

export const getSeriesListFail = (actionType, error) => ({
  type: GET_SERIES_LIST_FAIL,
  payload: { actionType, error },
})

export const getSeriesList = (pid, vid, data) => ({
  type: GET_SERIES_LIST,
  payload: { pid, vid, data },
})

export const getSeriesSuccess = (actionType, data) => ({
  type: GET_SERIES_SUCCESS,
  payload: { actionType, data },
})

export const getSeriesFail = (actionType, error) => ({
  type: GET_SERIES_FAIL,
  payload: { actionType, error },
})

export const getSeries = (pid, vid, id) => ({
  type: GET_SERIES,
  payload: { pid, vid, id },
})


export const addSeriesSuccess = (actionType, data) => ({
  type: ADD_SERIES_SUCCESS,
  payload: { actionType, data },
})

export const addSeriesFail = (actionType, error) => ({
  type: ADD_SERIES_FAIL,
  payload: { actionType, error },
})

export const addSeries = (data, history) => ({
  type: ADD_SERIES,
  payload: { data, history },
})

export const updateSeriesSuccess = (actionType, data) => ({
  type: UPDATE_SERIES_SUCCESS,
  payload: { actionType, data },
})

export const updateSeriesFail = (actionType, error) => ({
  type: UPDATE_SERIES_FAIL,
  payload: { actionType, error },
})

export const updateSeries = (id, data, history) => ({
  type: UPDATE_SERIES,
  payload: { id, data, history },
})

export const deleteSeriesSuccess = (actionType, data) => ({
  type: DELETE_SERIES_SUCCESS,
  payload: { actionType, data },
})

export const deleteSeriesFail = (actionType, error) => ({
  type: DELETE_SERIES_FAIL,
  payload: { actionType, error },
})

export const deleteSeries = (pid, vid, id, history) => ({
  type: DELETE_SERIES,
  payload: { pid, vid, id, history },
})


export const clearProduct = () => ({
  type: PRODUCT_RESET,
})
export const productsResetAll = () => ({
  type: PRODUCTS_RESET,
})

export const productsResetError = () => ({
  type: PRODUCTS_RESET_ERROR,
})

export const productsResetSuccess = () => ({
  type: PRODUCTS_RESET_SUCCESS,
})
