import {
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAIL,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAIL,
  COMPANIES_RESET_ERROR,
  COMPANIES_RESET,
  GET_K_CAT_SUCCESS,
  GET_K_CAT_FAIL,
  GET_PERSONS_SUCCESS,
  GET_PERSONS_FAIL,
  SET_PERSONS_SUCCESS,
  SET_PERSONS_FAIL,
  GET_GAIN_SETTINGS_SUCCESS,
  GET_GAIN_SETTINGS_FAIL,
  SET_GAIN_SETTINGS_SUCCESS,
  SET_GAIN_SETTINGS_FAIL,
  COMPANIES_RESET_SUCCESS,
  GET_BUY_TYPES_SUCCESS,
  GET_BUY_TYPES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  branches: [],
  banks: [],
  persons: [],
  k_cat: [],
  k_cat_sub: [],
  k_cat_sub_alt: [],
  buy_types: [],
  buy_type_sub: [],
  gainSettings: null,
  error: null,
  success: null,
}

const Companies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: action.payload?.data,
      }
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.payload?.data,
      }
    case GET_BUY_TYPES_SUCCESS:
      return {
        ...state,
        buy_types: action.payload?.data?.buy_types,
        buy_type_sub: action.payload?.data?.buy_type_sub,
      }
    case GET_PERSONS_SUCCESS:
      return {
        ...state,
        persons: action.payload?.data,
      }
    case SET_PERSONS_SUCCESS:
      return {
        ...state,
        success: action.payload?.data,
      }
    case GET_GAIN_SETTINGS_SUCCESS:
      return {
        ...state,
        gainSettings: action.payload?.data,
      }
    case SET_GAIN_SETTINGS_SUCCESS:
      return {
        ...state,
        success: action.payload?.data,
      }
    case GET_K_CAT_SUCCESS:
      return {
        ...state,
        k_cat: action.payload?.data?.k_cat,
        k_cat_sub: action.payload?.data?.k_cat_sub,
        k_cat_sub_alt: action.payload?.data?.k_cat_sub_alt,
      }
    case GET_BUY_TYPES_FAIL:
    case GET_BANKS_FAIL:
    case GET_BRANCHES_FAIL:
    case GET_K_CAT_FAIL:
    case GET_PERSONS_FAIL:
    case SET_PERSONS_FAIL:
    case GET_GAIN_SETTINGS_FAIL:
    case SET_GAIN_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case COMPANIES_RESET_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case COMPANIES_RESET_ERROR:
      return {
        ...state,
        error: null,
      }
    case COMPANIES_RESET:
      return {
        ...state,
        data: [],
        branches: [],
        banks: [],
        persons: [],
        k_cat: [],
        k_cat_sub: [],
        k_cat_sub_alt: [],
        error: null,
        success: null,
        gainSettings: [],
      }
    default:
      return state
  }
}

export default Companies
