import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import DashboardCard from "./DashboardCard"
import { useDispatch, useSelector } from "react-redux"

//redux
import {
  kasaGetSell as onGetData,
  kasaResetAll as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasaSellDelete as onDelete,
  kasaGetDailyCash as onDailyCash,
  companiesResetAll as onCompaniesResetAll,
  getReports as onReports,
} from "store/actions"
import { currencyFormat } from "helpers/functions"
import { isEmpty } from "lodash"
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import Swal from "sweetalert2"
import "./../../components/Common/custom.scss"
import tr from 'date-fns/locale/tr' 
registerLocale("tr", tr)

const Dashboard = props => {
  //meta title
  document.title = "Dashboard"
  const dispatch = useDispatch()
  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const { error, branches, reports, persons, loading } = useSelector(state => ({
    error: state.Dashboard.error,
    branches: state.Companies.branches,
    reports: state.Dashboard.reports,
    persons: state.Companies.persons,
    loading: state.Dashboard.loading,
  }))

  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    localStorage.setItem("date", date)
  }, [date])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }
  }, [branches, branchId, error])

  useEffect(() => {
    if (branchId != undefined && date != undefined) {
      localStorage.setItem("branch", branchId)
      handle(date, branchId)
      if (isEmpty(persons)) {
        dispatch(onPersons())
      }
    }

    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [branchId, error])

  const handle = (_date, _branchId) => {
    if (_date && _branchId) {
      dispatch(onReports(_date, _branchId))
    }
  }

  const addDay = count => {
    let isVal = moment(date, "DD-MM-YYYY").add(count, "days").toDate()
    setDate(moment(isVal).format("DD-MM-YYYY"))
    handle(moment(isVal).format("DD-MM-YYYY"), branchId)
  }

  const Loading = ({ status }) =>
    status === true ? (
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#b5b5b529",
          paddingTop: "250px",
        }}
      >
        <Col
          md={12}
          className="d-flex justify-content-center align-item-center m-2 p-2"
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </div>
    ) : null

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {Loading(loading)}
          <Row className="mb-3">
            <Col md={2} xs={12} sm={6}>
              {branches && branches?.length > 1 && branchId != undefined ? (
                <select
                  className="form-select me-xxl-2 my-3 my-xxl-0 d-inline-block"
                  value={branchId}
                  onChange={e => {
                    setBranchId(e.target.value)
                  }}
                >
                  {branches.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </Col>
            <Col md={8}></Col>
            <Col md={2}>
              <div className="aok-datepicker d-flex">
                <a
                  onClick={() => {
                    addDay(-1)
                  }}
                  className="d-flex align-items-center me-2" 
                >
                  {"<"}
                </a>
                <div className="search-box aok-datepicker me-xxl-2 my-3 my-xxl-0 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Arama İşlemi
                      </span>
                      <DatePicker
                        onChange={value => {
                          setDate(moment(value).format("DD-MM-YYYY"))
                          handle(moment(value).format("DD-MM-YYYY"), branchId)
                        }}
                        id="search-bar-1"
                        className="form-control"
                        placeholderText={`GG-AA-YYYY`}
                        selected={moment(date, "DD-MM-YYYY").toDate()}
                        dateFormat="dd-MM-yyyy"
                        locale={"tr"}
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
                <a
                  onClick={() => {
                    addDay(1)
                  }}
                  className="d-flex align-items-center ms-2" 
                >
                  {">"}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <DashboardCard title={"Toplam Ciro"} val={0} />
            <DashboardCard
              title={"Toplam Nakit Ciro"}
              val={currencyFormat(reports?.gelir?.nakit)}
            />
            <DashboardCard
              title={"Toplam K.Karti ve Havale/EFT Cirosu"}
              val={currencyFormat(reports?.gelir?.banka || 0)}
            />
          </Row>
          <Row>
            <DashboardCard
              title={"Toplam Temlik Cirosu"}
              val={currencyFormat(reports?.taahutlu?.toplam || 0)}
            />
            <DashboardCard
              title={"Toplam Ön Ödeme"}
              val={currencyFormat(
                reports?.taahutlu?.nakit + reports?.taahutlu?.banka || 0
              )}
            />
            <DashboardCard
              title={"Toplam Ön Ödeme Tutarı Nakit"}
              val={currencyFormat(reports?.taahutlu?.nakit || 0)}
            />
            <DashboardCard
              title={"Toplam Ön Ödeme Tutarı K.Kartı"}
              val={currencyFormat(reports?.taahutlu?.banka || 0)}
            />
          </Row>
          <Row>
            {reports && reports.persons
              ? reports.persons.map((person, idx) => (
                  <Col key={idx} md={3}>
                    <Card>
                      <CardBody>
                        <h4>{person.name}</h4>
                        <div>
                          <div>
                            <b className="me-2">Temlik:</b>
                            <span className="float-end">
                              {person.temlik_count} Adet -{" "}
                              {currencyFormat(person.temlik)}
                            </span>
                          </div>
                          <div>
                            <b className="me-2">Perakende:</b>
                            <span className="float-end">
                              {person.perakende_count} Adet -{" "}
                              {currencyFormat(person.perakende)} - Kar:{" "}
                              {currencyFormat(person.perakende_gain)}
                            </span>
                          </div>
                          <div>
                            <b className="me-2">Aktivasyon:</b>
                            <span className="float-end">
                              {person.aktivasyon} Adet
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              : null}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
