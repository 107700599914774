import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Table,
  Card,
  CardBody,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import {
  kasaQrCodeCheck as onQRCheck,
  qrCodeSearchReset as onQRCheckReset,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"
import "./custom.scss"
import Loading2 from "components/Common/Loading2"
import Loading from "components/Common/Loading"
import Swal from "sweetalert2"
import { replaceAll } from "helpers/functions"
const Payment_Purchase_Selection_Modal = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.open == true) {
      setTimeout(() => {
        let _input = document.getElementById("search_input")
        if (_input) {
          _input.focus()
        }
      }, 100)
    } else if (props.open == false) {
      console.log("xxx", new Date())
      dispatch(onQRCheckReset())
      setSearch("")
    }
  }, [props.open])

  const { error, qrCheck, loading } = useSelector(state => ({
    error: state.Kasa.error,
    qrCheck: state.Kasa.qrCheck,
    loading: state.Kasa.loading,
  }))
  const [_search, setSearch] = useState("")

  const [price, setPrice] = useState("")
  const [priceType, setPriceType] = useState("")
  const [description, setDescription] = useState("")

  const [selected, setSelected] = useState(null)

  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const links = [
    {
      title: "Ödeme",
      description: "Ödeme",
      priceType: `odeme`,
      iconClass: "bx-money",
      k_cat: "648b82cdd5d8928b1a84c185",
      k_cat_sub: "648b82e9d5d8928b1a84c187",
      k_cat_sub_alt: "648b873fd5d8928b1a84c18c",
      minPrice: 0,
      maxPrice: 9999999,
    },
    {
      title: "Yükleme",
      description: "Yükleme",
      priceType: `yukleme`,
      iconClass: "bx-money",
      k_cat: "648b82cdd5d8928b1a84c185",
      k_cat_sub: "648b8305d5d8928b1a84c188",
      k_cat_sub_alt: "648b8778d5d8928b1a84c18e",
      minPrice: 0,
      maxPrice: 9999999,
    },
    {
      title: "Uygulamaya Nakit Yükleme",
      description: "Uygulamaya Nakit Yükleme",
      priceType: `uygulamaya-nakit-yukleme`,
      iconClass: "bx-tab",
      k_cat: "648b82cdd5d8928b1a84c185",
      k_cat_sub: "648b8305d5d8928b1a84c188",
      k_cat_sub_alt: "648b8778d5d8928b1a84c18e",
      minPrice: 0,
      maxPrice: 9999999,
    },
  ]

  useEffect(() => {
    if (props.open == false) {
      setTimeout(() => {
        reset()
      }, 500)
    }
  }, [props.open])

  // useEffect(() => {
  //   if (!isEmpty(qrCheck)) {
  //     props.handleBody(null, selected, qrCheck)
  //   } else {
  //     reset()
  //   }
  // }, [qrCheck])

  useEffect(() => {
    if (isEmpty(priceType)) {
      reset()
    }
  }, [priceType])

  const reset = () => {
    console.log("reset")
    dispatch(onQRCheckReset())
    setPriceType("")
    setSearch("")
    setPrice("")
    setSelected(null)
  }

  const qrControl = async _qrcode => {
    if (!isEmpty(_qrcode)) {
      if (isEmpty(qrCheck)) {
        dispatch(onQRCheck(_qrcode, "user"))
      } else {
        return { status: true, data: qrCheck }
      }
    } else {
      return null
    }
  }

  const handleSet = async _priceType => {
    if (price != "" && price != undefined && price != null) {
      props.handleBody(price, _priceType, _search, description)
    } else {
      Swal.fire("Eksik Bilgi", "Fiyat Bilgisi Boş Olamaz", "error")
    }
  }

  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.t("Search")} -{" "}
        <small>
          {props.branchName} - {props.date}
        </small>
      </ModalHeader>
      <ModalBody>
        <Loading status={loading?.status || false} zIndex={99999} />

        <Row>
          <Col md={12}>
            <Input
              id="search_input"
              placeholder={`${props.t("QRCode")}`}
              style={{ height: "60px", fontSize: "20px" }}
              value={_search || ""}
              // onKeyPress={e => {
              //   if (e.key === "Enter" || e.key === "Tab") {
              //     if (!isEmpty(_search)) {
              //       dispatch(onQRCheck(_search, "user"))
              //     } else {
              //       props.handleBody(null, selected, qrCheck)
              //     }
              //   }
              // }}
              onChange={e => {
                setSearch(replaceAll(e.target.value || undefined, '*', '-'))
              }}
            />
          </Col>
          <Col md={12} className="mt-3">
            <Input
              id="price_input"
              type={"number"}
              step={"0.01"}
              style={{ height: "60px", fontSize: "20px" }}
              placeholder={`${props.t("Amount")}`}
              value={price || ""}
              onChange={e => {
                setPrice(e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={12} className="mt-3">
            <Input
              id="description"
              placeholder={`${props.t("Description")}`}
              style={{ height: "60px", fontSize: "20px" }}
              value={description || ""}
              onChange={e => {
                setDescription(e.target.value || undefined)
              }}
            />
          </Col>

          {/* <Col md={2}>
              <Button
                className="btn-success w-100"
                onClick={() => {
                  //props.handleBody();
                  if (!isEmpty(_search)) {
                    dispatch(onQRCheck(_search, "user"))
                    setSearch("")
                  } else {
                    props.handleBody(null, selected, qrCheck)
                  }
                }}
              >
                {isEmpty(_search)
                  ? props.t("New Add")
                  : props.t("QRCode") + " " + props.t("Search")}
              </Button>
            </Col> */}
        </Row>

        <Row className="mt-3">
          {links.map((item, idx) => (
            <Col md={6} key={idx}>
              <a
                onClick={() => {
                  console.log(item)
                  // setPriceType(item.priceType)
                  // setSelected(item)
                  handleSet(item.priceType)
                }}
              >
                <Card
                  className={
                    "mini-stats-wid shadow shadow-sm custom_hover" +
                    (selected?.priceType == item.priceType
                      ? " border border-success"
                      : "")
                  }
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1 d-flex align-items-center ">
                        {/* <p className="text-muted fw-medium">{item.title}</p> */}
                        <h4 className="mb-0">{item.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={"bx " + item.iconClass + " font-size-24"}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  )
}

Payment_Purchase_Selection_Modal.propTypes = {
  loading: PropTypes.bool,
  body: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  date: PropTypes.string,
  branchId: PropTypes.number,
  branchName: PropTypes.string,
  handleBody: PropTypes.func,
}

export default withRouter(withTranslation()(Payment_Purchase_Selection_Modal))
