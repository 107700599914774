import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Label } from "reactstrap"


import "./custom.scss"
const DefaultButton = props => {
  
  return (
    <div
      className={"default-btn "+props.className}
      onClick={() => { 
        props.onClick();
      }}
    >  
      <Label className="add-btn-label">{props.label}</Label>
    </div>
  )
}

DefaultButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
}

export default DefaultButton
