import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"
import AddButton from "./AddButton"
import DefaultButton from "./DefaultButton"
import back from "./../../assets/images/custom/back.png"
import { authorize } from "helpers/authorize"

const Breadcrumb = props => {
  const [file, setFile] = useState()
  const {
    breadcrumbItems,
    filters,
    handle,
    isAdd,
    addTitle,
    addHandle,
    className,
    isImportExcel,
    isDownloadLink,
    handleImportExcel,
    handleDownloadLink,
    backLink,
    title,
  } = props
  const itemLength = breadcrumbItems.length

  const handleFileChange = e => {
    if (e.target.files) {
      setFile(e.target.files[0])
      handleImportExcel(e.target.files[0])
      document.getElementById("file_upload").value = ""
    }
  }
  return (
    <Row>
      <Col xs="12">
        <div
          className={
            "page-title-box d-flex align-items-center justify-content-between " +
            (className ? className : "")
          }
        >
          {backLink ? (
            backLink == "/" ? (
              <h4 className="mb-0 font-size-18 d-flex d-flex-row">{title}</h4>
            ) : (
              <>
                <div className="d-flex d-flex-row">
                  <Link to={backLink || "#"} className="img-link">
                    <img src={back} className="img-back me-5" />
                  </Link>
                  <h4 className="mb-0 font-size-18">
                    {title}
                  </h4>
                </div>
              </>
            )
          ) : null}
          <div className="font-size-18">
            {filters
              ? filters.map((item, idx) =>
                  authorize(item.role) ? (
                    <a
                      key={idx}
                      className={item.badge + " p-2 me-2"}
                      onClick={() => {
                        handle(item.id)
                      }}
                    >
                      {item.title}
                    </a>
                  ) : null
                )
              : null}
          </div>
          <div className="page-title-right d-flex align-items-center">
            {isImportExcel ? (
              <DefaultButton
                className="btn btn-light btn-excel-passive btn-35 me-3"
                label={"Excel Şablonu İndir"}
                onClick={() => handleDownloadLink()}
              />
            ) : null}
            {isDownloadLink ? (
              <>
                <input
                  type="file"
                  id="file_upload"
                  style={{ display: "none" }}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileChange}
                />
                <DefaultButton
                  className="btn btn-success btn-35 me-3"
                  label={"Excel'den Yükle"}
                  onClick={() => {
                    let file_upload_obj = document
                      .getElementById("file_upload")
                      .click()
                    handleImportExcel()
                  }}
                />
              </>
            ) : null}

            {isAdd ? (
              <AddButton label={addTitle} onClick={() => addHandle()} />
            ) : (
              <div className="m-4"></div>
            )}
            {breadcrumbItems ? (
              <ol className="breadcrumb m-0 ms-2">
                {breadcrumbItems.map((item, key) => (
                  <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                    <Link to={item.link || "#"}>{item.title}</Link>
                  </BreadcrumbItem>
                ))}
              </ol>
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  filters: PropTypes.array,
  handle: PropTypes.func,
  isAdd: PropTypes.bool,
  isImportExcel: PropTypes.bool,
  handleImportExcel: PropTypes.func,
  isDownloadLink: PropTypes.bool,
  handleDownloadLink: PropTypes.func,
  addTitle: PropTypes.string,
  addHandle: PropTypes.func,
  className: PropTypes.string,
  backLink: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
