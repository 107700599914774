import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA, GET_REPORTS, GET_REPORTS_FAIL, GET_REPORTS_SUCCESS } from "./actionTypes";
import { apiSuccess, apiFail, getReportsSuccess, getReportsFail, dashboardLoading } from "./actions";

//Include Both Helper File with needed methods
import {
    getReports,
    getWeeklyData,
    getYearlyData,
    getMonthlyData
}
    from "../../helpers/fakebackend_helper";


function* reportsFetch({ payload: {date, branchId} }) {
    yield put(dashboardLoading(true))
    try {  
        var response = yield call(getReports, date, branchId);  
        if(response.status){  
            yield put(getReportsSuccess(GET_REPORTS_SUCCESS, response.data));
            yield put(dashboardLoading(false))
        }else{ 
            yield put(getReportsFail(GET_REPORTS_FAIL, response.message || "Beklenmedik HATA - CODE:SAGA-DASHBOARD-REPORTS-1005"));
            yield put(dashboardLoading(false))
        } 
    } catch (error) { 
        yield put(getReportsFail(GET_REPORTS_FAIL, error));
        yield put(dashboardLoading(false))
    }
}
    

function* getChartsData({ payload: periodType }) {
    try {
        var response;
        if (periodType == "monthly") {
            response = yield call(getWeeklyData, periodType);
        }
        if (periodType == "yearly") {
            response = yield call(getYearlyData, periodType);
        }
        if (periodType == "weekly") {
            response = yield call(getMonthlyData, periodType);
        }

        yield put(apiSuccess(GET_CHARTS_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_CHARTS_DATA, error));
    }
}

export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
    yield takeEvery(GET_REPORTS, reportsFetch); 
}

export default dashboardSaga;
