import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getProductsSuccess,
  getProductsFail,
  addProductSuccess,
  addProductFail,
  getProductSuccess,
  getProductFail,
  updateProductSuccess,
  updateProductFail,
  deleteProductSuccess,
  deleteProductFail,
  productLoading,
  importXLSXProductsSuccess,
  importXLSXProductsFail,
  getVariantsSuccess,
  getVariantsFail,
  getVariantSuccess,
  getVariantFail,
  addVariantSuccess,
  addVariantFail,
  updateVariantSuccess,
  updateVariantFail,
  deleteVariantSuccess,
  deleteVariantFail,
  getSeriesListSuccess,
  getSeriesListFail,
  getSeriesSuccess,
  addSeriesSuccess,
  addSeriesFail,
  updateSeriesSuccess,
  updateSeriesFail,
  deleteSeriesSuccess,
  deleteSeriesFail,
  getVariants_v2_Success,
  getVariants_v2_Fail,
  getProductsAllSuccess,
  getProductsAllFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  getProduct,
  importProductXLSX,
  getVariants,
  getVariant,
  addVariant,
  updateVariant,
  deleteVariant,
  getSeriesList,
  getSeries,
  addSeries,
  updateSeries,
  deleteSeries,
  getVariants_v2,
  getProductsAll,
} from "../../helpers/fakebackend_helper"
import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT,
  ADD_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  IMPORT_XLSX_PRODUCTS,
  IMPORT_XLSX_PRODUCTS_SUCCESS,
  IMPORT_XLSX_PRODUCTS_FAIL,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAIL,
  GET_VARIANT_SUCCESS,
  GET_VARIANT_FAIL,
  GET_VARIANTS,
  GET_VARIANT,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
  ADD_VARIANT,
  UPDATE_VARIANT,
  DELETE_VARIANT,
  GET_SERIES_LIST_SUCCESS,
  GET_SERIES_LIST_FAIL,
  GET_SERIES_SUCCESS,
  GET_SERIES_FAIL,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_FAIL,
  UPDATE_SERIES_SUCCESS,
  UPDATE_SERIES_FAIL,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_FAIL,
  GET_SERIES_LIST,
  GET_SERIES,
  ADD_SERIES,
  UPDATE_SERIES,
  DELETE_SERIES,
  GET_VARIANTS_V2_SUCCESS,
  GET_VARIANTS_V2_FAIL,
  GET_VARIANTS_V2,
  GET_PRODUCTS_ALL_SUCCESS,
  GET_PRODUCTS_ALL_FAIL,
  GET_PRODUCTS_ALL,
} from "./actionTypes"

//PRODUCTS
function* getProductsAllFetch({ payload: { data } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(getProductsAll)
    if (response.status) { 
      yield put(
        getProductsAllSuccess(GET_PRODUCTS_ALL_SUCCESS, response.data)
      )
    } else {
      yield put(
        getProductsAllFail(
          GET_PRODUCTS_ALL_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getProductsAllFail(GET_PRODUCTS_ALL_FAIL, error))
    yield put(productLoading(false))
  }
}

function* getProductsFetch({ payload: { data } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(getProducts, data)
    if (response.status) {
      yield put(
        getProductsSuccess(GET_PRODUCTS_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getProductsFail(
          GET_PRODUCTS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getProductsFail(GET_PRODUCTS_FAIL, error))
    yield put(productLoading(false))
  }
}

function* addProductFetch({ payload: { data, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(addProduct, data)
    if (response.status) {
      yield put(addProductSuccess(ADD_PRODUCT_SUCCESS, response.data))
      history.push("/v1/products")
    } else {
      yield put(
        addProductFail(
          ADD_PRODUCT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }

    yield put(productLoading(false))
  } catch (error) {
    yield put(addProductFail(ADD_PRODUCT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* updateProductFetch({ payload: { id, data, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(updateProduct, id, data)
    if (response.status) {
      yield put(updateProductSuccess(UPDATE_PRODUCT_SUCCESS, response.data))
      history.push("/v1/products")
    } else {
      yield put(
        updateProductFail(
          UPDATE_PRODUCT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(updateProductFail(UPDATE_PRODUCT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* deleteProductFetch({ payload: { id, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(deleteProduct, id)
    if (response.status) {
      yield put(deleteProductSuccess(DELETE_PRODUCT_SUCCESS, response.data))
      history.push("/v1/products")
    } else {
      yield put(
        deleteProductFail(
          DELETE_PRODUCT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(deleteProductFail(DELETE_PRODUCT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* getProductByIdFetch({ payload: { id } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(getProduct, id)
    if (response.status) {
      yield put(getProductSuccess(GET_PRODUCT_SUCCESS, response.data))
    } else {
      yield put(
        getProductFail(
          GET_PRODUCT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getProductFail(GET_PRODUCT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* importXLSXFetch({ payload: { file } }) {
  try {
    yield put(productLoading(true))
    console.log("importXLSXFetch start", file)
    var response = yield call(importProductXLSX, file)
    console.log("importXLSXFetch end", response)
    if (response.status) {
      yield put(
        importXLSXProductsSuccess(
          IMPORT_XLSX_PRODUCTS_SUCCESS,
          response.data,
          response
        )
      )
    } else {
      yield put(
        importXLSXProductsFail(
          IMPORT_XLSX_PRODUCTS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-PRODUCTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getProductsFail(IMPORT_XLSX_PRODUCTS_FAIL, error))
    yield put(productLoading(false))
  }
}

//VARIANTS
function* getVariantsFetch({ payload: { pid, data } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(getVariants, pid, data)
    if (response.status) {
      yield put(
        getVariantsSuccess(GET_VARIANTS_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getVariantsFail(
          GET_VARIANTS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-VARIANTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getVariantsFail(GET_VARIANTS_FAIL, error))
    yield put(productLoading(false))
  }
}
function* getVariantsV2Fetch({ payload: { pid, data } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(getVariants_v2, pid, data)
    if (response.status) {
      yield put(
        getVariants_v2_Success(GET_VARIANTS_V2_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getVariants_v2_Fail(
          GET_VARIANTS_V2_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-VARIANTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getVariants_v2_Fail(GET_VARIANTS_V2_FAIL, error))
    yield put(productLoading(false))
  }
}
function* getVariantByIdFetch({ payload: { id } }) {
  try {
    yield put(productLoading(true))
    console.log("getVariantByIdFetch", id)
    var response = yield call(getVariant, id)
    if (response.status) {
      yield put(getVariantSuccess(GET_VARIANT_SUCCESS, response.data))
    } else {
      yield put(
        getVariantFail(
          GET_VARIANT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-VARIANTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getVariantFail(GET_VARIANT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* addVariantFetch({ payload: { data, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(addVariant, data)
    if (response.status) {
      yield put(addVariantSuccess(ADD_VARIANT_SUCCESS, response.data))
      history.push(`/v1/products/variants/${response.data.Product}`)
      //location.reload();
    } else {
      yield put(
        addVariantFail(
          ADD_VARIANT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-VARIANTS-1001"
        )
      )
    }

    yield put(productLoading(false))
  } catch (error) {
    yield put(addVariantFail(ADD_VARIANT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* updateVariantFetch({ payload: { id, data, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(updateVariant, id, data)
    if (response.status) {
      yield put(updateVariantSuccess(UPDATE_VARIANT_SUCCESS, response.data))
      history.push(`/v1/products/variants/${response.data.Product}`)
      // location.reload();
    } else {
      yield put(
        updateVariantFail(
          UPDATE_VARIANT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-VARIANTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(updateVariantFail(UPDATE_VARIANT_FAIL, error))
    yield put(productLoading(false))
  }
}

function* deleteVariantFetch({ payload: { id, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(deleteVariant, id)
    if (response.status) {
      yield put(deleteVariantSuccess(DELETE_VARIANT_SUCCESS, response.data))
      //history.push(`/v1/products/series/${response.data.Product}`)
      if (history) {
        history.push(`/v1/products/variants/${response.data.Product}`)
      }
    } else {
      yield put(
        deleteVariantFail(
          DELETE_VARIANT_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-VARIANTS-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(deleteVariantFail(DELETE_VARIANT_FAIL, error))
    yield put(productLoading(false))
  }
}

//SERIES_LIST
function* getSeriesListFetch({ payload: { pid, vid, data } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(getSeriesList, pid, vid, data)
    if (response.status) {
      yield put(
        getSeriesListSuccess(GET_SERIES_LIST_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getSeriesListFail(
          GET_SERIES_LIST_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SERIES_LIST-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getSeriesListFail(GET_SERIES_LIST_FAIL, error))
    yield put(productLoading(false))
  }
}
function* getSeriesByIdFetch({ payload: { id, pid, vid } }) {
  try {
    yield put(productLoading(true))
    console.log("getSeriesByIdFetch", id)
    var response = yield call(getSeries, pid, vid, id)
    if (response.status) {
      yield put(getSeriesSuccess(GET_SERIES_SUCCESS, response.data))
    } else {
      yield put(
        GET_SERIES_FAIL(
          GET_SERIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SERIES_LIST-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(getSeriesFail(GET_SERIES_FAIL, error))
    yield put(productLoading(false))
  }
}

function* addSeriesFetch({ payload: { data, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(addSeries, data)
    if (response.status) {
      yield put(addSeriesSuccess(ADD_SERIES_SUCCESS, response.data))
      history.push(
        `/v1/products/variants/series/${response.data.Variant}?pid=${response.data.Product}`
      )
      //location.reload();
    } else {
      yield put(
        addSeriesFail(
          ADD_SERIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SERIES_LIST-1001"
        )
      )
    }

    yield put(productLoading(false))
  } catch (error) {
    yield put(addSeriesFail(ADD_SERIES_FAIL, error))
    yield put(productLoading(false))
  }
}

function* updateSeriesFetch({ payload: { id, data, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(updateSeries, id, data)
    if (response.status) {
      yield put(updateSeriesSuccess(UPDATE_SERIES_SUCCESS, response.data))
      history.push(
        `/v1/products/variants/series/${response.data.Variant}?pid=${response.data.Product}`
      )
      // location.reload();
    } else {
      yield put(
        updateSeriesFail(
          UPDATE_SERIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SERIES_LIST-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(updateSeriesFail(UPDATE_SERIES_FAIL, error))
    yield put(productLoading(false))
  }
}

function* deleteSeriesFetch({ payload: { pid, vid, id, history } }) {
  try {
    yield put(productLoading(true))
    var response = yield call(deleteSeries, pid, vid, id)
    if (response.status) {
      yield put(deleteSeriesSuccess(DELETE_SERIES_SUCCESS, response.data._id))
      //history.push(`/v1/products/series/${response.data.Product}`)
      if (history) {
        history.push(
          `/v1/products/variants/series/${response.data.Variant}?pid=${response.data.Product}`
        )
      }
    } else {
      yield put(
        deleteSeriesFail(
          DELETE_SERIES_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-SERIES_LIST-1001"
        )
      )
    }
    yield put(productLoading(false))
  } catch (error) {
    yield put(deleteSeriesFail(DELETE_SERIES_FAIL, error))
    yield put(productLoading(false))
  }
}

function* productsSaga() {
  yield takeEvery(GET_PRODUCTS_ALL, getProductsAllFetch)
  yield takeEvery(GET_PRODUCTS, getProductsFetch)
  yield takeEvery(GET_PRODUCT, getProductByIdFetch)
  yield takeEvery(ADD_PRODUCT, addProductFetch)
  yield takeEvery(UPDATE_PRODUCT, updateProductFetch)
  yield takeEvery(DELETE_PRODUCT, deleteProductFetch)
  yield takeEvery(IMPORT_XLSX_PRODUCTS, importXLSXFetch)

  yield takeEvery(GET_VARIANTS, getVariantsFetch)
  yield takeEvery(GET_VARIANTS_V2, getVariantsV2Fetch)
  yield takeEvery(GET_VARIANT, getVariantByIdFetch)
  yield takeEvery(ADD_VARIANT, addVariantFetch)
  yield takeEvery(UPDATE_VARIANT, updateVariantFetch)
  yield takeEvery(DELETE_VARIANT, deleteVariantFetch)

  yield takeEvery(GET_SERIES_LIST, getSeriesListFetch)
  yield takeEvery(GET_SERIES, getSeriesByIdFetch)
  yield takeEvery(ADD_SERIES, addSeriesFetch)
  yield takeEvery(UPDATE_SERIES, updateSeriesFetch)
  yield takeEvery(DELETE_SERIES, deleteSeriesFetch)
}

export default productsSaga
