import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useDispatch, useSelector } from "react-redux"
import {
  termCampaign as onGetTerm,
  addTermCampaign as onCreate,
  updateTermCampaign as onUpdate,
  companiesResetError as onClearError,
  campaignLoading as onLoading,
} from "store/actions"
import {
  default_term_step_1_object,
  default_term_step_2_object,
  terms_step_1,
  terms_step_2,
} from "./../../../common/data/_terms"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"

import { isEmpty } from "lodash"
import Loading from "components/Common/Loading"

const CampaignCreateStep2 = props => {
  //meta title
  document.title = props.t("Create Campaign")

  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Create Campaign"), link: `/v1/campaigns/}` },
    { title: props.t("Terms"), link: `#` },
  ]

  const [data, setData] = useState(null)
  const [isEdit, setIsEdit] = useState(false)

  const dispatch = useDispatch()
  const { error, term, loading } = useSelector(state => ({
    error: state.Campaigns?.error,
    term: state.Campaigns?.term,
    loading: state.Campaigns?.loading || false,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (query.get("l") === "new") {
      //Createf
      dispatch(onLoading(false))
    } else {
      //Update -- GET Data
      setIsEdit(true)
      dispatch(onGetTerm(params.id, query.get("l")))
    }
  }, [])

  useEffect(() => {
    if (query.get("l") != "new" && !isEmpty(term)) {
      const temp_data = { ...term }
      validation.setFieldValue("step_1", temp_data.step_1?.toString())
      validation.setFieldValue("step_2", temp_data.step_2?.toString())
      for (let i = 0; i < temp_data.terms.length; i++) {
        validation.setFieldValue(
          `terms[${i}].count`,
          temp_data.terms[i].count?.toString()
        )
        validation.setFieldValue(
          `terms[${i}].gainTL`,
          temp_data.terms[i].gainTL?.toString()
        )
        validation.setFieldValue(
          `terms[${i}].gainCount`,
          temp_data.terms[i].gainCount?.toString()
        )
        validation.setFieldValue(
          `terms[${i}].startTL`,
          temp_data.terms[i].startTL?.toString()
        )
        validation.setFieldValue(
          `terms[${i}].endTL`,
          temp_data.terms[i].endTL?.toString()
        )
      }
    }
  }, [term])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      step_1: "",
      step_2: "",
      terms: [],
    },
    validationSchema: Yup.object({
      // name: Yup.string().required(props.t("Required")),
      // startDate: Yup.string().required(props.t("Required")),
      // finishDate: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values)
      if (!isEdit && query.get("l") == "new") {
        //create
        dispatch(onCreate(params.id, values, props.history))
      } else if (
        isEdit &&
        !isEmpty(query.get("l")) &&
        query.get("l") != "new"
      ) {
        //update
        dispatch(onUpdate(params.id, query.get("l"), values, props.history))
      } else {
        console.error(
          "Hata! URL Guncellemeye veya Yeniden Oluşturmaya Uygun Değil"
        )
        alert("Hata! URL Guncellemeye veya Yeniden Oluşturmaya Uygun Değil")
      }
    },
  })

  const insert = (arr, index, ...newItems) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ]

  const Term_1 = (index, item) => (
    <Col md="12">
      <Row>
        <Col md="2" className="d-flex mb-3">
          <Col md="5" className="me-3 d-flex align-items-end">
            <Label htmlFor={`terms[${index}].startTL`}></Label>
            <Input
              id={`terms[${index}].startTL`}
              name={`terms[${index}].startTL`}
              type="number"
              step="0.01"
              value={validation.values.terms[index].startTL}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              disabled={true}
            />
          </Col>
          <div className="d-flex align-items-end me-3 pb-1">
            {props.t("with")}
          </div>
          <Col md="5" className="me-3">
            <Label htmlFor={`terms[${index}].endTL`}>
              {props.t("For TL")}
            </Label>
            <Input
              id={`terms[${index}].endTL`}
              name={`terms[${index}].endTL`}
              type="number"
              step="0.01"
              min={
                validation.values.terms[index].startTL
                  ? parseFloat(validation.values.terms[index].startTL)
                  : 0
              }
              value={validation.values.terms[index].endTL}
              onChange={e => {
                validation.handleChange(e)
                if (validation.values.terms.length >= index + 2) {
                  validation.setFieldValue(
                    `terms[${index + 1}].startTL`,
                    (parseFloat(e.target.value) + 0.01).toFixed(2)
                  )
                }
              }}
              onBlur={validation.handleBlur}
            />
          </Col>
        </Col>

        <Col md="2" className="me-3 mb-3">
          <Label htmlFor={`terms[${index}].gainTL`}>{props.t("Gain TL")}</Label>
          <Input
            id={`terms[${index}].gainTL`}
            name={`terms[${index}].gainTL`}
            type="number"
            step="0.01"
            value={validation.values.terms[index].gainTL}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
        </Col>
        <Col md="1" className="me-3 mb-3">
          <Label>{props.t("Add or Remove")}</Label>
          <div className="d-flex">
            <a
              className="me-2 btn-add"
              onClick={() => {
                let newObj = default_term_step_1_object
                if (validation.values.terms.length > 0) {
                  newObj.startTL = validation.values.terms[index].endTL
                    ? (parseFloat(validation.values.terms[index].endTL) + 0.01).toFixed(2)
                    : "" || "0"
                }
                console.log(newObj)
                let newList = insert(validation.values.terms, index + 1, newObj)
                console.log(newList)
                validation.setFieldValue("terms", newList)
              }}
            >
              <img />
            </a>
            {index > 0 ? (
              <a
                className="btn-remove"
                onClick={() => {
                  let newList = [...validation.values.terms]
                  newList.splice(index, 1)
                  validation.setFieldValue("terms", newList)
                }}
              >
                <img />
              </a>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  )
  const Term_2 = (index, item) => (
    <Col md="12" className="mb-3">
      <Row>
        <Col md="2" className="d-flex mb-3">
          <Col md="6" className="me-3">
            <Label htmlFor={`terms[${index}].count`}>
              {props.t("For Count")}
            </Label>
            <Input
              id={`terms[${index}].count`}
              name={`terms[${index}].count`}
              type="number"
              step="1"
              min={
                index > 0
                  ? parseInt(validation.values.terms[index - 1].count || "0") +
                    1
                  : 0
              }
              value={validation.values.terms[index].count}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
          </Col>
          <div className="d-flex align-items-end pb-1">
            {props.t("Buy Units")}
          </div>
        </Col>

        <Col md="2" className="me-3 mb-3">
          <Label htmlFor={`terms[${index}].buyCount`}>
            {props.t("Buy Count")}
          </Label>
          <Input
            id={`terms[${index}].buyCount`}
            name={`terms[${index}].buyCount`}
            type="number"
            step="0.01"
            value={validation.values.terms[index].buyCount}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
        </Col>
        <Col md="1" className="mb-3 d-flex align-items-end"> 
          <div className="pb-1">{props.t("_Buy")}</div>
        </Col>
        <Col md="1" className="me-3 mb-3">
          <Label>{props.t("Add or Remove")}</Label>
          <div className="d-flex">
            <a
              className="me-2 btn-add"
              onClick={() => {
                let newObj = { ...default_term_step_2_object }
                newObj.count =
                  parseInt(validation.values.terms[index].count || 0) + 1
                let newList = insert(validation.values.terms, index + 1, newObj)
                validation.setFieldValue("terms", newList)
              }}
            >
              <img />
            </a>
            {index > 0 ? (
              <a
                className="btn-remove"
                onClick={() => {
                  let newList = [...validation.values.terms]
                  newList.splice(index, 1)
                  validation.setFieldValue("terms", newList)
                }}
              >
                <img />
              </a>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  )

  const TermInputs = (index, item, status) => {
    switch (status) {
      case "1":
        return Term_1(index, item)
      case "2":
        return Term_2(index, item)
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaigns")}
            backLink={"/v1/campaigns"}
            breadcrumbItems={breadcrumbItems}
          />
          <Loading status={loading} />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("Campaign Terms")}</h4>
                  <p className="card-title-desc">
                    {props.t("Campaign Terms Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3 border border-success border-radius-20 d-flex align-items-center">
                      {terms_step_1.map((item, idx) => (
                        <Col
                          md="4"
                          className={"mt-3 mb-3 ps-3 pe-3 term-btn"}
                          key={idx}
                          onClick={() => {
                            if (validation.values.step_1 != item.value) {
                              validation.setFieldValue("step_1", item.value)
                              validation.setFieldValue("step_2", "")

                              switch (item.value) {
                                case "1":
                                  validation.setFieldValue("terms", [
                                    default_term_step_1_object,
                                  ])
                                  break
                                case "2":
                                  validation.setFieldValue("terms", [
                                    default_term_step_2_object,
                                  ])
                                  break
                                default:
                                  break
                              }
                            }
                          }}
                        >
                          <div className="term-container">
                            <img
                              className={
                                validation.values.step_1 == item.value
                                  ? "active"
                                  : ""
                              }
                            />
                            <Label>{item.label}</Label>
                          </div>
                        </Col>
                      ))}
                    </Row>

                    <Row className="mb-3 border border-success border-radius-20 d-flex align-items-center">
                      {terms_step_2
                        .filter(x =>
                          x.parent.includes(validation.values.step_1)
                        )
                        .map((item, idx) => (
                          <Col
                            md="4"
                            className="mt-3 mb-3 ps-3 pe-3 term-btn"
                            key={idx}
                            onClick={() => {
                              // if(!isEdit){
                              //   validation.setFieldValue("step_2", item.value)
                              // }
                              validation.setFieldValue("step_2", item.value)
                            }}
                          >
                            <div className="term-container">
                              <img
                                className={
                                  validation.values.step_2 == item.value
                                    ? "active"
                                    : ""
                                }
                              />
                              <div>
                                <Label>{item.label}</Label>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                    {!isEmpty(validation.values.terms) &&
                    !isEmpty(validation.values.step_1) &&
                    !isEmpty(validation.values.step_2)
                      ? validation.values.terms.map((item, idx) => (
                          <Row key={idx}>
                            {TermInputs(idx, item, validation.values.step_1)}
                          </Row>
                        ))
                      : null}
                    <Button color="primary" type="submit" className="mt-3">
                      {props.t("Save")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignCreateStep2.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignCreateStep2)
