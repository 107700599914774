import {
  KASA_GET_BUY_SUCCESS,
  KASA_GET_BUY_FAIL,  
  KASA_UPDATE_BUY_SUCCESS,
  KASA_ADD_BUY_SUCCESS,
  KASA_ADD_BUY_FAIL,
  KASA_UPDATE_BUY_FAIL, 
  KASA_DELETE_BUY_FAIL,
  KASA_DELETE_BUY_SUCCESS,  
  KASA_RESET_BUY_ERROR, 
  KASA_BUY_LOADING,
} from "./actionTypes"

const INIT_STATE = {
  data: [],  
  error: null,
  loading: true,
}

const KasaBuy = (state = INIT_STATE, action) => {
  switch (action.type) {
    case KASA_GET_BUY_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
      } 
    case KASA_ADD_BUY_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }

    case KASA_UPDATE_BUY_SUCCESS:
      return {
        ...state,
        data: state.data.map(data =>
          data._id.toString() === action.payload.data._id.toString()
            ? { data, ...action.payload.data }
            : data
        ),
      }
    case KASA_DELETE_BUY_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          data => data._id.toString() !== action.payload.data._id.toString()
        ),
      }

    case KASA_GET_BUY_FAIL:
    case KASA_ADD_BUY_FAIL:
    case KASA_UPDATE_BUY_FAIL:
    case KASA_DELETE_BUY_FAIL:  
      return {
        ...state,
        error: action.payload,
      }
    case KASA_RESET_BUY_ERROR:
      return {
        ...state,
        error: null,
      }
    case KASA_RESET_BUY_ERROR:
      return {
        ...state,
        data: [], 
        error: null,
      } 
    case KASA_BUY_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default KasaBuy
