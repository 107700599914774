export const KASA_BUY_LOADING = "KASA_BUY_LOADING" 
   
export const KASA_GET_BUY = "KASA_GET_BUY";
export const KASA_GET_BUY_SUCCESS = "KASA_GET_BUY_SUCCESS";
export const KASA_GET_BUY_FAIL = "KASA_GET_BUY_FAIL";
 

export const KASA_ADD_BUY = "KASA_ADD_BUY";
export const KASA_ADD_BUY_SUCCESS = "KASA_ADD_BUY_SUCCESS";
export const KASA_ADD_BUY_FAIL = "KASA_ADD_BUY_FAIL";

export const KASA_UPDATE_BUY = "KASA_UPDATE_BUY";
export const KASA_UPDATE_BUY_SUCCESS = "KASA_UPDATE_BUY_SUCCESS";
export const KASA_UPDATE_BUY_FAIL = "UPDATE_BUY";

export const KASA_DELETE_BUY = "KASA_DELETE_BUY";
export const KASA_DELETE_BUY_SUCCESS = "KASA_DELETE_BUY_SUCCESS";
export const KASA_DELETE_BUY_FAIL = "KASA_DELETE_BUY_FAIL";

export const KASA_LOADING_BUY = "KASA_LOADING_BUY"
 

export const KASA_RESET_BUY_ERROR = "KASA_RESET_BUY_ERROR";
export const KASA_RESET_BUY = "KASA_RESET_BUY";