import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import {
  kasa2SellAdd as onSellAdd,
  kasa2SellUpdate as onSellUpdate,
  qrCodeSearchReset as onQRCheckReset,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const Custom_Payment_Purchase_Modal = props => {
  const dispatch = useDispatch()

  const [customerName, setCustomerName] = useState("")
  const [customerPhone, setCustomerPhone] = useState("")
  const [selectedItem, setSelectedItem] = useState(null)

  const { qrCheck } = useSelector(state => ({
    qrCheck: state.Kasa.qrCheck,
  }))

  const [_body, setBody] = useState({
    id: null,
    branchId: props.branchId,
    sku: "",
    name: "",
    description: "",
    vat: 18,
    barcode: "",
    date: props.date,
    k_cat: null,
    k_cat_sub: null,
    k_cat_sub_alt: null,
    person: null,
    Price: {
      cost: null,
      cash: null,
      card: null,
      bank: null,
      installment: null,
      installmentRate: null,
      bankNet: null,
      net: null,
      gain: null,
      wallet: null,
      price: null,
    },
    Customer: {
      name: "",
      phone: "",
      User: null,
    },
    QRCode: null,
  })

  const resetBody = () => {
    setBody({
      id: null,
      branchId: props.branchId,
      sku: "",
      name: "",
      serial_number: "",
      description: "",
      vat: 18,
      date: props.date,
      k_cat: null,
      k_cat_sub: null,
      k_cat_sub_alt: null,
      person: null,
      Price: {
        cost: null,
        cash: null,
        card: null,
        bank: null,
        installment: null,
        installmentRate: null,
        bankNet: null,
        net: null,
        gain: null,
        wallet: null,
        price: null,
      },
      Customer: {
        name: "",
        phone: "",
        User: null,
      },
      QRCode: null,
    })
  }

  const changeInputMultiple = objs => {
    let b = { ..._body }
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }
  const changeInput = (key, value) => {
    let b = { ..._body }
    b[key] = value
    setBody(b)
  }
  const changeInput2 = (key, key2, value) => {
    let b = { ..._body }
    b[key][key2] = value
    setBody(b)
  }

  useEffect(() => {
    let __body = { ..._body }
    __body.date = props.date
    __body.branchId = props.branchId
    // console.log("__BODY", __body)
    setBody(__body)
  }, [props.date, props.branchId])

  useEffect(() => {
    console.log("props.body", props.body)
    setBody(props.body)
  }, [props.body])

  useEffect(() => {
    let __body = { ..._body }
    if (props.selectedItem) {
      __body.k_cat = props.selectedItem.k_cat
      __body.k_cat_sub = props.selectedItem.k_cat_sub
      __body.k_cat_sub_alt = props.selectedItem.k_cat_sub_alt
      __body.name =
        props.selectedItem.title + " " + props.selectedItem.description
      __body.sku = props.selectedItem.priceType 
    } else {
      __body.k_cat = null
      __body.k_cat_sub = null
      __body.k_cat_sub_alt = null
    }

    setBody(__body)
    setSelectedItem(props.selectedItem)
    console.log(props.selectedItem)
  }, [props.selectedItem])

  useEffect(() => {
    let __body = { ..._body }
    if (!isEmpty(qrCheck)) {
      __body.QRCode = qrCheck.qr._id
      __body.Customer.User = qrCheck.user._id
      __body.Customer.name = qrCheck.user.name + " " + qrCheck.user.lastName
      __body.Customer.phone = qrCheck.user.phone.substring(1).trim()
    } else {
      __body.QRCode = null
      __body.Customer.User = null
      __body.Customer.name = ""
      __body.Customer.phone = ""
    }
    setBody(__body)
  }, [qrCheck])

  useEffect(() => {
    if (props.open == false) {
      setTimeout(() => {
        reset()
      }, 500)
    }
  }, [props.open])

  const reset = () => {
    resetBody()
    setSelectedItem(null)
    dispatch(onQRCheckReset())
  }
  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="xl">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.isEdit ? props.t("Sell Edit") : props.t("Sell Add")} -{" "}
        {selectedItem
          ? selectedItem.title + " " + selectedItem.description + " - "
          : " - "}
        <small>
          {props.branchName} - {_body.date}
        </small>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <Label>
              {props.t("Person")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <select
              className="form-control"
              value={_body.person || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                changeInputMultiple([{ key: "person", value: v }])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.persons
                ? props.persons?.map((item, idx) => (
                    <option key={idx} value={item.name}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <Label>{props.t("Customer Name")}</Label>
            <Input
              placeholder={props.t("Customer Name")}
              disabled={!isEmpty(qrCheck)}
              value={_body.Customer?.name || ""}
              onChange={e => {
                changeInput2("Customer", "name", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={6}>
            <Label>{props.t("Customer Phone")}</Label>
            <Input
              disabled={!isEmpty(qrCheck)}
              tag={InputMask}
              mask={"(999) 999-9999"}
              placeholder={props.t("Customer Phone")}
              value={_body.Customer?.phone || ""}
              onChange={e => {
                changeInput2("Customer", "phone", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h4>{props.t("Product Infos")}</h4>
            {_body.product_definition}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>
              {props.t("Sell Type")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <select
              className="form-control"
              value={_body.k_cat || ""}
              disabled={true}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                let k_cat = props.k_cat.find(x => x._id == v).name

                changeInputMultiple([
                  { key: "k_cat", value: v },
                  { key: "k_cat_sub", value: null },
                  { key: "k_cat_sub_alt", value: null },
                  {
                    key: "product_definition",
                    value: `${k_cat}`,
                  },
                  {
                    key: "agreementNumber",
                    value: "",
                  },
                  {
                    key: "Price.agreementPrice",
                    value: null,
                  },
                ])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.k_cat
                ? props.k_cat?.map((item, idx) => (
                    <option key={idx} value={item._id?.toString()}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </Col>
          <Col md={4}>
            <Label>{props.t("Category")}</Label>
            <select
              className="form-control"
              disabled={true}
              value={_body.k_cat_sub || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                let k_cat = props.k_cat.find(x => x._id == _body.k_cat).name
                let k_cat_sub = props.k_cat_sub.find(x => x._id == v).name

                changeInputMultiple([
                  { key: "k_cat_sub", value: v },
                  { key: "k_cat_sub_alt", value: null },
                  {
                    key: "product_definition",
                    value: `${k_cat} > ${k_cat_sub}`,
                  },
                ])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.k_cat_sub && _body.k_cat
                ? props.k_cat_sub
                    ?.filter(x => x.k_cat == _body.k_cat?.toString())
                    ?.map((item, idx) => (
                      <option key={idx} value={item._id?.toString()}>
                        {item.name}
                      </option>
                    ))
                : null}
            </select>
          </Col>
          <Col md={4}>
            <Label>{props.t("SubCategory")}</Label>
            <select
              className="form-control"
              disabled={true}
              value={_body.k_cat_sub_alt || ""}
              onChange={e => {
                let v = e.target.value || undefined
                v = v == "Seç" ? null : v
                let k_cat = props.k_cat.find(x => x._id == _body.k_cat).name
                let k_cat_sub = props.k_cat_sub.find(
                  x => x._id == _body.k_cat_sub
                ).name
                let k_cat_sub_alt = props.k_cat_sub_alt.find(x => x._id == v)

                let temp = [
                  {
                    key: "k_cat_sub_alt",
                    value: v,
                  },
                  {
                    key: "product_definition",
                    value: `${k_cat} > ${k_cat_sub} > ${k_cat_sub_alt.name}`,
                  },
                ]

                changeInputMultiple(temp)
              }}
            >
              <option>{props.t("Select")}</option>
              {props.k_cat_sub && _body.k_cat && _body.k_cat_sub
                ? props.k_cat_sub_alt
                    ?.filter(x => x.k_cat_sub == _body.k_cat_sub?.toString())
                    ?.map((item, idx) => (
                      <option key={idx} value={item._id?.toString()}>
                        {item.name}
                      </option>
                    ))
                : null}
            </select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6}>
            <Label>
              {props.t("Product Name")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <Input
              placeholder={props.t("Product Name")}
              value={_body.name || ""}
              disabled={true}
              onChange={e => {
                changeInput("name", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("sku")}</Label>
            <Input
              placeholder={props.t("sku")}
              disabled={true}
              value={_body.sku || ""}
              onChange={e => {
                changeInput("sku", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("vat")}</Label>
            <Input
              type="number"
              disabled={true}
              step={0.01}
              placeholder={props.t("vat")}
              value={_body.vat == "0" ? "0" : _body.vat || ""}
              onChange={e => {
                changeInput("vat", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <h4>{props.t("SellInfo")}</h4>
          </Col>
        </Row>
        {selectedItem?.priceType == "odeme-nakit" ||
        selectedItem?.priceType == "yukleme-nakit-220-alt" ||
        selectedItem?.priceType == "yukleme-nakit-220-ust" ? (
          <Row className="mt-2">
            <Col md={4}>
              <Label>{props.t("cash")}</Label>
              <Input
                type="number"
                step={0.01}
                min={selectedItem?.minPrice || 0}
                max={selectedItem?.maxPrice || 9999}
                placeholder={props.t("cash")}
                value={_body.Price?.cash == "0" ? "0" : _body.Price?.cash || ""}
                onChange={e => {
                  changeInput2("Price", "cash", e.target.value || undefined)
                }}
              />
            </Col>
          </Row>
        ) : null}
        {selectedItem?.priceType == "odeme-kredikarti" ? (
          <Row className="mt-2">
            <Col md={4}>
              <Label>{props.t("bank")}</Label>
              <select
                className="form-control"
                value={_body.Price?.bank || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  changeInput2("Price", "bank", v)

                  changeInput2("Price", "installment", null)
                  changeInput2("Price", "installmentRate", 0)
                }}
              >
                <option>{props.t("Select")}</option>
                {props.banks.map((bank, idx) => (
                  <option key={idx} value={bank.slug}>
                    {bank.title}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={4}>
              <Label>{props.t("card_price")}</Label>
              <Input
                type="number"
                step={0.01}
                placeholder={props.t("card_price")}
                value={_body.Price?.card == "0" ? "0" : _body.Price?.card || ""}
                onChange={e => {
                  changeInput2("Price", "card", e.target.value || undefined)
                }}
              />
            </Col>
            <Col md={4}>
              <Label>
                {props.t("installment")}
                {_body.Price?.installmentRate
                  ? " : %" + _body.Price?.installmentRate?.toFixed(2)
                  : null}
                {!isEmpty(_body.Price?.bank) ? (
                  <span className="text-danger ms-2">*Zorunlu</span>
                ) : null}
              </Label>
              <select
                className="form-control"
                value={_body.Price?.installment || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  changeInput2("Price", "installment", v)
                  let index = props.banks.findIndex(
                    x => x.slug == _body.Price?.bank
                  )
                  let commission = 0
                  if (index != -1) {
                    let indexInstallment = props.banks[
                      index
                    ].installments.findIndex(
                      x => x.installment == e.target.value
                    )
                    if (indexInstallment != -1) {
                      commission =
                        props.banks[index].installments[indexInstallment]
                          .commission || 0
                    }
                  }
                  changeInput2("Price", "installmentRate", commission)
                }}
              >
                <option>{props.t("Select")}</option>
                {_body.Price?.bank
                  ? props.banks
                      .find(x => x.slug == _body.Price?.bank)
                      ?.installments?.map((item, idx) => (
                        <option key={idx} value={item.installment}>
                          {item.title}
                        </option>
                      ))
                  : null}
              </select>
            </Col>
          </Row>
        ) : null}

        <Row className="mt-2">
          <Col md={12}>
            <Label>{props.t("Description")}</Label>
            <Input
              type="textarea"
              placeholder={props.t("Description")}
              value={_body.description || ""}
              onChange={e => {
                changeInput("description", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-success"
          disabled={
            isEmpty(_body.person) ||
            isEmpty(_body.name) ||
            isEmpty(_body.k_cat) ||
            !(
              isEmpty(_body.Price?.bank) ||
              (!isEmpty(_body.Price?.bank) &&
                !isEmpty(_body.Price?.installment))
            )
              ? true
              : false
          }
          onClick={() => {
            if (props.isEdit) {
              dispatch(onSellUpdate(props.id, _body))
            } else {
              dispatch(onSellAdd(_body))
            }
            resetBody()
            props.toggle()
          }}
        >
          {props.t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

Custom_Payment_Purchase_Modal.propTypes = {
  body: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  date: PropTypes.string,
  branchId: PropTypes.number,
  branchName: PropTypes.string,
  banks: PropTypes.array,
  id: PropTypes.string,
  k_cat: PropTypes.array,
  k_cat_sub: PropTypes.array,
  k_cat_sub_alt: PropTypes.array,
  persons: PropTypes.array,
  selectedItem: PropTypes.object,
}

export default withRouter(withTranslation()(Custom_Payment_Purchase_Modal))
