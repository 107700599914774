import {
  APP_USERS_RESET_ERROR,
  APP_USERS_RESET,
  APP_USERS_RESET_SUCCESS,
  GET_APP_USERS_SUCCESS,
  GET_APP_USERS_FAIL, 
  DELETE_APP_USER_SUCCESS,
  GET_APP_USER_SUCCESS,
  GET_APP_USER_FAIL,
  DELETE_APP_USER_FAIL,
  APP_USER_LOADING, 
} from "./actionTypes"

const INIT_STATE = {
  data: [], 
  error: null, 
  loading: true, 
  appUser: null
}

const AppUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
      }
    case GET_APP_USER_SUCCESS:
      return {
        ...state,
        appUser: action.payload?.data, 
      } 
    case DELETE_APP_USER_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          x => x._id.toString() !== action.payload.data.toString()
        ),
      } 
      
    case GET_APP_USERS_FAIL:
    case GET_APP_USER_FAIL: 
    case DELETE_APP_USER_FAIL: 
      return {
        ...state,
        error: action.payload,
      }  
    case APP_USER_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      }

    default:
      return state
  }
}

export default AppUsers
