/** Get Dashboard Chart data */
export const KASA_LOADING = "KASA_LOADING" 

export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_BUY = "GET_BUY";

export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS";
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL";


export const KASA_SEARCH_RESET = "KASA_SEARCH_RESET";
export const KASA_SEARCH_GET_SELL = "KASA_SEARCH_GET_SELL";
export const KASA_SEARCH_GET_SUCCESS = "KASA_SEARCH_GET_SUCCESS";
export const KASA_SEARCH_GET_FAIL = "KASA_SEARCH_GET_FAIL";


export const KASA_QRCODE_CHECK_RESET = "KASA_QRCODE_CHECK_RESET"; 
export const KASA_QRCODE_CHECK = "KASA_QRCODE_CHECK";
export const KASA_QRCODE_CHECK_SUCCESS = "KASA_QRCODE_CHECK_SUCCESS";
export const KASA_QRCODE_CHECK_FAIL = "KASA_QRCODE_CHECK_FAIL";


export const KASA_GET_SELL = "KASA_GET_SELL";
export const KASA_GET_SUCCESS = "KASA_GET_SUCCESS";
export const KASA_GET_FAIL = "KASA_GET_FAIL";

export const KASA_ADD_SELL = "KASA_ADD_SELL";
export const KASA_ADD_SELL_SUCCESS = "KASA_ADD_SELL_SUCCESS";
export const KASA_ADD_SELL_FAIL = "KASA_ADD_SELL_FAIL";

export const KASA_UPDATE_SELL = "KASA_UPDATE_SELL";
export const KASA_UPDATE_SELL_SUCCESS = "KASA_UPDATE_SELL_SUCCESS";
export const KASA_UPDATE_SELL_FAIL = "UPDATE_SELL";

export const KASA_DELETE_SELL = "KASA_DELETE_SELL";
export const KASA_DELETE_SELL_SUCCESS = "KASA_DELETE_SELL_SUCCESS";
export const KASA_DELETE_SELL_FAIL = "KASA_DELETE_SELL_FAIL";

export const KASA2_GET_SELL = "KASA2_GET_SELL";
export const KASA2_GET_SUCCESS = "KASA2_GET_SUCCESS";
export const KASA2_GET_FAIL = "KASA2_GET_FAIL";

export const KASA2_ADD_SELL = "KASA2_ADD_SELL";
export const KASA2_ADD_SELL_SUCCESS = "KASA2_ADD_SELL_SUCCESS";
export const KASA2_ADD_SELL_FAIL = "KASA2_ADD_SELL_FAIL";

export const KASA2_UPDATE_SELL = "KASA2_UPDATE_SELL";
export const KASA2_UPDATE_SELL_SUCCESS = "KASA2_UPDATE_SELL_SUCCESS";
export const KASA2_UPDATE_SELL_FAIL = "UPDATE_SELL";

export const KASA2_DELETE_SELL = "KASA2_DELETE_SELL";
export const KASA2_DELETE_SELL_SUCCESS = "KASA2_DELETE_SELL_SUCCESS";
export const KASA2_DELETE_SELL_FAIL = "KASA2_DELETE_SELL_FAIL";

export const KASA_GET_DAILY_CASH = "KASA_GET_DAILY_CASH";
export const KASA_GET_DAILY_CASH_SUCCESS = "KASA_GET_DAILY_CASH_SUCCESS";
export const KASA_GET_DAILY_CASH_FAIL = "KASA_GET_DAILY_CASH_FAIL";


export const KASA_SET_DAILY_CASH = "KASA_SET_DAILY_CASH";
export const KASA_SET_DAILY_CASH_SUCCESS = "KASA_SET_DAILY_CASH_SUCCESS";
export const KASA_SET_DAILY_CASH_FAIL = "KASA_SET_DAILY_CASH_FAIL";

export const KASA_RESET_ERROR = "KASA_RESET_ERROR";
export const KASA_RESET = "KASA_RESET";


export const KASA_SEARCH = "KASA_SEARCH";
export const KASA_SEARCH_SUCCESS = "KASA_SEARCH_SUCCESS";
export const KASA_SEARCH_FAIL = "KASA_SEARCH_FAIL";