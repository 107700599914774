import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux
import {
  kasaGetSell as onGetData,
  kasaResetAll as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasaSellDelete as onDelete,
  kasaGetDailyCash as onDailyCash,
  companiesResetAll as onCompaniesResetAll,
} from "store/actions"
import { currencyFormat } from "helpers/functions"
import { isEmpty } from "lodash"
import DatePicker, { registerLocale } from "react-datepicker" 
import "react-datepicker/dist/react-datepicker.css" 
import moment from "moment"
import Swal from "sweetalert2"
//import "./../../../components/Common/custom.scss"
//import "./../custom.scss"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import zipcelx from "zipcelx"
import Loading2 from "components/Common/Loading2"
 
import tr from 'date-fns/locale/tr' 
registerLocale("tr", tr)

const ReportsCategoriesByUser = props => {
  //meta title
  document.title = "Personel Bazlı Kategori Raporu"
  const dispatch = useDispatch()
  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )

  const [loading2, setLoading2] = useState(false)
  const [date, setDate] = useState(moment().format("MM-YYYY"))
  const [data, setData] = useState([])  

  const [_body, setBody] = useState({
    id: null,
    branchId: props.branchId,
    sku: "",
    product_definition: "",
    name: "",
    serial_number: "",
    description: "",
    vat: 18,
    barcode: "",
    agreementNumber: "",
    date: props.date,
    k_cat: null,
    k_cat_sub: null,
    k_cat_sub_alt: null,
    person: null,
    Price: {
      cost: null,
      cash: null,
      agreementPrice: null,
      card: null,
      bank: null,
      installment: null,
      installmentRate: null,
      bankNet: null,
      card2: null,
      bank2: null,
      installment2: null,
      installmentRate2: null,
      bankNet2: null,
      card3: null,
      bank3: null,
      installment3: null,
      installmentRate3: null,
      bankNet3: null,
      net: null,
      gain: null,
      wallet: null,
      price: null,
    },
    Customer: {
      name: "",
      phone: "",
    },
  })

  const changeInputMultiple = objs => {
    let b = { ..._body }
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }

  const {
    error,
    branches,
    reports,
    persons,
    loading,
    k_cat,
    k_cat_sub,
    k_cat_sub_alt,
  } = useSelector(state => ({
    error: state.Dashboard.error,
    branches: state.Companies.branches,
    reports: state.Dashboard.reports,
    persons: state.Companies.persons,
    loading: state.Dashboard.loading,
    k_cat: state.Companies.k_cat,
    k_cat_sub: state.Companies.k_cat_sub,
    k_cat_sub_alt: state.Companies.k_cat_sub_alt,
  }))

  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }

    if (isEmpty(persons)) {
      dispatch(onPersons())
    }
    if (isEmpty(k_cat)) {
      dispatch(onK_CAT())
    }
  }, [branches, branchId, error])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [branchId, error])

  const handle = async () => {
    setLoading2(true)
    //let startDate = moment(date, "MM-YYYY").format("MM-YYYY")
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_BASE_API +
        `/api/reports/v1/report-categories-by-user/get/${date}?depo=${branchId}`,
      headers: {
        Authorization: authHeader().Authorization,
      },
    }

    await axios
      .request(config)
      .then(response => {
        
        setLoading2(false)
        if (response.data.status == true) {
          setData({ list: response.data.data })
        } else {
          setData([])
        }
      })
      .catch(error => {
        setLoading2(false)
        console.log(error)
        Swal.fire(
          props.t("Error"),
          error?.error ||
            error?.message ||
            error?.error?.message ||
            JSON.stringify(error),
          "error"
        )
        setData([])
      })
  }
 

  const exportExcel = () => {
    console.log(branches, branchId)
    const config = {
      filename: `Personel Bazlı Kategori Raporu - ${
        branches.find(x => x.id == branchId)?.name
      } - ${date}`,
      sheet: {
        data: [],
      },
    }
    const dataSet = config.sheet.data
    dataSet.push([
      { value: 'Personel', type: "string" },
      { value: 'Faturalı Yeni Tesis', type: "string" },
      { value: 'Faturalı Swich', type: "string" },
      { value: 'Faturalı Data', type: "string" },
      { value: 'Faturalı MNT', type: "string" },
      { value: 'Hazırkart Yeni Tesis', type: "string" },
      { value: 'Hazırkart Data', type: "string" },
      { value: 'Hazırkart MNT', type: "string" },
      { value: 'Rahat Swich', type: "string" },
      { value: 'Rahat Yeni Tesis', type: "string" },
      { value: 'ADSL / VDSL', type: "string" }, 
      { value: 'Fiber', type: "string" },
      { value: 'Superbox', type: "string" },
      { value: 'Yedek Sim Faturlı', type: "string" },
      { value: 'Yedek Sim Hazırkart', type: "string" },
      { value: 'Cep Kask', type: "string" },
      { value: 'Akıllım Güvende', type: "string" },
      { value: 'Hizmet Bedeli', type: "string" },
      { value: 'TK', type: "string" },
      { value: 'Diğer', type: "string" },
      { value: 'Devir', type: "string" }, 
      { value: 'Aksesuar', type: "string" },
      { value: 'Cep Telefonu', type: "string" },
      { value: 'Mobil Modem', type: "string" },
      { value: 'Tablet', type: "string" },
      { value: '2. EL / Yenilenmiş', type: "string" },
      { value: 'Smart Phone', type: "string" },
      { value: 'Taahhütlü Modem', type: "string" },
      { value: 'Taahüütlü Tablet', type: "string" },
      { value: 'Taahhütlü Laptop', type: "string" },
      { value: 'Taahhütlü Yenilenmiş', type: "string" }, 
      { value: 'IOT-A', type: "string" },
      { value: 'IOT-B', type: "string" },
      { value: 'IOT-C', type: "string" },
      { value: 'Teknik Servis', type: "string" }, 
    ])

   

    if (!isEmpty(data.list)) {
      for (let item of data.list) {
        dataSet.push([
          { value: item['Personel'] || "", type: "string" },  
          { value: item['Faturalı Yeni Tesis'] || "", type: "number" }, 
          { value: item['Faturalı Swich'] || "", type: "number" },  
          { value: item['Faturalı Data'] || "", type: "number" }, 
          { value: item['Faturalı MNT'] || "", type: "number" },  
          { value: item['Hazırkart Yeni Tesis'] || "", type: "number" }, 
          { value: item['Hazırkart Data'] || "", type: "number" },  
          { value: item['Hazırkart MNT'] || "", type: "number" }, 
          { value: item['Rahat Swich'] || "", type: "number" },  
          { value: item['Rahat Yeni Tesis'] || "", type: "number" }, 
          { value: item['ADSL / VDSL'] || "", type: "number" },  
          { value: item['Fiber'] || "", type: "number" }, 
          { value: item['Superbox'] || "", type: "number" },  
          { value: item['Yedek Sim Faturlı'] || "", type: "number" }, 
          { value: item['Yedek Sim Hazırkart'] || "", type: "number" },  
          { value: item['Cep Kask'] || "", type: "number" },  
          { value: item['Akıllım Güvende'] || "", type: "number" }, 
          { value: item['Hizmet Bedeli'] || "", type: "number" }, 
          { value: item['TK'] || "", type: "number" },  
          { value: item['Diğer'] || "", type: "number" }, 
          { value: item['Devir'] || "", type: "number" },  
          { value: item['Aksesuar'] || "", type: "number" }, 
          { value: item['Cep Telefonu'] || "", type: "number" },  
          { value: item['Mobil Modem'] || "", type: "number" }, 
          { value: item['Tablet'] || "", type: "number" },  
          { value: item['2. EL / Yenilenmiş'] || "", type: "number" }, 
          { value: item['Smart Phone'] || "", type: "number" },  
          { value: item['Taahhütlü Modem'] || "", type: "number" }, 
          { value: item['Taahüütlü Tablet'] || "", type: "number" },  
          { value: item['Taahhütlü Laptop'] || "", type: "number" }, 
          { value: item['Taahhütlü Yenilenmiş'] || "", type: "number" },  
          { value: item['IOT-A'] || "", type: "number" }, 
          { value: item['IOT-B'] || "", type: "number" }, 
          { value: item['IOT-C'] || "", type: "number" },  
          { value: item['Teknik Servis'] || "", type: "number" }, 
        ])
      }
    }

    zipcelx(config)
  }

  const countComponent = (count) =>{
    return count == '0'? '0':<b>{count}</b>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Report Category By User")}
          />
          <Loading2 status={loading2} />
          <Row className="mb-3">
            <Col md={2} xs={12} sm={6}>
              {branches && branches?.length > 1 && branchId != undefined ? (
                <select
                  className="form-select me-xxl-2 my-3 my-xxl-0 d-inline-block"
                  value={branchId}
                  onChange={e => {
                    setBranchId(e.target.value)
                  }}
                >
                  {branches.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </Col>
            <Col md={2}>
              <div className="aok-datepicker d-flex">
                <div className="search-box aok-datepicker me-xxl-2 my-3 my-xxl-0 d-inline-block border-radius-2">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Arama İşlemi
                      </span>
                      {/* <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={update => {
                          setDateRange(update)
                        }}
                        className="form-control custom-picker"
                        isClearable={true}
                        placeholderText={`GG.AA.YYYY - GG.AA.YYYY`}
                        dateFormat="dd.MM.yyyy"
                      /> */}
                      <DatePicker
                        onChange={value => {
                          setDate(moment(value).format("MM-YYYY"))
                          //handle(moment(value).format("MM-YYYY"), branchId)
                        }}
                        id="search-bar-1"
                        className="form-control custom-picker"
                        placeholderText={`GG.AA.YYYY - GG.AA.YYYY`}
                        selected={moment(date, "MM-YYYY").toDate()}
                        dateFormat="MM-yyyy"
                        style={{ borderRadius: "2px", paddingLeft: "10px" }}
                        showMonthYearPicker
                        locale="tr"
                      />
                    </label>
                    <i className="bx bx-calendar-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={1}>
              <div>
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    console.log(branchId)
                    console.log(_body)
                    if (isEmpty(date)) {
                      Swal.fire(
                        props.t("Error"),
                        "Tarih Seçmek Zorunlu",
                        "error"
                      )
                    } else {
                      await handle()
                    }
                  }}
                >
                  {props.t("Get Reports")}
                </Button>
              </div>
            </Col> 
          </Row>
          <Row></Row>
          {!isEmpty(data?.list) ? (
            <Card className="mt-2 mb-2">
              <CardBody>
                <div className="alert alert-info">
                  {props.t("Report Category By User")}
                  <Button
                    className="ms-4"
                    type="button"
                    color="success"
                    onClick={() => {
                      exportExcel()
                    }}
                  >
                    <i className="mdi mdi-microsoft-excel"></i>{" "}
                    {props.t("Export")}
                  </Button>
                </div>
                <div className="table-responsive">
                  <table className="react-table table-bordered custom-tr-gray">
                    <thead>
                      <tr>
                        <th className="rotate-90">Personel</th>
                        <th className="rotate-90">Faturalı Yeni Tesis</th>
                        <th className="rotate-90">Faturalı Swich</th>
                        <th className="rotate-90">Faturalı Data</th>
                        <th className="rotate-90">Faturalı MNT</th>
                        <th className="rotate-90">Hazırkart Yeni Tesis</th>
                        <th className="rotate-90">Hazırkart Data</th>
                        <th className="rotate-90">Hazırkart MNT</th>
                        <th className="rotate-90">Rahat Swich</th>
                        <th className="rotate-90">Rahat Yeni Tesis</th>
                        <th className="rotate-90">ADSL / VDSL</th>
                        <th className="rotate-90">Fiber</th>
                        <th className="rotate-90">Superbox</th>
                        <th className="rotate-90">Yedek Sim Faturlı</th>
                        <th className="rotate-90">Yedek Sim Hazırkart</th>
                        <th className="rotate-90">Cep Kask</th>
                        <th className="rotate-90">Akıllım Güvende</th>
                        <th className="rotate-90">Hizmet Bedeli</th>
                        <th className="rotate-90">TK</th>
                        <th className="rotate-90">Diğer</th>
                        <th className="rotate-90">Devir</th>
                        <th className="rotate-90">Aksesuar</th>
                        <th className="rotate-90">Cep Telefonu</th>
                        <th className="rotate-90">Mobil Modem</th>
                        <th className="rotate-90">Tablet</th>
                        <th className="rotate-90">2. EL / Yenilenmiş</th>
                        <th className="rotate-90">Smart Phone</th>
                        <th className="rotate-90">Taahhütlü Modem</th>
                        <th className="rotate-90">Taahüütlü Tablet</th>
                        <th className="rotate-90">Taahhütlü Laptop</th>
                        <th className="rotate-90">Taahhütlü Yenilenmiş</th>
                        <th className="rotate-90">IOT-A</th>
                        <th className="rotate-90">IOT-B</th>
                        <th className="rotate-90">IOT-C</th>
                        <th className="rotate-90">Teknik Servis</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.list?.map((item, idx) => (
                        <tr key={idx} className="text-center">
                          <td className="no-wrap p-2">{item['Personel']}</td>
                          <td>{countComponent(item['Faturalı Yeni Tesis'])}</td>
                          <td>{countComponent(item['Faturalı Swich'])}</td>
                          <td>{countComponent(item['Faturalı Data'])}</td>
                          <td>{countComponent(item['Faturalı MNT'])}</td>
                          <td>{countComponent(item['Hazırkart Yeni Tesis'])}</td>
                          <td>{countComponent(item['Hazırkart Data'])}</td>
                          <td>{countComponent(item['Hazırkart MNT'])}</td>
                          <td>{countComponent(item['Rahat Swich'])}</td>
                          <td>{countComponent(item['Rahat Yeni Tesis'])}</td>
                          <td>{countComponent(item['ADSL / VDSL'])}</td>
                          <td>{countComponent(item['Fiber'])}</td>
                          <td>{countComponent(item['Superbox'])}</td>
                          <td>{countComponent(item['Yedek Sim Faturlı'])}</td>
                          <td>{countComponent(item['Yedek Sim Hazırkart'])}</td>
                          <td>{countComponent(item['Cep Kask'])}</td>
                          <td>{countComponent(item['Akıllım Güvende'])}</td>
                          <td>{countComponent(item['Hizmet Bedeli'])}</td>
                          <td>{countComponent(item['TK'])}</td>
                          <td>{countComponent(item['Diğer'])}</td>
                          <td>{countComponent(item['Devir'])}</td>
                          <td>{countComponent(item['Aksesuar'])}</td>
                          <td>{countComponent(item['Cep Telefonu'])}</td>
                          <td>{countComponent(item['Mobil Modem'])}</td>
                          <td>{countComponent(item['Tablet'])}</td>
                          <td>{countComponent(item['2. EL / Yenilenmiş'])}</td>
                          <td>{countComponent(item['Smart Phone'])}</td>
                          <td>{countComponent(item['Taahhütlü Modem'])}</td>
                          <td>{countComponent(item['Taahüütlü Tablet'])}</td>
                          <td>{countComponent(item['Taahhütlü Laptop'])}</td>
                          <td>{countComponent(item['Taahhütlü Yenilenmiş'])}</td>
                          <td>{countComponent(item['IOT-A'])}</td>
                          <td>{countComponent(item['IOT-B'])}</td>
                          <td>{countComponent(item['IOT-C'])}</td>
                          <td className="text-center">{countComponent(item['Teknik Servis'])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

ReportsCategoriesByUser.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ReportsCategoriesByUser)
