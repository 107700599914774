/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";


export const GET_REPORTS= "GET_REPORTS";
export const GET_REPORTS_SUCCESS= "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL= "GET_REPORTS_FAIL";

export const DASHBOARD_RESET = "DASHBOARD_RESET"
export const DASHBOARD_ERROR_RESET = "DASHBOARD_ERROR_RESET"

export const DASHBOARD_LOADING="DASHBOARD_LOADING"  