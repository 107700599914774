import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { coupons } from "./../../../common/data/coupon"
import {
  getCoupon as onGet,
  updateCoupon as onSave,
  clearCoupon as onClearCoupon,
  companiesResetError as onClearError,
  get_K_CAT as onK_CAT,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { SketchPicker } from "react-color"

import * as DOMPurify from "dompurify"

import Dropzone from "react-dropzone"
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import Loading from "components/Common/Loading"

const CouponUpdate = props => {
  //meta title
  document.title = props.t("Edit Coupon")

  const [data, setData] = useState(null)

  const [selectedFiles, setselectedFiles] = useState([])
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const [text, setText] = useState()

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE
  const breadcrumbItems = [
    { title: props.t("Coupons"), link: "/v1/coupons" },
    { title: props.t("Edit Coupon"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, coupon, loading, k_cat, k_cat_sub, k_cat_sub_alt } =
    useSelector(state => ({
      error: state.Coupons?.error,
      coupon: state.Coupons?.coupon,
      loading: state.Coupons?.loading || false,
      k_cat: state.Companies.k_cat,
      k_cat_sub: state.Companies.k_cat_sub,
      k_cat_sub_alt: state.Companies.k_cat_sub_alt,
    }))

  const [selectionColor1, setSelectionColor1] = useState(false)
  const [selectionColor2, setSelectionColor2] = useState(false)
  const [selectionColor3, setSelectionColor3] = useState(false)

  const amountTypeList = [
    {
      key: "TL",
      label: "TL",
    },
    {
      key: "percent",
      label: "Yüzde",
    },
  ]

  useEffect(() => {
    if (isEmpty(k_cat)) {
      dispatch(onK_CAT())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

  useEffect(() => {
    if (isEmpty(coupon)) {
      dispatch(onGet(params.id))
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(coupon)) {
      const temp_data = { ...coupon }
      console.log("temp_data", temp_data)
      setData(temp_data)
      default_value_setting(temp_data)
      setTimeout(() => {
        dispatch(onClearCoupon())
      }, 300)
    }
  }, [coupon])

  const default_value_setting = async temp_data => {
    console.log(validation.values)
    for await (let key of Object.keys(validation.values)) {
      try {
        if (key == "description") {
          const val = temp_data[key] || ""
          validation.setFieldValue(key, val)
        } else if (
          (key == "startDate" || key == "finishDate") &&
          temp_data[key] &&
          !isEmpty(temp_data[key])
        ) {
          validation.setFieldValue(
            key,
            moment(temp_data[key])?.format("DD.MM.YYYY HH:mm")
          )
        } else {
          console.log("key", key, temp_data[key])
          validation.setFieldValue(key, temp_data[key])
        }
      } catch (err) {
        console.error("key converter failed", key, err)
      }
    }
    if (temp_data.image) {
      let fName = temp_data.image
      let formatArr = temp_data.image.split(".")
      console.log("formatArr", formatArr)
      let format = formatArr[formatArr.length - 1]
      setselectedFiles([
        {
          path: temp_data.image,
          preview: IMAGE_BASE + temp_data.image,
          name: temp_data.image,
          type: "image/" + format,
          formattedSize: "..",
        },
      ])
    }
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      Company: "",
      startDate: "",
      finishDate: "",
      description: "",
      mini_description: "",
      maxUserCount: "",
      textColor: "",
      color1: "",
      color2: "",
      guest: false,
      image: "",
      k_cat: "",
      k_cat_sub: "",
      k_cat_sub_alt: "",
      amount: "",
      amountType: "TL",
      maxUse: "1",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required")),
      startDate: Yup.string().required(props.t("Required")),
      finishDate: Yup.string().required(props.t("Required")),
      maxUse: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values, selectedFiles)
      let tempSelectedFiles = []
      if (selectedFiles.length > 0) {
        if (selectedFiles[0].name != values.image) {
          tempSelectedFiles = selectedFiles
        }
      }
      dispatch(onSave(params.id, values, tempSelectedFiles, props.history))
      //props.history.push(`/v1/coupons/create/step2/01`)
    },
  })

  // const onEditorStateChange = function (editorState) {
  //   setEditorState(editorState)
  //   const { blocks } = convertToRaw(editorState.getCurrentContent())
  //   /*let text = blocks.reduce((acc, item) => {
  //     acc = acc + item.text;
  //     return acc;
  //   }, "");*/
  //   let text = editorState.getCurrentContent().getPlainText("\u0001")
  //   setText(text)
  //   validation.setFieldValue(
  //     "description",
  //     draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //   )
  //   //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Coupons")}
            backLink={"/v1/coupons"}
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New Coupon")}</h4>
                  <p className="card-title-desc">
                    {props.t("New Coupon Description")}
                  </p>
                  {data && data ? (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="name">
                                  {props.t("Coupon Name")}
                                </Label>
                                <Input
                                  name="name"
                                  placeholder={props.t("Coupon Name")}
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={"12"}>
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustomMiniDescription">
                                  {props.t("Mini Description")}
                                </Label>
                                <Input
                                  name="mini_description"
                                  placeholder={props.t("Mini Description")}
                                  type="text"
                                  className="form-control"
                                  id="validationCustomMiniDescription"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.mini_description || ""
                                  }
                                  invalid={
                                    validation.touched.mini_description &&
                                    validation.errors.mini_description
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.mini_description &&
                                validation.errors.mini_description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.mini_description}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6" className="mb-3">
                          <Row className="relative">
                            <div
                              id="preview"
                              style={
                                validation.values.color1?.length >= 4 &&
                                validation.values.color2?.length >= 4
                                  ? {
                                      background: `linear-gradient(45deg, ${validation.values.color1}, ${validation.values.color2})`,
                                      borderRadius: "20px",
                                      padding: "25px",
                                    }
                                  : {
                                      borderRadius: "50px",
                                      padding: "25px",
                                    }
                              }
                            >
                              <div>
                                <h2
                                  style={
                                    validation.values.textColor?.length >= 4
                                      ? { color: validation.values.textColor }
                                      : {}
                                  }
                                >
                                  {validation.values.name}
                                </h2>
                                <p
                                  style={
                                    validation.values.textColor?.length >= 4
                                      ? { color: validation.values.textColor }
                                      : {}
                                  }
                                >
                                  {validation.values.mini_description}
                                </p>
                              </div>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={"6"}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationMaxUserCount">
                              {props.t("Max User Count")}
                            </Label>
                            <Input
                              name="maxUserCount"
                              placeholder={props.t("Max User Count")}
                              type="number"
                              step={"1"}
                              min={"0"}
                              className="form-control"
                              id="validationMaxUserCount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.maxUserCount || ""}
                              invalid={
                                validation.touched.maxUserCount &&
                                validation.errors.maxUserCount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.maxUserCount &&
                            validation.errors.maxUserCount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.maxUserCount}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md={"6"}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationMaxUse">
                              {props.t("maxUse")}
                            </Label>
                            <Input
                              name="maxUse"
                              placeholder={props.t("maxUse")}
                              type="number"
                              step={"1"}
                              min={"0"}
                              className="form-control"
                              id="validationMaxUse"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.maxUse || ""}
                              invalid={
                                validation.touched.maxUse &&
                                validation.errors.maxUse
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.maxUse &&
                            validation.errors.maxUse ? (
                              <FormFeedback type="invalid">
                                {validation.errors.maxUse}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={4}>
                          <Label>
                            {props.t("Sell Type")}
                            <span className="text-danger ms-2">*Zorunlu</span>
                          </Label>
                          <select
                            className="form-control"
                            value={validation.values.k_cat || ""}
                            onChange={e => {
                              let v = e.target.value || undefined
                              v = v == "Seç" ? null : v
                              validation.setFieldValue("k_cat", v)
                              validation.setFieldValue("k_cat_sub", "")
                              validation.setFieldValue("k_cat_sub_alt", "")
                            }}
                          >
                            <option>{props.t("Select")}</option>
                            {k_cat
                              ? k_cat?.map((item, idx) => (
                                  <option
                                    key={idx}
                                    value={item._id?.toString()}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </Col>
                        <Col md={4}>
                          <Label>{props.t("Category")}</Label>
                          <select
                            className="form-control"
                            value={validation.values.k_cat_sub || ""}
                            onChange={e => {
                              let v = e.target.value || undefined
                              v = v == "Seç" ? null : v
                              validation.setFieldValue("k_cat_sub", v)
                              validation.setFieldValue("k_cat_sub_alt", "")
                            }}
                          >
                            <option>{props.t("Select")}</option>
                            {k_cat_sub && validation.values.k_cat
                              ? k_cat_sub
                                  ?.filter(
                                    x =>
                                      x.k_cat ==
                                      validation.values.k_cat?.toString()
                                  )
                                  ?.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item._id?.toString()}
                                    >
                                      {item.name}
                                    </option>
                                  ))
                              : null}
                          </select>
                        </Col>
                        <Col md={4}>
                          <Label>{props.t("SubCategory")}</Label>
                          <select
                            className="form-control"
                            value={validation.values.k_cat_sub_alt || ""}
                            onChange={e => {
                              let v = e.target.value || undefined
                              v = v == "Seç" ? null : v
                              validation.setFieldValue("k_cat_sub_alt", v)
                            }}
                          >
                            <option>{props.t("Select")}</option>
                            {k_cat_sub &&
                            validation.values.k_cat &&
                            validation.values.k_cat_sub
                              ? k_cat_sub_alt
                                  ?.filter(
                                    x =>
                                      x.k_cat_sub ==
                                      validation.values.k_cat_sub?.toString()
                                  )
                                  ?.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item._id?.toString()}
                                    >
                                      {item.name}
                                    </option>
                                  ))
                              : null}
                          </select>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="amount">{props.t("Amount")}</Label>
                            <Input
                              name="amount"
                              placeholder={props.t("Amount")}
                              type="number"
                              step={"0.01"}
                              className="form-control"
                              id="Amount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.amount || ""}
                              invalid={
                                validation.touched.amount &&
                                validation.errors.amount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.amount &&
                            validation.errors.amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.amount}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <Label>
                            {props.t("Amount Type")}
                            <span className="text-danger ms-2">*Zorunlu</span>
                          </Label>
                          <select
                            className="form-control"
                            value={validation.values.amountType || ""}
                            onChange={e => {
                              let v = e.target.value || undefined
                              v = v == "Seç" ? null : v
                              validation.setFieldValue("amountType", v)
                            }}
                          >
                            <option>{props.t("Select")}</option>
                            {amountTypeList?.map((item, idx) => (
                              <option key={idx} value={item.key}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustomStartDate">
                              {props.t("Coupon Start Date")}
                            </Label>
                            <InputGroup className="relative">
                              <Flatpickr
                                className={
                                  validation.touched.startDate &&
                                  validation.errors.startDate
                                    ? "form-control d-block is-invalid"
                                    : "form-control d-block"
                                }
                                placeholder="gg.aa.yyyy ss:dd"
                                options={{
                                  locale: Turkish,
                                  enableTime: true,
                                  dateFormat: "d.m.Y H:i",
                                }}
                                id="validationCustomStartDate"
                                onChange={v => {
                                  validation.setFieldValue(
                                    "startDate",
                                    v.length > 0
                                      ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                      : ""
                                  )
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.startDate || ""}
                              />
                              <img className="form-date-img" />
                              {validation.touched.startDate &&
                              validation.errors.startDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.startDate}
                                </FormFeedback>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustomFinishDate">
                              {props.t("Coupon Finish Date")}
                            </Label>
                            <InputGroup className="relative">
                              <Flatpickr
                                className={
                                  validation.touched.finishDate &&
                                  validation.errors.finishDate
                                    ? "form-control d-block is-invalid"
                                    : "form-control d-block"
                                }
                                placeholder="gg.aa.yyyy ss:dd"
                                options={{
                                  locale: Turkish,
                                  enableTime: true,
                                  dateFormat: "d.m.Y H:i",
                                }}
                                id="validationCustomFinishDate"
                                onChange={v => {
                                  validation.setFieldValue(
                                    "finishDate",
                                    v.length > 0
                                      ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                      : ""
                                  )
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.finishDate || ""}
                              />
                              <img className="form-date-img" />
                              {validation.touched.finishDate &&
                              validation.errors.finishDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.finishDate}
                                </FormFeedback>
                              ) : null}
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" className="mt-2">
                          <FormGroup className="mb-3 d-flex flex-column">
                            <Label htmlFor="validationGuest">
                              {props.t("Color-1")}
                            </Label>
                            <Input
                              name="color1"
                              placeholder={props.t("Color-1")}
                              type="text"
                              className="form-control ms-2"
                              id="validationColor1"
                              onClick={() => {
                                setSelectionColor1(!selectionColor1)
                                setSelectionColor2(false)
                                setSelectionColor3(false)
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.color1 || ""}
                              invalid={
                                validation.touched.color1 &&
                                validation.errors.color1
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.color1 &&
                            validation.errors.color1 ? (
                              <FormFeedback type="invalid">
                                {validation.errors.color1}
                              </FormFeedback>
                            ) : null}
                            {selectionColor1 ? (
                              <SketchPicker
                                color={validation.values.color1}
                                value={validation.values.color1}
                                onChangeComplete={color => {
                                  validation.setFieldValue("color1", color.hex)
                                }}
                                width="260px"
                              />
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4" className="mt-2">
                          <FormGroup className="mb-3 d-flex flex-column">
                            <Label htmlFor="validationGuest">
                              {props.t("Color-2")}
                            </Label>
                            <Input
                              name="color2"
                              placeholder={props.t("Color-2")}
                              type="text"
                              className="form-control ms-2"
                              id="validationColor2"
                              onClick={() => {
                                setSelectionColor2(!selectionColor2)
                                setSelectionColor1(false)
                                setSelectionColor3(false)
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.color2 || ""}
                              invalid={
                                validation.touched.color2 &&
                                validation.errors.color2
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.color2 &&
                            validation.errors.color2 ? (
                              <FormFeedback type="invalid">
                                {validation.errors.color2}
                              </FormFeedback>
                            ) : null}
                            {selectionColor2 ? (
                              <SketchPicker
                                color={validation.values.color2}
                                value={validation.values.color2}
                                onChangeComplete={color => {
                                  validation.setFieldValue("color2", color.hex)
                                }}
                                width="260px"
                              />
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4" className="mt-2">
                          <FormGroup className="mb-3 d-flex flex-column">
                            <Label htmlFor="validationGuest">
                              {props.t("Text Color")}
                            </Label>
                            <Input
                              name="textColor"
                              placeholder={props.t("Text Color")}
                              type="text"
                              className="form-control ms-2"
                              id="validationtextColor"
                              onClick={() => {
                                setSelectionColor3(!selectionColor3)
                                setSelectionColor2(false)
                                setSelectionColor1(false)
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.textColor || ""}
                              invalid={
                                validation.touched.textColor &&
                                validation.errors.textColor
                                  ? true
                                  : false
                              }
                            />

                            {validation.touched.textColor &&
                            validation.errors.textColor ? (
                              <FormFeedback type="invalid">
                                {validation.errors.textColor}
                              </FormFeedback>
                            ) : null}
                            {selectionColor3 ? (
                              <SketchPicker
                                color={validation.values.textColor}
                                value={validation.values.textColor}
                                onChangeComplete={color => {
                                  validation.setFieldValue(
                                    "textColor",
                                    color.hex
                                  )
                                }}
                                width="260px"
                              />
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" className="mt-2">
                          <FormGroup className="mb-3 d-flex">
                            <Label htmlFor="validationGuest">
                              {props.t("Coupon Guest")}
                            </Label>
                            <Input
                              name="guest"
                              placeholder={props.t("Coupon Guest")}
                              type="checkbox"
                              className="form-control ms-2"
                              id="validationGuest"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.guest}
                              defaultChecked={validation.values.guest}
                              invalid={
                                validation.touched.guest &&
                                validation.errors.guest
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.guest &&
                            validation.errors.guest ? (
                              <FormFeedback type="invalid">
                                {validation.errors.guest}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="12">
                          <Label htmlFor="description">
                            {props.t("Coupon Description")}
                          </Label>
                          <CKEditor
                            id="description"
                            name="description"
                            editor={ClassicEditor}
                            data={validation.values.description}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor)
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              validation.setFieldValue("description", data)
                            }}
                          />
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">
                        {props.t("Continue")}
                      </Button>
                    </Form>
                  ) : (
                    <Loading status={true} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CouponUpdate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CouponUpdate)
