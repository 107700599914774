import {
  KASA_RESET_ERROR,
  KASA_RESET,
  KASA_GET_SUCCESS,
  KASA_GET_FAIL,
  KASA_ADD_SELL_SUCCESS,
  KASA_ADD_SELL_FAIL,
  KASA_UPDATE_SELL_SUCCESS,
  KASA_UPDATE_SELL_FAIL,
  KASA_DELETE_SELL_FAIL,
  KASA_DELETE_SELL_SUCCESS,
  KASA2_GET_SUCCESS,
  KASA2_GET_FAIL,
  KASA2_ADD_SELL_SUCCESS,
  KASA2_ADD_SELL_FAIL,
  KASA2_UPDATE_SELL_SUCCESS,
  KASA2_UPDATE_SELL_FAIL,
  KASA2_DELETE_SELL_FAIL,
  KASA2_DELETE_SELL_SUCCESS,
  KASA_LOADING,
  KASA_SEARCH_GET_SUCCESS,
  KASA_SEARCH_RESET,
  KASA_SEARCH_GET_FAIL,
  KASA_GET_DAILY_CASH_FAIL,
  KASA_GET_DAILY_CASH_SUCCESS,
  KASA_SET_DAILY_CASH_SUCCESS,
  KASA_SET_DAILY_CASH_FAIL,
  KASA_QRCODE_CHECK_SUCCESS,
  KASA_QRCODE_CHECK_FAIL,
  KASA_QRCODE_CHECK_RESET,
  KASA_SEARCH_SUCCESS,
  KASA_SEARCH_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  data: [],
  data2: [],
  search: [],
  dailyCash: null,
  today: null,
  error: null,
  loading: true,
  qrCheck: null,
}

const Kasa = (state = INIT_STATE, action) => {
  switch (action.type) {
    case KASA_SEARCH_SUCCESS:
    case KASA_SEARCH_GET_SUCCESS:
      return {
        ...state,
        search: action.payload?.data,
      }
    case KASA_GET_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
      }
    case KASA_ADD_SELL_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }

    case KASA_UPDATE_SELL_SUCCESS:
      return {
        ...state,
        data: state.data.map(data =>
          data._id.toString() === action.payload.data._id.toString()
            ? { data, ...action.payload.data }
            : data
        ),
      }
    case KASA_DELETE_SELL_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          data => data._id.toString() !== action.payload.data._id.toString()
        ),
      }

    case KASA2_GET_SUCCESS:
      return {
        ...state,
        data2: action.payload?.data,
      }
    case KASA2_ADD_SELL_SUCCESS:
      return {
        ...state,
        data2: [...state.data2, action.payload.data],
      }

    case KASA2_UPDATE_SELL_SUCCESS:
      return {
        ...state,
        data2: state.data2.map(data =>
          data._id.toString() === action.payload.data._id.toString()
            ? { data, ...action.payload.data }
            : data
        ),
      }
    case KASA2_DELETE_SELL_SUCCESS:
      return {
        ...state,
        data2: state.data2.filter(
          data => data._id.toString() !== action.payload.data._id.toString()
        ),
      }
    case KASA_GET_DAILY_CASH_SUCCESS:
      return {
        ...state,
        dailyCash: action.payload?.data,
        today: action.payload?.today,
      }
    case KASA_SET_DAILY_CASH_SUCCESS:
      return {
        ...state,
        dailyCash: action.payload?.data,
        today: action.payload?.today,
      }
    case KASA_QRCODE_CHECK_SUCCESS:
      return {
        ...state,
        qrCheck: action.payload?.data,
      }
    case KASA_SET_DAILY_CASH_FAIL:
    case KASA_GET_DAILY_CASH_FAIL:
    case KASA_GET_FAIL:
    case KASA_ADD_SELL_FAIL:
    case KASA_UPDATE_SELL_FAIL:
    case KASA_DELETE_SELL_FAIL:
    case KASA2_GET_FAIL:
    case KASA2_ADD_SELL_FAIL:
    case KASA2_UPDATE_SELL_FAIL:
    case KASA2_DELETE_SELL_FAIL:
    case KASA_SEARCH_GET_FAIL:
    case KASA_QRCODE_CHECK_FAIL:
    case KASA_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case KASA_RESET_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      }
    case KASA_RESET:
      return {
        ...state,
        data: [],
        data2: [],
        search: [],
        error: null,
        dailyCash: null,
        today: null,
        qrCheck: null,
      }
    case KASA_SEARCH_RESET:
      return {
        ...state,
        search: [],
      }
    case KASA_QRCODE_CHECK_RESET:
      return {
        ...state,
        qrCheck: null,
      }
    case KASA_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default Kasa
