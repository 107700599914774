import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//KASA
import Kasa from "./kasa/reducer";
import KasaBuy from "./kasaBuy/reducer";
import Companies from "./companies/reducer";
 

//Dashboard 
import Dashboard from "./dashboard/reducer";
import Campaigns from "./campaigns/reducer";
import Products from "./products/reducer";
import AppUsers from "./appusers/reducer";
import Coupons from "./coupons/reducer";

 

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Kasa, KasaBuy, Companies,
  Profile, 
  Dashboard, 
  Campaigns,
  Products,
  AppUsers,
  Coupons
})

export default rootReducer
