import {
  KASA_RESET,
  KASA_LOADING,
  KASA_GET_SUCCESS,
  KASA_GET_FAIL,
  KASA_GET_SELL,
  KASA_ADD_SELL,
  KASA_ADD_SELL_FAIL,
  KASA_ADD_SELL_SUCCESS,
  KASA_UPDATE_SELL,
  KASA_UPDATE_SELL_FAIL,
  KASA_UPDATE_SELL_SUCCESS,
  KASA_DELETE_SELL_FAIL,
  KASA_DELETE_SELL,
  KASA_DELETE_SELL_SUCCESS,
  KASA2_GET_SUCCESS,
  KASA2_GET_FAIL,
  KASA2_GET_SELL,
  KASA2_ADD_SELL,
  KASA2_ADD_SELL_FAIL,
  KASA2_ADD_SELL_SUCCESS,
  KASA2_UPDATE_SELL,
  KASA2_UPDATE_SELL_FAIL,
  KASA2_UPDATE_SELL_SUCCESS,
  KASA2_DELETE_SELL_FAIL,
  KASA2_DELETE_SELL,
  KASA2_DELETE_SELL_SUCCESS,
  KASA_SEARCH_GET_SELL,
  KASA_SEARCH_GET_SUCCESS,
  KASA_SEARCH_GET_FAIL,
  KASA_RESET_ERROR,
  KASA_GET_DAILY_CASH_SUCCESS,
  KASA_GET_DAILY_CASH_FAIL,
  KASA_GET_DAILY_CASH,
  KASA_SET_DAILY_CASH,
  KASA_SET_DAILY_CASH_FAIL,
  KASA_SET_DAILY_CASH_SUCCESS,
  KASA_SEARCH_RESET,
  KASA_QRCODE_CHECK_SUCCESS,
  KASA_QRCODE_CHECK_FAIL,
  KASA_QRCODE_CHECK,
  KASA_QRCODE_CHECK_RESET,
  KASA_SEARCH,
  KASA_SEARCH_FAIL,
  KASA_SEARCH_SUCCESS,
} from "./actionTypes"

export const kasaLoading = status => ({
  type: KASA_LOADING,
  payload: { status },
})

//SELL GET
export const kasaGetSuccess = (actionType, data) => ({
  type: KASA_GET_SUCCESS,
  payload: { actionType, data },
})

export const kasaGetFail = (actionType, error) => ({
  type: KASA_GET_FAIL,
  payload: { actionType, error },
})

export const kasaGetSell = (date, branchId) => ({
  type: KASA_GET_SELL,
  payload: { date, branchId },
})

//SELL ADD
export const kasaSellAddSuccess = (actionType, data) => ({
  type: KASA_ADD_SELL_SUCCESS,
  payload: { actionType, data },
})

export const kasaSellAddFail = (actionType, error) => ({
  type: KASA_ADD_SELL_FAIL,
  payload: { actionType, error },
})

export const kasaSellAdd = body => ({
  type: KASA_ADD_SELL,
  payload: { body },
})

//SELL UPDATE
export const kasaSellUpdateSuccess = (actionType, data) => ({
  type: KASA_UPDATE_SELL_SUCCESS,
  payload: { actionType, data },
})

export const kasaSellUpdateFail = (actionType, error) => ({
  type: KASA_UPDATE_SELL_FAIL,
  payload: { actionType, error },
})

export const kasaSellUpdate = (id, body) => ({
  type: KASA_UPDATE_SELL,
  payload: { id, body },
})

//SELL DELETE
export const kasaSellDeleteSuccess = (actionType, data) => ({
  type: KASA_DELETE_SELL_SUCCESS,
  payload: { actionType, data },
})

export const kasaSellDeleteFail = (actionType, error) => ({
  type: KASA_DELETE_SELL_FAIL,
  payload: { actionType, error },
})

export const kasaSellDelete = id => ({
  type: KASA_DELETE_SELL,
  payload: { id },
})

//SELL GET
export const kasa2GetSuccess = (actionType, data) => ({
  type: KASA2_GET_SUCCESS,
  payload: { actionType, data },
})

export const kasa2GetFail = (actionType, error) => ({
  type: KASA2_GET_FAIL,
  payload: { actionType, error },
})

export const kasa2GetSell = (date, branchId) => ({
  type: KASA2_GET_SELL,
  payload: { date, branchId },
})

//SELL ADD
export const kasa2SellAddSuccess = (actionType, data) => ({
  type: KASA2_ADD_SELL_SUCCESS,
  payload: { actionType, data },
})

export const kasa2SellAddFail = (actionType, error) => ({
  type: KASA2_ADD_SELL_FAIL,
  payload: { actionType, error },
})

export const kasa2SellAdd = body => ({
  type: KASA2_ADD_SELL,
  payload: { body },
})

//SELL UPDATE
export const kasa2SellUpdateSuccess = (actionType, data) => ({
  type: KASA2_UPDATE_SELL_SUCCESS,
  payload: { actionType, data },
})

export const kasa2SellUpdateFail = (actionType, error) => ({
  type: KASA2_UPDATE_SELL_FAIL,
  payload: { actionType, error },
})

export const kasa2SellUpdate = (id, body) => ({
  type: KASA2_UPDATE_SELL,
  payload: { id, body },
})

//SELL DELETE
export const kasa2SellDeleteSuccess = (actionType, data) => ({
  type: KASA2_DELETE_SELL_SUCCESS,
  payload: { actionType, data },
})

export const kasa2SellDeleteFail = (actionType, error) => ({
  type: KASA2_DELETE_SELL_FAIL,
  payload: { actionType, error },
})

export const kasa2SellDelete = id => ({
  type: KASA2_DELETE_SELL,
  payload: { id },
})

//SEARCH

export const kasaSearchGetSuccess = (actionType, data) => ({
  type: KASA_SEARCH_GET_SUCCESS,
  payload: { actionType, data },
})

export const kasaSearchGetFail = (actionType, error) => ({
  type: KASA_SEARCH_GET_FAIL,
  payload: { actionType, error },
})

export const kasaSearchGetSell = (date, search) => ({
  type: KASA_SEARCH_GET_SELL,
  payload: { date, search },
})

//QRCODE
export const kasaQrCodeCheckSuccess = (actionType, data) => ({
  type: KASA_QRCODE_CHECK_SUCCESS,
  payload: { actionType, data },
})

export const kasaQrCodeCheckFail = (actionType, error) => ({
  type: KASA_QRCODE_CHECK_FAIL,
  payload: { actionType, error },
})

export const kasaQrCodeCheck = (qrCode, type) => ({
  type: KASA_QRCODE_CHECK,
  payload: { qrCode, type },
})

//DAILY CASH
export const kasaGetDailyCashSuccess = (actionType, data, today) => ({
  type: KASA_GET_DAILY_CASH_SUCCESS,
  payload: { actionType, data, today },
})

export const kasaGetDailyCashFail = (actionType, error) => ({
  type: KASA_GET_DAILY_CASH_FAIL,
  payload: { actionType, error },
})

export const kasaGetDailyCash = (date, branchId) => ({
  type: KASA_GET_DAILY_CASH,
  payload: { date, branchId },
})

export const kasaSetDailyCashSuccess = (actionType, data, today) => ({
  type: KASA_SET_DAILY_CASH_SUCCESS,
  payload: { actionType, data, today },
})

export const kasaSetDailyCashFail = (actionType, error) => ({
  type: KASA_SET_DAILY_CASH_FAIL,
  payload: { actionType, error },
})

export const kasaSetDailyCash = (date, branchId, body) => ({
  type: KASA_SET_DAILY_CASH,
  payload: { date, branchId, body },
})

export const kasaResetError = () => ({
  type: KASA_RESET_ERROR,
})
export const kasaResetAll = () => ({
  type: KASA_RESET,
})

export const searchReset = () => ({
  type: KASA_SEARCH_RESET,
})
export const qrCodeSearchReset = () => ({
  type: KASA_QRCODE_CHECK_RESET,
})

//SEARCH
export const kasaSearchSuccess = (actionType, data) => ({
  type: KASA_SEARCH_SUCCESS,
  payload: { actionType, data },
})

export const kasaSearchFail = (actionType, error) => ({
  type: KASA_SEARCH_FAIL,
  payload: { actionType, error },
})

export const kasaSearch = (body) => ({
  type: KASA_SEARCH,
  payload: { body },
})
