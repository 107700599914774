import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getCouponsSuccess,
  getCouponsFail,
  addCouponSuccess,
  addCouponFail,
  getCouponSuccess,
  getCouponFail,
  updateCouponSuccess,
  updateCouponFail,
  deleteCouponSuccess,
  deleteCouponFail,
  couponLoading,
  addTermCouponSuccess,
  addTermCouponFail,
  updateTermCouponSuccess,
  updateTermCouponFail,
  termsCouponSuccess,
  termsCouponFail,
  termCouponSuccess,
  termCouponFail,
  deleteTermCouponSuccess,
  deleteTermCouponFail,
  getBranchGroupByCouponSuccess,
  getBranchGroupByCouponFail,
  updateBranchGroupByCouponSuccess,
  updateBranchGroupByCouponFail,
  postCouponProductTypeSuccess,
  postCouponProductTypeFail,
  postCouponStatusSuccess,
  postCouponStatusFail,
  couponSuccess,
  postCouponNewDataSuccess,
  postCouponNewDataFail,
  getCouponNewDataSuccess,
  getCouponNewDataFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCoupons,
  addCoupon,
  updateCoupon,
  deleteCoupon,
  getCoupon,
  addTermCoupon,
  updateTermCoupon,
  getTermsCoupon,
  getTermCoupon,
  deleteTermCoupon,
  getBranchGroupByCoupon,
  updateBranchGroupByCoupon,
  postCouponProductType_Step4,
  postCouponStatus,
  postCouponNewData,
  getCouponNewData,
} from "../../helpers/fakebackend_helper"
import {
  GET_COUPONS,
  GET_COUPONS_FAIL,
  GET_COUPONS_SUCCESS,
  ADD_COUPON_SUCCESS,
  ADD_COUPON,
  ADD_COUPON_FAIL,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAIL,
  GET_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  UPDATE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  DELETE_COUPON,
  ADD_TERM_COUPON_FAIL,
  ADD_TERM_COUPON_SUCCESS,
  ADD_TERM_COUPON,
  UPDATE_TERM_COUPON_SUCCESS,
  UPDATE_TERM_COUPON_FAIL,
  UPDATE_TERM_COUPON,
  TERMS_COUPON_SUCCESS,
  TERMS_COUPON_FAIL,
  TERM_COUPON_SUCCESS,
  TERM_COUPON_FAIL,
  TERMS_COUPON,
  TERM_COUPON,
  DELETE_TERM_COUPON,
  DELETE_TERM_COUPON_SUCCESS,
  DELETE_TERM_COUPON_FAIL,
  GET_BRANCHGROUPS_BY_COUPON_SUCCESS,
  GET_BRANCHGROUPS_BY_COUPON_FAIL,
  GET_BRANCHGROUPS_BY_COUPON,
  UPDATE_COUPON_STEP3,
  UPDATE_COUPON_STEP3_SUCCESS,
  UPDATE_COUPON_STEP3_FAIL,
  POST_COUPON_PRODUCT_TYPE_SUCCESS,
  POST_COUPON_PRODUCT_TYPE_FAIL,
  POST_COUPON_PRODUCT_TYPE,
  POST_COUPON_STATUS_SUCCESS,
  POST_COUPON_STATUS_FAIL,
  POST_COUPON_STATUS,
  POST_COUPON_NEW_DATA_SUCCESS,
  POST_COUPON_NEW_DATA_FAIL,
  POST_COUPON_NEW_DATA,
  GET_COUPON_NEW_DATA_SUCCESS,
  GET_COUPON_NEW_DATA_FAIL,
  GET_COUPON_NEW_DATA,
} from "./actionTypes"

//COUPONS
function* getCouponsFetch({ payload: {} }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(getCoupons)
    if (response.status) {
      yield put(
        getCouponsSuccess(GET_COUPONS_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getCouponsFail(
          GET_COUPONS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(getCouponsFail(GET_COUPONS_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* addCouponFetch({ payload: { data, file, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(addCoupon, data, file)
    if (response.status) {
      yield put(addCouponSuccess(ADD_COUPON_SUCCESS, response.data))
      //history.push(`/v1/coupons/create/step2/${response.data._id}?l=new`)
      history.push(`/v1/coupons/${response.data._id}`)
    } else {
      yield put(
        addCouponFail(
          ADD_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }

    yield put(couponLoading(false))
  } catch (error) {
    yield put(addCouponFail(ADD_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* updateCouponFetch({ payload: { id, data, file, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(updateCoupon, id, data, file)
    if (response.status) {
      yield put(updateCouponSuccess(UPDATE_COUPON_SUCCESS, response.data))
      //history.push("/v1/coupons")
      //history.push(`/v1/coupons/create/step2/${id}?l=new`)
      history.push(`/v1/coupons/${id}`)
    } else {
      yield put(
        updateCouponFail(
          UPDATE_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(updateCouponFail(UPDATE_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* deleteCouponFetch({ payload: { id, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(deleteCoupon, id)
    if (response.status) {
      yield put(deleteCouponSuccess(DELETE_COUPON_SUCCESS, response.data))
      history.push("/v1/coupons")
    } else {
      yield put(
        deleteCouponFail(
          DELETE_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(deleteCouponFail(DELETE_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* getCouponByIdFetch({ payload: { id } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(getCoupon, id)
    if (response.status) {
      yield put(getCouponSuccess(GET_COUPON_SUCCESS, response.data))
    } else {
      yield put(
        getCouponFail(
          GET_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(getCouponFail(GET_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* getTermsCouponFetch({ payload: { id, level } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(getTermsCoupon, id, level)
    if (response.status) {
      yield put(termsCouponSuccess(TERMS_COUPON_SUCCESS, response.data))
    } else {
      yield put(
        termsCouponFail(
          TERMS_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(termsCouponFail(TERMS_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* getTermCouponFetch({ payload: { id, level } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(getTermCoupon, id, level)
    if (response.status) {
      yield put(termCouponSuccess(TERM_COUPON_SUCCESS, response.data))
    } else {
      yield put(
        termCouponFail(
          TERM_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(termCouponFail(TERM_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* addTempCouponFetch({ payload: { id, data, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(addTermCoupon, id, data)
    if (response.status) {
      yield put(
        addTermCouponSuccess(ADD_TERM_COUPON_SUCCESS, response.data)
      )
      history.push(`/v1/coupons/${response.data._id}`)
      location.reload()
    } else {
      yield put(
        addTermCouponFail(
          ADD_TERM_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }

    yield put(couponLoading(false))
  } catch (error) {
    yield put(addTermCouponFail(ADD_TERM_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* updateTempCouponFetch({ payload: { id, level, data, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(updateTermCoupon, id, level, data)
    if (response.status) {
      yield put(
        updateTermCouponSuccess(UPDATE_TERM_COUPON_SUCCESS, response.data)
      )
      history.push(`/v1/coupons/${response.data._id}`)
      location.reload()
    } else {
      yield put(
        updateTermCouponFail(
          UPDATE_TERM_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }

    yield put(couponLoading(false))
  } catch (error) {
    yield put(updateTermCouponFail(UPDATE_TERM_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* deleteTempCouponFetch({ payload: { id, level, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(deleteTermCoupon, id, level)
    if (response.status) {
      yield put(
        deleteTermCouponSuccess(DELETE_TERM_COUPON_SUCCESS, response.data)
      )
      history.push(`/v1/coupons/${response.data._id}`)
      location.reload()
    } else {
      yield put(
        deleteTermCouponFail(
          DELETE_TERM_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(deleteTermCouponFail(DELETE_TERM_COUPON_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* getBranchGroupByCouponFetch({ payload: { id } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(getBranchGroupByCoupon, id)
    if (response.status) {
      yield put(
        getBranchGroupByCouponSuccess(
          GET_BRANCHGROUPS_BY_COUPON_SUCCESS,
          response.data,
          response.groupList,
          response.branchList
        )
      )
    } else {
      yield put(
        getBranchGroupByCouponFail(
          GET_BRANCHGROUPS_BY_COUPON_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(
      getBranchGroupByCouponFail(GET_BRANCHGROUPS_BY_COUPON_FAIL, error)
    )
    yield put(couponLoading(false))
  }
}

function* updateBranchGroupByCouponFetch({ payload: { id, data, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(updateBranchGroupByCoupon, id, data)
    if (response.status) {
      yield put(
        updateBranchGroupByCouponSuccess(
          UPDATE_COUPON_STEP3_SUCCESS,
          response.data
        )
      )
      //history.push(`/v1/coupons/create/step4/${id}`)
      history.push(`/v1/coupons/${id}`)
    } else {
      yield put(
        updateBranchGroupByCouponFail(
          UPDATE_COUPON_STEP3_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(
      updateBranchGroupByCouponFail(UPDATE_COUPON_STEP3_FAIL, error)
    )
    yield put(couponLoading(false))
  }
}

function* postCouponProductTypeFetch({ payload: { id, data, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(postCouponProductType_Step4, id, data)
    if (response.status) {
      yield put(
        postCouponProductTypeSuccess(
          POST_COUPON_PRODUCT_TYPE_SUCCESS,
          response.data
        )
      )
      history.push(`/v1/coupons/${id}`)
    } else {
      yield put(
        postCouponProductTypeFail(
          POST_COUPON_PRODUCT_TYPE_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(
      postCouponProductTypeFail(POST_COUPON_PRODUCT_TYPE_FAIL, error)
    )
    yield put(couponLoading(false))
  }
}

function* postCouponStatusFetch({ payload: { id, data, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(postCouponStatus, id, data)
    if (response.status) {
      if (response.data.status == "wait") {
        yield put(
          couponSuccess({
            status: "success",
            title: "Başarılı",
            message: "Kampanya Yayınlanmak Üzere Beklemeye Alındı",
          })
        )
      } else if (response.data.status == "stop") {
        yield put(
          couponSuccess({
            status: "success",
            title: "Kampanya Durduruldu",
            message: "Kampanya Başarıyla Durduruldu",
          })
        )
      } else if (response.data.status == "design") {
        yield put(
          couponSuccess({
            status: "success",
            title: "Kampanya İptal Edildi",
            message: "Kampanyayı Yeniden Düzenleyebilirsiniz.",
          })
        )
      }

      yield put(
        postCouponStatusSuccess(POST_COUPON_STATUS_SUCCESS, response.data)
      )
    } else {
      yield put(
        postCouponStatusFail(
          POST_COUPON_STATUS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(postCouponStatusFail(POST_COUPON_STATUS_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* postCouponNewDataFetch({ payload: { id, data, history, dataId } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(postCouponNewData, id, data, dataId)
    if (response.status) {
      yield put(
        postCouponNewDataSuccess(
          POST_COUPON_NEW_DATA_SUCCESS,
          response.data
        )
      )
      console.log("data", response.data)
      yield put(
        couponSuccess({
          status: response.data.autoConfirm == true ? "success": "warning",
          title: "Kaydedildi",
          message:
            response.data.autoConfirm == true
              ? "Kampanya Başvurusu Başarıyla Kaydedildi"
              : response.data.autoMessage,
        })
      )
      history.push(`/v1/coupons/new-data-list/${id}`)
    } else {
      yield put(
        postCouponNewDataFail(
          POST_COUPON_NEW_DATA_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(postCouponNewDataFail(POST_COUPON_NEW_DATA_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* getCouponNewDataFetch({ payload: { id, couponId, history } }) {
  try {
    yield put(couponLoading(true))
    var response = yield call(getCouponNewData, id, couponId)
    if (response.status) {
      yield put(
        getCouponNewDataSuccess(
          GET_COUPON_NEW_DATA_SUCCESS,
          response.data
        )
      )
        
    } else {
      yield put(
        getCouponNewDataFail(
          GET_COUPON_NEW_DATA_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-COUPONS-1001"
        )
      )
    }
    yield put(couponLoading(false))
  } catch (error) {
    yield put(getCouponNewDataFail(GET_COUPON_NEW_DATA_FAIL, error))
    yield put(couponLoading(false))
  }
}

function* couponsSaga() {
  yield takeEvery(GET_COUPONS, getCouponsFetch)
  yield takeEvery(GET_COUPON, getCouponByIdFetch)
  yield takeEvery(ADD_COUPON, addCouponFetch)
  yield takeEvery(UPDATE_COUPON, updateCouponFetch)
  yield takeEvery(DELETE_COUPON, deleteCouponFetch)

  yield takeEvery(TERMS_COUPON, getTermsCouponFetch)
  yield takeEvery(TERM_COUPON, getTermCouponFetch)
  yield takeEvery(ADD_TERM_COUPON, addTempCouponFetch)
  yield takeEvery(UPDATE_TERM_COUPON, updateTempCouponFetch)
  yield takeEvery(DELETE_TERM_COUPON, deleteTempCouponFetch)

  yield takeEvery(GET_BRANCHGROUPS_BY_COUPON, getBranchGroupByCouponFetch)
  yield takeEvery(UPDATE_COUPON_STEP3, updateBranchGroupByCouponFetch)
  yield takeEvery(POST_COUPON_PRODUCT_TYPE, postCouponProductTypeFetch)
  yield takeEvery(POST_COUPON_STATUS, postCouponStatusFetch)

  yield takeEvery(GET_COUPON_NEW_DATA, getCouponNewDataFetch)
  yield takeEvery(POST_COUPON_NEW_DATA, postCouponNewDataFetch)
}

export default couponsSaga
