import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"

import { currencyFormat } from "helpers/functions"
import moment from "moment"
import Swal from "sweetalert2"
import "./custom.scss"
import { isEmpty } from "lodash"

const BreadcrumbCustomNTS = props => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="nts">
            <div
              className="table-responsive react-table "
              style={{ width: "100%", marginBottom: "-15px" }}
            >
              <table className="table text-center aok-table-border">
                <thead className="table-light table-nowrap thead-hover">
                  <tr>
                    <th scope="col">
                      {props.isEdit == true ? (
                        <a
                          className="btn btn-primary btn-sm"
                          style={{ zIndex: 9999 }}
                          onClick={() => {
                            const _date = moment(
                              props.date,
                              "DD-MM-YYYY"
                            ).toDate()
                            const _today = moment(new Date()).toDate()
                            console.log(
                              "_date _today",
                              _date,
                              _today,
                              _date > _today
                            )
                            if (_date > _today) {
                              Swal.fire(
                                "Bugün İçin Devir Kaydı Yapamazsınız",
                                `Seçilen Gün, Bugünden Büyük Olamaz! <br>Seçilen Gün: ${
                                  props.date
                                }<br>Bugün: ${moment(_today).format(
                                  "DD-MM-YYYY"
                                )}`,
                                "warning"
                              )
                            } else {
                              props.handleEdit()
                            }
                          }}
                        >
                          {props.t("Case Close")}
                        </a>
                      ) : null}
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    >
                      Web Kontor
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    >
                      NTS
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    >
                      Paycell
                    </th>
                    <th
                      scope="col"
                      style={{ textAlign: "right" }}
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                    >
                      <a>
                        <i
                          className={
                            isOpen ? `bx bx-chevron-up` : `bx bx-chevron-down`
                          }
                        ></i>
                      </a>
                    </th>
                  </tr>
                </thead>
                {isOpen && isOpen ? (
                  <tbody>
                    <tr>
                      <th scope="row" className="table-light table-nowrap">
                        <div style={{ marginTop: "15px" }}>Nakit</div>
                      </th>
                      <td>
                        {/* Web Kontor - Nakit */}
                        <div className="d-flex justify-content-around">
                          <div className="col me-2">
                            <div>
                              <b>Kar</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.WebCredit?.Cash?.gain || 0
                              ) || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Adet</b>
                            </div>
                            <div>
                              {props.dailyCash?.WebCredit?.Cash?.count || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Tutar</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.WebCredit?.Cash?.amount || 0
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* NTS - Nakit*/}
                        <div className="d-flex justify-content-around">
                          <div className="col me-2">
                            <div>
                              <b></b>
                            </div>
                            <div></div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Adet</b>
                            </div>
                            <div>
                              {props.dailyCash?.NTS?.Cash?.count || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Tutar</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.NTS?.Cash?.amount || 0
                              ) || "-"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* PayCell - Nakit */}
                        <div className="d-flex justify-content-around">
                          <div className="col me-2">
                            <div>
                              <b></b>
                            </div>
                            <div></div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Adet</b>
                            </div>
                            <div>
                              {props.dailyCash?.PayCell?.Cash?.count || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Tutar</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.PayCell?.Cash?.amount || 0
                              ) || "-"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* Devir - Nakit */}
                        <div className="d-flex justify-content-around">
                          <div className="col me-2">
                            <div>
                              <b>Dünden Devir</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.Transfer?.Cash?.yesterdayAmount
                              ) || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            {/* <div>
                          <b>{moment(props.date, 'DD-MM-YYYY').subtract(1, "day").format('DD-MM-YYYY')}</b>
                        </div>
                        <div>{currencyFormat(
                            props.dailyCash?.Transfer?.Cash?.yesterdayAmount
                          ) || '-'}</div> */}
                            <b>{props.t("Cash")}</b>
                            <div>
                              {currencyFormat(
                                //props.dailyCash?.Transfer?.Cash?.transferAmount
                                (props.today?.gelir?.nakit || 0) +
                                  (props.dailyCash?.Transfer?.Cash
                                    ?.yesterdayAmount || 0) -
                                  (props.today?.gider?.nakit || 0)
                              ) || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Tahsilat</b>
                            </div>
                            <div>
                              {currencyFormat(
                                parseFloat(
                                  props.dailyCash?.NTS?.Cash?.amount || 0
                                ) +
                                  parseFloat(
                                    props.dailyCash?.PayCell?.Cash?.amount || 0
                                  ) +
                                  parseFloat(
                                    props.dailyCash?.WebCredit?.Cash?.amount ||
                                      0
                                  )
                              ) || "-"}
                            </div>
                            <div>
                              <b></b>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="table-light table-nowrap">
                        <div style={{ marginTop: "15px" }}>Kredi Karti</div>
                      </th>
                      <td>
                        {/* Web Kontor - Kredi Karti */}
                        <div className="d-flex justify-content-around">
                          <div className="col me-2">
                            <div>
                              <b></b>
                            </div>
                            <div></div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Adet</b>
                            </div>
                            <div>
                              {props.dailyCash?.WebCredit?.Card?.count || "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b>Tutar</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.WebCredit?.Card?.amount || 0
                              ) || "-"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* NTS - Kredi Karti*/}
                        <div className="d-flex justify-content-around">
                          <div className="me-2">
                            <div>
                              <b>Hizmet B.</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.NTS?.Card?.commission || 0
                              ) || "-"}
                            </div>
                          </div>
                          <div className="me-2">
                            <div>
                              <b>Adet</b>
                            </div>
                            <div>
                              {props.dailyCash?.NTS?.Card?.count || "-"}
                            </div>
                          </div>
                          <div className="me-2">
                            <div>
                              <b>Tutar</b>
                            </div>
                            <div>
                              {currencyFormat(
                                props.dailyCash?.NTS?.Card?.amount || 0
                              ) || "-"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* PayCell - Kredi Karti */}
                        <div className="d-flex justify-content-around"></div>
                      </td>

                      <td>
                        {/* Devir - Card */}
                        <div className="d-flex justify-content-around">
                          <div className="col me-2">
                            <div>
                              <b></b>
                            </div>
                            <div></div>
                          </div>
                          <div className="col me-2">
                            <b>{props.t("Card")}</b>
                            <div>
                              {currencyFormat(props.today?.gelir?.k_karti) ||
                                "-"}
                            </div>
                          </div>
                          <div className="col me-2">
                            <div>
                              <b></b>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
              {!isEmpty(props.isDayReport) ? (
                <table className="table text-center aok-table-border">
                  <tbody className="table-light table-nowrap thead-hover">
                    <tr>
                      <td scope="col"><b>Aksesuar Kar: </b>{props.isDayReport?.aksesuar?.kar}</td>
                      <td scope="col"><b>Cihaz Kar: </b>{props.isDayReport?.cihaz?.kar}</td>
                      <td scope="col"><b>Diğer Kar: </b>{props.isDayReport?.diger?.kar}</td>
                      <td scope="col"><b>Toplam Kar: </b>{props.isDayReport?.total?.kar}</td>
                    </tr>
                  </tbody> 
                </table>
              ) : null}
            </div>
          </div>
          <div className="page-title-right mt-2">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

BreadcrumbCustomNTS.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  t: PropTypes.any,
  dailyCash: PropTypes.object,
  handleEdit: PropTypes.func,
  date: PropTypes.string,
  today: PropTypes.object,
  isEdit: PropTypes.bool,
  isDayReport: PropTypes.object,
}

export default BreadcrumbCustomNTS
