import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

//redux
import {
  kasaGetSell as onGetData,
  kasaResetAll as onResetData,
  getBranches as onBranches,
  getBanks as onBanks,
  getPersons as onPersons,
  get_K_CAT as onK_CAT,
  kasaResetError as onResetError,
  kasaSellDelete as onDelete,
  kasaGetDailyCash as onDailyCash,
  companiesResetAll as onCompaniesResetAll,
} from "store/actions"
import { currencyFormat } from "helpers/functions"
import { isEmpty } from "lodash"
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import Swal from "sweetalert2"
import "./../../../components/Common/custom.scss"
import "./../custom.scss"
import axios from "axios"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import zipcelx from "zipcelx"
import Loading2 from "components/Common/Loading2"
import tr from 'date-fns/locale/tr' 
registerLocale("tr", tr)

const ReportsCategories = props => {
  //meta title
  document.title = "Report-Category"
  const dispatch = useDispatch()
  const [branchId, setBranchId] = useState(
    localStorage.getItem("branch")
      ? parseInt(localStorage.getItem("branch"))
      : undefined
  )

  const [loading2, setLoading2] = useState(false)
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"))
  const [data, setData] = useState([])
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [_body, setBody] = useState({
    id: null,
    branchId: props.branchId,
    sku: "",
    product_definition: "",
    name: "",
    serial_number: "",
    description: "",
    vat: 18,
    barcode: "",
    agreementNumber: "",
    date: props.date,
    k_cat: null,
    k_cat_sub: null,
    k_cat_sub_alt: null,
    person: null,
    Price: {
      cost: null,
      cash: null,
      agreementPrice: null,
      card: null,
      bank: null,
      installment: null,
      installmentRate: null,
      bankNet: null,
      card2: null,
      bank2: null,
      installment2: null,
      installmentRate2: null,
      bankNet2: null,
      card3: null,
      bank3: null,
      installment3: null,
      installmentRate3: null,
      bankNet3: null,
      net: null,
      gain: null,
      wallet: null,
      price: null,
    },
    Customer: {
      name: "",
      phone: "",
    },
  })
  const resetBody = () => {
    setBody({
      id: null,
      branchId: props.branchId,
      sku: "",
      product_definition: "",
      name: "",
      serial_number: "",
      description: "",
      vat: 18,
      barcode: "",
      agreementNumber: "",
      date: props.date,
      k_cat: null,
      k_cat_sub: null,
      k_cat_sub_alt: null,
      person: null,
      Price: {
        cost: null,
        cash: null,
        agreementPrice: null,
        card: null,
        bank: null,
        installment: null,
        installmentRate: null,
        bankNet: null,
        card2: null,
        bank2: null,
        installment2: null,
        installmentRate2: null,
        bankNet2: null,
        card3: null,
        bank3: null,
        installment3: null,
        installmentRate3: null,
        bankNet3: null,
        net: null,
        gain: null,
        wallet: null,
        price: null,
      },
      Customer: {
        name: "",
        phone: "",
      },
    })
  }

  const changeInputMultiple = objs => {
    let b = { ..._body }
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }

  const {
    error,
    branches,
    reports,
    persons,
    loading,
    k_cat,
    k_cat_sub,
    k_cat_sub_alt,
  } = useSelector(state => ({
    error: state.Dashboard.error,
    branches: state.Companies.branches,
    reports: state.Dashboard.reports,
    persons: state.Companies.persons,
    loading: state.Dashboard.loading,
    k_cat: state.Companies.k_cat,
    k_cat_sub: state.Companies.k_cat_sub,
    k_cat_sub_alt: state.Companies.k_cat_sub_alt,
  }))

  useEffect(() => {
    dispatch(onCompaniesResetAll())
    dispatch(onResetData())
  }, [history])

  useEffect(() => {
    if (isEmpty(branches)) {
      dispatch(onBranches())
    } else if (!isEmpty(branches) && branchId == undefined) {
      setBranchId(branches[0].id)
      localStorage.setItem("branch", branches[0].id)
    } else if (!isEmpty(branchId)) {
      localStorage.setItem("branch", branchId)
    }

    if (isEmpty(persons)) {
      dispatch(onPersons())
    }
    if (isEmpty(k_cat)) {
      dispatch(onK_CAT())
    }
  }, [branches, branchId, error])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [branchId, error])

  const handle = async () => {
    setLoading2(true)
    let startDate = moment(dateRange[0]).format("DD.MM.YYYY")
    let endDate = moment(dateRange[1]).format("DD.MM.YYYY")

    let body = {
      startDate: startDate,
      endDate: endDate,
      branchId: branchId,
      k_cat: _body.k_cat,
      k_cat_sub: _body.k_cat_sub,
      k_cat_sub_alt: _body.k_cat_sub_alt,
      product_definition: _body.product_definition,
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BASE_API + "/api/reports/v1/categories",
      headers: {
        Authorization: authHeader().Authorization,
      },
      data: body,
    }

    await axios
      .request(config)
      .then(response => {
        setLoading2(false)
        console.log(JSON.stringify(response.data))
        if (response.data.status == true) {
          setData(response.data.data)
        } else {
          setData([])
        }
      })
      .catch(error => {
        setLoading2(false)
        console.log(error)
        setData([])
      })
  }

  const addDay = count => {
    let isVal = moment(date, "DD-MM-YYYY").add(count, "days").toDate()
    setDate(moment(isVal).format("DD-MM-YYYY"))
    //handle(moment(isVal).format("DD-MM-YYYY"), branchId)
  }

 

  const exportExcel = () => {
    const config = {
      filename: data.product_definition + " Raporu",
      sheet: {
        data: [],
      },
    }
    const dataSet = config.sheet.data
    dataSet.push([{ value: data.product_definition, type: "string" }])
    dataSet.push([
      { value: "Tarih", type: "string" },
      { value: "Ciro", type: "string" },
      { value: "Kar", type: "string" },
      { value: "Adet", type: "string" },
      // { value: "Web Kontör Nakit Adet", type: "string" },
      // { value: "Web Kontör Nakit Ciro", type: "string" },
      // { value: "Web Kontör Nakit Kar", type: "string" },
      // { value: "Web Kontör K.Kartı Adet", type: "string" },
      // { value: "Web Kontör K.Kartı Ciro", type: "string" },
      // { value: "Web Kontör K.Kartı Kar", type: "string" },
    ])

    if (!isEmpty(data.list)) {
      for (let item of data.list) {
        dataSet.push([
          { value: item.date, type: "string" },
          { value: item.gelir.toplamGelir, type: "number" },
          { value: item.gelir.gain, type: "number" },
          { value: item.gelir.count, type: "number" },
          // { value: item.daily?.WebCredit?.Cash?.count || 0, type: "number" },
          // { value: item.daily?.WebCredit?.Cash?.amount || 0, type: "number" },
          // { value: item.daily?.WebCredit?.Cash?.gain || 0, type: "number" },
          // { value: item.daily?.WebCredit?.Card?.count || 0, type: "number" },
          // { value: item.daily?.WebCredit?.Card?.amount || 0, type: "number" },
          // { value: item.daily?.WebCredit?.Card?.gain || 0, type: "number" },
        ])
      }
    }

    zipcelx(config)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Category Reports")}
          />
          <Loading2 status={loading2} />
          <Row className="mb-3">
            <Col md={2} xs={12} sm={6}>
              {branches && branches?.length > 1 && branchId != undefined ? (
                <select
                  className="form-select me-xxl-2 my-3 my-xxl-0 d-inline-block"
                  value={branchId}
                  onChange={e => {
                    setBranchId(e.target.value)
                  }}
                >
                  {branches.map((val, idx) => (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </Col>
            <Col md={2}>
              <div className="aok-datepicker d-flex">
                <div className="search-box aok-datepicker me-xxl-2 my-3 my-xxl-0 d-inline-block border-radius-2">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Arama İşlemi
                      </span>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={update => {
                          setDateRange(update)
                        }}
                        className="form-control custom-picker"
                        isClearable={true}
                        placeholderText={`GG.AA.YYYY - GG.AA.YYYY`}
                        dateFormat="dd.MM.yyyy"
                        locale={"tr"}
                      />
                      {/* <DatePicker
                        onChange={value => {
                          setDate(moment(value).format("DD-MM-YYYY"))
                          handle(moment(value).format("DD-MM-YYYY"), branchId)
                        }}
                        id="search-bar-1"
                        className="form-control custom-picker"
                        placeholderText={`GG.AA.YYYY - GG.AA.YYYY`}
                        selected={moment(date, "DD-MM-YYYY").toDate()}
                        dateFormat="dd-MM-yyyy"
                        style={{ borderRadius: "2px", paddingLeft: "10px" }}
                      /> */}
                    </label>
                    <i className="bx bx-calendar-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>
                {props.t("Sell Type")}
                <span className="text-danger ms-2">*Zorunlu</span>
              </Label>
              <select
                className="form-control"
                value={_body.k_cat || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  let _k_cat = k_cat.find(x => x._id == v)?.name

                  changeInputMultiple([
                    { key: "k_cat", value: v },
                    { key: "k_cat_sub", value: null },
                    { key: "k_cat_sub_alt", value: null },
                    {
                      key: "product_definition",
                      value: `${_k_cat}`,
                    },
                    {
                      key: "agreementNumber",
                      value: "",
                    },
                    {
                      key: "Price.agreementPrice",
                      value: null,
                    },
                  ])
                }}
              >
                <option>{props.t("Select")}</option>
                {k_cat
                  ? k_cat?.map((item, idx) => (
                      <option key={idx} value={item._id?.toString()}>
                        {item.name}
                      </option>
                    ))
                  : null}
              </select>
            </Col>
            <Col md={3}>
              <Label>{props.t("Category")}</Label>
              <select
                className="form-control"
                value={_body.k_cat_sub || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  let _k_cat = k_cat?.find(x => x._id == _body.k_cat).name
                  let _k_cat_sub = k_cat_sub?.find(x => x._id == v)?.name

                  changeInputMultiple([
                    { key: "k_cat_sub", value: v },
                    { key: "k_cat_sub_alt", value: null },
                    {
                      key: "product_definition",
                      value: `${_k_cat} > ${_k_cat_sub}`,
                    },
                  ])
                }}
              >
                <option>{props.t("Select")}</option>
                {k_cat_sub && _body.k_cat
                  ? k_cat_sub
                      ?.filter(x => x.k_cat == _body.k_cat?.toString())
                      ?.map((item, idx) => (
                        <option key={idx} value={item._id?.toString()}>
                          {item.name}
                        </option>
                      ))
                  : null}
              </select>
            </Col>
            <Col md={3}>
              <Label>{props.t("SubCategory")}</Label>
              <select
                className="form-control"
                value={_body.k_cat_sub_alt || ""}
                onChange={e => {
                  let v = e.target.value || undefined
                  v = v == "Seç" ? null : v
                  let _k_cat = k_cat?.find(x => x._id == _body.k_cat).name
                  let _k_cat_sub = k_cat_sub?.find(
                    x => x._id == _body.k_cat_sub
                  ).name
                  let _k_cat_sub_alt = k_cat_sub_alt?.find(x => x._id == v)

                  let temp = [
                    {
                      key: "k_cat_sub_alt",
                      value: v,
                    },
                    {
                      key: "product_definition",
                      value: _k_cat_sub_alt
                        ? `${_k_cat} > ${_k_cat_sub} > ${_k_cat_sub_alt.name}`
                        : `${_k_cat} > ${_k_cat_sub}`,
                    },
                  ]

                  if (
                    (_body.barcode == "8697414671067" ||
                      _body.barcode == "8697414671074" ||
                      _body.barcode == "8697414671340" ||
                      _body.barcode == "8697414670978" ||
                      _body.sku == "100000000000064000TDM") &&
                    k_cat_sub_alt?.cost > 0
                  ) {
                    temp.push({
                      key: "Price.cost",
                      value: k_cat_sub_alt.cost,
                    })
                  } else if (
                    _body.barcode == "8697414671067" ||
                    _body.barcode == "8697414671074" ||
                    _body.barcode == "8697414671340" ||
                    _body.barcode == "8697414670978" ||
                    _body.sku == "100000000000064000TDM"
                  ) {
                    temp.push({
                      key: "Price.cost",
                      value: "",
                    })
                  }

                  changeInputMultiple(temp)
                }}
              >
                <option>{props.t("Select")}</option>
                {k_cat_sub && _body.k_cat && _body.k_cat_sub
                  ? k_cat_sub_alt
                      ?.filter(x => x.k_cat_sub == _body.k_cat_sub?.toString())
                      ?.map((item, idx) => (
                        <option key={idx} value={item._id?.toString()}>
                          {item.name}
                        </option>
                      ))
                  : null}
              </select>
            </Col>
            <Col md={3}>
              <Label></Label>
              <div style={{ marginTop: "6px" }}>
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    console.log(branchId)
                    console.log(dateRange)
                    console.log(_body)
                    if (isEmpty(branchId?.toString())) {
                      Swal.fire(
                        props.t("Error"),
                        "Şube Seçimi Zorunlu",
                        "error"
                      )
                    } else if (
                      isEmpty(dateRange) ||
                      dateRange.length != 2 ||
                      dateRange[0] == null ||
                      dateRange[1] == null
                    ) {
                      Swal.fire(
                        props.t("Error"),
                        "Tarih Başlangıç ve Bitişini Seçmek Zorunlu",
                        "error"
                      )
                    } else if (isEmpty(_body.k_cat)) {
                      Swal.fire(
                        props.t("Error"),
                        "Satış Tipi Seçmek Zorunlu",
                        "error"
                      )
                    } else {
                      console.log(branchId)
                      console.log(dateRange)
                      console.log(_body)
                      await handle()
                    }
                  }}
                >
                  {props.t("Get Reports")}
                </Button>
              </div>
            </Col>
          </Row>
          {!isEmpty(data?.list) ? (
            <Card className="mt-2 mb-2">
              <CardBody>
                <div className="alert alert-info">
                  {data.product_definition}{" "}
                  <Button
                    className="ms-4"
                    type="button"
                    color="success"
                    onClick={() => {
                      exportExcel()
                    }}
                  >
                    <i className="mdi mdi-microsoft-excel"></i>{" "}
                    {props.t("Export")}
                  </Button>
                </div>
                <div className="table-responsive">
                  <table className="react-table table">
                    <thead>
                      <tr>
                        <td>Tarih</td>
                        {/* <td>Aktivasyon</td>
                        <td>Perakende Adet</td>
                        <td>Perakende Ciro</td>
                        <td>Perakende Kar</td> 
                        <td>Temlik</td>  */}
                        <td className="align-right">Ciro</td>
                        <td className="align-right">Kar</td>
                        <td className="align-right">Adet</td>
                        {/* <td>Web Kontör Nakit Adet</td>
                        <td>Web Kontör Nakit Ciro</td>
                        <td>Web Kontör Nakit Kar</td>
                        <td>Web Kontör K.Kartı Adet</td>
                        <td>Web Kontör K.Kartı Ciro</td>
                        <td>Web Kontör K.Kartı Kar</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.list?.map((item, idx) => (
                        <tr key={idx}>
                          <td>{item.date}</td>
                          {/* <td>{item.aktivasyon}</td>
                          <td>{item.perakende_count}</td>
                          <td>{currencyFormat(item.perakende)}</td>
                          <td>{currencyFormat(item.perakende_gain)}</td> 
                          <td>{currencyFormat(item.temlik)}</td>  */}
                          <td className="align-right">{currencyFormat(item.gelir.toplamGelir)}</td>
                          <td className="align-right">{currencyFormat(item.gelir.gain)}</td>
                          <td className="align-right">{item.gelir.count}</td>
                          {/* <td>{item.daily?.WebCredit?.Cash?.count || 0}</td>
                          <td>
                            {currencyFormat(
                              item.daily?.WebCredit?.Cash?.amount || 0
                            )}
                          </td>
                          <td>
                            {currencyFormat(
                              item.daily?.WebCredit?.Cash?.gain || 0
                            )}
                          </td>
                          <td>{item.daily?.WebCredit?.Card?.count || 0}</td>
                          <td>
                            {currencyFormat(
                              item.daily?.WebCredit?.Card?.amount || 0
                            )}
                          </td>
                          <td>
                            {currencyFormat(
                              item.daily?.WebCredit?.Card?.gain || 0
                            )}
                          </td> */}
                        </tr>
                      ))}
                      <tr>
                        <td><b>TOPLAM:</b></td>
                        <td className="align-right">
                          <b>{currencyFormat(
                            !isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.gelir?.toplamGelir)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0
                          )}</b>
                        </td>
                        <td className="align-right">
                          <b>{currencyFormat(
                            !isEmpty(data?.list)
                              ? data?.list
                                  .map(item => item.gelir?.gain)
                                  .reduce((prev, next) => prev + (next || 0))
                              : 0
                          )}</b>
                        </td>
                        <td className="align-right">
                          <b>{!isEmpty(data?.list)
                            ? data?.list
                                .map(item => item.gelir?.count)
                                .reduce((prev, next) => prev + (next || 0))
                            : 0}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

ReportsCategories.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(ReportsCategories)
