import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { campaigns } from "./../../../common/data/campaign"
import {
  addCampaign as onSave,  
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import { isEmpty } from "lodash"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Dropzone from "react-dropzone"

const CampaignCreate = props => {
  //meta title
  document.title = props.t("Create Campaign")

  const [data, setData] = useState(null)

  const breadcrumbItems = [
    { title: props.t("Campaigns"), link: "/v1/campaigns" },
    { title: props.t("Create Campaign"), link: "#" },
  ]

  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, loading } = useSelector(state => ({
    error: state.Campaigns?.error, 
    loading: state.Campaigns?.loading || false,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      console.log(error?.error ||
        error?.message ||
        error?.error?.message ||
        JSON.stringify(error))
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])

 

  const [selectedFiles, setselectedFiles] = useState([])
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "", 
      startDate: "",
      finishDate: "",
      description: "",  
      guest:false
    },
    validationSchema: Yup.object({ 
      name: Yup.string().required(props.t("Required")), 
      startDate: Yup.string().required(props.t("Required")),
      finishDate: Yup.string().required(props.t("Required")),
    }),
    onSubmit: values => {
      console.log("values", values)
      dispatch(onSave(values, selectedFiles, props.history)) 
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Campaigns")}
            backLink={"/v1/campaigns"}
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{props.t("New Campaign")}</h4>
                  <p className="card-title-desc">
                    {props.t("New Campaign Description")}
                  </p>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6"> 
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Campaign Name")}
                          </Label>
                          <Input
                            name="name"
                            placeholder={props.t("Campaign Name")}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6" className="mb-3">
                        <Row className="relative">
                          <Dropzone
                            multiple={false}
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{props.t("Campaign Image Drop here")}</h4>
                                  <h5>{props.t("Max Size")} 2 MB</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          {!isEmpty(selectedFiles) ? (
                            <div
                              className="dropzone-previews"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mb-0 shadow-none border-dashed dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="text-end">
                                          <img alt={f.name} src={f.preview} />
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                              <div
                                className="dropzone-close"
                                onClick={() => {
                                  setselectedFiles([])
                                }}
                              >
                                <img />
                              </div>
                            </div>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                    <Row> 
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustomStartDate">
                            {props.t("Campaign Start Date")}
                          </Label>
                          <InputGroup className="relative">
                            <Flatpickr
                              className={
                                validation.touched.startDate &&
                                validation.errors.startDate
                                  ? "form-control d-block is-invalid"
                                  : "form-control d-block"
                              }
                              placeholder="gg.aa.yyyy ss:dd"
                              options={{
                                locale: Turkish,
                                enableTime: true,
                                dateFormat: "d.m.Y H:i",
                              }}
                              id="validationCustomStartDate"
                              onChange={v => {
                                validation.setFieldValue(
                                  "startDate",
                                  v.length > 0
                                    ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                    : ""
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.startDate || ""}
                            />
                            <img className="form-date-img" />
                            {validation.touched.startDate &&
                            validation.errors.startDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.startDate}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustomFinishDate">
                            {props.t("Campaign Finish Date")}
                          </Label>
                          <InputGroup className="relative">
                            <Flatpickr
                              className={
                                validation.touched.finishDate &&
                                validation.errors.finishDate
                                  ? "form-control d-block is-invalid"
                                  : "form-control d-block"
                              }
                              placeholder="gg.aa.yyyy ss:dd"
                              options={{
                                locale: Turkish,
                                enableTime: true,
                                dateFormat: "d.m.Y H:i",
                              }}
                              id="validationCustomFinishDate"
                              onChange={v => {
                                validation.setFieldValue(
                                  "finishDate",
                                  v.length > 0
                                    ? moment(v[0]).format("DD.MM.YYYY HH:mm")
                                    : ""
                                )
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.finishDate || ""}
                            />
                            <img className="form-date-img" />
                            {validation.touched.finishDate &&
                            validation.errors.finishDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.finishDate}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row> 
                      <Col md="4" className="mt-2">
                        <FormGroup className="mb-3 d-flex">
                          <Label htmlFor="validationGuest">
                            {props.t("Campaign Guest")}
                          </Label>
                          <Input
                            name="guest"
                            placeholder={props.t("Campaign Guest")}
                            type="checkbox"
                            className="form-control ms-2"
                            id="validationGuest"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.guest || ""}
                            invalid={
                              validation.touched.guest && validation.errors.guest
                                ? true
                                : false
                            }
                          />
                          {validation.touched.guest && validation.errors.guest ? (
                            <FormFeedback type="invalid">
                              {validation.errors.guest}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col> 
                    </Row>
                    <Row className="mb-3">
                      <Col md="12">
                        <Label>{props.t("Campaign Description")}</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.description}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor)
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("description", data)
                          }}
                        />
                      </Col>
                    </Row> 
                    <Button color="primary" type="submit">
                      {props.t("Save")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CampaignCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withTranslation()(CampaignCreate)
