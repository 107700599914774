export const GET_APP_USERS = "GET_APP_USERS"
export const GET_APP_USERS_SUCCESS = "GET_APP_USERS_SUCCESS"
export const GET_APP_USERS_FAIL = "GET_APP_USERS_FAIL"

export const GET_APP_USER = "GET_APP_USER"
export const GET_APP_USER_SUCCESS = "GET_APP_USER_SUCCESS"
export const GET_APP_USER_FAIL = "GET_APP_USER_FAIL" 

export const DELETE_APP_USER = "DELETE_APP_USER"
export const DELETE_APP_USER_SUCCESS = "DELETE_APP_USER_SUCCESS"
export const DELETE_APP_USER_FAIL = "DELETE_APP_USER_FAIL"

 
export const APP_USER_LOADING = "APP_USER_LOADING"

