import PropTypes from "prop-types"
import React from "react"
import { Col } from "reactstrap"
const Loading2 = ({ status, zIndex = 99 }) => {
  return status ? (
    <div
      style={{
        position: "absolute",
        zIndex: zIndex,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#b5b5b529",
        paddingTop: "250px",
      }}
    >
      <Col
        md={12}
        className="d-flex justify-content-center align-item-center m-2 p-2"
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Col>
    </div>
  ) : null
}
Loading2.propTypes = {
  status: PropTypes.bool,
}

export default Loading2
