import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Redux States
import {
  getAppUsersSuccess,
  getAppUsersFail,
  getAppUserSuccess,
  getAppUserFail,
  appUserLoading,
  deleteAppUserFail,
  deleteAppUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAppUsers,
  deleteAppUser,
  getAppUser,
} from "../../helpers/fakebackend_helper"
import {
  GET_APP_USERS,
  GET_APP_USERS_FAIL,
  GET_APP_USERS_SUCCESS,
  GET_APP_USER_SUCCESS,
  GET_APP_USER_FAIL,
  GET_APP_USER,
  DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_FAIL,
  DELETE_APP_USER,
} from "./actionTypes"

//APP_USERS
function* getAppUsersFetch({ payload: { parameters } }) {
  try {
    yield put(appUserLoading(true))
    var response = yield call(getAppUsers, parameters)
    if (response.status) {
      yield put(
        getAppUsersSuccess(GET_APP_USERS_SUCCESS, response.data, response)
      )
    } else {
      yield put(
        getAppUsersFail(
          GET_APP_USERS_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-APP_USERS-1001"
        )
      )
    }
    yield put(appUserLoading(false))
  } catch (error) {
    yield put(getAppUsersFail(GET_APP_USERS_FAIL, error))
    yield put(appUserLoading(false))
  }
}

function* deleteAppUserFetch({ payload: { id, history } }) {
  try {
    yield put(appUserLoading(true))
    var response = yield call(deleteAppUser, id)
    if (response.status) {
      yield put(deleteAppUserSuccess(DELETE_APP_USER_SUCCESS, response.data))
      history.push("/v1/app-users")
    } else {
      yield put(
        deleteAppUserFail(
          DELETE_APP_USER_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-APP_USERS-1001"
        )
      )
    }
    yield put(appUserLoading(false))
  } catch (error) {
    console.log(error)
    yield put(deleteAppUserFail(DELETE_APP_USER_FAIL, error))
    yield put(appUserLoading(false))
  }
}

function* getAppUserByIdFetch({ payload: { id } }) {
  try {
    yield put(appUserLoading(true))
    var response = yield call(getAppUser, id)
    if (response.status) {
      yield put(getAppUserSuccess(GET_APP_USER_SUCCESS, response.data))
    } else {
      yield put(
        getAppUserFail(
          GET_APP_USER_FAIL,
          response.message || "Beklenmedik HATA - CODE:SAGA-APP_USERS-1001"
        )
      )
    }
    yield put(appUserLoading(false))
  } catch (error) {
    yield put(getAppUserFail(GET_APP_USER_FAIL, error))
    yield put(appUserLoading(false))
  }
}

function* appUsersSaga() {
  yield takeEvery(GET_APP_USERS, getAppUsersFetch)
  yield takeEvery(GET_APP_USER, getAppUserByIdFetch)
  yield takeEvery(DELETE_APP_USER, deleteAppUserFetch)
}

export default appUsersSaga
