import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import InputMask from "react-input-mask"
import { isEmpty } from "lodash"
import {
  kasaBuyAdd as onBuyAdd,
  kasaBuyUpdate as onBuyUpdate,
} from "store/actions"
import { useDispatch } from "react-redux"

const CustomModal = props => {
  const dispatch = useDispatch()

  const [_body, setBody] = useState({
    id: null,
    branchId: props.branchId,
    buy_type: null,
    buy_type_sub: null,  
    description: "",
    amount: null, 
    cardAmount:null,
    transferAmount: null,
    date: props.date,
  })

  const resetBody = () => {
    setBody({
      id: null,
      branchId: props.branchId,
      buy_type: null,
      buy_type_sub: null,  
      description: "",
      amount: null, 
      cardAmount:null,
      transferAmount: null,
      date: props.date,
    })
  }

  const changeInputMultiple = objs => {
    let b = { ..._body }
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[obj.key] = obj.value
      }
    }
    setBody(b)
  }
  const changeInput = (key, value) => {
    let b = { ..._body }
    b[key] = value
    setBody(b)
  }
  const changeInput2 = (key, key2, value) => {
    let b = { ..._body }
    b[key][key2] = value
    setBody(b)
  }

  useEffect(() => {
    let __body = { ..._body }
    __body.date = props.date
    __body.branchId = props.branchId
    // console.log("__BODY", __body)
    setBody(__body)
  }, [props.date, props.branchId])

  useEffect(() => { 
    console.log("props.body", props.body) 
    if(!isEmpty(props.body)){ 
      setBody(props.body)
    }
  }, [props.body])

  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="xl">
      <ModalHeader toggle={props.toggle} tag="h4">
        {props.isEdit ? props.t("Buy Edit") : props.t("Buy Add")} -{" "}
        <small>
          {props.branchName} - {_body.date}
        </small>
      </ModalHeader>
      <ModalBody> 
          
        <Row className="mt-4">
          <Col md={12}>
            <h4>{props.t("Product Infos")}</h4>
            {_body.product_definition}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>
              {props.t("Buy Type")}
              <span className="text-danger ms-2">*Zorunlu</span>
            </Label>
            <select
              className="form-control"
              value={_body.buy_type || ""}
              onChange={e => { 
                changeInputMultiple([
                  { key: "buy_type", value: e.target.value || undefined },
                  { key: "buy_type_sub", value: null },
                ])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.buy_types
                ? props.buy_types?.map((item, idx) => (
                    <option key={idx} value={item.slug?.toString()}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </Col>
          <Col md={4}>
            <Label>{props.t("Category")}</Label>
            <select
              className="form-control"
              value={_body.buy_type_sub || ""}
              onChange={e => { 
                changeInputMultiple([
                  { key: "buy_type_sub", value: e.target.value || undefined }, 
                ])
              }}
            >
              <option>{props.t("Select")}</option>
              {props.buy_type_sub && _body.buy_type
                ? props.buy_type_sub
                    ?.filter(x => x.buy_type == _body.buy_type?.toString())
                    ?.map((item, idx) => (
                      <option key={idx} value={item.slug?.toString()}>
                        {item.name}
                      </option>
                    ))
                : null}
            </select>
          </Col> 
        </Row>  
        <Row className="mt-2">
          <Col md={4}>
            <Label>{props.t("Cash")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("Cash")}
              value={_body.amount || ""}
              onChange={e => {
                changeInput("amount", e.target.value || undefined)
              }}
            />
          </Col> 
          <Col md={4}>
            <Label>{props.t("Card")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("Card")}
              value={_body.cardAmount || ""}
              onChange={e => {
                changeInput("cardAmount", e.target.value || undefined)
              }}
            />
          </Col>
          <Col md={4}>
            <Label>{props.t("Transfer Buy")}</Label>
            <Input
              type="number"
              step={0.01}
              placeholder={props.t("Transfer Buy")}
              value={_body.transferAmount || ""}
              onChange={e => {
                changeInput("transferAmount", e.target.value || undefined)
              }}
            />
          </Col>
        </Row> 
        <Row className="mt-2">
          <Col md={12}>
            <Label>{props.t("Description")}</Label>
            <Input
              type="textarea"
              placeholder={props.t("Description")}
              value={_body.description || ""}
              onChange={e => {
                changeInput("description", e.target.value || undefined)
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-success"
          disabled={
            !isEmpty(_body.buy_type) &&
            (!isEmpty(_body.amount) || !isEmpty(_body.cardAmount) || !isEmpty(_body.transferAmount))
              ? false
              : true 
          }
          onClick={() => {
            if (props.isEdit) {
              dispatch(onBuyUpdate(props.id, _body))
            } else {
              dispatch(onBuyAdd(_body))
            }
            resetBody()
            props.toggle()
          }}
        >
          {props.t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

CustomModal.propTypes = {
  body: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  date: PropTypes.string,
  branchId: PropTypes.number,
  branchName: PropTypes.string,
  buy_types: PropTypes.array,
  buy_type_sub: PropTypes.array,
  id: PropTypes.string,  
}

export default withRouter(withTranslation()(CustomModal))
