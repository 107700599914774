import axios from "axios";
import { del, get, post, postX, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

//DASHBOARD

export const getReports = (date, branchId) => get(`${url.BASE_API}/reports/v1/get/${branchId}/${date}`);


//SELL
export const getKasaSell = (date, branchId) => get(`${url.KASA_SELL}/get/${branchId}/${date}?platform_id=${process.env.REACT_APP_MIKROID}`);
export const addKasaSell = (body) => post(`${url.KASA_SELL}/add`, body);
export const updateKasaSell = (id, body) => put(`${url.KASA_SELL}/update/${id}`, body);
export const deleteKasaSell = (id) => del(`${url.KASA_SELL}/delete/${id}`);


//SELL2
export const getKasa2Sell = (date, branchId) => get(`${url.KASA2_SELL}/get/${branchId}/${date}?platform_id=${process.env.REACT_APP_MIKROID}`);
export const addKasa2Sell = (body) => post(`${url.KASA2_SELL}/add`, body);
export const updateKasa2Sell = (id, body) => put(`${url.KASA2_SELL}/update/${id}`, body);
export const deleteKasa2Sell = (id) => del(`${url.KASA2_SELL}/delete/${id}`);

export const getKasaSearchSell = (date, search) => get(`${url.BASE_API}/kasa/get-products/${date}?platform_id=${process.env.REACT_APP_MIKROID}&search=${search}`);
export const getKasaSearch = (body) => post(`${url.BASE_API}/kasa/search`, body);

//QRCODE
//TYPE: user - coupon - user
export const postQRCodeCheck = (body, type) => post(`${url.BASE_API}/qrcode/control?qrType=${type}`, body);


export const getDailyCash = (date, branchId) => get(`${url.BASE_API}/kasa/daily-cash/get/${branchId}/${date}`);
export const setDailyCash = (date, branchId, body) => put(`${url.BASE_API}/kasa/daily-cash/update/${branchId}/${date}`, body);

//BUY
export const getKasaBuy = (date, branchId) => get(`${url.KASA_BUY}/get/${branchId}/${date}`);
export const addKasaBuy = (body) => post(`${url.KASA_BUY}/add`, body);
export const updateKasaBuy = (id, body) => put(`${url.KASA_BUY}/update/${id}`, body);
export const deleteKasaBuy = (id) => del(`${url.KASA_BUY}/delete/${id}`);

//GLOBAL SETTINGS
export const getBranches = () => get(`${url.BASE_API}/companies/branches`);
export const getBanks = () => get(`${url.BASE_API}/companies/banks`);
export const getPersons = () => get(`${url.BASE_API}/companies/persons`);
export const setPersons = (body) => post(`${url.BASE_API}/companies/persons`, body);
export const getGainSettings = () => get(`${url.BASE_API}/companies/gain-settings`);
export const setGainSettings = (body) => post(`${url.BASE_API}/companies/gain-settings`, body);
export const get_K_CAT_ALL = () => get(`${url.BASE_API}/k_cat/list/all`);
export const getBuyTypes = () => get(`${url.BASE_API}/companies/buy_types`);


export const getCities = () => get(`${url.BASE_API}/locations/cities`)
export const getDistricts = id =>
  get(`${url.BASE_API}/locations/districts/${id}`)

  //BRANDS
export const getBrands = () => get(`${url.BASE_API}/brands`)
export const getBrand = id => get(`${url.BASE_API}/brands/${id}`)
export const addBrand = data => post(`${url.BASE_API}/brands/add`, data)
export const updateBrand = (id, data) =>
  put(`${url.BASE_API}/brands/update/${id}`, data)
export const deleteBrand = id => del(`${url.BASE_API}/brands/del/${id}`)

//CAMPAIGNS
export const getCampaigns = () => get(`${url.BASE_API}/campaigns`)
export const getCampaign = id => get(`${url.BASE_API}/campaigns/${id}`)
export const addCampaign = (data, file) =>
  postX(`${url.BASE_API}/campaigns/add`, data, file)
export const updateCampaign = (id, data, file) =>
  postX(`${url.BASE_API}/campaigns/update/${id}`, data, file)
export const deleteCampaign = id => del(`${url.BASE_API}/campaigns/del/${id}`) 


export const postCampaignProductType_Step4 = (id, data) => post(`${url.BASE_API}/campaigns/post-product-type/${id}`, data)
export const postCampaignStatus = (id, data) => post(`${url.BASE_API}/campaigns/status-change/${id}`, data)

export const postCampaignNewData = (id, data, dataId) => post(`${url.BASE_API}/campaigns/new-data/${id}${dataId!=null?`?id=${dataId}`:''}`, data)
export const getCampaignNewData = (id, campaignId) => get(`${url.BASE_API}/campaigns/new-data/${id}/${campaignId}`)


export const getBranchGroupByCampaign = id => get(`${url.BASE_API}/branch-groups/status/group/${id}`)
export const updateBranchGroupByCampaign = (id, data) => put(`${url.BASE_API}/branch-groups/status/group/${id}`, data)
 


export const getTermsCampaign = (id, level) => get(`${url.BASE_API}/campaigns/terms/${id}`) 
export const getTermCampaign = (id, level) => get(`${url.BASE_API}/campaigns/terms/${id}/${level}`) 
export const addTermCampaign = (id, data) => post(`${url.BASE_API}/campaigns/terms/${id}/new`, data) 
export const updateTermCampaign = (id, level, data) => post(`${url.BASE_API}/campaigns/terms/${id}/${level}`, data) 
export const deleteTermCampaign = (id, level) => del(`${url.BASE_API}/campaigns/terms/${id}/${level}`) 

//COUPONS
export const getCoupons = () => get(`${url.BASE_API}/coupons`)
export const getCoupon = id => get(`${url.BASE_API}/coupons/${id}`)
export const addCoupon = (data, file) =>
  postX(`${url.BASE_API}/coupons/add`, data, file)
export const updateCoupon = (id, data, file) =>
  postX(`${url.BASE_API}/coupons/update/${id}`, data, file)
export const deleteCoupon = id => del(`${url.BASE_API}/coupons/del/${id}`) 


export const postCouponProductType_Step4 = (id, data) => post(`${url.BASE_API}/coupons/post-product-type/${id}`, data)
export const postCouponStatus = (id, data) => post(`${url.BASE_API}/coupons/status-change/${id}`, data)

export const postCouponNewData = (id, data, dataId) => post(`${url.BASE_API}/coupons/new-data/${id}${dataId!=null?`?id=${dataId}`:''}`, data)
export const getCouponNewData = (id, couponId) => get(`${url.BASE_API}/coupons/new-data/${id}/${couponId}`)


export const getBranchGroupByCoupon = id => get(`${url.BASE_API}/branch-groups/status/group/${id}`)
export const updateBranchGroupByCoupon = (id, data) => put(`${url.BASE_API}/branch-groups/status/group/${id}`, data)
 


export const getTermsCoupon = (id, level) => get(`${url.BASE_API}/coupons/terms/${id}`) 
export const getTermCoupon = (id, level) => get(`${url.BASE_API}/coupons/terms/${id}/${level}`) 
export const addTermCoupon = (id, data) => post(`${url.BASE_API}/coupons/terms/${id}/new`, data) 
export const updateTermCoupon = (id, level, data) => post(`${url.BASE_API}/coupons/terms/${id}/${level}`, data) 
export const deleteTermCoupon = (id, level) => del(`${url.BASE_API}/coupons/terms/${id}/${level}`) 



// get Products
export const getProductsAll = () => get(`${url.BASE_API}/products/list-all`)
//export const getProducts = data => post(`${url.BASE_API}/products/list`, data)
export const getProduct = id => get(`${url.BASE_API}/products/${id}`)
export const addProduct = data => post(`${url.BASE_API}/products/add`, data)
export const updateProduct = (id, data) =>
  put(`${url.BASE_API}/products/update/${id}`, data)
export const deleteProduct = id => del(`${url.BASE_API}/products/del/${id}`)

export const importProductXLSX = (file) => postXFileOnly(`${url.BASE_API}/products/xlsx-product-import`, file)

export const getVariants = (pid, data) => post(`${url.BASE_API}/products/variants/list/${pid}`, data)
export const getVariants_v2 = (pid, data) => post(`${url.BASE_API}/products/variants/v2/list/${pid}`, data)
export const getVariant = (id) => get(`${url.BASE_API}/products/variants/${id}`)
export const addVariant = data => post(`${url.BASE_API}/products/variants/add`, data)
export const updateVariant = (id, data) =>
  put(`${url.BASE_API}/products/variants/update/${id}`, data)
export const deleteVariant = id => del(`${url.BASE_API}/products/variants/del/${id}`)

export const getSeriesList = (pid, vid, data) => post(`${url.BASE_API}/products/variants/series/list/${pid}/${vid}`, data)
export const getSeries = (pid, vid, id) => get(`${url.BASE_API}/products/variants/series/${pid}/${vid}/${id}`)
export const addSeries = data => post(`${url.BASE_API}/products/variants/series/add`, data)
export const updateSeries = (id, data) =>
  put(`${url.BASE_API}/products/variants/series/update/${id}`, data)
export const deleteSeries = (pid, vid, id) => del(`${url.BASE_API}/products/variants/series/del/${id}?pid=${pid}&vid=${vid}`)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });



//APP USERS
export const getAppUsers = (parameters) => post(`${url.BASE_API}/app-users/list`, parameters)
export const getAppUser = id => get(`${url.BASE_API}/app-users/${id}`) 
export const deleteAppUser = id => del(`${url.BASE_API}/app-users/del/${id}`) 

// get Events
export const getEvents = () => get(url.GET_EVENTS);

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES);

// get chats
export const getChats = () => get(url.GET_CHATS);

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);

// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });

// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS);

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail);

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS);

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS);

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS);

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS);

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};
