import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getAppUsers as onList, deleteAppUser as onDelete } from "store/actions"
import { isEmpty } from "lodash"
import Swal from "sweetalert2" 
import TableContainer from "../../components/Common/TableContainer"
import moment from "moment"
import './datatables.scss'
//redux

const AppUserList = props => {
  const dispatch = useDispatch()
  const { app_users, loading } = useSelector(state => ({
    app_users: state.AppUsers.data,
    loading: state.AppUsers.loading,
  }))

  const [listParameters, setListParameters] = useState({
    skip:0,
    page:0,
    limit: 2000,
    select: 'confirmed'
  })

  //meta title
  document.title = props.t("App Users")
  useEffect(() => {
    if (isEmpty(app_users)) {
      dispatch(onList(listParameters))
    }
  }, [])

  const gender = context => {
    switch (context) {
      case "men":
        return "Erkek"
      case "women":
        return "Kadın"
      case "other":
        return "Belirtilmemiş"
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "isCode",
      },
      {
        Header: props.t("Name"),
        accessor: "name",
      },
      {
        Header: props.t("LastName"),
        accessor: "lastName",
      },
      {
        Header: props.t("Gender"),
        accessor: "gender",
        Cell: ({ value }) => `${gender(value)}`,
      },
      {
        Header: props.t("Birth of Day"),
        accessor: "birthOfDay",
        Cell: ({ value }) => `${value?moment(value).format("DD.MM.YYYY"):''}`,
      },
      {
        Header: props.t("phone"),
        accessor: "phone",
      }, 
      {
        Header: props.t("City"),
        accessor: "city",
      },
      {
        Header: props.t("Email"),
        accessor: "email",
        Cell: props => props.row.original.confirmed? <span className="text-success" title="Onaylı Mail">{props.value}</span>:<span className="text-danger" title="Onaylanmamış Mail">{props.value}</span>,
      },
      {
        Header: props.t("Action"),
        accessor: "_id",
        search: false,
        Cell: ({ value }) => (
          <div>
            {/* {(isToday && Role("edit")) || Role("past-edit") ? (
              <Button
                className="btn-sm btn-light"
                onClick={() => {
                  let d = [...data]
                  let p = d.filter(x => x._id == value) 
                  if (p.length == 1) {
                    console.log(p[0])
                    setSelectedId(value)
                    setIsEdit(true)
                    setEditBody(p[0])
                    setModal(true)
                  }
                }}
              >
                <i className="bx bx-edit-alt"></i>
              </Button>
            ) : null} */}
            <Button
              className="btn-sm btn-light ms-2"
              onClick={() => {
                Swal.fire({
                  title: "Silmek İstediğinize Emin Misiniz?",
                  text: "Bu İşlem Geri Alınamaz",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Evet, Sil",
                  cancelButtonText: "İptal",
                }).then(result => {
                  if (result.isConfirmed) {
                    dispatch(onDelete(value, props.history))
                  }
                })
              }}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      },
    ],
    [app_users]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("App")}
            breadcrumbItem={props.t("App Users")}
          /> 
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <TableContainer
                    loading={loading}
                    columns={columns}
                    data={app_users}
                    isGlobalFilter={true}
                    isDateFilter={false}
                    isAddOptions={false}
                    customPageSize={2000}
                    exportXLSX={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AppUserList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AppUserList)
