import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/BreadcrumbBack"

//i18n
import { withTranslation } from "react-i18next"

//redux
//import { coupons } from "./../../../common/data/coupon"
import {
  getCoupon as onGet,
  postCouponStatus as onStatus,
  couponSuccess as onSuccess,
  deleteCoupon as onDelete,
  deleteTermCoupon as onDeleteTerm,
  companiesResetError as onClearError,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"

//Custom CSS
import "./custom.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import img_forward from "./../../../assets/images/custom/forward.png"
import { isEmpty } from "lodash"
import img_empty from "./../../../assets/images/jobs.png"

import * as DOMPurify from "dompurify"
import Loading from "components/Common/Loading"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { Role, authorize } from "helpers/authorize"

const CouponDetails = props => {
  //meta title
  document.title = props.t("Coupon Details")

  const IMAGE_BASE = process.env.REACT_APP_IMAGE_BASE
  const [data, setData] = useState({})
  const [imageZoom, setimageZoom] = useState([img_empty])
  const [photoIndex, setphotoIndex] = useState(0)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)

  const breadcrumbItems = [
    { title: props.t("Coupons"), link: "/v1/coupons" },
    { title: props.t("Coupon Details"), link: "#" },
  ]
  const query = new URLSearchParams(props.location.search)
  const {
    match: { params },
  } = props

  const dispatch = useDispatch()
  const { error, coupon, loading, success } = useSelector(state => ({
    error: state.Coupons?.error,
    coupon: state.Coupons?.coupon,
    loading: state.Coupons?.loading || false,
    success: state.Coupons?.success,
  }))

  useEffect(() => {
    if (!isEmpty(error)) {
      console.error("error", error)
      Swal.fire(
        props.t("Error"),
        error?.error ||
          error?.message ||
          error?.error?.message ||
          JSON.stringify(error),
        "error"
      )
      dispatch(onClearError())
    }
  }, [error])
  useEffect(() => {
    console.log("status change", success)
    if (!isEmpty(success)) {
      Swal.fire(success.title, success.message, success.status).then(
        ({ isConfirmed }) => {
          dispatch(onSuccess(null))
        }
      )
    }
  }, [success])

  useEffect(() => {
    if (isEmpty(coupon)) {
      dispatch(onGet(params.id))
    } else {
      if (!isEmpty(coupon.image)) {
        setimageZoom([IMAGE_BASE + coupon.image])
      }
      setData(coupon)
      if (!isEmpty(query.get("search"))) {
        setTimeout(() => {
          window.find(query.get("search"))
        }, 300)
      }
    }
  }, [coupon])

  const cardStatus = status => {
    switch (status) {
      case "continue":
        return "border border-success"
      case "wait":
      case "publish":
      case "design":
        return "border border-warning"
      case "finish":
        return "border border-danger"
      default:
        return "border border-light"
    }
  }
  const cardStatusText = status => {
    switch (status) {
      case "continue":
        return (
          <span className="badge badge-soft-success">
            {props.t("Coupon_Continues")}
          </span>
        )
      case "wait":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Coupon_Wait")}
          </span>
        )
      case "publish":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Coupon_Publish")}
          </span>
        )
      case "design":
        return (
          <span className="badge badge-soft-warning">
            {props.t("Coupon_Design")}
          </span>
        )
      case "finish":
        return (
          <span className="badge badge-soft-danger">
            {props.t("Coupon_Finish")}
          </span>
        )
      default:
        return (
          <span className="badge badge-soft-light">
            {props.t("Coupon_Other")}
          </span>
        )
    }
  }

  const TermType = type =>
    type == "1"
      ? "TL Hedefi"
      : type == "2"
      ? "Adet Hedefi"
      : type == "3"
      ? "Ürün Hedefi"
      : type
  const TermTypeLong_Step1 = (type, startTL, endTL) =>
    endTL == null
      ? `Kullanıcının ${startTL} TL'den fazla alımlarında aşağıdakiler kazanılır`
      : `Kullanıcının ${startTL}-${endTL} TL arasındaki alımlarında aşağıdakiler kazanılır`

  const TermTypeLong_Step2 = (type, count, buyCount) =>
    `Kullanıcı ${count} adet alımlarında ${buyCount} adet öder`

  const PuantItem = (title, unit, description, roles = []) =>
    description != null && (authorize(roles) || roles.length == 0) ? (
      <Col md={3}>
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{title}</p>
                <h4 className="mb-0">
                  {description} {unit}
                </h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    ) : null

  const Term_Detail_Step1 = (term, idx) => (
    <>
      <CardBody>
        <div className="d-flex d-flex-row justify-content-between">
          <h4>
            Her Alışverişte Geçerli{" - "}
            <b>{TermType(term.step_2)}</b>
          </h4>{" "}
          {authorize([Role.Dev]) &&
          (data.status == "design" || data.status == "stop") ? (
            <div>
              <a
                className="text-warning me-2"
                href={`/v1/coupons/create/step2/${params.id}?l=${idx}`}
              >
                {props.t("Edit")} <i className="bx bx-pencil"></i>
              </a>
              <a
                className="text-danger"
                onClick={() => {
                  Swal.fire({
                    title: props.t("Are You Sure"),
                    text: props.t("DeleteWarning"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: props.t("Yes, delete it!"),
                    cancelButtonText: props.t("Cancel"),
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      dispatch(onDeleteTerm(params.id, idx, props.history))
                    }
                  })
                }}
              >
                {props.t("Delete")} <i className="bx bx-trash"></i>
              </a>
            </div>
          ) : null}
        </div>
        {term.terms.map((item, index) => (
          <Row key={index}>
            {parseFloat(item.gainTL || 0) > 0 ? (
              <>
                <Col md={12}>
                  {TermTypeLong_Step1(term.step_2, item.startTL, item.endTL)}
                </Col>

                {PuantItem(props.t("User"), "TL", item.gainTL, [])}
              </>
            ) : null}
          </Row>
        ))}
      </CardBody>
    </>
  )

  const all_role_control = item => {
    return (
      (authorize([Role.User]) && parseFloat(item.gainPerson || 0) > 0) ||
      (authorize([Role.Manager]) &&
        parseFloat(item.gainPerson || 0) + parseFloat(item.gainManager || 0) >
          0) ||
      (authorize([Role.Coordinator]) &&
        parseFloat(item.gainPerson || 0) +
          parseFloat(item.gainManager || 0) +
          parseFloat(item.gainCoordinator || 0) >
          0) ||
      authorize([Role.Dev])
    )
  }

  const Term_Detail_Step2 = (term, idx) => (
    <>
      <div className="d-flex d-flex-row justify-content-between">
        <h4>
          X Adet Al Y Adet Öde{" - "}
          <b>{TermType(term.step_2)}</b>
        </h4>{" "}
        {authorize([Role.Dev]) &&
        (data.status == "design" || data.status == "stop") ? (
          <div>
            <a
              className="text-warning me-2"
              href={`/v1/coupons/create/step2/${params.id}?l=${idx}`}
            >
              {props.t("Edit")} <i className="bx bx-pencil"></i>
            </a>
            <a
              className="text-danger"
              onClick={() => {
                Swal.fire({
                  title: props.t("Are You Sure"),
                  text: props.t("DeleteWarning"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: props.t("Yes, delete it!"),
                  cancelButtonText: props.t("Cancel"),
                }).then(({ isConfirmed }) => {
                  if (isConfirmed) {
                    dispatch(onDeleteTerm(params.id, idx, props.history))
                  }
                })
              }}
            >
              {props.t("Delete")} <i className="bx bx-trash"></i>
            </a>
          </div>
        ) : null}
      </div>
      <CardBody>
        {term.terms.map((item, index) => (
          <Row key={index}>
            {parseFloat(item.buyCount || 0) > 0 && all_role_control(item) ? (
              <>
                <Col md={12}>
                  {TermTypeLong_Step2(term.step_2, item.count, item.buyCount)}
                </Col>

                {PuantItem(
                  props.t("User"),
                  "",
                  `${item.count} Al ${item.buyCount} Öde`,
                  []
                )}
              </>
            ) : null}
          </Row>
        ))}
      </CardBody>
    </>
  )

  const Term_Detail_Step3 = (term, idx) => (
    <>
      <div className="d-flex d-flex-row justify-content-between">
        <h4>
          X Ürünü Alana Y Ürünü Hediye{" - "}
          <b>{TermType(term.step_2)}</b>
        </h4>{" "}
        {authorize([Role.Dev]) &&
        (data.status == "design" || data.status == "stop") ? (
          <div>
            <a
              className="text-warning me-2"
              href={`/v1/coupons/create/step2/${params.id}?l=${idx}`}
            >
              {props.t("Edit")} <i className="bx bx-pencil"></i>
            </a>
            <a
              className="text-danger"
              onClick={() => {
                Swal.fire({
                  title: props.t("Are You Sure"),
                  text: props.t("DeleteWarning"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: props.t("Yes, delete it!"),
                  cancelButtonText: props.t("Cancel"),
                }).then(({ isConfirmed }) => {
                  if (isConfirmed) {
                    dispatch(onDeleteTerm(params.id, idx, props.history))
                  }
                })
              }}
            >
              {props.t("Delete")} <i className="bx bx-trash"></i>
            </a>
          </div>
        ) : null}
      </div>
      <CardBody>
        {term.terms.map((item, index) => (
          <Row key={index}>
            {parseFloat(item.buyCount || 0) > 0 && all_role_control(item) ? (
              <>
                <Col md={12}>{TermTypeLong_Step2(term.step_2, item.count)}</Col>

                {PuantItem(
                  props.t("User"),
                  "",
                  `${item.count} Al ${item.buyCount} Öde`,
                  []
                )}
              </>
            ) : null}
          </Row>
        ))}
      </CardBody>
    </>
  )

  const statusChangeButton = () => {
    let _status = data.status
    let _title = ""
    let _desc = ""
    let _confirmButtonText = ""
    let _btn_status = ""
    let _action_data = {}
    let _status_btn = false
    let _btn_text = ""
    console.log("_status", _status)
    switch (_status) {
      case "design":
      case "stop":
        _title = props.t("Save and Publish")
        _desc = props.t("Save and Publish Description")
        _confirmButtonText = props.t("Yes, Publish!")
        _btn_text = props.t("Save and Publish")
        _btn_status = "btn-success"
        _action_data = { status: "wait" }
        _status_btn = true
        break
      case "wait":
      case "publish":
        _title = props.t("Coupon Close")
        _desc = props.t("Coupon Close Description")
        _confirmButtonText = props.t("Yes, Close Coupon!")
        _btn_text = props.t("Coupon Close")
        _btn_status = "btn-warning"
        _action_data = { status: "design" }
        _status_btn = true
        break
      case "continue":
        _title = props.t("Coupon Stop")
        _desc = props.t("Coupon Stop Description")
        _confirmButtonText = props.t("Yes, Stop Coupon!")
        _btn_text = props.t("Coupon Stop")
        _btn_status = "btn-danger"
        _action_data = { status: "stop" }
        _status_btn = true
        break
      default:
        _status_btn = false
        break
    }

    return _status_btn  ? (
      //&& !isEmpty(data.Terms)
      //&& !isEmpty(data.ProductSelection?.searchType)
      <a
        className={"btn " + _btn_status}
        onClick={() => {
          Swal.fire({
            title: _title,
            html: _desc,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: _confirmButtonText,
            cancelButtonText: props.t("Cancel"),
          }).then(({ isConfirmed }) => {
            if (isConfirmed === true) {
              dispatch(onStatus(params.id, _action_data, props.history))
            }
          })
        }}
      >
        {_btn_text}
      </a>
    ) : null
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Coupons")}
            backLink={"/v1/coupons"}
            breadcrumbItems={breadcrumbItems}
          />

          <Loading status={loading} />
          {isGalleryZoom ? (
            <Lightbox
              mainSrc={imageZoom[photoIndex]}
              nextSrc={imageZoom[(photoIndex + 1) % imageZoom.length]}
              prevSrc={
                imageZoom[
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                ]
              }
              onCloseRequest={() => {
                setisGalleryZoom(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + imageZoom.length - 1) % imageZoom.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % imageZoom.length)
              }}
            />
          ) : null}

          {!isEmpty(data) ? (
            <Row>
              <Col md={12}>
                <Card className={cardStatus(data.status)}>
                  <CardBody>
                    <Row>
                      <Col md={3} className="mb-4">
                        <Row>
                          <Col md={12}>
                            {data.image ? (
                              <img
                                src={IMAGE_BASE + data.image}
                                className="coupon-img"
                                onClick={() => {
                                  setisGalleryZoom(true)
                                  setphotoIndex(0)
                                }}
                              />
                            ) : (
                              <img src={img_empty} className="coupon-img" />
                            )}
                          </Col>
                          <Col className="text-end">
                            {cardStatusText(data.status)}
                          </Col>
                          <Col md={12} className="text-end">
                            <div className="date-div">
                              <div className="date-title">
                                <span>{props.t("Start Date")} : </span>
                              </div>
                              <div className="date">
                                {moment(data.startDate).format("DD.MM.YYYY")}
                              </div>
                            </div>
                            <div className="date-div">
                              <div className="date-title">
                                <span>{props.t("End Date")} : </span>
                              </div>
                              <div className="date">
                                {moment(data.finishDate).format("DD.MM.YYYY")}
                              </div>
                            </div>
                          </Col>
                          <Col md={12} className="mt-2">
                            <p className="title">{data.name}</p>
                            <p className="title">{data.mini_description}</p>
                          </Col>

                          {authorize([Role.Dev]) ? (
                            <>
                              <Col md={12} className="mt-2 mb-2">
                                <a
                                  href={`/v1/coupons/create/step4/${params.id}`}
                                  className="relative a-btn w-100"
                                >
                                  {props.t("Products")}{" "}
                                  <img
                                    src={img_forward}
                                    className="img-forward"
                                  />
                                </a>
                              </Col>
                              <Col md={12} className="mt-2 mb-2">
                                <a
                                  href={`/v1/coupons/new-data-list-control/${params.id}`}
                                  className="relative a-btn w-100"
                                >
                                  {props.t("Data Control")}{" "}
                                  <img
                                    src={img_forward}
                                    className="img-forward"
                                  />
                                </a>
                              </Col>
                              {/**
                               * TODO: coupon.status == "stop" Kaldirilacak
                               */}
                              {coupon.status == "design" ||
                              coupon.status == "wait" ||
                              coupon.status == "stop" ? (
                                <Col md={12} className="mt-2 mb-2">
                                  <a
                                    href={`/v1/coupons/update/${params.id}`}
                                    className="relative a-btn w-100"
                                  >
                                    {props.t("Edit")}{" "}
                                    <img
                                      src={img_forward}
                                      className="img-forward"
                                    />
                                  </a>
                                </Col>
                              ) : null}
                            </>
                          ) : null}
                          {coupon.status == "design" ||
                          coupon.status == "stop" ||
                          coupon.status == "wait" ? (
                            <Col md={12} className="mt-2 mb-2">
                              <a
                                className="relative a-btn w-100"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Silmek İstediğinize Emin Misiniz?",
                                    text: "Bu İşlem Geri Alınamaz",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Evet, Sil",
                                    cancelButtonText: "İptal",
                                  }).then(result => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        onDelete(params.id, props.history)
                                      )
                                    }
                                  })
                                }}
                              >
                                {props.t("Delete")}{" "}
                                <img
                                  src={img_forward}
                                  className="img-forward"
                                />
                              </a>
                            </Col>
                          ) : null}
                          {coupon.calculateStatus == "calculated" ? (
                            <Col md={12} className="mt-2 mb-2">
                              <a
                                href={`/v1/coupons/puank-list/${params.id}`}
                                className="relative a-btn w-100"
                              >
                                {props.t("Puank List")}
                                <img
                                  src={img_forward}
                                  className="img-forward"
                                />
                              </a>
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={12}>
                            <Card className="shadow-sm">
                              <CardBody>
                                <Col md={12} className="mt-2 mb-2">
                                  <span className="title">
                                    {props.t("Coupon Description")}
                                    {data.guest ? (
                                      <span className="badge badge-soft-success ms-2">
                                        {props.t("Open to Guest")}
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger ms-2">
                                        {props.t("Closed to Guest")}
                                      </span>
                                    )}
                                  </span>
                                </Col>
                                <Col md={12}> 
                                  <span className="description-max">
                                    {
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            data.description
                                          ),
                                        }}
                                      />
                                    }
                                  </span>
                                </Col>
                              </CardBody>
                            </Card>
                            <Card className="shadow-sm">
                              <CardBody>
                                <Col md={12} className="mt-2 mb-2">
                                  <span className="title">
                                    {props.t("Coupon Terms")}
                                  </span>
                                </Col>
                                <Col md={12}>
                                  {data?.Terms?.map((term, idx) => (
                                    <Row key={idx}>
                                      <Col md={12}>
                                        <Card>
                                          {term.step_1 == "1"
                                            ? Term_Detail_Step1(term, idx)
                                            : term.step_1 == "2"
                                            ? Term_Detail_Step2(term, idx)
                                            : null}
                                        </Card>
                                      </Col>
                                    </Row>
                                  ))}
                                  {/* {(authorize([Role.Dev]) &&
                                    data.status == "design") ||
                                  data.status == "stop" ? (
                                    <a
                                      className="btn btn-primary me-2"
                                      href={`/v1/coupons/create/step2/${params.id}?l=new`}
                                    >
                                      {props.t("New Terms")}
                                    </a>
                                  ) : null} */}

                                  {/* {authorize([Role.Dev]) &&
                                  data.status == "design" &&
                                  isEmpty(data.Terms) ? (
                                    <div className="alert alert-warning mt-2 mb-2">
                                      <h5>{props.t("Required")}</h5>
                                      <ul>
                                        {authorize([Role.Dev]) &&
                                        isEmpty(data.Terms) ? (
                                          <li>
                                            <a
                                              className="ms-2"
                                              href={`/v1/coupons/create/step2/${params.id}?l=new`}
                                            >
                                              {props.t("Coupon Terms Required")}
                                              <i className="mdi mdi-cursor-default-click-outline ms-2 font-size-18"></i>
                                            </a>
                                          </li>
                                        ) : null}
                                        {authorize([Role.Dev]) &&
                                        isEmpty(
                                          data.ProductSelection?.searchType
                                        ) ? (
                                          <li>
                                            <a
                                              className="ms-2"
                                              href={`/v1/coupons/create/step4/${params.id}`}
                                            >
                                              {props.t(
                                                "ProductSelection Required"
                                              )}
                                              <i className="mdi mdi-cursor-default-click-outline ms-2 font-size-18"></i>
                                            </a>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  ) : null} */}

                                  {/* {authorize([Role.Dev])
                                    ? statusChangeButton()
                                    : null} */}
                                  {statusChangeButton()}
                                </Col>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

CouponDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CouponDetails)
