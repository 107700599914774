import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  get_K_CAT as onK_CAT,
  getGainSettings as onList,
  setGainSettings as onSave,
  companiesResetError as onResetError,
  companiesResetSuccess as onResetSuccess,
} from "store/actions"
import { isEmpty } from "lodash"
import Swal from "sweetalert2" 

//redux

const GainSettings = props => {
  const dispatch = useDispatch()
  const { k_cat, k_cat_sub, k_cat_sub_alt, gainSettings, success, error } =
    useSelector(state => ({
      k_cat: state.Companies.k_cat,
      k_cat_sub: state.Companies.k_cat_sub,
      k_cat_sub_alt: state.Companies.k_cat_sub_alt,
      gainSettings: state.Companies.gainSettings,
      product_definition: "",
      success: state.Companies.success,
      error: state.Companies.error,
    }))

  const defaultItem = {
    id: null,
    k_cat: null,
    k_cat_sub: null,
    k_cat_sub_alt: null,
    gainType: "",
    gain: 0,
  }
  const [_body, setBody] = useState([
    {
      id: null,
      k_cat: null,
      k_cat_sub: null,
      k_cat_sub_alt: null,
      gainType: "",
      gain: 0,
    },
  ])
  //meta title
  document.title = "Kazanım Ayarları"
  useEffect(() => {
    if (isEmpty(k_cat)) {
      dispatch(onK_CAT())
    }
  }, [])

  useEffect(() => {
    if (isEmpty(gainSettings)) {
      dispatch(onList())
      console.log('s1')
    } else if (isEmpty(gainSettings)) {
      setBody([defaultItem])
      console.log('s2', gainSettings)
    } else if (JSON.stringify(_body) == JSON.stringify([defaultItem])) {
      setBody(gainSettings)
      console.log('s3')
    }

    if (!isEmpty(success)) {
      Swal.fire("İşlem Başarılı", success, "success").then(result => {
        if (result.isConfirmed) {
          dispatch(onResetSuccess())
        }
      })
    }
  }, [dispatch, gainSettings, success])

  useEffect(() => {
    if (!isEmpty(error)) {
      Swal.fire("Hata", error?.error || JSON.stringify(error), "error").then(
        result => {
          if (result.isConfirmed) {
            dispatch(onResetError())
          }
        }
      )
    }
  }, [error])

  const changeInputMultiple = (objs, idx) => {
    let b = [..._body]
    for (let obj of objs) {
      if (obj.key.includes(".")) {
        let arr = obj.key.split(".")
        if (arr.length == 2) {
          b[idx][arr[0]][arr[1]] = obj.value
        } else if (arr.length == 3) {
          b[idx][arr[0]][arr[1]][arr[2]] = obj.value
        }
      } else {
        b[idx][obj.key] = obj.value
      }
    }
    setBody(b)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Settings")}
            breadcrumbItem={props.t("Kazanım Ayarları")}
          />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={2}>
                      <Label>{props.t("Sell Type")}</Label>
                    </Col>
                    <Col md={2}>
                      <Label>{props.t("Category")}</Label>
                    </Col>
                    <Col md={2}>
                      <Label>{props.t("SubCategory")}</Label>
                    </Col>
                    <Col md={2}>
                      <Label>{props.t("GainType")}</Label>
                    </Col>
                    <Col md={2}>
                      <Label>{props.t("Gain")}</Label>
                    </Col>
                    <Col md={2}>
                      <Label>{props.t("Add or Remove")}</Label>
                    </Col>
                  </Row>
                  {!isEmpty(_body)
                    ? _body.map((item, idx) => (
                        <Row key={idx} className="mb-2">
                          <Col md={2}>
                            <select
                              className="form-control"
                              value={item.k_cat || ""}
                              onChange={e => {
                                let v = e.target.value || undefined
                                v = v == "Seç" ? null : v
                                let _k_cat = k_cat.find(x => x._id == v).name

                                changeInputMultiple(
                                  [
                                    { key: "k_cat", value: v },
                                    { key: "k_cat_sub", value: null },
                                    { key: "k_cat_sub_alt", value: null },
                                    {
                                      key: "product_definition",
                                      value: `${_k_cat}`,
                                    },
                                  ],
                                  idx
                                )
                              }}
                            >
                              <option>{props.t("Select")}</option>
                              {k_cat
                                ? k_cat?.map((item, idx) => (
                                    <option
                                      key={idx}
                                      value={item._id?.toString()}
                                    >
                                      {item.name}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </Col>
                          <Col md={2}>
                            <select
                              className="form-control"
                              value={item.k_cat_sub || ""}
                              onChange={e => {
                                let v = e.target.value || undefined
                                v = v == "Seç" ? null : v
                                let _k_cat = k_cat.find(
                                  x => x._id == item.k_cat
                                ).name
                                let _k_cat_sub = k_cat_sub.find(
                                  x => x._id == v
                                ).name

                                changeInputMultiple(
                                  [
                                    { key: "k_cat_sub", value: v },
                                    { key: "k_cat_sub_alt", value: null },
                                    {
                                      key: "product_definition",
                                      value: `${_k_cat} > ${_k_cat_sub}`,
                                    },
                                  ],
                                  idx
                                )
                              }}
                            >
                              <option>{props.t("Select")}</option>
                              {k_cat_sub && item.k_cat
                                ? k_cat_sub
                                    ?.filter(
                                      x => x.k_cat == item.k_cat?.toString()
                                    )
                                    ?.map((item, idx) => (
                                      <option
                                        key={idx}
                                        value={item._id?.toString()}
                                      >
                                        {item.name}
                                      </option>
                                    ))
                                : null}
                            </select>
                          </Col>
                          <Col md={2}>
                            <select
                              className="form-control"
                              value={item.k_cat_sub_alt || ""}
                              onChange={e => {
                                let v = e.target.value || undefined
                                v = v == "Seç" ? null : v
                                let _k_cat = k_cat.find(
                                  x => x._id == item.k_cat
                                ).name
                                let _k_cat_sub = k_cat_sub.find(
                                  x => x._id == item.k_cat_sub
                                ).name
                                let _k_cat_sub_alt = k_cat_sub_alt.find(
                                  x => x._id == v
                                )

                                changeInputMultiple(
                                  [
                                    { key: "k_cat_sub_alt", value: v },
                                    {
                                      key: "product_definition",
                                      value: `${_k_cat} > ${_k_cat_sub}`,
                                    },
                                  ],
                                  idx
                                )
                              }}
                            >
                              <option>{props.t("Select")}</option>
                              {k_cat_sub && item.k_cat && item.k_cat_sub
                                ? k_cat_sub_alt
                                    ?.filter(
                                      x =>
                                        x.k_cat_sub ==
                                        item.k_cat_sub?.toString()
                                    )
                                    ?.map((_item, _idx) => (
                                      <option
                                        key={_idx}
                                        value={_item._id?.toString()}
                                      >
                                        {_item.name}
                                      </option>
                                    ))
                                : null}
                            </select>
                          </Col>
                          <Col md={2}>
                            <select
                              className="form-control"
                              value={item.gainType || ""}
                              onChange={e => {
                                let v = e.target.value || undefined
                                changeInputMultiple(
                                  [{ key: "gainType", value: v }],
                                  idx
                                )
                              }}
                            >
                              <option>{props.t("Select")}</option>
                              <option value={"percent"}>
                                {props.t("Percent")}
                              </option>
                              <option value={"TL"}>{props.t("TL")}</option>
                            </select>
                          </Col>
                          <Col md={2}>
                            <input
                              name={`gain[${idx}]`}
                              type="number"
                              step={"0.01"}
                              className="form-control"
                              value={item.gain}
                              onChange={e => {
                                let v = e.target.value || undefined
                                changeInputMultiple(
                                  [{ key: "gain", value: v }],
                                  idx
                                )
                              }}
                            />
                          </Col>
                          <Col md={2}>
                            <div className="font-size-24">
                              <a
                                className="me-2"
                                onClick={() => {
                                  let temp = [..._body]
                                  temp.push(defaultItem)
                                  setBody(temp)
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline text-success"></i>
                              </a>
                              {idx > 0 ? (
                                <a
                                  onClick={() => {
                                    let l = [..._body]
                                    l.splice(idx, 1)
                                    setBody(l)
                                  }}
                                >
                                  <i className="mdi mdi-minus-circle-outline text-danger"></i>
                                </a>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      ))
                    : null}
                  <a
                    className="btn btn-success mt-2"
                    onClick={() => {
                      dispatch(onSave({ data: _body }))
                    }}
                  >
                    {props.t("Save")}
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

GainSettings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(GainSettings)
